import React from 'react';

import {
    Row, Col,
    WidgetContainer,
    SimpleTableContainer,
    RadialGaugeResult,
    Chart,
    ChartTooltip,
    ChartSeries,
    ChartSeriesItem,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartValueAxis,
    ChartValueAxisItem,
    ChartAxisDefaults,
    ChartArea,
    RadialGauge,
    ChartLegend,
    ChartContainer
} from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../helpers/helpers';


const hidden = { visible: false };

const mmhgPlotBands = [
    {
        from: 0, to: 25, color: 'var(--color-success)', opacity: 1
    }, {
        from: 25, to: 56, color: 'var(--color-warning)', opacity: 1
    },
    {
        from: 56, to: 100, color: 'var(--color-danger)', opacity: 1
    }
];

const radialOptions = {
    shape: 'arrow',
    scale: {
        minorUnit: 20,
        majorUnit: 20,
        max: 800,
        min: 0,
        ranges: [
            { from: 0, to: 350, color: 'var(--color-danger)' },
            { from: 350, to: 660, color: 'var(--color-warning)' },
            { from: 500, to: 660, color: 'var(--color-good)' },
            { from: 660, to: 750, color: 'var(--color-success)' },
            { from: 750, to: 800, color: 'var(--color-excellent)' },
        ],
        rangeSize: 16,
        labels: {
            visible: false
        },
        majorTicks: { visible: true, color: "var(--color-text-muted)" },
        minorTicks: { visible: true, color: "var(--color-text-muted)" },
        startAngle: -50,
        endAngle: 230,
    },
    pointer: [{
        value: 703,
        color: 'var(--color-text)'
    }],
    transitions: true,
};

const tooltipRender = ({ point }: any) => {
    return (
        point && <div>
            <h3>{point.category} </h3>
            <p>Value: {point.value}</p>
        </div>)
}

const tooltipRenderBullet = ({ point }: any) => {
    const { value } = point;
    let statusText = 'Success';
    if (value.target > 26 && value.target < 56) {
        statusText = 'Warning'
    } else if (value.target > 56) {
        statusText = 'Danger'
    }

    return (
        <div>
            <h3>Current: {value.target}</h3>
            <p>Status: {statusText}</p>
        </div>
    )
};

const tooltipSingleColumn = ({ point }: any) => {
    return (
        point && <div>
            <p>Value: {point.series.labels.content()}</p>
        </div>)
};

const tooltipPieChart = ({ point }: any) => {
    return (
        point && <div>
            <p>{`${point.category}: ${Math.floor(point.percentage * 100).toString()}%`}</p>
        </div>)
};

const [firstSeries, secondSeries, thirdSeries, fourthSeries] = [[5], [2], [5], [4]];

const pieSeries = [
    { category: 'Least', value: 0.86, color: 'var(--wq-item-color-blue)' },
    { category: 'Vacant', value: 0.14, color: 'var(--color-success)' },
];

class DemoChartsPage extends React.Component {


    render() {

        return (
            <>
                <Row className="mt-l">
                    <Col xs="12" className="mb-s">
                        <WidgetContainer dataUI={getDataUI()}  title={<a href='/'>Combined Ratio</a>}>
                            <SimpleTableContainer dataUI={getDataUI()} >
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Ratio</th>
                                            <th>Value</th>
                                            <th>Chart</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Debt to income ratio</td>
                                            <td className="text-right">34.00%</td>
                                            <td className="text-right">
                                                <ChartContainer dataUI={getDataUI()}>
                                                    <Chart style={{ height: "18px", width: "100%" }} >
                                                        <ChartTooltip render={tooltipRenderBullet} />
                                                        <ChartArea background="transparent" margin={0} />
                                                        <ChartSeries>
                                                            <ChartSeriesItem
                                                                type="bullet"
                                                                color="var(--color-text)"
                                                                data={[[33, 34]]}
                                                                target={{ color: "var(--color-text)", line: { width: 2 } }}
                                                                highlight={{ visible: false }}
                                                            />
                                                        </ChartSeries>
                                                        <ChartCategoryAxis>
                                                            <ChartCategoryAxisItem majorGridLines={hidden} minorGridLines={hidden} visible={false} />
                                                        </ChartCategoryAxis>
                                                        <ChartValueAxis>
                                                            <ChartValueAxisItem majorGridLines={hidden} minorTicks={hidden} min={0} max={100} plotBands={mmhgPlotBands} labels={{ visible: false }} visible={false} />
                                                        </ChartValueAxis>
                                                    </Chart>
                                                </ChartContainer>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Debt to income ratio before</td>
                                            <td className="text-right">12.00%</td>
                                            <td className="text-right">
                                                <ChartContainer dataUI={getDataUI()} >
                                                    <Chart style={{ height: "18px", width: "100%" }} >
                                                        <ChartTooltip render={tooltipRenderBullet} />
                                                        <ChartArea background="transparent" margin={0} />
                                                        <ChartSeries>
                                                            <ChartSeriesItem
                                                                type="bullet"
                                                                color="var(--color-text)"
                                                                data={[[11, 12]]}
                                                                target={{ color: "var(--color-text)", line: { width: 2 } }}
                                                                highlight={{ visible: false }}
                                                            />
                                                        </ChartSeries>
                                                        <ChartCategoryAxis>
                                                            <ChartCategoryAxisItem majorGridLines={hidden} minorGridLines={hidden} visible={false} />
                                                        </ChartCategoryAxis>
                                                        <ChartValueAxis>
                                                            <ChartValueAxisItem majorGridLines={hidden} minorTicks={hidden} min={0} max={100} plotBands={mmhgPlotBands} labels={{ visible: false }} visible={false} />
                                                        </ChartValueAxis>
                                                    </Chart>
                                                </ChartContainer>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Available Revolving</td>
                                            <td className="text-right">16.00%</td>
                                            <td className="text-right">
                                                <ChartContainer dataUI={getDataUI()} >
                                                    <Chart style={{ height: "18px", width: "100%" }} >
                                                        <ChartTooltip render={tooltipRenderBullet} />
                                                        <ChartArea background="transparent" margin={0} />
                                                        <ChartSeries>
                                                            <ChartSeriesItem
                                                                type="bullet"
                                                                color="var(--color-text)"
                                                                data={[[15, 16]]}
                                                                target={{ color: "var(--color-text)", line: { width: 2 } }}
                                                                highlight={{ visible: false }}
                                                            />
                                                        </ChartSeries>
                                                        <ChartCategoryAxis>
                                                            <ChartCategoryAxisItem majorGridLines={hidden} minorGridLines={hidden} visible={false} />
                                                        </ChartCategoryAxis>
                                                        <ChartValueAxis>
                                                            <ChartValueAxisItem majorGridLines={hidden} minorTicks={hidden} min={0} max={100} plotBands={mmhgPlotBands} labels={{ visible: false }} visible={false} />
                                                        </ChartValueAxis>
                                                    </Chart>
                                                </ChartContainer>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Total Unsecured Bal / Anual Income</td>
                                            <td className="text-right">22.00%</td>
                                            <td className="text-right">
                                                <ChartContainer dataUI={getDataUI()} >
                                                    <Chart style={{ height: "18px", width: "100%" }} >
                                                        <ChartTooltip render={tooltipRenderBullet} visible={true} />
                                                        <ChartArea background="transparent" margin={0} />
                                                        <ChartSeries>
                                                            <ChartSeriesItem
                                                                type="bullet"
                                                                color="var(--color-text)"
                                                                data={[[21, 22]]}
                                                                target={{ color: "var(--color-text)", line: { width: 2 } }}
                                                                highlight={{ visible: false }}
                                                            />
                                                        </ChartSeries>
                                                        <ChartCategoryAxis>
                                                            <ChartCategoryAxisItem majorGridLines={hidden} minorGridLines={hidden} visible={false} />
                                                        </ChartCategoryAxis>
                                                        <ChartValueAxis>
                                                            <ChartValueAxisItem majorGridLines={hidden} minorTicks={hidden} min={0} max={100} plotBands={mmhgPlotBands} labels={{ visible: false }} visible={false} />
                                                        </ChartValueAxis>
                                                    </Chart>
                                                </ChartContainer>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Total Unsecured Bal / Total Debt</td>
                                            <td className="text-right">18.00%</td>
                                            <td className="text-right">
                                                <ChartContainer dataUI={getDataUI()} >
                                                    <Chart style={{ height: "18px", width: "100%" }} >
                                                        <ChartTooltip render={tooltipRenderBullet} />
                                                        <ChartArea background="transparent" margin={0} />
                                                        <ChartSeries>
                                                            <ChartSeriesItem
                                                                type="bullet"
                                                                color="var(--color-text)"
                                                                data={[[17, 18]]}
                                                                target={{ color: "var(--color-text)", line: { width: 2 } }}
                                                                highlight={{ visible: false }}
                                                            />
                                                        </ChartSeries>
                                                        <ChartCategoryAxis>
                                                            <ChartCategoryAxisItem majorGridLines={hidden} minorGridLines={hidden} visible={false} />
                                                        </ChartCategoryAxis>
                                                        <ChartValueAxis>
                                                            <ChartValueAxisItem majorGridLines={hidden} minorTicks={hidden} min={0} max={100} plotBands={mmhgPlotBands} labels={{ visible: false }} visible={false} />
                                                        </ChartValueAxis>
                                                    </Chart>
                                                </ChartContainer>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>XLTV</td>
                                            <td className="text-right">15.00%</td>
                                            <td className="text-right">
                                                <ChartContainer dataUI={getDataUI()} >
                                                    <Chart style={{ height: "18px", width: "100%" }} >
                                                        <ChartTooltip render={tooltipRenderBullet} />
                                                        <ChartArea background="transparent" margin={0} />
                                                        <ChartSeries>
                                                            <ChartSeriesItem
                                                                type="bullet"
                                                                color="var(--color-text)"
                                                                data={[[14, 15]]}
                                                                target={{ color: "var(--color-text)", line: { width: 2 } }}
                                                                highlight={{ visible: false }}
                                                            />
                                                        </ChartSeries>
                                                        <ChartCategoryAxis>
                                                            <ChartCategoryAxisItem majorGridLines={hidden} minorGridLines={hidden} visible={false} />
                                                        </ChartCategoryAxis>
                                                        <ChartValueAxis>
                                                            <ChartValueAxisItem majorGridLines={hidden} minorTicks={hidden} min={0} max={100} plotBands={mmhgPlotBands} labels={{ visible: false }} visible={false} />
                                                        </ChartValueAxis>
                                                    </Chart>
                                                </ChartContainer>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>CLTV</td>
                                            <td className="text-right">4.00%</td>
                                            <td className="text-right">
                                                <ChartContainer dataUI={getDataUI()} >
                                                    <Chart style={{ height: "18px", width: "100%" }} >
                                                        <ChartArea background="transparent" margin={0} />
                                                        <ChartTooltip render={tooltipRenderBullet} />
                                                        <ChartSeries>
                                                            <ChartSeriesItem
                                                                type="bullet"
                                                                color="var(--color-text)"
                                                                data={[[3, 4]]}
                                                                target={{ color: "var(--color-text)", line: { width: 2 } }}
                                                                highlight={{ visible: false }}
                                                            />
                                                        </ChartSeries>
                                                        <ChartCategoryAxis>
                                                            <ChartCategoryAxisItem majorGridLines={hidden} minorGridLines={hidden} visible={false} />
                                                        </ChartCategoryAxis>
                                                        <ChartValueAxis>
                                                            <ChartValueAxisItem majorGridLines={hidden} minorTicks={hidden} min={0} max={100} plotBands={mmhgPlotBands} labels={{ visible: false }} visible={false} />
                                                        </ChartValueAxis>
                                                    </Chart>
                                                </ChartContainer>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </SimpleTableContainer>
                        </WidgetContainer>
                    </Col>

                    <Col className="mb-s">
                        <WidgetContainer dataUI={getDataUI()}  title="Credit Score Range">
                            <ChartContainer dataUI={getDataUI()} >
                                <RadialGauge {...radialOptions} style={{ height: "300px" }} />
                                <RadialGaugeResult
                                    dataUI={getDataUI()} 
                                    pointerValue={radialOptions.pointer[0].value}
                                    description="Very Good"
                                />
                            </ChartContainer>
                        </WidgetContainer>
                    </Col>
                    <Col className="mb-s">
                        <WidgetContainer dataUI={getDataUI()}  title="Trade Line Summary">
                            <SimpleTableContainer dataUI={getDataUI()}  className="mb-xl">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Debt Type</th>
                                            <th>Payment</th>
                                            <th>Balance</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Installment</td>
                                            <td className="text-right">$412</td>
                                            <td className="text-right">$15,588</td>
                                        </tr>
                                        <tr>
                                            <td>Mortage</td>
                                            <td className="text-right">$2,143</td>
                                            <td className="text-right">$255,079</td>
                                        </tr>
                                        <tr>
                                            <td>Revolving</td>
                                            <td className="text-right">$70</td>
                                            <td className="text-right">$2,444</td>
                                        </tr>
                                        <tr>
                                            <td className="text-bold">Total</td>
                                            <td className="text-right text-bold">$2,625</td>
                                            <td className="text-right text-bold">$273,111</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </SimpleTableContainer>
                        </WidgetContainer>
                    </Col>
                    <Col className="mb-s">
                        <WidgetContainer dataUI={getDataUI()}  title="Assets vs. Liabilities - Donna Testcase ">
                            <ChartContainer dataUI={getDataUI()} >
                                <Chart style={{ height: "350px" }}>
                                    <ChartTooltip render={tooltipRender} />
                                    <ChartArea background="transparent" margin={0} />
                                    <ChartAxisDefaults
                                        labels={{ format: 'c0', font: "12px Roboto,sans-serif" }}
                                        color="var(--color-text-muted)"
                                        majorGridLines={{ color: "var(--color-2)" }}
                                    />
                                    <ChartCategoryAxis>
                                        <ChartCategoryAxisItem categories={['Total Assets', 'Total Liabilities', 'NetWorth']} />
                                    </ChartCategoryAxis>
                                    <ChartSeries>
                                        <ChartSeriesItem
                                            gap={5}
                                            type="column"
                                            border={{ width: 0 }}
                                            color="var(--color-primary)"
                                            data={[410000, 255000, 155000]}
                                            labels={{
                                                visible: true,
                                                format: 'c0',
                                                color: "var(--color-text)",
                                                background: "transparent",
                                                font: "bold 14px Roboto Condensed,sans-serif"
                                            }}
                                        />
                                    </ChartSeries>
                                </Chart>
                            </ChartContainer>
                        </WidgetContainer>
                    </Col>

                    <Col xs={12} className="mb-s">
                        <WidgetContainer dataUI={getDataUI()}  title="Rent Roll" >
                            <SimpleTableContainer dataUI={getDataUI()} >
                                <table>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Number of Units</th>
                                            <th>Anual Rent</th>
                                            <th>Leasable SF</th>
                                            <th>Rent SF(avg)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Total Lease</td>
                                            <td className="text-right">6</td>
                                            <td className="text-right">$79,796,416</td>
                                            <td className="text-right">38,414</td>
                                            <td className="text-right">$2,077.27</td>
                                        </tr>
                                        <tr>
                                            <td>Total Vacant</td>
                                            <td className="text-right">1</td>
                                            <td className="text-right">$325</td>
                                            <td className="text-right">2,324</td>
                                            <td className="text-right">$0,14</td>
                                        </tr>
                                        <tr>
                                            <td>Total Potential</td>
                                            <td className="text-right">7</td>
                                            <td className="text-right">$79,796,741</td>
                                            <td className="text-right">40,738</td>
                                            <td className="text-right">$1,958.78</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>
                                                <ChartContainer dataUI={getDataUI()} >
                                                    <Chart style={{ height: "250px", width: '100%' }}>
                                                        <ChartTooltip render={tooltipPieChart} />
                                                        <ChartArea background="transparent" margin={0} />
                                                        <ChartLegend
                                                            position="top"
                                                            labels={{
                                                                color: 'var(--color-text-muted)',
                                                                font: "12px Roboto,sans-serif",
                                                            }} />
                                                        <ChartSeries>
                                                            <ChartSeriesItem
                                                                type="pie"
                                                                data={pieSeries}
                                                                field="value"
                                                                categoryField="category"
                                                                labels={{
                                                                    visible: true,
                                                                    position: 'center',
                                                                    color: "var(--color-1)",
                                                                    background: "transparent",
                                                                    font: "bold 14px Roboto Condensed,sans-serif",
                                                                    content: (props: any) => {
                                                                        return `${Math.floor(props.percentage * 100).toString()}%`;
                                                                    }
                                                                }}
                                                            />
                                                        </ChartSeries>
                                                    </Chart>
                                                </ChartContainer>
                                            </td>
                                            <td>
                                                <ChartContainer dataUI={getDataUI()} >
                                                    <Chart style={{ height: "250px" }}>
                                                        <ChartTooltip render={tooltipSingleColumn} />
                                                        <ChartArea background="transparent" margin={0} />
                                                        <ChartAxisDefaults
                                                            labels={{ visible: false }}
                                                            color="var(--color-text-muted)"
                                                            majorGridLines={{ color: "var(--color-2)" }}
                                                        />
                                                        <ChartSeries>
                                                            <ChartSeriesItem
                                                                stack={true}
                                                                type="column"
                                                                data={firstSeries}
                                                                color="var(--wq-item-color-blue)"
                                                                border={{ width: 0 }}
                                                                highlight={{ visible: false }}
                                                                labels={{
                                                                    visible: true,
                                                                    padding: 0,
                                                                    content: () => '79,796,416',
                                                                    position: 'center',
                                                                    color: "var(--color-1)",
                                                                    background: "transparent",
                                                                    font: "bold 14px Roboto Condensed,sans-serif"
                                                                }}
                                                                gap={3}
                                                            />
                                                            <ChartSeriesItem
                                                                type="column"
                                                                data={secondSeries}
                                                                color={"var(--color-success)"}
                                                                border={{ width: 0 }}
                                                                highlight={{ visible: false }}
                                                                labels={{
                                                                    visible: true,
                                                                    padding: 0,
                                                                    content: () => '325',
                                                                    position: 'center',
                                                                    color: "var(--color-1)",
                                                                    background: "transparent",
                                                                    font: "bold 14px Roboto Condensed,sans-serif"
                                                                }}
                                                                gap={3}
                                                            />
                                                        </ChartSeries>
                                                    </Chart>
                                                </ChartContainer>
                                            </td>
                                            <td>
                                                <ChartContainer dataUI={getDataUI()} >
                                                    <Chart style={{ height: "250px" }}>
                                                        <ChartTooltip render={tooltipSingleColumn} />
                                                        <ChartArea background="transparent" margin={0} />
                                                        <ChartAxisDefaults
                                                            labels={{ visible: false }}
                                                            color="var(--color-text-muted)"
                                                            majorGridLines={{ color: "var(--color-2)" }}
                                                        />
                                                        <ChartSeries>
                                                            <ChartSeriesItem
                                                                stack={true}
                                                                type="column"
                                                                data={thirdSeries}
                                                                color="var(--wq-item-color-blue)"
                                                                border={{ width: 0 }}
                                                                highlight={{ visible: false }}
                                                                labels={{
                                                                    visible: true,
                                                                    padding: 0,
                                                                    content: () => '38,414',
                                                                    position: 'center',
                                                                    color: "var(--color-1)",
                                                                    background: "transparent",
                                                                    font: "bold 14px Roboto Condensed,sans-serif"
                                                                }}
                                                                gap={3}
                                                            />
                                                            <ChartSeriesItem
                                                                type="column"
                                                                data={fourthSeries}
                                                                color={"var(--color-success)"}
                                                                border={{ width: 0 }}
                                                                highlight={{ visible: false }}
                                                                labels={{
                                                                    visible: true,
                                                                    padding: 0,
                                                                    content: () => '2,324',
                                                                    position: 'center',
                                                                    color: "var(--color-1)",
                                                                    background: "transparent",
                                                                    font: "bold 14px Roboto Condensed,sans-serif"
                                                                }}
                                                                gap={3}
                                                            />
                                                        </ChartSeries>
                                                    </Chart>
                                                </ChartContainer>
                                            </td>
                                            <td>
                                                <ChartContainer dataUI={getDataUI()} >
                                                    <Chart style={{ height: "250px" }}>
                                                        <ChartTooltip render={tooltipRender} />
                                                        <ChartArea background="transparent" margin={0} />
                                                        <ChartAxisDefaults
                                                            labels={{ format: 'c0', font: "12px Roboto,sans-serif" }}
                                                            color="var(--color-text-muted)"
                                                            majorGridLines={{ color: "var(--color-2)" }}
                                                        />
                                                        <ChartCategoryAxis>
                                                            <ChartCategoryAxisItem categories={['Total Leased', 'Total Vacant', 'Total Potential']} />
                                                        </ChartCategoryAxis>
                                                        <ChartSeries>
                                                            <ChartSeriesItem
                                                                gap={5}
                                                                type="column"
                                                                border={{ width: 0 }}
                                                                color="var(--wq-item-color-blue)"
                                                                data={[2077.27, 0.14, 1958.78]}
                                                                labels={{
                                                                    visible: true,
                                                                    format: `\${0:N2}`,
                                                                    color: "var(--color-text)",
                                                                    background: "transparent",
                                                                    font: "bold 14px Roboto Condensed,sans-serif"
                                                                }}
                                                            />
                                                        </ChartSeries>
                                                    </Chart>
                                                </ChartContainer>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </SimpleTableContainer>
                        </WidgetContainer>
                    </Col>

                    <Col xs={12} className="mb-xl">
                        <WidgetContainer dataUI={getDataUI()}  title="Past History">
                            <ChartContainer dataUI={getDataUI()} >
                                <Chart style={{ height: "350px" }}>
                                    <ChartTooltip render={tooltipRender} />
                                    <ChartArea background="transparent" margin={0} />
                                    <ChartAxisDefaults
                                        labels={{ font: "12px Roboto,sans-serif" }}
                                        color="var(--color-text-muted)"
                                        majorGridLines={{ color: "var(--color-2)" }}
                                    />
                                    <ChartValueAxis>
                                        <ChartValueAxisItem min={400} max={1000} />
                                    </ChartValueAxis>
                                    <ChartCategoryAxis>
                                        <ChartCategoryAxisItem categories={['Jul 20', 'Jul 20', 'Jul 20', 'Aug 20',]} />
                                    </ChartCategoryAxis>
                                    <ChartSeries>
                                        <ChartSeriesItem
                                            data={[680, 550, 450, 830]}
                                            type="line"
                                            color="var(--color-primary)"
                                            markers={{
                                                background: "var(--color-1)"
                                            }}
                                        />
                                    </ChartSeries>
                                </Chart>
                            </ChartContainer>
                        </WidgetContainer>
                    </Col>
                </Row>
            </>
        );
    }
}

export default DemoChartsPage;