import React from 'react';
import Highlight from 'react-highlight';

import { WizardStepsContainer, WizardStep, Button } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

class WizardStepsContainerPage extends React.Component {

    state = {
        currentStepIndex: 1
    }

    render() {

        const currentStepIndex = this.state.currentStepIndex;

        const previousStep = currentStepIndex > 0 ?
            <Button dataUI={getDataUI()} icon="fal fa-arrow-circle-left" btnType="secondary" onClick={() => this.setState({ currentStepIndex: currentStepIndex - 1 })}>Previous step</Button>
            : undefined;

        const nextStep = currentStepIndex === 2 ?
            undefined
            : <Button dataUI={getDataUI()} className="ml-s" icon="fal fa-arrow-circle-right" onClick={() => this.setState({ currentStepIndex: currentStepIndex + 1 })}  >Next step</Button>;


        return (
            <div>
                <h1 className="mb-l">WizardStepsContainer</h1>

                <h2 className="mb-m">WizardStepsContainer module components:</h2>
                <p className="mb-s"><strong><a href="#wizard-steps-container">WizardStepsContainer</a></strong> - The main wrapper component</p>
                <p className="mb-xl"><strong><a href="#wizard-step">WizardStep</a></strong> - Element that show the step with status.</p>

                <WizardStepsContainer
                    dataUI={getDataUI()}
                    title="Credit Check"
                    previousStep={previousStep}
                    nextStep={nextStep}
                    currentStepIndex={currentStepIndex}>
                    <WizardStep dataUI={getDataUI()} title="1. Credit History" status="success" icon="fal fa-history" onClick={() => this.setState({ currentStepIndex: 0 })} />
                    <WizardStep dataUI={getDataUI()} title="2. Debt Schedule" status="warning" icon="fal fa-calendar-alt" onClick={() => this.setState({ currentStepIndex: 1 })} />
                    <WizardStep dataUI={getDataUI()} title="3. Loan Options" icon="fal fa-tasks" onClick={() => this.setState({ currentStepIndex: 2 })} />
                    <WizardStep dataUI={getDataUI()} title="4. Underwriting Ratio" status="success" icon="fal fa-tachometer-alt" onClick={() => this.setState({ currentStepIndex: 3 })} disabled />
                </WizardStepsContainer>

                <Highlight className="React">
                    {
                        `
import React from 'react';

import { WizardStepsContainer, WizardStep, Button } from '@jkhy/vsg-loanvantage-design-system';

class Example extends React.Component {

    state = {
        currentStepIndex: 1
    }
    
    render() {
        
        const currentStepIndex = this.state.currentStepIndex;
    
        const previousStep = currentStepIndex > 0 ?
            <Button dataUI={getDataUI()} icon="fal fa-arrow-circle-left" btnType="secondary" onClick={
                () => this.setState({ currentStepIndex: currentStepIndex - 1 })}>Previous step</Button>
            : undefined;
    
        const nextStep = currentStepIndex === 2 ?
            undefined
            : <Button dataUI={getDataUI()} className="ml-s" icon="fal fa-arrow-circle-right" onClick={
                () => this.setState({ currentStepIndex: currentStepIndex + 1 })}>Next step</Button>;
        
            return (
            <WizardStepsContainer
                dataUI={getDataUI()}
                title="Credit Check"
                previousStep={previousStep}
                nextStep={nextStep}
                currentStepIndex={currentStepIndex}>
                <WizardStep dataUI={getDataUI()} title="1. Credit History" status="success" icon="fal fa-history" onClick={() => this.setState({ currentStepIndex: 0 })} />
                <WizardStep dataUI={getDataUI()} title="2. Debt Schedule" status="warning" icon="fal fa-calendar-alt" onClick={() => this.setState({ currentStepIndex: 1 })} />
                <WizardStep dataUI={getDataUI()} title="3. Loan Options" icon="fal fa-tasks" onClick={() => this.setState({ currentStepIndex: 2 })} />
                <WizardStep dataUI={getDataUI()} title="4. Underwriting Ratio" status="success" icon="fal fa-tachometer-alt" 
                    onClick={() => this.setState({ currentStepIndex: 3 })} disabled />
            </WizardStepsContainer>
        );
    }
}

export default Example;
            `}
                </Highlight>

                <h2 className="mb-m"> API</h2>

                <p className="mb-xl">WizardStep component has React properties
                    which are prefixed with an underscore. <strong style={{ color: 'var(--color-warning)' }}>They must not be taken as public API.</strong></p>

                <h3 id="wizard-steps-container">WizardStepsContainer</h3>
                <table className="doc-table mb-l">
                    <thead>
                        <tr>
                            <th colSpan={4}>Props</th>
                        </tr>
                        <tr>
                            <td>
                                <strong>Name</strong>
                            </td>
                            <td>
                                <strong>Options</strong>
                            </td>
                            <td>
                                <strong>Default</strong>
                            </td>
                            <td>
                                <strong>Description</strong>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>title</td>
                            <td>string</td>
                            <td className="text-center">-</td>
                            <td>Sets the title.</td>
                        </tr>
                        <tr>
                            <td>previousStep</td>
                            <td>JSX.Element</td>
                            <td className="text-center">-</td>
                            <td>Sets the element for previousStep.</td>
                        </tr>
                        <tr>
                            <td>nextStep</td>
                            <td>JSX.Element</td>
                            <td className="text-center">-</td>
                            <td>Sets the element for nextStep.</td>
                        </tr>
                        <tr>
                            <td>currentStepIndex</td>
                            <td>number</td>
                            <td className="text-center">-</td>
                            <td>The indicator for the currently active index.</td>
                        </tr>
                    </tbody>
                </table>

                <h3 id="wizard-step">WizardStep</h3>
                <table className="doc-table mb-l">
                    <thead>
                        <tr>
                            <th colSpan={4}>Props</th>
                        </tr>
                        <tr>
                            <td>
                                <strong>Name</strong>
                            </td>
                            <td>
                                <strong>Options</strong>
                            </td>
                            <td>
                                <strong>Default</strong>
                            </td>
                            <td>
                                <strong>Description</strong>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>title</td>
                            <td>string</td>
                            <td className="text-center">-</td>
                            <td>Sets the title.</td>
                        </tr>
                        <tr>
                            <td>icon</td>
                            <td>string</td>
                            <td className="text-center">-</td>
                            <td>Set the icon.</td>
                        </tr>
                        <tr>
                            <td>status</td>
                            <td>"success" or "warning"</td>
                            <td className="text-center">-</td>
                            <td>Sets the status for the step.</td>
                        </tr>
                        <tr>
                            <td>onClick</td>
                            <td>() {'=>'} void</td>
                            <td className="text-center">-</td>
                            <td>Callback when the step is clicked.</td>
                        </tr>
                        <tr>
                            <td>disabled</td>
                            <td>boolean</td>
                            <td>false</td>
                            <td>Disables the step.</td>
                        </tr>
                    </tbody>
                </table>
            </div >
        )
    }
};

export default WizardStepsContainerPage;
