import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Highlight from 'react-highlight';

import {
    Tabs, TabList, TabItem, TabContent, TabContentItem,
    TabItemTitle, TabItemDetails, TabItemRow, Button,
    VerticalTabs, VerticalTabList, VerticalTabItem,
    VerticalTabContent, VerticalTabContentItem,
    Tooltip,
} from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';


const TabsPage: React.FC = () => {
    const [tabIndex, setTabindex] = useState(0);
    const [largetabIndex, setLargeTabindex] = useState(0);
    const [verticalTabIndex, setVerticalTabindex] = useState(0);

    return (
        <div>
            <h1 className="mb-s">Tabs</h1>

            <p className="mb-s">
                Tabs component provides tab navigation functionality for easy switching between views.
                There are regular and large Tabs variations.
            </p>

            <p className="mb-l">Tabs component has a specific architecture, that should be strictly followed in
                order to achieve its visual and functional design.
            </p>

            <h2 className="mb-m">Tabs module components:</h2>
            <p className="mb-s"><strong><a href="#api-tabs">Tabs</a></strong> - The main wrapper component</p>
            <p className="mb-s"><strong>TabList</strong> - Only available child for Tabs, along with TabContent. Wraps all TabItem elements.</p>
            <p className="mb-s"><strong><a href="#api-tab-item">TabItem</a></strong> - An element, that triggers changes in the whole Tabs component.</p>
            <p className="mb-s"><strong><a href="#api-tab-item-title">TabItemTitle</a></strong> - The content wrapper inside TabItems.</p>
            <p className="mb-s"><strong>TabContent</strong> - Only available child for Tabs, along with TabList. Wraps all TabContentItem elements.</p>
            <p className="mb-s"><strong><a href="#api-tab-content-item">TabContentItem</a></strong> - The single views that are shown, depending on the currently active TabItem.</p>
            <p className="mb-s"><strong>TabItemDetails</strong> - Specific component for additional details in the TabItems, visible only in large tabs.</p>
            <p className="mb-xl"><strong><a href="#api-tab-item-row">TabItemRow</a></strong> - Specific component for table-like information inside TabItemDetails. Visible only in large tabs.</p>

            <p className="mb-xl">For <strong>vertical orientation</strong> of the Tabs, see <a href="#vertical-tabs">the detailed <strong>VerticalTabs</strong> documentation.</a></p>

            <p className="mb-s"><strong>Important: </strong>Tabs component expects the count
                of the TabItems to be equal to that of the TabContentItems. If a TabItem is flagged as a
                summary item, it is not considered in the total TabItems count.</p>
            <p className="mb-xl"><strong>Important: </strong>This component uses <Link to="/docs/tooltip">Tooltip</Link> and therefore requires the
                Tooltip component to be rendered in the current view.</p>

            <div className="background-color-1 mt-m" style={{ padding: '8px' }}>
                <h3 className="mb-m">Tabs</h3>
                <Tabs dataUI={getDataUI()} onTabIndexChange={(index: number) => setTabindex(index)} currentlyActiveTabIndex={tabIndex}>
                    <TabList dataUI={getDataUI()}>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>Request</TabItemTitle>
                        </TabItem>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>Borrowers</TabItemTitle>
                        </TabItem>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>Terms</TabItemTitle>
                        </TabItem>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>ARM Information</TabItemTitle>
                        </TabItem>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>Schedules</TabItemTitle>
                        </TabItem>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>Participations</TabItemTitle>
                        </TabItem>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}><Link to="?compliance">Compliance</Link></TabItemTitle>
                        </TabItem>
                    </TabList>
                    <TabContent dataUI={getDataUI()}>
                        <TabContentItem dataUI={getDataUI()}>Request tab content</TabContentItem>
                        <TabContentItem dataUI={getDataUI()}>Borrowers tab content</TabContentItem>
                        <TabContentItem dataUI={getDataUI()}>Terms tab content</TabContentItem>
                        <TabContentItem dataUI={getDataUI()}>ARM Information tab content</TabContentItem>
                        <TabContentItem dataUI={getDataUI()} isPrerendered>
                            Schedules tab content 
                            (when the tab is not active, this is rendered and invisible)
                        </TabContentItem>
                        <TabContentItem dataUI={getDataUI()}>Participations tab content</TabContentItem>
                        <TabContentItem dataUI={getDataUI()}>Compliance tab content</TabContentItem>
                    </TabContent>
                </Tabs>
                <h3 className="mt-xl mb-m">Large tabs</h3>
                <Tabs dataUI={getDataUI()} largeTabs onTabIndexChange={(index: number) => setLargeTabindex(index)} currentlyActiveTabIndex={largetabIndex}>
                    <TabList dataUI={getDataUI()}>
                        <TabItem dataUI={getDataUI()} isSummaryTabItem>
                            <TabItemTitle dataUI={getDataUI()}>Exposure</TabItemTitle>
                            <TabItemDetails dataUI={getDataUI()}>
                                <TabItemRow dataUI={getDataUI()} leftCol="Current Exposure:" rightCol="$5,620,105,142"></TabItemRow>
                                <TabItemRow dataUI={getDataUI()} leftCol="Proposed Changes:" rightCol="$0"></TabItemRow>
                                <TabItemRow dataUI={getDataUI()} leftCol="Plus Pending Approvals:" rightCol="$0"></TabItemRow>
                                <TabItemRow dataUI={getDataUI()} leftCol="Total Proposed Exposure:" rightCol="$5,620,105,142"></TabItemRow>
                            </TabItemDetails>
                        </TabItem>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()} icon={<Tooltip title="File">
                                <Button dataUI={getDataUI()} btnType="icon" icon="fal fa-file-alt" onClick={(e: any) => { e.stopPropagation(); console.log('Click on icon'); }} />
                            </Tooltip>}
                            >
                                Loans
                            </TabItemTitle>
                            <TabItemDetails dataUI={getDataUI()}>
                                <TabItemRow dataUI={getDataUI()} leftCol="Used:" rightCol="$1,575,892,358"></TabItemRow>
                                <TabItemRow dataUI={getDataUI()} leftCol="Available:" rightCol="$4,044,188,248"></TabItemRow>
                                <TabItemRow dataUI={getDataUI()} leftCol="Exposure:" rightCol="$5,620,080,605"></TabItemRow>
                            </TabItemDetails>
                        </TabItem>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>Deposits</TabItemTitle>
                            <TabItemDetails dataUI={getDataUI()}>
                                <TabItemRow dataUI={getDataUI()} leftCol="Curr:" rightCol="$903,035,336"></TabItemRow>
                                <TabItemRow dataUI={getDataUI()} leftCol="Avg:" rightCol="$903,040,599"></TabItemRow>
                                <TabItemRow dataUI={getDataUI()} leftCol="Exp:" rightCol="$24,537"></TabItemRow>
                            </TabItemDetails>
                        </TabItem>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>Other products</TabItemTitle>
                            <TabItemDetails dataUI={getDataUI()}>
                                <TabItemRow dataUI={getDataUI()} leftCol="Curr Bal:" rightCol="$0"></TabItemRow>
                                <TabItemRow dataUI={getDataUI()} leftCol="Exposure:" rightCol="$0"></TabItemRow>
                            </TabItemDetails>
                        </TabItem>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>Details</TabItemTitle>
                            <TabItemDetails dataUI={getDataUI()}>
                                <p>Custom content paragraph</p>
                            </TabItemDetails>
                        </TabItem>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>Parties</TabItemTitle>
                        </TabItem>
                    </TabList>
                    <TabContent dataUI={getDataUI()}>
                        <TabContentItem dataUI={getDataUI()}>Loans tab content</TabContentItem>
                        <TabContentItem dataUI={getDataUI()}>Deposits tab content</TabContentItem>
                        <TabContentItem dataUI={getDataUI()}>Checklist content</TabContentItem>
                        <TabContentItem dataUI={getDataUI()}>Other products tab content</TabContentItem>
                        <TabContentItem dataUI={getDataUI()}>Parties tab content</TabContentItem>
                    </TabContent>
                </Tabs>
            </div>


            <Highlight className="React">
                {
                    `
import React, { useState } from 'react';

// Third-party Link is for demonstration of links inside TabItem
import { Link } from 'react-router-dom';
import { getDataUI } from '../../helpers/helpers';

import {
    Tabs, TabList, TabItem, TabContent, TabContentItem,
    TabItemTitle, TabItemDetails, TabItemRow, Button, Tooltip,
} from '@jkhy/vsg-loanvantage-design-system';

class Example extends React.Component {

    const [tabIndex, setTabindex] = useState(0);
    const [largetabIndex, setLargeTabindex] = useState(0);

    render() {
        return (
            // Tabs
            <Tabs dataUI={getDataUI()} 
                onTabIndexChange={(index: number) => setTabindex(index)} 
                currentlyActiveTabIndex={tabIndex}
            >
            <TabList dataUI={getDataUI()}>
                <TabItem dataUI={getDataUI()}>
                    <TabItemTitle dataUI={getDataUI()}>Request</TabItemTitle>
                </TabItem>
                <TabItem dataUI={getDataUI()}>
                    <TabItemTitle dataUI={getDataUI()}>Borrowers</TabItemTitle>
                </TabItem>
                <TabItem dataUI={getDataUI()}>
                    <TabItemTitle dataUI={getDataUI()}>Terms</TabItemTitle>
                </TabItem>
                <TabItem dataUI={getDataUI()}>
                    <TabItemTitle dataUI={getDataUI()}>ARM Information</TabItemTitle>
                </TabItem>
                <TabItem dataUI={getDataUI()}>
                    <TabItemTitle dataUI={getDataUI()}>Schedules</TabItemTitle>
                </TabItem>
                <TabItem dataUI={getDataUI()}>
                    <TabItemTitle dataUI={getDataUI()}>Participations</TabItemTitle>
                </TabItem>
                <TabItem dataUI={getDataUI()}>
                    <TabItemTitle dataUI={getDataUI()}><Link to="?compliance">Compliance</Link></TabItemTitle>
                </TabItem>
            </TabList>
            <TabContent dataUI={getDataUI()}>
                <TabContentItem dataUI={getDataUI()}>Request tab content</TabContentItem>
                <TabContentItem dataUI={getDataUI()}>Borrowers tab content</TabContentItem>
                <TabContentItem dataUI={getDataUI()}>Terms tab content</TabContentItem>
                <TabContentItem dataUI={getDataUI()}>ARM Information tab content</TabContentItem>
                <TabContentItem dataUI={getDataUI()} isPrerendered>
                    Schedules tab content 
                    (when the tab is not active, this is rendered and invisible)
                </TabContentItem>
                <TabContentItem dataUI={getDataUI()}>Participations tab content</TabContentItem>
                <TabContentItem dataUI={getDataUI()}>Compliance tab content</TabContentItem>
            </TabContent>
        </Tabs>
        
        // Large tabs
        <Tabs dataUI={getDataUI()} 
            largeTabs onTabIndexChange={(index: number) => setLargeTabindex(index)} 
            currentlyActiveTabIndex={largetabIndex}
        >
            <TabList dataUI={getDataUI()}>
                <TabItem dataUI={getDataUI()} isSummaryTabItem>
                    <TabItemTitle dataUI={getDataUI()}>Exposure</TabItemTitle>
                    <TabItemDetails dataUI={getDataUI()}>
                        <TabItemRow dataUI={getDataUI()} leftCol="Current Exposure:" rightCol="$5,620,105,142"></TabItemRow>
                        <TabItemRow dataUI={getDataUI()} leftCol="Proposed Changes:" rightCol="$0"></TabItemRow>
                        <TabItemRow dataUI={getDataUI()} leftCol="Plus Pending Approvals:" rightCol="$0"></TabItemRow>
                        <TabItemRow dataUI={getDataUI()} leftCol="Total Proposed Exposure:" rightCol="$5,620,105,142"></TabItemRow>
                    </TabItemDetails>
                </TabItem>
                <TabItem dataUI={getDataUI()}>
                    <TabItemTitle dataUI={getDataUI()} icon={
                        <Tooltip title="File">
                            <Button dataUI={getDataUI()} btnType="icon" icon="fal fa-file-alt"
                                onClick={(e) => { e.stopPropagation(); console.log('Click on icon'); }} />
                        </Tooltip>
                        }
                    >
                        Loans
                    </TabItemTitle>
                    <TabItemDetails dataUI={getDataUI()}>
                        <TabItemRow dataUI={getDataUI()} leftCol="Used:" rightCol="$1,575,892,358"></TabItemRow>
                        <TabItemRow dataUI={getDataUI()} leftCol="Available:" rightCol="$4,044,188,248"></TabItemRow>
                        <TabItemRow dataUI={getDataUI()} leftCol="Exposure:" rightCol="$5,620,080,605"></TabItemRow>
                    </TabItemDetails>
                </TabItem>
                <TabItem dataUI={getDataUI()}>
                    <TabItemTitle dataUI={getDataUI()}>Deposits</TabItemTitle>
                    <TabItemDetails dataUI={getDataUI()}>
                        <TabItemRow dataUI={getDataUI()} leftCol="Curr:" rightCol="$903,035,336"></TabItemRow>
                        <TabItemRow dataUI={getDataUI()} leftCol="Avg:" rightCol="$903,040,599"></TabItemRow>
                        <TabItemRow dataUI={getDataUI()} leftCol="Exp:" rightCol="$24,537"></TabItemRow>
                    </TabItemDetails>
                </TabItem>
                <TabItem dataUI={getDataUI()}>
                    <TabItemTitle dataUI={getDataUI()}>Other products</TabItemTitle>
                    <TabItemDetails dataUI={getDataUI()}>
                        <TabItemRow dataUI={getDataUI()} leftCol="Curr Bal:" rightCol="$0"></TabItemRow>
                        <TabItemRow dataUI={getDataUI()} leftCol="Exposure:" rightCol="$0"></TabItemRow>
                    </TabItemDetails>
                </TabItem>
                <TabItem dataUI={getDataUI()}>
                    <TabItemTitle dataUI={getDataUI()}>Details</TabItemTitle>
                    <TabItemDetails dataUI={getDataUI()}>
                        <p>Custom content paragraph</p>
                    </TabItemDetails>
                </TabItem>
                <TabItem dataUI={getDataUI()}>
                    <TabItemTitle dataUI={getDataUI()}>Parties</TabItemTitle>
                </TabItem>
            </TabList>
            <TabContent dataUI={getDataUI()}>
                <TabContentItem dataUI={getDataUI()}>Loans tab content</TabContentItem>
                <TabContentItem dataUI={getDataUI()}>Deposits tab content</TabContentItem>
                <TabContentItem dataUI={getDataUI()}>Checklist content</TabContentItem>
                <TabContentItem dataUI={getDataUI()}>Other products tab content</TabContentItem>
                <TabContentItem dataUI={getDataUI()}>Parties tab content</TabContentItem>
            </TabContent>
        </Tabs>
        );
    }
}

export default Example;
        `}
            </Highlight>

            <h2 className="mb-m">API</h2>
            <p className="mb-xl">Some of the components in Tabs module have React properties
                which are prefixed with an underscore. <strong style={{ color: 'var(--color-warning)' }}>They must not be taken as public API.</strong></p>

            <h3 id="api-tabs">Tabs</h3>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>currentlyActiveTabIndex</td>
                        <td>number</td>
                        <td className="text-center">-</td>
                        <td>The indicator for the currently active index. This field is required.</td>
                    </tr>
                    <tr>
                        <td>onTabIndexChange</td>
                        <td>(index:number) {'=>'} void</td>
                        <td className="text-center">-</td>
                        <td>Callback when the active tab is changed. Returns the current active tab index.</td>
                    </tr>
                    <tr>
                        <td>largeTabs</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Defines the size of the Tabs.</td>
                    </tr>
                </tbody>
            </table>

            <h3 id="api-tab-item">TabItem</h3>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>isSummaryTabItem</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Marks the element as a summary TabItem.</td>
                    </tr>
                </tbody>
            </table>

            <h3 id="api-tab-item-title">TabItemTitle</h3>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>icon</td>
                        <td>ReactNode</td>
                        <td>null</td>
                        <td>Sets the passed in element as an
                            icon in the title of the TabItem. The most
                            suitable element to pass is a Button with <i>btnType</i> of 'icon'.
                        </td>
                    </tr>
                </tbody>
            </table>

            <h3 id="api-tab-content-item">TabContentItem</h3>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>isPrerendered</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>
                             Force eager render of the tab content. Useful when some logic needs to happen even the tab is not visible for instance form validation
                        </td>
                    </tr>
                </tbody>
            </table>

            <h3 id="api-tab-item-row">TabItemRow</h3>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>leftCol</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Sets the text content of the left column of the TabItemRow.</td>
                    </tr>
                    <tr>
                        <td>rightCol</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Sets the text content of the right column of the TabItemRow.</td>
                    </tr>
                </tbody>
            </table>

            <h1 className="mb-s" id="vertical-tabs">VerticalTabs</h1>
            <p className="mb-s">
                VerticalTabs component is similar to the Tabs, having almost the same structure and API,
                but visualized vertically.
            </p>

            <h2 className="mb-m">VerticalTabs module components:</h2>
            <p className="mb-s"><strong><a href="#api-vertical-tabs">VerticalTabs</a></strong> - The main wrapper component</p>
            <p className="mb-s"><strong>VerticalTabList</strong> - Only available child for VerticalTabs, along with VerticalTabContent. Wraps all VerticalTabItem elements.</p>
            <p className="mb-s"><strong>VerticalTabItem</strong> - An element that holds the tab title text and triggers changes in the whole VerticalTabs component.</p>
            <p className="mb-s"><strong>VerticalTabContent</strong> - Only available child for VerticalTabs, along with VerticalTabList. Wraps all VerticalTabContentItem elements.</p>
            <p className="mb-s"><strong>VerticalTabContentItem</strong> - The single views that are shown, depending on the currently active VerticalTabItem.</p>

            <div className="background-color-1 mt-m" style={{ padding: '8px' }}>
                <VerticalTabs dataUI={getDataUI()} currentlyActiveTabIndex={verticalTabIndex} onTabIndexChange={index => setVerticalTabindex(index)}> 
                    <VerticalTabList dataUI={getDataUI()}>
                        <VerticalTabItem dataUI={getDataUI()}>General</VerticalTabItem>
                        <VerticalTabItem dataUI={getDataUI()}>First Southeast Insurance Services, Inc</VerticalTabItem>
                        <VerticalTabItem dataUI={getDataUI()}>Collateral IUDF</VerticalTabItem>
                        <VerticalTabItem dataUI={getDataUI()}>Complience</VerticalTabItem>
                        <VerticalTabItem dataUI={getDataUI()}>Criticized Asset Data</VerticalTabItem>
                        <VerticalTabItem dataUI={getDataUI()}><Link to="?taskFlow">Task Flow Data</Link></VerticalTabItem>
                    </VerticalTabList>
                    <VerticalTabContent dataUI={getDataUI()}>
                        <VerticalTabContentItem dataUI={getDataUI()}>General content</VerticalTabContentItem>
                        <VerticalTabContentItem dataUI={getDataUI()}>
                            It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.
                        </VerticalTabContentItem>
                        <VerticalTabContentItem dataUI={getDataUI()}>Collateral content</VerticalTabContentItem>
                        <VerticalTabContentItem dataUI={getDataUI()}>Complience content</VerticalTabContentItem>
                        <VerticalTabContentItem dataUI={getDataUI()}>Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.</VerticalTabContentItem>
                        <VerticalTabContentItem dataUI={getDataUI()}>Task Flow Data content</VerticalTabContentItem>
                    </VerticalTabContent>
                </VerticalTabs>
            </div>

            <Highlight className="React">
                {
                    `
import React, { useState } from 'react';

// Third-party Link is for demonstration of links inside TabItem
import { Link } from 'react-router-dom';
import { getDataUI } from '../../helpers/helpers';

import {
    VertivalTabs, VertivalTabList, VertivalTabItem,
    VertivalTabContent, VertivalTabContentItem,
} from '@jkhy/vsg-loanvantage-design-system';

class Example extends React.Component {

    const [verticalTabIndex, setVerticalTabindex] = useState(0);

    render() {
        return (
            <VerticalTabs 
                dataUI={getDataUI()}  
                currentlyActiveTabIndex={verticalTabIndex} 
                onTabIndexChange={index => setVerticalTabindex(index)}
            >
                <VerticalTabList dataUI={getDataUI()}>
                    <VerticalTabItem dataUI={getDataUI()}>General</VerticalTabItem>
                    <VerticalTabItem dataUI={getDataUI()}>First Southeast Insurance Services, Inc</VerticalTabItem>
                    <VerticalTabItem dataUI={getDataUI()}>Collateral IUDF</VerticalTabItem>
                    <VerticalTabItem dataUI={getDataUI()}>Complience</VerticalTabItem>
                    <VerticalTabItem dataUI={getDataUI()}>Criticized Asset Data</VerticalTabItem>
                    <VerticalTabItem dataUI={getDataUI()}><Link to="?taskFlow">Task Flow Data</Link></VerticalTabItem>
                </VerticalTabList>
                <VerticalTabContent dataUI={getDataUI()}>
                    <VerticalTabContentItem dataUI={getDataUI()}>General content</VerticalTabContentItem>
                    <VerticalTabContentItem dataUI={getDataUI()}>It is a long established fact that a reader will...</VerticalTabContentItem>
                    <VerticalTabContentItem dataUI={getDataUI()}>Collateral content</VerticalTabContentItem>
                    <VerticalTabContentItem dataUI={getDataUI()}>Complience content</VerticalTabContentItem>
                    <VerticalTabContentItem dataUI={getDataUI()}>Lorem Ipsum comes from sections...</VerticalTabContentItem>
                    <VerticalTabContentItem dataUI={getDataUI()}>Task Flow Data content</VerticalTabContentItem>
                </VerticalTabContent>
            </VerticalTabs>
        );
    }
}

export default Example;
        `}
            </Highlight>

            <h2 className="mb-m">API</h2>
            <p className="mb-xl">Some of the components in VerticalTabs module have React properties
                which are prefixed with an underscore. <strong style={{ color: 'var(--color-warning)' }}>They must not be taken as public API.</strong></p>

            <h3 id="api-vertical-tabs">VerticalTabs</h3>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>currentlyActiveTabIndex</td>
                        <td>number</td>
                        <td className="text-center">-</td>
                        <td>The indicator for the currently active index. This field is required.</td>
                    </tr>
                    <tr>
                        <td>onTabIndexChange</td>
                        <td>(index:number) {'=>'} void</td>
                        <td className="text-center">-</td>
                        <td>Callback when the active tab is changed. Returns the current active tab index.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
};

export default TabsPage;
