import React from "react";
import {
    ReactAux, MainContainerHeader, ActionContainer,
    Button, ActionContainerHeader, ActionContainerCrumbs, MainContainerScrollable, Toolbar, Input, SimpleTableContainer,
    Tooltip
} from '@jkhy/vsg-loanvantage-design-system';
import KendoTableMultipleHeaders from './KendoTable/KendoTableMultipleHeaders';
import { getDataUI } from "../helpers/helpers";

const DemoOpenApi = (props: any) => {

    return (
        <ReactAux>
            <MainContainerHeader dataUI={getDataUI()}>
                <ActionContainer dataUI={getDataUI()}>
                    <ActionContainerHeader dataUI={getDataUI()}>
                        <ActionContainerCrumbs dataUI={getDataUI()} crumbs={[<a href="/">Home</a>, 'Integrations', 'LoanVantage Open API']} />
                    </ActionContainerHeader>
                </ActionContainer>
            </MainContainerHeader>

            <MainContainerScrollable>
                <div className="section-container mt-m mb-m">
                    <h2 className="mb-m">Source</h2>
                    <Toolbar
                        className="mb-m"
                        dataUI={getDataUI()}
                        leftSide={
                            <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-plus" btnType="secondary">Add Source</Button>
                        }
                    />

                    <SimpleTableContainer dataUI={getDataUI()} >
                        <table>
                            <thead>
                                <tr>
                                    <th style={{ width: 42 }}></th>
                                    <th>Source</th>
                                    <th>Operation</th>
                                    <th>End Point</th>
                                </tr>

                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className='d-flex justify-content-center'>
                                            <Tooltip title="Delete">
                                                <Button
                                                    btnType="icon"
                                                    dataUI={getDataUI()}
                                                    icon='fal fa-trash'
                                                />
                                            </Tooltip>
                                        </div>
                                    </td>
                                    <td>Indirect</td>
                                    <td>Decision Upodate</td>
                                    <td>some URL</td>
                                </tr>
                            </tbody>
                        </table>
                    </SimpleTableContainer>

                </div>

                <div className="section-container flex-full-height">
                    <h2 className="mb-m">API Matrix</h2>
                    <Toolbar
                        className="mb-m"
                        dataUI={getDataUI()}
                        leftSide={
                            <>
                                <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-plus" btnType="secondary">Add API Matrix</Button>
                                <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-file-export" btnType="secondary">Export</Button>
                                <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-file-import" btnType="secondary">Import</Button>
                                <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-file-pdf" btnType="secondary">Download Pdf</Button>
                                <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-file-excel" btnType="secondary">Download Excel</Button>
                                <Input dataUI={getDataUI()} icon="fal fa-search" className="width-m" />
                            </>
                        }
                    />

                    {/* <MainContainerScrollable> */}
                    <KendoTableMultipleHeaders />
                    {/* </MainContainerScrollable> */}
                </div>
            </MainContainerScrollable>
        </ReactAux>
    )
}

export default DemoOpenApi