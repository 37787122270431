import React from 'react';

import {
    MainContainerScrollable, Button, Checkbox, Select, DragNDropSection, Droparea, DraggableItem, DragNDropContext,
    DraggableItemActions, DraggableItemTitle, DraggableItemContainer, DragNDropUtils, DynamicScrollableContainer, DragNDropSectionHeader,
    ActionContainer, ActionContainerHeader, ActionContainerCrumbs, MainContainerHeader,
    Row, Col, Tooltip
} from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

type DnDPresentationReportProps = {
    asyncData: boolean
};

type DnDPresentationReportState = {
    availableItems: { title: string, id: string }[],
    selectedItems: { title: string, id: string, selected: boolean, initial: boolean }[],
    dragStartEvent: Event | null,
    dragUpdateEvent: Event | null,
    selectValue1: string,
}

class DnDPresentationReport extends React.Component<DnDPresentationReportProps> {

    private static readonly AVAILABLE_SECTION_NAME = 'available';
    private static readonly SELECTED_SECTION_NAME = 'selected';

    state: DnDPresentationReportState = {
        availableItems: this.props.asyncData ? [] : [
            { title: 'Marisol Testcase', id: 'item-1' }, { title: 'Carlos Testcase', id: 'item-2' }, { title: 'Title', id: 'item-3' }, { title: 'Title 1 that is somehow very long text', id: 'item-4' }, { title: 'Title  2', id: 'item-5' }, { title: 'Carlos Testcase 2', id: 'item-6' },
            { title: 'Demo 1', id: 'item-7' }, { title: 'Demo 2', id: 'item-8' }, { title: 'Demo 3', id: 'item-9' }, { title: 'Demo 4', id: 'item-10' }, { title: 'Demo 5', id: 'item-11' }, { title: 'Demo 6', id: 'item-12' }, { title: 'Demo 7', id: 'item-13' }, { title: 'Demo 8', id: 'item-14' },
        ],
        selectedItems: this.props.asyncData ? [] : [{ title: 'Item 1', id: 'item-15', selected: false, initial: true }, { title: 'Item 2', id: 'item-16', selected: false, initial: true }],
        dragStartEvent: null,
        dragUpdateEvent: null,
        selectValue1: '1',
    };

    constructor(props: any) {
        super(props);
        if (this.props.asyncData) {
            setTimeout(() => {
                this.initializeData();
            }, 5000);
        }
    }

    // This method is invoked after some delay, when the asyncData flag is on,
    // to simulate asynchrounous data loading.
    private initializeData() {
        this.setState({
            availableItems: [
                { title: 'Marisol Testcase', id: 'item-1' }, { title: 'Carlos Testcase', id: 'item-2' }, { title: 'Title', id: 'item-3' }, { title: 'Title 1', id: 'item-4' }, { title: 'Title  2', id: 'item-5' }, { title: 'Carlos Testcase 2', id: 'item-6' },
                { title: 'Demo 1', id: 'item-7' }, { title: 'Demo 2', id: 'item-8' }, { title: 'Demo 3', id: 'item-9' }, { title: 'Demo 4', id: 'item-10' }, { title: 'Demo 5', id: 'item-11' }, { title: 'Demo 6', id: 'item-12' }, { title: 'Demo 7', id: 'item-13' }, { title: 'Demo 8', id: 'item-14' },
            ],
            availableClone: [{ title: 'Item 1', id: 'item-15', selected: false, initial: true }, { title: 'Item 2', id: 'item-16', selected: false, initial: true }]
        });
    }

    checkSelectAll(isChecked: boolean) {
        const { selectedItems } = this.state;
        let selectedClone = Array.from(selectedItems);
        selectedClone = selectedClone.map(e => { return { ...e, selected: isChecked } });
        this.setState({ selectedItems: selectedClone });
    }

    toggleCheckSelectedItem(index: number) {
        const { selectedItems } = this.state;
        const selectedClone = Array.from(selectedItems);
        selectedClone[index].selected = !selectedClone[index].selected;
        this.setState({ selectedItems: selectedClone });
    }

    moveAvailableItemToSelected(index: number) {
        const { availableItems, selectedItems } = this.state;
        const availableClone = Array.from(availableItems);
        const selectedClone = Array.from(selectedItems);
        const [removed] = availableClone.splice(index, 1);
        selectedClone.push({ ...removed, selected: false, initial: false });
        this.setState({ availableItems: availableClone, selectedItems: selectedClone });
    }

    deleteSelectedItem(index: number) {
        const { selectedItems } = this.state;
        const selectedClone = Array.from(selectedItems);
        selectedClone.splice(index, 1);
        this.setState({ selectedItems: selectedClone });
    }

    returnItemToAvailable(index: number) {
        const { availableItems, selectedItems } = this.state;
        const availableClone = Array.from(availableItems);
        const selectedClone = Array.from(selectedItems);
        const [returned] = selectedClone.splice(index, 1);
        availableClone.push({ ...returned });
        this.setState({ availableItems: availableClone, selectedItems: selectedClone });
    }

    getList(listId: string) {
        if (listId === DnDPresentationReport.AVAILABLE_SECTION_NAME) {
            return this.state.availableItems;
        } else if (listId === DnDPresentationReport.SELECTED_SECTION_NAME) {
            return this.state.selectedItems;
        }
        return null;
    }

    onDragEnd(result: any) {
        const { source, destination } = result;
        if (!result.destination) {
            this.setState({
                dragStartEvent: null,
                dragUpdateEvent: null,
            });
            return;
        }

        if (source.droppableId === destination.droppableId) {
            if (source.droppableId === DnDPresentationReport.AVAILABLE_SECTION_NAME) {
                const items = DragNDropUtils.reorderList(
                    this.state.availableItems,
                    source.index,
                    destination.index
                );
                this.setState({ availableItems: items });
            } else if (source.droppableId === DnDPresentationReport.SELECTED_SECTION_NAME) {
                const items = DragNDropUtils.reorderList(
                    this.state.selectedItems,
                    source.index,
                    destination.index
                );
                this.setState({ selectedItems: items });
            }
        } else {
            const sourceCloneArray = this.getList(source.droppableId);
            if (sourceCloneArray && destination.droppableId === DnDPresentationReport.SELECTED_SECTION_NAME) {
                (sourceCloneArray[source.index] as any).selected = false;
            }
            const result: any = DragNDropUtils.moveBetweenLists(
                sourceCloneArray || [],
                this.getList(destination.droppableId) || [],
                source.index,
                destination.index
            );

            if (source.droppableId === DnDPresentationReport.AVAILABLE_SECTION_NAME) {
                this.setState({ availableItems: result[0], selectedItems: result[1], })
            } else if (source.droppableId === DnDPresentationReport.SELECTED_SECTION_NAME) {
                this.setState({ selectedItems: result[0], availableItems: result[1], })
            }
        }

        this.setState({
            dragStartEvent: null,
            dragUpdateEvent: null,
        });
    }

    handleDragStart(event: any) {
        this.setState({
            dragStartEvent: event,
            dragUpdateEvent: null,
        });
    }

    handleDragUpdate(event: any) {
        this.setState({
            dragStartEvent: null,
            dragUpdateEvent: event
        });
    }

    render() {

        return (
            <>
                <MainContainerHeader dataUI={getDataUI()}>
                    <ActionContainer dataUI={getDataUI()}>
                        <ActionContainerHeader dataUI={getDataUI()}>
                            <ActionContainerCrumbs dataUI={getDataUI()} crumbs={['Presentation Report']} />
                        </ActionContainerHeader>
                        <div className="d-flex justify-content-between width-full">
                            <div>
                                <Button dataUI={getDataUI()} icon="fal fa-save">Save</Button>
                                <Button dataUI={getDataUI()} btnType="secondary" icon="fal fa-print">Print</Button>
                                <Button dataUI={getDataUI()} btnType="secondary" icon="fal fa-file-alt">On File</Button>
                                <Button dataUI={getDataUI()} btnType="secondary" icon="fal fa-file-alt">Gen Docs</Button>
                            </div>
                            <div className="d-flex align-items-center">
                                <Button dataUI={getDataUI()} icon="fal fa-download">Upload</Button>
                                <Button dataUI={getDataUI()} btnType="secondary" icon="fal fa-file-export">Export to Flo</Button>
                            </div>
                        </div>
                    </ActionContainer>
                </MainContainerHeader>
                <MainContainerScrollable>
                    <div className="d-flex align-items-center mt-m mb-m">
                        <p className="mr-s">Choose presentation report:</p>
                        <Select dataUI={getDataUI()}
                            className="width-m"
                            options={[{ value: '1', label: 'Historical Cash flow' }, { value: '2', label: 'test 2' }]}
                            value={this.state.selectValue1}
                            onChange={(obj: any) => this.setState({ selectValue1: obj.value })}
                        />
                    </div>
                    <DragNDropContext onDragStart={this.handleDragStart.bind(this)} onDragUpdate={this.handleDragUpdate.bind(this)} onDragEnd={this.onDragEnd.bind(this)}>
                        <Row>
                            <Col xs={6}>
                                <DynamicScrollableContainer dataUI={getDataUI()}>
                                    <DragNDropSection dataUI={getDataUI()}>
                                        <DragNDropSectionHeader dataUI={getDataUI()}>
                                            <h2>Available Report Items</h2>
                                        </DragNDropSectionHeader>
                                        <Droparea dataUI={getDataUI()} dropareaID={DnDPresentationReport.AVAILABLE_SECTION_NAME}
                                            dragStartEvent={this.state.dragStartEvent}
                                            dragUpdateEvent={this.state.dragUpdateEvent}
                                            allowedItems={[DnDPresentationReport.AVAILABLE_SECTION_NAME]}
                                        >
                                            {this.state.availableItems.map((item, index) => {
                                                return (
                                                    <DraggableItem dataUI={getDataUI()} key={item.title} index={index} draggableID={item.id}>
                                                        <DraggableItemActions dataUI={getDataUI()}>
                                                            <DraggableItemTitle dataUI={getDataUI()}>{item.title}</DraggableItemTitle>
                                                        </DraggableItemActions>
                                                        <DraggableItemActions dataUI={getDataUI()}>
                                                            {/* The title check below is just for demo purpose, to prevent rendering Selects
                                                            on all elements */}
                                                            {item.title.toLowerCase().includes('title') && <Select dataUI={getDataUI()} className="width-m" placeholder="Select" options={[{ label: 'Test 1', value: 'test 1' }, { label: 'Test 2', value: 'test 2' }]} value="" />}
                                                            <Tooltip title="Move">
                                                                <Button dataUI={getDataUI()} onClick={() => this.moveAvailableItemToSelected(index)} btnType="icon" icon="fal fa-arrow-right" />
                                                            </Tooltip>
                                                        </DraggableItemActions>
                                                    </DraggableItem>
                                                )
                                            })}
                                        </Droparea>
                                    </DragNDropSection>
                                </DynamicScrollableContainer>
                            </Col>

                            <Col xs={6}>
                                <DynamicScrollableContainer dataUI={getDataUI()}>
                                    <DragNDropSection dataUI={getDataUI()}>
                                        <DragNDropSectionHeader dataUI={getDataUI()}>
                                            <h2 className="mb-m">Selected Report Items</h2>
                                            <div className="d-flex justify-content-between align-items-center mb-m">
                                                {/* <Checkbox checked={this.state.selectedItems.length > 0 && this.state.selectedItems.filter(e => !e.selected).length === 0} onChange={(event) => { this.checkSelectAll(event.target.checked) }}>Select all</Checkbox> */}
                                                <p>Select file for action: <strong>{this.state.selectedItems.filter(e => e.selected).length} items selected</strong></p>
                                            </div>
                                            <DraggableItemContainer dataUI={getDataUI()}>
                                                <Button dataUI={getDataUI()} className="width-full" icon="fal fa-paperclip">Attach a file</Button>
                                            </DraggableItemContainer>
                                        </DragNDropSectionHeader>
                                        <Droparea
                                            dataUI={getDataUI()}
                                            dropareaID={DnDPresentationReport.SELECTED_SECTION_NAME}
                                            callToActionText="Drop items here."
                                            dragStartEvent={this.state.dragStartEvent}
                                            dragUpdateEvent={this.state.dragUpdateEvent}
                                            allowedItems={[DnDPresentationReport.AVAILABLE_SECTION_NAME, DnDPresentationReport.SELECTED_SECTION_NAME]}
                                        >
                                            {this.state.selectedItems.map((item, index) => {
                                                return (
                                                    <DraggableItem dataUI={getDataUI()} key={item.title} index={index} draggableID={item.id}>
                                                        <DraggableItemActions dataUI={getDataUI()}>
                                                            <Checkbox dataUI={getDataUI()} checked={item.selected} onChange={() => { this.toggleCheckSelectedItem(index) }} />
                                                            <DraggableItemTitle dataUI={getDataUI()}>{item.title}</DraggableItemTitle>
                                                        </DraggableItemActions>
                                                        <DraggableItemActions dataUI={getDataUI()}>
                                                            {item.initial ?
                                                                (<>
                                                                    <Tooltip title="Edit">
                                                                        <Button dataUI={getDataUI()} btnType="icon" icon="fal fa-pen" />
                                                                    </Tooltip>
                                                                    <Tooltip title="Delete">
                                                                        <Button dataUI={getDataUI()} onClick={() => this.deleteSelectedItem(index)} btnType="icon" icon="fal fa-trash" />
                                                                    </Tooltip>
                                                                </>)
                                                                :
                                                                <Tooltip title="Remove">
                                                                    <Button dataUI={getDataUI()} onClick={() => this.returnItemToAvailable(index)} btnType="icon" icon="fal fa-times" />
                                                                </Tooltip>
                                                            }
                                                        </DraggableItemActions>
                                                    </DraggableItem>
                                                )
                                            })}
                                        </Droparea>
                                    </DragNDropSection>
                                </DynamicScrollableContainer>
                            </Col>
                        </Row>
                    </DragNDropContext>
                </MainContainerScrollable>
            </>
        );
    }
}

export default DnDPresentationReport;