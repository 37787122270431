import React, { useState } from 'react';
import { Button, Modal, Toolbar, Tabs, TabList, TabItem, TabItemTitle, TabContent, TabContentItem, ModalScrollableContainer } from '@jkhy/vsg-loanvantage-design-system';
import TestConditionsBox from './TestConditionsBox';
import ConditionsBox from './ConditionsBox';
import { getDataUI } from '../../helpers/helpers';
import ConditionDetailTabs from './ConditionDetailTabs';


const BusinessRulesModal = () => {
    const [isQBModalVisible, setIsQBModalVisible] = useState(false);
    const [isConditionDetailVisible, setIsConditionDetailVisible] = useState(false);
    const [tabIndex, setTabindex] = useState(0);
    return (
        <>
            <Button className="width-l mb-s" icon="fal fa-database" onClick={() => setIsQBModalVisible(true)} dataUI={getDataUI()}>Open Business Rules Modal</Button>
            <Button className="width-l" icon="fal fa-list" onClick={() => setIsConditionDetailVisible(true)} dataUI={getDataUI()}>Condition Detail Modal</Button>
            <Modal title="Edit ‘Elena’s Branch’ Business Rule" isVisible={isQBModalVisible} size="L" onClose={() => setIsQBModalVisible(false)} dataUI={getDataUI()}>
                <Toolbar className="mb-s" dataUI={getDataUI()} leftSide={(
                    <>
                        <Button className="mr-s" icon="fal fa-save" dataUI={getDataUI()}>Save</Button>
                        <Button btnType="secondary" icon="fal fa-times-circle" dataUI={getDataUI()}>Cancel</Button>
                    </>
                )}>
                </Toolbar>

                <Tabs
                    onTabIndexChange={(index: number) => setTabindex(index)}
                    currentlyActiveTabIndex={tabIndex}
                    dataUI={getDataUI()}
                >
                    <TabList dataUI={getDataUI()}>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>Information</TabItemTitle>
                        </TabItem>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>Conditions</TabItemTitle>
                        </TabItem>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>Usage</TabItemTitle>
                        </TabItem>
                    </TabList>
                    <TabContent dataUI={getDataUI()}>
                        <TabContentItem dataUI={getDataUI()}>Information tab content</TabContentItem>
                        <TabContentItem dataUI={getDataUI()}>
                            <ModalScrollableContainer>
                                <ConditionsBox />
                                <TestConditionsBox />
                            </ModalScrollableContainer>
                        </TabContentItem>
                        <TabContentItem dataUI={getDataUI()}>Usage tab content</TabContentItem>
                    </TabContent>
                </Tabs>
            </Modal>

            <Modal title="Condition Detail" isVisible={isConditionDetailVisible} size="L" onClose={() => setIsConditionDetailVisible(false)} dataUI={getDataUI()}>
                <Toolbar className="mb-s" dataUI={getDataUI()} leftSide={(
                    <>
                        <Button className="mr-s" icon="fal fa-save" dataUI={getDataUI()}>Save</Button>
                        <Button btnType="secondary" icon="fal fa-times-circle" dataUI={getDataUI()}>Cancel</Button>
                    </>
                )}>
                </Toolbar>
                <ConditionDetailTabs />
            </Modal>
        </>
    );
};

export default BusinessRulesModal;