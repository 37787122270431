import React from 'react';

import {
    Button, Input, DragNDropSection, Droparea, DraggableItem, DragNDropContext,
    DraggableItemActions, DraggableItemTitle, DragNDropUtils, DynamicScrollableContainer, DragNDropSectionHeader,
    Row, Col, Tooltip, Tabs, TabList, TabItem, TabContent, TabContentItem, TabItemTitle,
} from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

type DnDPrerenderedTabsState = {
    availableItems: { title: string, id: string, type: string }[],
    assignedItems: { title: string, id: string, type: string }[],
    dragStartEvent: Event | null,
    dragUpdateEvent: Event | null,
    availableSearch: string,
    assignedSearch: string,
    tabIndex: number
}

const defaultGeneralAvailable = [
    { title: 'Analysis', id: 'item-1' }, { title: 'Branch', id: 'item-2' }, { title: 'CD Completed', id: 'item-3' }, { title: 'CD Required', id: 'item-4' },
    { title: 'CBA Completed', id: 'item-5' }, { title: 'CBA Required', id: 'item-6' }, { title: 'HDMA Reportable Closing Percent', id: 'item-7' }, { title: 'Competition', id: 'item-8' },
    { title: 'Create Date', id: 'item-9' }, { title: 'Days in Decisioning', id: 'item-10' }, { title: 'Days in Last Review', id: 'item-11' }, { title: 'General item 1', id: 'item-12' },
    { title: 'General item 2', id: 'item-13' }, { title: 'General item 3', id: 'item-14' }, { title: 'General item 4', id: 'item-15' }, { title: 'General item 5', id: 'item-16' }, { title: 'General item 6', id: 'item-17' }
];

class DnDPrerenderedTabs extends React.Component {

    private static readonly AVAILABLE_SECTION_NAME = 'available';
    private static readonly ASSIGNED_SECTION_NAME = 'assigned';

    state: DnDPrerenderedTabsState = {
        availableItems: defaultGeneralAvailable.map(e => { return { ...e, type: DnDPrerenderedTabs.AVAILABLE_SECTION_NAME } }),
        assignedItems: [],
        dragStartEvent: null,
        dragUpdateEvent: null,
        availableSearch: '',
        assignedSearch: '',
        tabIndex: 0
    };

    moveAvailableItemToAssigned(index: number) {
        const { availableItems, assignedItems } = this.state;
        const assignedClone = Array.from(assignedItems);
        const availableClone = Array.from(availableItems);
        const [removed] = availableClone.splice(index, 1);
        assignedClone.push(removed);
        this.setState({ availableItems: availableClone, assignedItems: assignedClone });
    }

    returnItemToAvailable(index: number) {
        const { availableItems, assignedItems } = this.state;
        const assignedClone = Array.from(assignedItems);
        const availableClone = Array.from(availableItems);
        const [removed] = assignedClone.splice(index, 1);
        availableClone.push(removed);
        this.setState({ availableItems: availableClone, assignedItems: assignedClone });
    }

    addAllToAssigned() {
        const { availableItems, assignedItems } = this.state;
        let assignedClone = Array.from(assignedItems);
        let availableClone = Array.from(availableItems);
        assignedClone = [...assignedClone, ...availableClone];
        availableClone = [];

        this.setState({ availableItems: availableClone, assignedItems: assignedClone });
    }

    removeAllAssigned() {
        const { availableItems, assignedItems } = this.state;
        let availableClone = Array.from(availableItems);
        let assignedClone = Array.from(assignedItems);
        for (const item of assignedClone) {
            availableClone.push(item);
        }
        this.setState({
            availableItems: availableClone,
            assignedItems: []
        });
    }

    getList(listId: string) {
        if (listId === DnDPrerenderedTabs.AVAILABLE_SECTION_NAME) {
            return this.state.availableItems;
        } else if (listId === DnDPrerenderedTabs.ASSIGNED_SECTION_NAME) {
            return this.state.assignedItems;
        }
        return null;
    }

    onDragEnd(result: any) {
        const { source, destination } = result;
        if (!result.destination) {
            this.setState({
                dragStartEvent: null,
                dragUpdateEvent: null,
            });
            return;
        }

        if (source.droppableId === destination.droppableId) {
            if (source.droppableId === DnDPrerenderedTabs.AVAILABLE_SECTION_NAME) {
                const items = DragNDropUtils.reorderList(
                    this.state.availableItems,
                    source.index,
                    destination.index
                );
                this.setState({ availableItems: items });
            } else if (source.droppableId === DnDPrerenderedTabs.ASSIGNED_SECTION_NAME) {
                const items = DragNDropUtils.reorderList(
                    this.state.assignedItems,
                    source.index,
                    destination.index
                );
                this.setState({ assignedItems: items });
            }
        } else {
            const result: any = DragNDropUtils.moveBetweenLists(
                this.getList(source.droppableId) || [],
                this.getList(destination.droppableId) || [],
                source.index,
                destination.index
            );

            if (source.droppableId === DnDPrerenderedTabs.AVAILABLE_SECTION_NAME) {
                this.setState({ availableItems: result[0], assignedItems: result[1], })
            } else if (source.droppableId === DnDPrerenderedTabs.ASSIGNED_SECTION_NAME) {
                this.setState({ assignedItems: result[0], availableItems: result[1], })
            }
        }

        this.setState({
            dragStartEvent: null,
            dragUpdateEvent: null
        });
    }

    handleDragStart(event: any) {
        this.setState({
            dragStartEvent: event,
            dragUpdateEvent: null,
        });
    }

    handleDragUpdate(event: any) {
        this.setState({
            dragStartEvent: null,
            dragUpdateEvent: event,
        });
    }

    setSearchValue(sectionName: string, value: string) {
        let statePropName = '';
        switch (sectionName) {
            case DnDPrerenderedTabs.AVAILABLE_SECTION_NAME:
                statePropName = 'availableSearch';
                break;
            case DnDPrerenderedTabs.ASSIGNED_SECTION_NAME:
                statePropName = 'assignedSearch';
                break;
            default:
                return;
        }
        this.setState({ [statePropName]: value })
    }

    render() {

        return (
            <>
                <Tabs dataUI={getDataUI()}
                    onTabIndexChange={(index: number) => this.setState({ tabIndex: index })}
                    currentlyActiveTabIndex={this.state.tabIndex}
                >
                    <TabList dataUI={getDataUI()}>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>Request</TabItemTitle>
                        </TabItem>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>Borrowers</TabItemTitle>
                        </TabItem>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>Terms</TabItemTitle>
                        </TabItem>
                    </TabList>
                    <TabContent dataUI={getDataUI()}>
                        <TabContentItem dataUI={getDataUI()}>Request tab content</TabContentItem>
                        <TabContentItem dataUI={getDataUI()} isPrerendered>
                            <DragNDropContext onDragStart={this.handleDragStart.bind(this)} onDragUpdate={this.handleDragUpdate.bind(this)} onDragEnd={this.onDragEnd.bind(this)}>
                                <Row>
                                    <Col xs={6}>
                                        <DynamicScrollableContainer dataUI={getDataUI()}>
                                            <DragNDropSection dataUI={getDataUI()}>
                                                <DragNDropSectionHeader dataUI={getDataUI()}>
                                                    <div className="d-flex justify-content-between align-items-center mb-m">
                                                        <h2>Available ({this.state.availableItems.length})</h2>
                                                        <Button dataUI={getDataUI()} onClick={() => this.addAllToAssigned()} btnType="secondary" icon="fal fa-file-import">Add all to assigned</Button>
                                                    </div>
                                                    <Input
                                                        dataUI={getDataUI()}
                                                        name="input-icon"
                                                        value={this.state.availableSearch}
                                                        onChange={(ev) => this.setSearchValue(DnDPrerenderedTabs.AVAILABLE_SECTION_NAME, ev.target.value)}
                                                        icon='fal fa-search'
                                                    />
                                                </DragNDropSectionHeader>
                                                <Droparea dataUI={getDataUI()} dropareaID={DnDPrerenderedTabs.AVAILABLE_SECTION_NAME} allowedItems={[DnDPrerenderedTabs.AVAILABLE_SECTION_NAME, DnDPrerenderedTabs.ASSIGNED_SECTION_NAME]}
                                                    dragStartEvent={this.state.dragStartEvent}
                                                    dragUpdateEvent={this.state.dragUpdateEvent}
                                                >

                                                    {this.state.availableItems.map((item, index) => {
                                                        return (
                                                            !this.state.availableSearch || (this.state.availableSearch && item.title.includes(this.state.availableSearch)) ?
                                                                <DraggableItem dataUI={getDataUI()} key={item.title} index={index} draggableID={item.id}>
                                                                    <DraggableItemActions dataUI={getDataUI()}>
                                                                        <DraggableItemTitle dataUI={getDataUI()}>{item.title}</DraggableItemTitle>
                                                                    </DraggableItemActions>
                                                                    <DraggableItemActions dataUI={getDataUI()}>
                                                                        <Tooltip title="Move">
                                                                            <Button dataUI={getDataUI()} onClick={() => this.moveAvailableItemToAssigned(index)} btnType="icon" icon="fal fa-arrow-right" />
                                                                        </Tooltip>
                                                                    </DraggableItemActions>
                                                                </DraggableItem>
                                                                :
                                                                ''
                                                        )
                                                    })}
                                                </Droparea>
                                            </DragNDropSection>
                                        </DynamicScrollableContainer>
                                    </Col>

                                    <Col xs={6}>
                                        <DynamicScrollableContainer dataUI={getDataUI()}>
                                            <DragNDropSection dataUI={getDataUI()}>
                                                <DragNDropSectionHeader dataUI={getDataUI()}>
                                                    <div className="d-flex justify-content-between align-items-center mb-m">
                                                        <h2>Assigned ({this.state.assignedItems.length})</h2>
                                                        <Button dataUI={getDataUI()} onClick={() => this.removeAllAssigned()} btnType="secondary" icon="fal fa-file-import">Remove all to assigned</Button>
                                                    </div>
                                                    <Input
                                                        dataUI={getDataUI()}
                                                        name="input-icon"
                                                        value={this.state.assignedSearch}
                                                        onChange={(ev) => this.setSearchValue(DnDPrerenderedTabs.ASSIGNED_SECTION_NAME, ev.target.value)}
                                                        icon='fal fa-search'
                                                    />
                                                </DragNDropSectionHeader>
                                                <Droparea
                                                    dataUI={getDataUI()}
                                                    dropareaID={DnDPrerenderedTabs.ASSIGNED_SECTION_NAME}
                                                    allowedItems={[DnDPrerenderedTabs.ASSIGNED_SECTION_NAME, DnDPrerenderedTabs.AVAILABLE_SECTION_NAME]}
                                                    callToActionText="Drop your files here."
                                                    dragStartEvent={this.state.dragStartEvent}
                                                    dragUpdateEvent={this.state.dragUpdateEvent}
                                                >
                                                    {this.state.assignedItems.map((item, index) => {
                                                        return (
                                                            !this.state.assignedSearch || (this.state.assignedSearch && item.title.includes(this.state.assignedSearch)) ?
                                                                <DraggableItem dataUI={getDataUI()} key={item.title} index={index} draggableID={item.id}>
                                                                    <DraggableItemActions dataUI={getDataUI()}>
                                                                        <DraggableItemTitle dataUI={getDataUI()}>{item.title}</DraggableItemTitle>
                                                                    </DraggableItemActions>
                                                                    <DraggableItemActions dataUI={getDataUI()}>
                                                                        <Tooltip title="Remove">
                                                                            <Button dataUI={getDataUI()} onClick={() => this.returnItemToAvailable(index)} btnType="icon" icon="fal fa-times" />
                                                                        </Tooltip>
                                                                    </DraggableItemActions>
                                                                </DraggableItem>
                                                                :
                                                                ''
                                                        )
                                                    })}
                                                </Droparea>
                                            </DragNDropSection>
                                        </DynamicScrollableContainer>
                                    </Col>
                                </Row>
                            </DragNDropContext>
                        </TabContentItem>
                        <TabContentItem dataUI={getDataUI()}>Terms tab content</TabContentItem>
                    </TabContent>
                </Tabs>


            </>
        );
    }
}

export default DnDPrerenderedTabs;