import React from 'react';
import Highlight from 'react-highlight';

class LoaderPage extends React.Component {

    render() {
        return (
            <div>
                <h1 className="mb-s">Loader</h1>

                <p className="mb-l">Indicates the loading state of a component or page.</p>

                <div className="mb-m">
                    <div className="lv-loader">
                        <div /><div /><div />
                    </div>
                </div>
                <div className="mb-m">
                    <div className="lv-loader-container">
                        <div className="lv-loader-big">
                            <div /><div /><div /><div /><div /><div /><div /><div />
                        </div>
                    </div>
                </div>

                <Highlight className="React">
                    {
                        `
import React from 'react';

class Example extends React.Component {    
    render() {
        return (
            <div>
                <div className="lv-loader">
                    <div /><div /><div />
                </div>

                <div className="lv-loader-container">
                    <div className="lv-loader-big">
                        <div /><div /><div /><div /><div /><div /><div /><div />
                    </div>
                </div>
            </div>
        );
    }
}

export default Example;
            `}
                </Highlight>
            </div >
        )
    }
};

export default LoaderPage;
