import React from 'react';
import Highlight from 'react-highlight';
import { ReactAux } from '@jkhy/vsg-loanvantage-design-system';

const ReactAuxPage: React.FC = () => {

    return <div>
        <h1 className="mb-s">ReactAux</h1>
        <p className="mb-l">A self-eradicating component for rendering multiple elements. Since version 16.2.0 React 
        supports offers <a href="https://reactjs.org/docs/fragments.html#short-syntax" target="_blank"  rel="noopener noreferrer">shorthand fragment syntax</a>, which
        makes ReactAux necessary only if the goal is older version support.</p>

        <ReactAux>
            <p>Hello, World!</p>
            <p>I am a demo for ReactAux.</p>
        </ReactAux>

        <Highlight className="React">
            {
                `
import React from 'react';
import { ReactAux } from 'vsg-design-system/ReactAux';

const Example: React.FC = () => {
    return (
    <ReactAux>
        <p>Hello, World!</p>
        <p>I am a demo for ReactAux.</p>
    </ReactAux>
    );
}
export default Example;
    `}
        </Highlight>

        <Highlight className="HTML">
            {`
// Rendered HTML will be:
<p>Hello, World!</p>
<p>I am a demo for ReactAux.</p>
            `}
        </Highlight>

    </div>;
}

export default ReactAuxPage;
