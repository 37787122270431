import React, { useState } from 'react';
import { flushSync } from 'react-dom';
import {
    Button, Toolbar, Toggle, Badge, InfoBox,
    QueryItem, QueryCombinator, QueryBuilder, Collapse
} from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const INITIAL_ITEMS: QueryItem[] = [
    {
        id: 1,
        title: 'File Credit Grade <> 1 ',
        type: 'condition',
        combinator: 'AND',
        testSuccess: true
    },
    {
        id: 2,
        title: 'Last Review date between 8/23/2022 and 8/31/2022 and soooooome veeeeery long title',
        type: 'condition',
        combinator: 'AND',
        testSuccess: false
    },
    {
        id: 3,
        type: 'group',
        children: [
            {
                id: 4,
                title: 'File Credit Grade <> 4',
                type: 'condition',
                combinator: 'AND',
                testSuccess: false
            }
        ],
        combinator: 'AND',
        testSuccess: true
    },
];

let nextId = 5;

const generateNewItem = () => {
    const newItem: QueryItem = {
        id: nextId,
        title: `File Credit Grade <> ${nextId % 2 === 0 ? 'and soooooome veeeeery long text that should break somewhere' : ''}  + ${nextId}`,
        type: 'condition',
        combinator: 'AND'
    };
    nextId++;
    return newItem;
};

const generateNewGroup = () => {
    const newGroup: QueryItem = {
        id: nextId,
        type: 'group',
        children: [],
        combinator: 'AND'
    };
    nextId++;
    return newGroup;
};


const ConditionsBox = () => {
    const [items, setItems] = useState(INITIAL_ITEMS);
    const [isSummaryExpanded, setIsSummaryExpanded] = useState(false);

    const addNewItem = (targetGroupId: number, isGroup = false) => {
        const newItem = isGroup ? generateNewGroup() : generateNewItem();
        if (targetGroupId === 0) { // Using 0 as identifier for root
            setItems((prevData) => {
                prevData.push(newItem);
                return [...prevData];
            });
            return;
        }
        const updateGroup = (list: QueryItem[], itemToAdd: QueryItem) => {
            for (const element of list) {
                if (element.type !== 'group' || !element.children) {
                    continue;
                }
                if (element.id === targetGroupId) {
                    element.children.push(itemToAdd);
                    break;
                }
                updateGroup(element.children, itemToAdd);
            }
            return list;
        };

        setItems((prevData) => {
            const newData = updateGroup(prevData, newItem);
            return [...newData];
        });
    }

    const resetState = () => setItems([]);


    const updateCombinator = (targetItemId: number, newCombinatorValue: QueryCombinator) => {
        const findItemAndUpdateCombinator = (list: QueryItem[]) => {
            for (const element of list) {
                if (element.id === targetItemId) {
                    element.combinator = newCombinatorValue;
                    break;
                }
                if (element.children) {
                    findItemAndUpdateCombinator(element.children);
                }
            }
            return list;
        };

        setItems((prevData) => {
            const newData = findItemAndUpdateCombinator(prevData);
            return [...newData];
        });
    }

    const handleDeleteItem = (targetItemId: number) => {
        const deleteItem = (list: QueryItem[]) => {
            if (list.findIndex(item => item.id === targetItemId) >= 0) {
                return list.filter(item => item.id !== targetItemId);;
            }
            for (const element of list) {
                if (element.children) {
                    const updatedList = deleteItem(element.children);
                    element.children = updatedList;
                    break;
                }
            }
            return list;
        };

        setItems((prevData) => {
            const newData = deleteItem(prevData);
            return [...newData];
        });
    }

    const previewElement = (addEllipsis = false) => {
        return (
            <p {...(addEllipsis && { className: 'overflowing-summary' })}>
                External Obsolence IN NO NEED CALL CODE,0000 DEFAULT,
                1000 LEASES <strong className="text-primary">OR</strong> Borrower CoopCode =
                Cooperative <strong className="text-primary">AND</strong> Net Profit {'>'}
                10 000 <strong className="text-primary">AND</strong> Policy Exception Type IN Lack of Appraisal, Minimum Scredit Score, Out of Market
                Loan, Other <strong className="text-primary">AND</strong> Other Contact Types - Loan Request IN DEP Deposit, LN Loan
            </p>
        );
    };

    return (
        <div className="section-container mb-m">
            <div className="d-flex justify-content-between">
                <h2>Conditions</h2>
                <Toggle checked={true} onChange={() => { }} dataUI={getDataUI()}>Аdvanced Mode</Toggle>
            </div>
            <hr className='mb-m mt-m' />
            <Toolbar className="mb-m" dataUI={getDataUI()} leftSide={(
                <>
                    <Button
                        btnType="secondary"
                        className="mr-s"
                        icon="fal fa-plus"
                        onClick={() => addNewItem(0)}
                        dataUI={getDataUI()}
                    >
                        Add Condition
                    </Button>
                    <Button
                        btnType="secondary"
                        className="mr-s"
                        icon="fal fa-plus"
                        onClick={() => addNewItem(0, true)}
                        dataUI={getDataUI()}
                    >
                        Add Group
                    </Button>
                    <Button btnType="secondary" icon="fal fa-trash"
                        onClick={resetState}
                        dataUI={getDataUI()}
                    >Remove All Conditions</Button>
                </>
            )} />

            <Collapse
                dataUI={getDataUI()}
                className="mb-m"
                expanded={isSummaryExpanded}
                onClick={() => setIsSummaryExpanded(!isSummaryExpanded)}
                title=""
                elementRender={() => (
                    <div className="results-preview-collapse-title">
                        <p className="mr-xs"><strong>Results Preview: </strong></p>
                        {previewElement(true)}
                    </div>
                )}
                elementRenderRight={() => <Badge dataUI={getDataUI()} className="summary-badge ml-s" type="success">True</Badge>}
            >
                <InfoBox dataUI={getDataUI()} icon="fal fa-database">
                    {previewElement()}
                </InfoBox>
            </Collapse>



            <QueryBuilder
                items={items}
                onItemsReorder={(reorderedItems) => {
                    flushSync(() => setItems(reorderedItems));
                }}
                onAddCondition={addNewItem}
                onAddGroup={(groupId) => addNewItem(groupId, true)}
                onCombinatorChange={updateCombinator}
                onDelete={handleDeleteItem}
                onEditCondition={(conditionId) => console.log(`Should edit: ${conditionId}`)}
                onDuplicate={(itemId) => console.log(`Should duplicate: ${itemId}`)}
                dataUI={getDataUI()}
            />

        </div>
    );
}


export default ConditionsBox;