import React from 'react';
import Highlight from 'react-highlight';
import { Link } from 'react-router-dom';

const LeftSideBarPage: React.FC = () => {

    return <div>
        <h1 className="mb-s">LeftSideBar</h1>
        <p className="mb-m">LeftSideBar component is an additional module to the <Link to="/docs/layout">Layout</Link>.</p>
        <p className="mb-s">Example of LeftSideBar with TopSection and BottomSection is available <Link target="blank" to="/components">here</Link>.</p>
        <p className="mb-l">Example of LeftSideBar with WizardNav can be seen <Link target="blank" to="/components?wizard-nav">here</Link>.</p>

        <h2 className="mb-m">LeftSideBar module components:</h2>
        <p className="mb-s"><strong><a href="#left-side-bar">LeftSideBar</a></strong> - The main wrapper component.</p>
        <p className="mb-s"><strong>TopSection</strong> - Wrapper component of the content for top side.</p>
        <p className="mb-s"><strong>BottomSection</strong> - Wrapper component of the content for bottom side.</p>
        <p className="mb-s"><strong><a href="#left-bar-crumbs">LeftBarCrumbs</a></strong> - Creates the breadcrumbs in LeftSideBar.</p>
        <p className="mb-s"><strong>ScrollableContainer</strong> - Wrapper component for the scrollable content. Use it in TopSection, BottomSection and WizardNav.</p>
        <p className="mb-s"><strong>WizardNav</strong> - Wrapper component.</p>
        <p className="mb-s"><strong><a href="#wizard-nav-steps-controls">WizardNavStepsControls</a></strong> - Wrapper for control elements.</p>
        <p className="mb-s"><strong><a href="#wizard-nav-bottom-section">WizardNavBottomSection</a></strong> - Wrapper component that place the content at bottom side in WizardNav.</p>
        <p className="mb-s"><strong><a href="#wizard-nav-step">WizardNavStep</a></strong> - Used to show the step with status and can be collapsible with more inner steps.</p>
        <p className="mb-s"><strong><a href='/docs/activity-checklist' title="ActivityChecklist doc">Activity Checklist</a></strong> - Group of components ChecklistActions, ChecklistContent, ChecklistItem, AttachmentsContent,
        Attachment used with Tabs and ScrollableContainer to build BottomSection.</p>

        <Highlight className="React">
            {
                `
import React from 'react';
import { Header, MainContainer, LeftSideBar } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

class Layout extends React.Component{
    state = {
        activityChecklistTabIndex: 0
    }

    render(){
        return (
            <Header>
                ...
            </Header>

            <Main dataUI={getDataUI()}>
                <LeftSideBar dataUI={getDataUI()}>
                    <TopSection dataUI={getDataUI()}>
                        <LeftBarCrumbs
                            dataUI={getDataUI()}
                            className="mb-m lv-side-bar-neggative-margin"
                            links={[<a href="/">Fallow Fruits</a>, <a href="/">36465: Loan Request</a>]} />
                        <h2 className="mb-s text-muted">Party navigation</h2>
                        <ScrollableContainer>
                            ...
                        </ScrollableContainer>
                    </TopSection>
                    <BottomSection dataUI={getDataUI()}>
                        ...
                    </BottomSection>
                </LeftSideBar>
                <MainContainer dataUI={getDataUI()}>
                    Content...
                </MainContainer>
            </Main>
        );
    }
}
export default Layout;
    `}
        </Highlight>

        <h2 className="mb-m">API</h2>

        <h3 id="left-side-bar">LeftSideBar</h3>
        <table className="doc-table mb-l">
            <thead>
                <tr>
                    <th colSpan={4}>Props</th>
                </tr>
                <tr>
                    <td>
                        <strong>Name</strong>
                    </td>
                    <td>
                        <strong>Options</strong>
                    </td>
                    <td>
                        <strong>Default</strong>
                    </td>
                    <td>
                        <strong>Description</strong>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>isExpanded</td>
                    <td>boolean</td>
                    <td className="text-center">-</td>
                    <td>Set the expanded.</td>
                </tr>
                <tr>
                    <td>isToggleable</td>
                    <td>boolean</td>
                    <td>true</td>
                    <td>Shows the button for toggle the LeftSideBar.</td>
                </tr>
                <tr>
                    <td>width</td>
                    <td>number</td>
                    <td className="text-center">-</td>
                    <td>Set the width for LeftSideBar.</td>
                </tr>
            </tbody>
        </table>

        <h3 id="left-bar-crumbs">LeftBarCrumbs</h3>
        <table className="doc-table mb-l">
            <thead>
                <tr>
                    <th colSpan={4}>Props</th>
                </tr>
                <tr>
                    <td>
                        <strong>Name</strong>
                    </td>
                    <td>
                        <strong>Options</strong>
                    </td>
                    <td>
                        <strong>Default</strong>
                    </td>
                    <td>
                        <strong>Description</strong>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>links</td>
                    <td>array of JSX.Elements</td>
                    <td className="text-center">-</td>
                    <td>Sets the links.</td>
                </tr>
            </tbody>
        </table>

        <h3 id="wizard-nav-steps-controls">WizardNavStepsControls</h3>
        <table className="doc-table mb-l">
            <thead>
                <tr>
                    <th colSpan={4}>Props</th>
                </tr>
                <tr>
                    <td>
                        <strong>Name</strong>
                    </td>
                    <td>
                        <strong>Options</strong>
                    </td>
                    <td>
                        <strong>Default</strong>
                    </td>
                    <td>
                        <strong>Description</strong>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>submitBtn</td>
                    <td>JSX.Element</td>
                    <td className="text-center">-</td>
                    <td>Sets the elenent for submitBtn.</td>
                </tr>
                <tr>
                    <td>saveBtn</td>
                    <td>JSX.Element</td>
                    <td className="text-center">-</td>
                    <td>Sets the elenent for saveBtn.</td>
                </tr>
                <tr>
                    <td>previousStep</td>
                    <td>JSX.Element</td>
                    <td className="text-center">-</td>
                    <td>Sets the elenent for previousStep.</td>
                </tr>
                <tr>
                    <td>nextStep</td>
                    <td>JSX.Element</td>
                    <td className="text-center">-</td>
                    <td>Sets the elenent for nextStep.</td>
                </tr>
            </tbody>
        </table>

        <h3 id="wizard-nav-step">WizardNavStep</h3>
        <table className="doc-table mb-l">
            <thead>
                <tr>
                    <th colSpan={4}>Props</th>
                </tr>
                <tr>
                    <td>
                        <strong>Name</strong>
                    </td>
                    <td>
                        <strong>Options</strong>
                    </td>
                    <td>
                        <strong>Default</strong>
                    </td>
                    <td>
                        <strong>Description</strong>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>title</td>
                    <td>string</td>
                    <td className="text-center">-</td>
                    <td>Sets the title.</td>
                </tr>
                <tr>
                    <td>icon</td>
                    <td>string</td>
                    <td className="text-center">-</td>
                    <td>Sets the icon.</td>
                </tr>
                <tr>
                    <td>status</td>
                    <td>"success" or "warning"</td>
                    <td className="text-center">-</td>
                    <td>Sets the status for the step.</td>
                </tr>
                <tr>
                    <td>onTitleClick</td>
                    <td>() {'=>'} void</td>
                    <td className="text-center">-</td>
                    <td>Callback when the title of step is clicked.</td>
                </tr>
                <tr>
                    <td>onIconClick</td>
                    <td>() {'=>'} void</td>
                    <td className="text-center">-</td>
                    <td>Callback when the status icon is clicked.</td>
                </tr>
                <tr>
                    <td>isActive</td>
                    <td>boolean</td>
                    <td>false</td>
                    <td>Shows the active state.</td>
                </tr>
                <tr>
                    <td>expanded</td>
                    <td>boolean</td>
                    <td>false</td>
                    <td>Shows the expanded content.</td>
                </tr>
                <tr>
                    <td>infoText</td>
                    <td>string</td>
                    <td className="text-center">-</td>
                    <td>Sets the info text for the step. It's show only when the step is active.</td>
                </tr>
                <tr>
                    <td>successBox</td>
                    <td>JSX.Element</td>
                    <td className="text-center">-</td>
                    <td>Sets the checkbox element. It's show only when the step is active.</td>
                </tr>
                <tr>
                    <td>disabled</td>
                    <td>boolean</td>
                    <td>false</td>
                    <td>Disables the step.</td>
                </tr>
            </tbody>
        </table>
    </div>;
}

export default LeftSideBarPage;
