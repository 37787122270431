import React from 'react';

import {
    MainContainerHeader, ReactAux, Tabs, TabList,
    TabItem, TabItemTitle, TabContent, TabContentItem, Collapse,
    ActionContainer, ActionContainerHeader, ActionContainerCrumbs,
    ActionSettings, ActionSettingsForm, LinkWithIcon, Button, Select,
    MainContainerScrollable, Checkbox, Tooltip,
} from '@jkhy/vsg-loanvantage-design-system';

import DemoLocationPage from './DemoLocationPage';
import { getDataUI } from '../helpers/helpers';

class DemoLoanRequest extends React.Component {

    state = {
        currentlyActiveTab: 1,
        collapseDemo2: false,
        collapseDemo3: false,
        actionContainerActiveFormIndex: -1,
        isFirstActionDisabled: false,
        isActionContainerLocked: true,
        isLockDisabled: false,
        selectValue1: '1',
        selectValue2: '1',
    }

    setCurrentlyActiveTab = (newTabIndex: number) => {
        this.setState({ currentlyActiveTab: newTabIndex });
    }

    openActionContainerForm(formIndex: number) {
        this.setState({ actionContainerActiveFormIndex: formIndex });
    }

    toggleActiveActionSetting() {
        this.setState({ isFirstActionDisabled: !this.state.isFirstActionDisabled });
        this.openActionContainerForm(-1);
    }

    toggleActionContainerLocked() {
        this.setState({ isActionContainerLocked: !this.state.isActionContainerLocked });
    }

    toggleLockDisabled() {
        this.setState({ isLockDisabled: !this.state.isLockDisabled });
        this.openActionContainerForm(-1);
    }

    render() {

        return (
            <ReactAux>
                <MainContainerHeader dataUI={getDataUI()}>
                    <ActionContainer dataUI={getDataUI()}>
                        <ActionContainerHeader dataUI={getDataUI()}>
                            <ActionContainerCrumbs dataUI={getDataUI()} crumbs={[<a href="/">Loan Collateral</a>, '36465: Loan Request']} />
                            <ActionSettings dataUI={getDataUI()} isActive={this.state.actionContainerActiveFormIndex === -1}>
                                <Tooltip title="Click to edit process definitions">
                                    <LinkWithIcon dataUI={getDataUI()} disabled={this.state.isFirstActionDisabled} icon="fal fa-cog">
                                        <a onClick={(e) => { e.preventDefault(); this.openActionContainerForm(1) }} href="/">Commercial loan request</a>
                                    </LinkWithIcon>
                                </Tooltip>
                                <LinkWithIcon dataUI={getDataUI()} icon="fal fa-eye"><a onClick={(e) => { e.preventDefault(); this.openActionContainerForm(2) }} href="/">Collateral Fields mode</a></LinkWithIcon>
                                <Tooltip title="All changes are allowed">
                                    <Button dataUI={getDataUI()} disabled={this.state.isLockDisabled} onClick={() => this.toggleActionContainerLocked()} btnType="icon" icon={this.state.isActionContainerLocked ? 'fas fa-lock' : 'fal fa-lock-open'} isDanger={this.state.isActionContainerLocked}></Button>
                                </Tooltip>
                            </ActionSettings>
                            <ActionSettingsForm dataUI={getDataUI()} isActive={this.state.actionContainerActiveFormIndex === 1}>
                                <p>Process definitions:</p>
                                <Select
                                    dataUI={getDataUI()}
                                    className="width-m"
                                    value={this.state.selectValue1}
                                    onChange={(obj: any) => this.setState({ selectValue1: obj.value })}
                                    options={[{ value: '1', label: 'Commercial loan request' }, { value: '2', label: 'test 2' }]}
                                />
                                <Button dataUI={getDataUI()} onClick={e => this.toggleLockDisabled()} icon="fal fa-save">Update</Button>
                                <Button dataUI={getDataUI()} onClick={e => this.openActionContainerForm(-1)} btnType="secondary" icon="fal fa-times">Cancel</Button>
                            </ActionSettingsForm>
                            <ActionSettingsForm dataUI={getDataUI()} isActive={this.state.actionContainerActiveFormIndex === 2}>
                                <p>Process definitions:</p>
                                <Select
                                    dataUI={getDataUI()}
                                    className="width-m"
                                    value={this.state.selectValue2}
                                    onChange={(obj: any) => this.setState({ selectValue2: obj.value })}
                                    options={[{ value: '1', label: 'Disable the previous action control' }, { value: '2', label: 'test 2' }]}
                                />
                                <Button dataUI={getDataUI()} icon="fal fa-save" onClick={() => this.toggleActiveActionSetting()}>Update</Button>
                                <Button dataUI={getDataUI()} onClick={e => this.openActionContainerForm(-1)} btnType="secondary" icon="fal fa-times">Cancel</Button>
                            </ActionSettingsForm>
                        </ActionContainerHeader>
                        <Button dataUI={getDataUI()} icon="fal fa-pen">Edit</Button>
                        <Button dataUI={getDataUI()} btnType="secondary" icon="fal fa-percent">LTV</Button>
                        <Button dataUI={getDataUI()} btnType="secondary" icon="fal fa-expand">Spreading</Button>
                        <Button dataUI={getDataUI()} btnType="secondary" icon="fal fa-coins">Appraisal</Button>
                        <Button dataUI={getDataUI()} btnType="secondary" icon="fal fa-map-marker-alt">Visit</Button>
                    </ActionContainer>
                </MainContainerHeader>
                <Tabs dataUI={getDataUI()} className="mt-m" currentlyActiveTabIndex={this.state.currentlyActiveTab} onTabIndexChange={i => this.setState({ currentlyActiveTab: i })}>
                    <TabList dataUI={getDataUI()}>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>Request</TabItemTitle>
                        </TabItem>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>Borrowers</TabItemTitle>
                        </TabItem>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>Terms</TabItemTitle>
                        </TabItem>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>ARM</TabItemTitle>
                        </TabItem>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>Schedules</TabItemTitle>
                        </TabItem>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>Fund</TabItemTitle>
                        </TabItem>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>Participations</TabItemTitle>
                        </TabItem>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>Source/Use</TabItemTitle>
                        </TabItem>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>Lines-of-credit</TabItemTitle>
                        </TabItem>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>Declarations</TabItemTitle>
                        </TabItem>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>Miscellaneous</TabItemTitle>
                        </TabItem>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>Compliance</TabItemTitle>
                        </TabItem>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>Fees/Closing</TabItemTitle>
                        </TabItem>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>Doc-Portal</TabItemTitle>
                        </TabItem>
                    </TabList>
                    <TabContent dataUI={getDataUI()} >
                        <TabContentItem dataUI={getDataUI()}>Overview tab content</TabContentItem>
                        <TabContentItem dataUI={getDataUI()}>

                            <MainContainerScrollable>
                                <DemoLocationPage />
                                <Collapse dataUI={getDataUI()}
                                    elementRender={() => <Checkbox dataUI={getDataUI()} />}
                                    title="Colapse 2"
                                    expanded={this.state.collapseDemo2}
                                    onClick={() => this.setState({ collapseDemo2: !this.state.collapseDemo2 })}
                                >
                                    <p>Colapse 2 Content...</p>
                                </Collapse>
                                <Collapse dataUI={getDataUI()}
                                    title="Colapse 3"
                                    expanded={this.state.collapseDemo3}
                                    onClick={() => this.setState({ collapseDemo3: !this.state.collapseDemo3 })}
                                >
                                    <p>Colapse 3 Content...</p>
                                </Collapse>
                            </MainContainerScrollable>

                        </TabContentItem>
                        <TabContentItem dataUI={getDataUI()}>Miscellaneous tab content</TabContentItem>
                        <TabContentItem dataUI={getDataUI()}></TabContentItem>
                        <TabContentItem dataUI={getDataUI()}></TabContentItem>
                        <TabContentItem dataUI={getDataUI()}></TabContentItem>
                        <TabContentItem dataUI={getDataUI()}></TabContentItem>
                        <TabContentItem dataUI={getDataUI()}></TabContentItem>
                        <TabContentItem dataUI={getDataUI()}></TabContentItem>
                        <TabContentItem dataUI={getDataUI()}></TabContentItem>
                        <TabContentItem dataUI={getDataUI()}></TabContentItem>
                        <TabContentItem dataUI={getDataUI()}></TabContentItem>
                        <TabContentItem dataUI={getDataUI()}></TabContentItem>
                        <TabContentItem dataUI={getDataUI()}></TabContentItem>
                    </TabContent>
                </Tabs>
            </ReactAux>
        );
    }
}

export default DemoLoanRequest;