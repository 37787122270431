import React from 'react';
import { Link } from 'react-router-dom';
import { MainContainerScrollable } from '@jkhy/vsg-loanvantage-design-system';


const DnD: React.FC = () => (
    <MainContainerScrollable>
        <h1 className="mb-s">Examples:</h1>
        <h3 className="mb-m"><Link className="lv-link-styles" to="/components/dnd/">Simple DnD example</Link></h3>
        <h3 className="mb-m"><Link className="lv-link-styles" to="/components/dnd/virtual-simple">Virtual Simple DnD example</Link></h3>
        <h3 className="mb-m"><Link className="lv-link-styles" to="/components/dnd/presentation-report">DnD Presentation report page</Link></h3>
        <h3 className="mb-m"><Link className="lv-link-styles" to="/components/dnd/presentation-report-async">DnD Presentation report page with async data</Link></h3>
        <h3 className="mb-m"><Link className="lv-link-styles" to="/components/dnd/column-selection">DnD Column selection page</Link></h3>
        <h3 className="mb-m"><Link className="lv-link-styles" to="/components/dnd/column-selection-tabs">DnD Column selection with vertical tabs page</Link></h3>
        <h3 className="mb-m"><Link className="lv-link-styles" to="/components/dnd/column-selection-tabs-async">DnD Column selection with vertical tabs page and async data</Link></h3>
        <h3 className="mb-m"><Link className="lv-link-styles" to="/components/dnd/process-definition-modal">Admin Process Definition Modal: DnD links and labels</Link></h3>
        <h3 className="mb-m"><Link className="lv-link-styles" to="/components/dnd/virtual-process-definition-modal">Virtual Process Definition Modal: DnD links and labels</Link></h3>
        <h3 className="mb-m"><Link className="lv-link-styles" to="/components/dnd/prerendered-tabs">DnD inside prerendered tabs</Link></h3>
    </MainContainerScrollable >
);

export default DnD;