import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import Docs from './views/docs/Docs';
import ComponentsLayout from './views/components/ComponentsLayout';
import Homepage from './views/components/Homepage';
import DemoBar from './views/components/DemoBar';
import DemoWorkPage1 from './views/components/DemoWorkPage1';
import DemoWorkPage2 from './views/components/DemoWorkPage2';
import DemoWorkPage3 from './views/components/DemoWorkPage3';
import DemoWorkPage4 from './views/components/DemoWorkPage4';
import HomepageAdmin from './views/components/HomepageAdmin';
import DemoKendoGridFixColumns from './views/components/DemoKendoGridFixColumns';

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Redirect exact from="/" to="/docs/introduction" />
        <Redirect exact from="/docs" to="/docs/introduction" />
        <Route path="/docs" component={Docs} />
        <Route path="/components" component={ComponentsLayout} />
        <Route path="/demo" component={ComponentsLayout} />
        <Route path="/home" component={Homepage} />
        <Route path="/home-admin" component={HomepageAdmin} />
        <Route path="/demo-bar" component={DemoBar} />
        <Route path="/work-page-1" component={DemoWorkPage1} />
        <Route path="/work-page-2" component={DemoWorkPage2} />
        <Route path="/work-page-3" component={DemoWorkPage3} />
        <Route path="/work-page-4" component={DemoWorkPage4} />
        <Route path="/kendo-test" component={DemoKendoGridFixColumns} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
