
import React from 'react';
import {
    UploadedFileRow, FILE_UPLOAD_DEFAULT_ERROR_MSG, isValidFile
} from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from './helpers';

export type UploadedFileRowProps = {
    uploadedFile: {
        file: File,
        uploading?: boolean,
        uploadDone?: boolean,
        uploadError?: boolean,
        errorInfo?: {
            title?: string,
            description?: string,
        }
    },
    viewMode?: boolean,
    key?: any,
};

const demoTextFile = new File(["file-from-database"], "file-from-database.txt", { type: "text/plain", });

const demoPdfFile = new File(['example pdf'], 'file.pdf', { type: 'application/pdf' });

export const examplePDF = {
    uploadedFile: {
        file: demoPdfFile,
        uploadError: false,
        uploading: false,
        uploadDone: true,
    },
    viewMode: false,
    key: "demoPdfFile-1",
} as UploadedFileRowProps;

export const exampleText = {
    uploadedFile: {
        file: demoTextFile,
        uploadError: false,
        uploading: false,
        uploadDone: true,
        linkSettings: {
            href: 'file-from-database.jpg',
            title: 'test',
            download: true
        }
    },
    viewMode: false,
    key: "demoTextFile-1",
} as UploadedFileRowProps;

export const simulateUploading = (updateProgress: any, fileData: any, statePropName: any, thisState: any, updateFileObjectProgress = false) => {
    // Simulated uploading with random time for upload.
    const that = thisState as any;
    const time = +((Math.floor(Math.random() * 10) + 1) + '000');
    let progress = fileData.uploadedFile.progress || 0;

    const interval = setInterval(() => {
        progress += 1;
        if (that[`${statePropName}UnmountedRows`].current[fileData.key] || progress > 100) {
            clearInterval(interval);
            return;
        }

        if (updateFileObjectProgress) {
            updateUploadedFileRowProp(statePropName, fileData, 'progress', progress, that);
        }
        updateProgress(progress);

    }, time / 100)
}

export const onDeleteFile = (fileKey: any, statePropName: any, thisState: any) => {
    const that = thisState as any;
    that[`${statePropName}UnmountedRows`].current[fileKey] = true;
    const copyState = { ...that.state } as any;

    that.setState({
        [statePropName]: copyState[statePropName].filter((f: any) => f.key !== fileKey)
    })
}

export const getErrorMsg = (err: any) => {
    let title = 'Error';
    let description = 'Try againg.';
    if (err === FILE_UPLOAD_DEFAULT_ERROR_MSG.invalidType) {
        title = 'Invalid File type';
        description = 'You are tring to upload invalid file type.';
    } else if (err === FILE_UPLOAD_DEFAULT_ERROR_MSG.sizeExceeded) {
        title = 'File size exceeded';
        description = 'Try to upload file with smaller size'
    } else if (err === FILE_UPLOAD_DEFAULT_ERROR_MSG.pdfPasswordProtected) {
        title = 'File is password protected';
        description = 'Uploaded file appears to have a password protection. Please upload a file that is not password protected.'
    }

    return {
        title,
        description
    }
}

export const onAddFile = (files: any, statePropName: any, thisState: any) => {
    const that = thisState as any;
    const filesToUpload = [...files];
    const copyState = { ...that.state } as any;
    let filesWithKeys = [...copyState[statePropName]];

    for (let i = 0; i < filesToUpload.length; i++) {
        const isError = !!filesToUpload[i].error;
        const newFileRow = {
            uploadedFile: {
                file: filesToUpload[i].file,
                uploading: !isError,
                uploadError: isError,
                errorInfo: filesToUpload[i].error ? getErrorMsg(filesToUpload[i].error) : '',
            },
            key: `${statePropName}-${filesToUpload[i].file.name}-${filesWithKeys.length + 1}`
        } as UploadedFileRowProps;

        that[`${statePropName}UnmountedRows`].current[newFileRow.key] = false;

        // Reverse Order
        // filesWithKeys = [
        //     newFileRow,
        //     ...filesWithKeys
        // ];
        filesWithKeys = [
            ...filesWithKeys,
            newFileRow,
        ];
    }

    that.setState({
        [statePropName]: filesWithKeys
    })
}

export const updateUploadedFileRowProp = (statePropName: any, fileData: any, prop: 'uploading' | 'uploadDone' | 'progress', value: any, thisState: any) => {
    const that = thisState as any;
    const copyState = { ...that.state } as any;
    const files = copyState[statePropName] as any;
    that.setState({
        [statePropName]: files.map((file: any) => {
            if (file.key === fileData.key) {
                const copyFile = { ...file };
                copyFile.uploadedFile[prop] = value;
                return copyFile;
            }
            return file;
        })
    })
}

export const checkFileOnServer = (files: any) => {
    if (files.length > 0) {
        return isValidFile(files[0].file, '.doc,.xls');
    }
    return false;
}

export const renderFileRows = (
    statePropName: any,
    thisState: any,
    onUploadedCallBack = () => { },
    onDeleteCallBack = () => { }
) => {
    const that = thisState as any;
    const copyState = { ...that.state } as any;
    const files = copyState[statePropName];

    if (files.length === 0) {
        return null;
    }

    return files.map((fileData: any) => {
        const isError = fileData.uploadedFile.uploadError;
        const isUploading = fileData.uploadedFile.uploading;

        return (
            <UploadedFileRow
                dataUI={getDataUI()}
                key={fileData.key}
                viewMode={fileData.viewMode}
                uploadedFile={{
                    ...fileData.uploadedFile,
                    uploadError: isError,
                }}
                onProgress={(updateProgress: any) => {
                    if (isUploading) {
                        simulateUploading(updateProgress, fileData, statePropName, that)
                    }
                }}
                onSuccessUploaded={(progress: any, setUploadSuccessful: any) => {
                    if (progress === 100) {
                        setUploadSuccessful(true);
                        updateUploadedFileRowProp(statePropName, fileData, "uploading", false, that)
                    }
                }}
                onUploadDone={(uploadSuccessful: any, setUploadDone: any) => {
                    if (uploadSuccessful) {
                        setTimeout(() => {
                            setUploadDone(true);
                            updateUploadedFileRowProp(statePropName, fileData, "uploadDone", true, that);
                            onUploadedCallBack();
                        }, 3000);
                    }
                }}
                onDelete={() => {
                    onDeleteFile(fileData.key, statePropName, that);
                    onDeleteCallBack();
                }}
            />
        );
    })
}