import React from 'react';

import {
    MainContainerScrollable, Tabs, TabList, TabItem,
    TabContent, TabContentItem, TabItemTitle,
    Button, Input, DragNDropSection, DragNDropContext,
    DraggableItemActions, DraggableItemTitle, DragNDropUtils,
    Modal, DragNDropSectionHeader, DynamicScrollableContainer,
    Row, Col, Toolbar, Tooltip, Label, Select, VirtualizedDroparea
} from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

type Item = { title: string, id: string, type: string };

type VirtualDnDProcessDefinitionModalState = {
    isModalVisible: boolean,
    isFetching: boolean,
    currentTabIndex: number,
    allAvailableTaskItems: Item[],
    allAssignedTaskItems: Item[],
    filteredAvailableTaskItems: Item[],
    filteredAssignedTaskItems: Item[],
    allAvailableDeclarationItems: Item[],
    allAssignedDeclarationItems: Item[],
    filteredAvailableDeclarationItems: Item[],
    filteredAssignedDeclarationItems: Item[],
    dragStartEvent: Event | null,
    dragUpdateEvent: Event | null,
    availableTasksSearch: string,
    availableDeclarationsSearch: string,
    assignedTasksSearch: string,
    assignedDeclarationsSearch: string,
}

// NOTE: It is important to provide unique id values for each item
const defaultTasksAvailable = [
    { title: '2nd Task', id: 'item-task-1' }, { title: '2000', id: 'item-task-2' }, { title: 'Action Plan', id: 'item-task-3' }, { title: 'Angel Test', id: 'item-task-4' },
    { title: 'Approval Days in Decisioning Approval a veeery long title that has to get ellipsis', id: 'item-task-5' }, { title: 'Application Submission', id: 'item-task-6' }, { title: 'Appraisal', id: 'item-task-7' }, { title: 'Approval', id: 'item-task-8' },
    { title: 'Approval Days in Decisioning', id: 'item-task-9' }, { title: 'Bal Sheet', id: 'item-task-10' }, { title: 'Reason', id: 'item-task-11' }, { title: 'Exceed Policy', id: 'item-task-12' },
];

Array.from({ length: 500 }).forEach((e, i) => {
    defaultTasksAvailable.push({ title: 'Virtualized item ' + i, id: 'virtual-item-' + i })
});

const defaultDeclarationsAvailable = [
    { title: '1236', id: 'item-decl-1' }, { title: '2000', id: 'item-decl-2' }, { title: '325235', id: 'item-decl-3' }, { title: '400', id: 'item-decl-4' },
    { title: '4000', id: 'item-decl-5' }, { title: '5000', id: 'item-decl-6' }, { title: '6000', id: 'item-decl-7' }, { title: '8000', id: 'item-decl-8' },
    { title: '9000', id: 'item-decl-9' }, { title: 'Bal Sheet', id: 'item-decl-10' }, { title: 'Reason', id: 'item-decl-11' }, { title: 'Exceed Policy', id: 'item-decl-12' },
];

const defaultDeclarationsAssigned = [{ title: '001', id: 'item-decl-13' }, { title: '7000', id: 'item-decl-14' }, { title: '004', id: 'item-decl-15' }, { title: '040', id: 'item-decl-16' }];


class VirtualDnDProcessDefinitionModal extends React.Component {
    private static readonly AVAILABLE_TASKS_SECTION_NAME = 'available-tasks';
    private static readonly AVAILABLE_DECLARATIONS_SECTION_NAME = 'available-declarations';
    private static readonly ASSIGNED_TASKS_SECTION_NAME = 'assigned-tasks';
    private static readonly ASSIGNED_DECLARATIONS_SECTION_NAME = 'assigned-declarations';

    state: VirtualDnDProcessDefinitionModalState = {
        isModalVisible: false,
        isFetching: true,
        currentTabIndex: 0,
        allAvailableTaskItems: [],
        allAssignedTaskItems: [],
        filteredAvailableTaskItems: [],
        filteredAssignedTaskItems: [],
        allAvailableDeclarationItems: defaultDeclarationsAvailable.map(e => { return { ...e, type: VirtualDnDProcessDefinitionModal.AVAILABLE_DECLARATIONS_SECTION_NAME } }),
        allAssignedDeclarationItems: defaultDeclarationsAssigned.map(e => { return { ...e, type: VirtualDnDProcessDefinitionModal.ASSIGNED_DECLARATIONS_SECTION_NAME } }),
        // Duplicating only for demo purpose
        filteredAvailableDeclarationItems: defaultDeclarationsAvailable.map(e => { return { ...e, type: VirtualDnDProcessDefinitionModal.AVAILABLE_DECLARATIONS_SECTION_NAME } }),
        filteredAssignedDeclarationItems: defaultDeclarationsAssigned.map(e => { return { ...e, type: VirtualDnDProcessDefinitionModal.ASSIGNED_DECLARATIONS_SECTION_NAME } }),
        dragStartEvent: null,
        dragUpdateEvent: null,
        availableTasksSearch: '',
        availableDeclarationsSearch: '',
        assignedTasksSearch: '',
        assignedDeclarationsSearch: '',
    };

    updateTabIndex(newIndex: number) {
        if (this.state.currentTabIndex !== newIndex) {
            if (newIndex === 1) {
                if (this.state.isFetching) {
                    const loadedAvailableItems = defaultTasksAvailable.map(e => { return { ...e, type: VirtualDnDProcessDefinitionModal.AVAILABLE_TASKS_SECTION_NAME } });
                    setTimeout(() => {
                        this.setState({
                            isFetching: false,
                            allAvailableTaskItems: loadedAvailableItems,
                            filteredAvailableTaskItems: loadedAvailableItems,
                        });
                    }, 2000);
                }
            }
            this.setState({ currentTabIndex: newIndex })
        }
    }

    moveAvailableItemToAssigned(type: string, itemId: string) {
        if (type === VirtualDnDProcessDefinitionModal.AVAILABLE_TASKS_SECTION_NAME) {
            const { filteredAvailableTaskItems, filteredAssignedTaskItems } = this.state;
            const assignedClone = Array.from(filteredAssignedTaskItems);
            const availableClone = Array.from(filteredAvailableTaskItems);
            const targetIndex = availableClone.findIndex(item => item.id === itemId);
            const [removed] = availableClone.splice(targetIndex, 1);
            assignedClone.push(removed);
            this.setState({ filteredAvailableTaskItems: availableClone, filteredAssignedTaskItems: assignedClone });
        } else if (type === VirtualDnDProcessDefinitionModal.AVAILABLE_DECLARATIONS_SECTION_NAME) {
            const { filteredAvailableDeclarationItems, filteredAssignedDeclarationItems } = this.state;
            const assignedClone = Array.from(filteredAssignedDeclarationItems);
            const availableClone = Array.from(filteredAvailableDeclarationItems);
            const targetIndex = availableClone.findIndex(item => item.id === itemId);
            const [removed] = availableClone.splice(targetIndex, 1);
            assignedClone.push(removed);
            this.setState({ filteredAvailableDeclarationItems: availableClone, filteredAssignedDeclarationItems: assignedClone });
        }
    }

    returnItemToAvailable(type: string, itemId: string) {
        if (type === VirtualDnDProcessDefinitionModal.AVAILABLE_TASKS_SECTION_NAME) {
            const { filteredAvailableTaskItems, filteredAssignedTaskItems } = this.state;
            const assignedClone = Array.from(filteredAssignedTaskItems);
            const availableClone = Array.from(filteredAvailableTaskItems);
            const targetIndex = assignedClone.findIndex(item => item.id === itemId);
            const [removed] = assignedClone.splice(targetIndex, 1);
            availableClone.push(removed);
            this.setState({ filteredAvailableTaskItems: availableClone, filteredAssignedTaskItems: assignedClone });
        } else if (type === VirtualDnDProcessDefinitionModal.AVAILABLE_DECLARATIONS_SECTION_NAME) {
            const { filteredAvailableDeclarationItems, filteredAssignedDeclarationItems } = this.state;
            const assignedClone = Array.from(filteredAssignedDeclarationItems);
            const availableClone = Array.from(filteredAvailableDeclarationItems);
            const targetIndex = assignedClone.findIndex(item => item.id === itemId);
            const [removed] = assignedClone.splice(targetIndex, 1);
            availableClone.push(removed);
            this.setState({ filteredAvailableDeclarationItems: availableClone, filteredAssignedDeclarationItems: assignedClone });
        }
    }

    addAllToAssigned(destinationType: string) {
        if (destinationType === VirtualDnDProcessDefinitionModal.ASSIGNED_TASKS_SECTION_NAME) {
            const { filteredAvailableTaskItems, filteredAssignedTaskItems } = this.state;
            let assignedClone = Array.from(filteredAssignedTaskItems);
            let availableClone = Array.from(filteredAvailableTaskItems);
            assignedClone = [...assignedClone, ...availableClone];
            availableClone = [];
            this.setState({ filteredAvailableTaskItems: availableClone, filteredAssignedTaskItems: assignedClone });
        } else if (destinationType === VirtualDnDProcessDefinitionModal.ASSIGNED_DECLARATIONS_SECTION_NAME) {
            const { filteredAvailableDeclarationItems, filteredAssignedDeclarationItems } = this.state;
            let assignedClone = Array.from(filteredAssignedDeclarationItems);
            let availableClone = Array.from(filteredAvailableDeclarationItems);
            assignedClone = [...assignedClone, ...availableClone];
            availableClone = [];
            this.setState({ filteredAvailableDeclarationItems: availableClone, filteredAssignedDeclarationItems: assignedClone });
        }
    }

    removeAllAssigned(destinationType: string) {
        if (destinationType === VirtualDnDProcessDefinitionModal.AVAILABLE_TASKS_SECTION_NAME) {
            const { filteredAvailableTaskItems, filteredAssignedTaskItems } = this.state;
            let availableClone = Array.from(filteredAvailableTaskItems);
            let assignedClone = Array.from(filteredAssignedTaskItems);
            for (const item of assignedClone) {
                availableClone.push(item);
            }
            this.setState({
                filteredAvailableTaskItems: availableClone,
                filteredAssignedTaskItems: []
            });
        } else if (destinationType === VirtualDnDProcessDefinitionModal.AVAILABLE_DECLARATIONS_SECTION_NAME) {
            const { filteredAvailableDeclarationItems, filteredAssignedDeclarationItems } = this.state;
            let availableClone = Array.from(filteredAvailableDeclarationItems);
            let assignedClone = Array.from(filteredAssignedDeclarationItems);
            for (const item of assignedClone) {
                availableClone.push(item);
            }
            this.setState({
                filteredAvailableDeclarationItems: availableClone,
                filteredAssignedDeclarationItems: []
            });
        }
    }

    getList(listId: string) {
        if (listId === VirtualDnDProcessDefinitionModal.AVAILABLE_TASKS_SECTION_NAME) {
            return this.state.filteredAvailableTaskItems;
        } else if (listId === VirtualDnDProcessDefinitionModal.AVAILABLE_DECLARATIONS_SECTION_NAME) {
            return this.state.filteredAvailableDeclarationItems;
        } else if (listId === VirtualDnDProcessDefinitionModal.ASSIGNED_TASKS_SECTION_NAME) {
            return this.state.filteredAssignedTaskItems;
        } else if (listId === VirtualDnDProcessDefinitionModal.ASSIGNED_DECLARATIONS_SECTION_NAME) {
            return this.state.filteredAssignedDeclarationItems;
        }
        return null;
    }

    onDragEnd(result: any) {
        const { source, destination } = result;
        if (!result.destination) {
            this.setState({
                dragStartEvent: null,
                dragUpdateEvent: null,
            });
            return;
        }

        if (source.droppableId === destination.droppableId) {
            if (source.droppableId === VirtualDnDProcessDefinitionModal.AVAILABLE_TASKS_SECTION_NAME) {
                const items = DragNDropUtils.reorderList(
                    this.state.filteredAvailableTaskItems,
                    source.index,
                    destination.index
                );
                this.setState({ filteredAvailableTaskItems: items });
            } else if (source.droppableId === VirtualDnDProcessDefinitionModal.ASSIGNED_TASKS_SECTION_NAME) {
                const items = DragNDropUtils.reorderList(
                    this.state.filteredAssignedTaskItems,
                    source.index,
                    destination.index
                );
                this.setState({ filteredAssignedTaskItems: items });
            } else if (source.droppableId === VirtualDnDProcessDefinitionModal.AVAILABLE_DECLARATIONS_SECTION_NAME) {
                const items = DragNDropUtils.reorderList(
                    this.state.filteredAvailableDeclarationItems,
                    source.index,
                    destination.index
                );
                this.setState({ filteredAvailableDeclarationItems: items });
            } else if (source.droppableId === VirtualDnDProcessDefinitionModal.ASSIGNED_DECLARATIONS_SECTION_NAME) {
                const items = DragNDropUtils.reorderList(
                    this.state.filteredAssignedDeclarationItems,
                    source.index,
                    destination.index
                );
                this.setState({ filteredAssignedDeclarationItems: items });
            }
        } else {
            const result: any = DragNDropUtils.moveBetweenLists(
                this.getList(source.droppableId) || [],
                this.getList(destination.droppableId) || [],
                source.index,
                destination.index
            );

            if (source.droppableId === VirtualDnDProcessDefinitionModal.AVAILABLE_TASKS_SECTION_NAME) {
                this.setState({ filteredAvailableTaskItems: result[0], filteredAssignedTaskItems: result[1], })
            } else if (source.droppableId === VirtualDnDProcessDefinitionModal.AVAILABLE_DECLARATIONS_SECTION_NAME) {
                this.setState({ filteredAvailableDeclarationItems: result[0], filteredAssignedDeclarationItems: result[1], })
            } else if (source.droppableId === VirtualDnDProcessDefinitionModal.ASSIGNED_TASKS_SECTION_NAME) {
                this.setState({ filteredAssignedTaskItems: result[0], filteredAvailableTaskItems: result[1], })
            } else if (source.droppableId === VirtualDnDProcessDefinitionModal.ASSIGNED_DECLARATIONS_SECTION_NAME) {
                this.setState({ filteredAssignedDeclarationItems: result[0], filteredAvailableDeclarationItems: result[1], })
            }
        }

        this.setState({
            dragStartEvent: null,
            dragUpdateEvent: null
        });
    }

    handleDragStart(event: any) {
        this.setState({
            dragStartEvent: event,
            dragUpdateEvent: null,
        });
    }

    handleDragUpdate(event: any) {
        this.setState({
            dragStartEvent: null,
            dragUpdateEvent: event,
        });
    }

    setSearchValue(sectionName: string, value: string) {
        let statePropName = '';
        switch (sectionName) {
            case VirtualDnDProcessDefinitionModal.AVAILABLE_TASKS_SECTION_NAME: {
                const availableClone = Array.from(this.state.allAvailableTaskItems);
                const filteredAvailable = availableClone.filter(item => item.title.includes(value));
                this.setState({ filteredAvailableTaskItems: filteredAvailable });
                statePropName = 'availableTasksSearch';
            }
                break;
            case VirtualDnDProcessDefinitionModal.AVAILABLE_DECLARATIONS_SECTION_NAME: {
                const availableClone = Array.from(this.state.allAvailableDeclarationItems);
                const filteredAvailable = availableClone.filter(item => item.title.includes(value));
                this.setState({ filteredAvailableDeclarationItems: filteredAvailable });
                statePropName = 'availableDeclarationsSearch';
            }
                break;
            case VirtualDnDProcessDefinitionModal.ASSIGNED_TASKS_SECTION_NAME: {
                const assignedClone = Array.from(this.state.allAssignedTaskItems);
                const filteredAssigned = assignedClone.filter(item => item.title.includes(value));
                this.setState({ filteredAssignedTaskItems: filteredAssigned });
                statePropName = 'assignedTasksSearch';
            }
                break;
            case VirtualDnDProcessDefinitionModal.ASSIGNED_DECLARATIONS_SECTION_NAME: {
                const assignedClone = Array.from(this.state.allAssignedTaskItems);
                const filteredAssigned = assignedClone.filter(item => item.title.includes(value));
                this.setState({ filteredAssignedDeclarationItems: filteredAssigned });
                statePropName = 'assignedDeclarationsSearch';
                break;
            }
            default:
                return;
        }
        this.setState({ [statePropName]: value })
    }

    render() {
        return (
            <MainContainerScrollable>
                <Button dataUI={getDataUI()} className="mb-m" icon="fal fa-grip-vertical" onClick={() => this.setState({ isModalVisible: true })}>Drag-n-Drop Selection Modal</Button>
                <Modal isHeightExpanded={this.state.currentTabIndex === 1 || this.state.currentTabIndex === 3} dataUI={getDataUI()} title="Select columns" isVisible={this.state.isModalVisible} size="L" onClose={() => this.setState({ isModalVisible: false })}>
                    <Toolbar
                        dataUI={getDataUI()}
                        className="mb-m"
                        leftSide={
                            <>
                                <Button dataUI={getDataUI()} icon="fal fa-save">Save</Button>
                            </>
                        }
                    />
                    <DragNDropContext onDragStart={this.handleDragStart.bind(this)} onDragUpdate={this.handleDragUpdate.bind(this)} onDragEnd={this.onDragEnd.bind(this)}>
                        <Tabs
                            dataUI={getDataUI()}
                            onTabIndexChange={(index: number) => this.updateTabIndex(index)}
                            currentlyActiveTabIndex={this.state.currentTabIndex}
                        >
                            <TabList dataUI={getDataUI()}>
                                <TabItem dataUI={getDataUI()}>
                                    <TabItemTitle dataUI={getDataUI()}>Details</TabItemTitle>
                                </TabItem>
                                <TabItem dataUI={getDataUI()}>
                                    <TabItemTitle dataUI={getDataUI()}>Tasks (prerendered & async)</TabItemTitle>
                                </TabItem>
                                <TabItem dataUI={getDataUI()}>
                                    <TabItemTitle dataUI={getDataUI()}>Narratives</TabItemTitle>
                                </TabItem>
                                <TabItem dataUI={getDataUI()}>
                                    <TabItemTitle dataUI={getDataUI()}>Declarations</TabItemTitle>
                                </TabItem>
                            </TabList>
                            <TabContent dataUI={getDataUI()}>
                                <TabContentItem dataUI={getDataUI()}>Details tab content</TabContentItem>
                                <TabContentItem dataUI={getDataUI()} isPrerendered>
                                    {this.state.isFetching && (
                                        <div className="lv-loader-container">
                                            <div className="lv-loader-big">
                                                <div /><div /><div /><div /><div /><div /><div /><div />
                                            </div>
                                        </div>
                                    )}
                                    <Row className="height-full">
                                        <Col xs={6}>
                                            <DynamicScrollableContainer dataUI={getDataUI()}>
                                                <DragNDropSection dataUI={getDataUI()}>
                                                    <DragNDropSectionHeader dataUI={getDataUI()}>
                                                        <div className="d-flex justify-content-between align-items-center mb-m">
                                                            <h2>Available ({this.state.filteredAvailableTaskItems.length})</h2>
                                                            <Button dataUI={getDataUI()} onClick={() => this.addAllToAssigned(VirtualDnDProcessDefinitionModal.ASSIGNED_TASKS_SECTION_NAME)} btnType="secondary" icon="fal fa-file-import">Add all</Button>
                                                        </div>
                                                        <Input
                                                            dataUI={getDataUI()}
                                                            name="input-icon"
                                                            value={this.state.availableTasksSearch}
                                                            onChange={(ev) => this.setSearchValue(VirtualDnDProcessDefinitionModal.AVAILABLE_TASKS_SECTION_NAME, ev.target.value)}
                                                            icon='fal fa-search'
                                                        />
                                                    </DragNDropSectionHeader>
                                                    <VirtualizedDroparea dataUI={getDataUI()} dropareaID={VirtualDnDProcessDefinitionModal.AVAILABLE_TASKS_SECTION_NAME} allowedItems={[VirtualDnDProcessDefinitionModal.AVAILABLE_TASKS_SECTION_NAME, VirtualDnDProcessDefinitionModal.ASSIGNED_TASKS_SECTION_NAME]}
                                                        dragStartEvent={this.state.dragStartEvent}
                                                        dragUpdateEvent={this.state.dragUpdateEvent}
                                                        items={this.state.filteredAvailableTaskItems}
                                                        itemIdKey="id"
                                                        itemTemplate={(item) => (
                                                            <>
                                                                <DraggableItemActions dataUI={getDataUI()}>
                                                                    <DraggableItemTitle dataUI={getDataUI()}><a href="./process-definition-modal" className="lv-link-styles">{item.title}</a></DraggableItemTitle>
                                                                </DraggableItemActions>
                                                                <DraggableItemActions dataUI={getDataUI()}>
                                                                    <Tooltip title="Move">
                                                                        <Button dataUI={getDataUI()} onClick={() => this.moveAvailableItemToAssigned(VirtualDnDProcessDefinitionModal.AVAILABLE_TASKS_SECTION_NAME, item.id)} btnType="icon" icon="fal fa-arrow-right" />
                                                                    </Tooltip>
                                                                </DraggableItemActions>
                                                            </>
                                                        )}
                                                    />
                                                </DragNDropSection>
                                            </DynamicScrollableContainer>
                                        </Col>

                                        <Col xs={6}>
                                            <DynamicScrollableContainer dataUI={getDataUI()}>
                                                <DragNDropSection dataUI={getDataUI()}>
                                                    <DragNDropSectionHeader dataUI={getDataUI()}>
                                                        <div className="d-flex justify-content-between align-items-center mb-m">
                                                            <h2>Assigned ({this.state.filteredAssignedTaskItems.length})</h2>
                                                            <Button dataUI={getDataUI()} onClick={() => this.removeAllAssigned(VirtualDnDProcessDefinitionModal.AVAILABLE_TASKS_SECTION_NAME)} btnType="secondary" icon="fal fa-file-import">Remove all</Button>
                                                        </div>
                                                        <Input
                                                            dataUI={getDataUI()}
                                                            name="input-icon"
                                                            value={this.state.assignedTasksSearch}
                                                            onChange={(ev) => this.setSearchValue(VirtualDnDProcessDefinitionModal.ASSIGNED_TASKS_SECTION_NAME, ev.target.value)}
                                                            icon='fal fa-search'
                                                        />
                                                    </DragNDropSectionHeader>
                                                    <VirtualizedDroparea
                                                        dataUI={getDataUI()}
                                                        dropareaID={VirtualDnDProcessDefinitionModal.ASSIGNED_TASKS_SECTION_NAME}
                                                        allowedItems={[VirtualDnDProcessDefinitionModal.ASSIGNED_TASKS_SECTION_NAME, VirtualDnDProcessDefinitionModal.AVAILABLE_TASKS_SECTION_NAME]}
                                                        dragStartEvent={this.state.dragStartEvent}
                                                        dragUpdateEvent={this.state.dragUpdateEvent}
                                                        items={this.state.filteredAssignedTaskItems}
                                                        itemIdKey="id"
                                                        itemTemplate={(item) => (
                                                            <>
                                                                <DraggableItemActions dataUI={getDataUI()}>
                                                                    <DraggableItemTitle dataUI={getDataUI()}>{item.title}</DraggableItemTitle>
                                                                </DraggableItemActions>
                                                                <DraggableItemActions dataUI={getDataUI()}>
                                                                    <Tooltip title="Remove">
                                                                        <Button dataUI={getDataUI()} onClick={() => this.returnItemToAvailable(VirtualDnDProcessDefinitionModal.AVAILABLE_TASKS_SECTION_NAME, item.id)} btnType="icon" icon="fal fa-times" />
                                                                    </Tooltip>
                                                                </DraggableItemActions>
                                                            </>
                                                        )}
                                                    />
                                                </DragNDropSection>
                                            </DynamicScrollableContainer>
                                        </Col>
                                    </Row>

                                </TabContentItem>
                                <TabContentItem dataUI={getDataUI()}>Narratives tab content</TabContentItem>
                                <TabContentItem dataUI={getDataUI()}>
                                    <Row className="height-full">
                                        <Col xs={6}>
                                            <DynamicScrollableContainer dataUI={getDataUI()}>
                                                <DragNDropSection dataUI={getDataUI()}>
                                                    <DragNDropSectionHeader dataUI={getDataUI()}>
                                                        <div className="d-flex justify-content-between align-items-center mb-m">
                                                            <h2>Available ({this.state.filteredAvailableDeclarationItems.length})</h2>
                                                            <Button dataUI={getDataUI()} onClick={() => this.addAllToAssigned(VirtualDnDProcessDefinitionModal.ASSIGNED_DECLARATIONS_SECTION_NAME)} btnType="secondary" icon="fal fa-file-import">Add all</Button>
                                                        </div>
                                                        <Input
                                                            dataUI={getDataUI()}
                                                            name="input-icon"
                                                            value={this.state.availableDeclarationsSearch}
                                                            onChange={(ev) => this.setSearchValue(VirtualDnDProcessDefinitionModal.AVAILABLE_DECLARATIONS_SECTION_NAME, ev.target.value)}
                                                            icon='fal fa-search'
                                                        />
                                                    </DragNDropSectionHeader>
                                                    <VirtualizedDroparea dataUI={getDataUI()} dropareaID={VirtualDnDProcessDefinitionModal.AVAILABLE_DECLARATIONS_SECTION_NAME} allowedItems={[VirtualDnDProcessDefinitionModal.AVAILABLE_DECLARATIONS_SECTION_NAME, VirtualDnDProcessDefinitionModal.ASSIGNED_DECLARATIONS_SECTION_NAME]}
                                                        dragStartEvent={this.state.dragStartEvent}
                                                        dragUpdateEvent={this.state.dragUpdateEvent}
                                                        items={this.state.filteredAvailableDeclarationItems}
                                                        itemIdKey="id"
                                                        itemTemplate={(item) => (
                                                            <>
                                                                <DraggableItemActions dataUI={getDataUI()}>
                                                                    <DraggableItemTitle dataUI={getDataUI()}>{item.title}</DraggableItemTitle>
                                                                </DraggableItemActions>
                                                                <DraggableItemActions dataUI={getDataUI()}>
                                                                    <Tooltip title="Move">
                                                                        <Button dataUI={getDataUI()} onClick={() => this.moveAvailableItemToAssigned(VirtualDnDProcessDefinitionModal.AVAILABLE_DECLARATIONS_SECTION_NAME, item.id)} btnType="icon" icon="fal fa-arrow-right" />
                                                                    </Tooltip>
                                                                </DraggableItemActions>
                                                            </>
                                                        )}
                                                    />
                                                </DragNDropSection>
                                            </DynamicScrollableContainer>
                                        </Col>

                                        <Col xs={6}>
                                            <DynamicScrollableContainer dataUI={getDataUI()}>
                                                <DragNDropSection dataUI={getDataUI()}>
                                                    <DragNDropSectionHeader dataUI={getDataUI()}>
                                                        <div className="d-flex justify-content-between align-items-center mb-m">
                                                            <h2>Assigned ({this.state.filteredAssignedDeclarationItems.length})</h2>
                                                            <Button dataUI={getDataUI()} onClick={() => this.removeAllAssigned(VirtualDnDProcessDefinitionModal.AVAILABLE_DECLARATIONS_SECTION_NAME)} btnType="secondary" icon="fal fa-file-import">Remove all</Button>
                                                        </div>
                                                        <Input
                                                            dataUI={getDataUI()}
                                                            name="input-icon"
                                                            value={this.state.assignedDeclarationsSearch}
                                                            onChange={(ev) => this.setSearchValue(VirtualDnDProcessDefinitionModal.ASSIGNED_DECLARATIONS_SECTION_NAME, ev.target.value)}
                                                            icon='fal fa-search'
                                                        />
                                                    </DragNDropSectionHeader>
                                                    <VirtualizedDroparea
                                                        dataUI={getDataUI()}
                                                        dropareaID={VirtualDnDProcessDefinitionModal.ASSIGNED_DECLARATIONS_SECTION_NAME}
                                                        allowedItems={[VirtualDnDProcessDefinitionModal.ASSIGNED_DECLARATIONS_SECTION_NAME, VirtualDnDProcessDefinitionModal.AVAILABLE_DECLARATIONS_SECTION_NAME]}
                                                        dragStartEvent={this.state.dragStartEvent}
                                                        dragUpdateEvent={this.state.dragUpdateEvent}
                                                        items={this.state.filteredAssignedDeclarationItems}
                                                        itemIdKey="id"
                                                        itemTemplate={(item) => (
                                                            <>
                                                                <DraggableItemActions dataUI={getDataUI()}>
                                                                    <DraggableItemTitle dataUI={getDataUI()}>{item.title}</DraggableItemTitle>
                                                                </DraggableItemActions>
                                                                <DraggableItemActions dataUI={getDataUI()}>
                                                                    <Label dataUI={getDataUI()} className="text-muted">Label</Label>
                                                                    <Select dataUI={getDataUI()} className="width-m" placeholder="Select" options={[{ label: 'Test 1', value: 'test 1' }, { label: 'Test 2', value: 'test 2' }]} value="" />
                                                                    <Tooltip title="Remove">
                                                                        <Button dataUI={getDataUI()} onClick={() => this.returnItemToAvailable(VirtualDnDProcessDefinitionModal.AVAILABLE_DECLARATIONS_SECTION_NAME, item.id)} btnType="icon" icon="fal fa-times" />
                                                                    </Tooltip>
                                                                </DraggableItemActions>
                                                            </>
                                                        )}
                                                    />
                                                </DragNDropSection>
                                            </DynamicScrollableContainer>
                                        </Col>
                                    </Row>
                                </TabContentItem>
                            </TabContent>
                        </Tabs>
                    </DragNDropContext>
                </Modal>
            </MainContainerScrollable>);
    };
}

export default VirtualDnDProcessDefinitionModal;
