import * as React from 'react';
import {
    Grid, GridColumn, GridContainer, GridCellProps, Button, Tooltip, Select
} from '@jkhy/vsg-loanvantage-design-system';

import products from './products.json';
import { getDataUI } from '../../helpers/helpers';

interface PageState {
    skip: number,
    take: number
}

const initialDataState: PageState = { skip: 0, take: 8 };

const selectOptions = [
    { value: '1', label: 'Option 1' },
    { value: '2', label: 'Option 2', secondCode: 'code-123' },
    { value: '3', label: 'Option 3', isSomething: false },
    { value: '4', label: 'Option 4' }
];

const KendoTable = () => {
    const [page, setPage] = React.useState<PageState>(initialDataState);

    const pageChange = (event: any) => {
        setPage(event.page);
    }

    const renderDeleteBtnCell = (cellProps: GridCellProps) => (
        <td className={cellProps.className}>
            <div className='d-flex justify-content-center'>
                <Tooltip title="Delete">
                    <Button
                        btnType="icon"
                        dataUI={getDataUI()}
                        icon='fal fa-trash'
                    />
                </Tooltip>
            </div>
        </td>
    );

    const renderSelectCell = (cellProps: GridCellProps) => (
        <td className={cellProps.className}>
            <Select value={''} options={selectOptions} dataUI={getDataUI()} />
        </td>
    );

    return (

        <GridContainer dataUI={getDataUI()}
            style={{ height: '100%', maxHeight: '100%' }}
            className="d-flex">
            <Grid
                style={{ height: '100%', maxHeight: '100%' }}
                data={products.slice(page.skip, page.take + page.skip)}
                skip={page.skip}
                take={page.take}
                total={products.length}
                pageable={true}
                onPageChange={pageChange}
                className="mb-0"
            >
                <GridColumn title='Input Fields' headerClassName='background-wq-item-bg-color-blue border-color-text text-center'>
                    <GridColumn field="ProductID" title='#' width='42' />
                    <GridColumn field="ProductID" title=' ' cell={renderDeleteBtnCell} width="42" />
                    <GridColumn field="ProductID" title="AutoDecisionFlag" cell={renderSelectCell} />
                    <GridColumn field="ProductID" title="TitledType" cell={renderSelectCell} />
                    <GridColumn field="ProductID" title="NewFlag" cell={renderSelectCell} />
                    <GridColumn field="ProductID" title='DemoFlag' headerClassName='border-color-text' cell={renderSelectCell} />
                </GridColumn>
                <GridColumn title='Output Fields' headerClassName='background-color-select text-center'>
                    <GridColumn field="ProductName" title="ProcessCode" className='border-color-text' cell={renderSelectCell} />
                    <GridColumn field="ProductName" title="LoanAction" cell={renderSelectCell} />
                    <GridColumn field="ProductName" title="ProductCode" cell={renderSelectCell} />
                    <GridColumn field="ProductName" title="CollateralType" cell={renderSelectCell} />
                    <GridColumn field="ProductName" title="SecuredBy" cell={renderSelectCell} />
                </GridColumn>
            </Grid>
        </GridContainer>


    );
}

export default KendoTable;