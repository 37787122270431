import React from 'react';
import Highlight from 'react-highlight';

const PortalHeaderPage: React.FC = () => {

    return <div>
        <h1 className="mb-s">PortalHeader</h1>
        <p className="mb-s">
            Additional branding section, positioned above the main Header component. Contains
            the Loan Vantage logo and the specific bank logo.
        </p>
        <p className="mb-l">An example can be seen <a href='/home' title="Portal header example">here</a>.</p>

        <Highlight className="React">
            {
                `
import React from 'react';
import { PortalHeader } from 'vsg-design-system';
import { getDataUI } from '../../helpers/helpers';

const Example: React.FC = () => {
    return (
        <PortalHeader
            dataUI={getDataUI()}
            loanVantageLogoLight={{ img: './img/JackHenryLending_LoanVantage_Logo_navy.png', alt: 'JackHenry Logo' }}
            loanVantageLogoDark={{ img: './img/JackHenryLending_LoanVantage_Logo_white.png', alt: 'JackHenry Logo' }}
            bankLogoLight={{ img: './img/bank-logo-white.png', alt: 'Bank logo' }}
            bankLogoDark={{ img: './img/bank-logo-dark.png', alt: 'Bank logo' }}
            isDark={isDark}
        />
    );
}
export default Example;
    `}
        </Highlight>

        <h2 className="mb-m">API</h2>
        <table className="doc-table mb-l">
            <thead>
                <tr>
                    <th colSpan={4}>Props</th>
                </tr>
                <tr>
                    <td>
                        <strong>Name</strong>
                    </td>
                    <td>
                        <strong>Options</strong>
                    </td>
                    <td>
                        <strong>Default</strong>
                    </td>
                    <td>
                        <strong>Description</strong>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>loanVantageLogoLight</td>
                    <td>object with: "img": string, "alt": string</td>
                    <td className="text-center">-</td>
                    <td>Sets the image URL and ALT of the logo on the left side for light theme.</td>
                </tr>
                <tr>
                    <td>loanVantageLogoDark</td>
                    <td>object with: "img": string, "alt": string</td>
                    <td className="text-center">-</td>
                    <td>Sets the image URL and ALT of the logo on the left side for dark theme.</td>
                </tr>
                <tr>
                    <td>bankLogoLight</td>
                    <td>object with: "img": string, "alt": string</td>
                    <td className="text-center">-</td>
                    <td>Sets the image URL and ALT of the custom bank logo on the right side for light theme.</td>
                </tr>
                <tr>
                    <td>bankLogoDark</td>
                    <td>object with: "img": string, "alt": string</td>
                    <td className="text-center">-</td>
                    <td>Sets the image URL and ALT of the custom bank logo on the right side for dark theme.</td>
                </tr>
                <tr>
                    <td>isDark</td>
                    <td>boolean</td>
                    <td>false</td>
                    <td>Shows the dark logos.</td>
                </tr>
                <tr>
                    <td>onLoanVantageLogoClick</td>
                    <td>() {'=>'} void</td>
                    <td>null</td>
                    <td>Makes the Loan Vantage logo clickable and sets the passed in function as a callback.</td>
                </tr>
                <tr>
                    <td>onBankLogoClick</td>
                    <td>() {'=>'} void</td>
                    <td>null</td>
                    <td>Makes the bank logo clickable and sets the passed in function as a callback.</td>
                </tr>
            </tbody>
        </table>
    </div>;
}

export default PortalHeaderPage;
