import React from 'react';

import {
    MainContainerHeader, ReactAux, Collapse,
    ActionContainer, ActionContainerHeader, ActionContainerCrumbs,
    ActionSettings, ActionSettingsForm, LinkWithIcon, Button, Select,
    MainContainerScrollable,
} from '@jkhy/vsg-loanvantage-design-system';

import DemoLocationPage from './DemoLocationPage';
import { getDataUI } from '../helpers/helpers';

class DemoScroll5 extends React.Component {

    state = {
        collapseDemo2: true,
        collapseDemo3: true,
        collapseDemo4: true,
        actionContainerActiveFormIndex: -1,
        isFirstActionDisabled: false,
        isActionContainerLocked: false,
        isLockDisabled: false,
        selectValue1: '1',
        selectValue2: '1',
    }

    openActionContainerForm(formIndex: number) {
        this.setState({ actionContainerActiveFormIndex: formIndex });
    }

    toggleActiveActionSetting() {
        this.setState({ isFirstActionDisabled: !this.state.isFirstActionDisabled });
        this.openActionContainerForm(-1);
    }

    toggleActionContainerLocked() {
        this.setState({ isActionContainerLocked: !this.state.isActionContainerLocked });
    }

    toggleLockDisabled() {
        this.setState({ isLockDisabled: !this.state.isLockDisabled });
        this.openActionContainerForm(-1);
    }

    render() {

        return (
            <ReactAux>
                <MainContainerHeader dataUI={getDataUI()}>
                    <ActionContainer dataUI={getDataUI()}>
                        <ActionContainerHeader dataUI={getDataUI()}>
                            <ActionContainerCrumbs dataUI={getDataUI()} crumbs={[<a href="/">Loan Collateral</a>, '36465: Loan Request']} />
                            <ActionSettings dataUI={getDataUI()}>
                                <LinkWithIcon dataUI={getDataUI()} disabled={this.state.isFirstActionDisabled} icon="fal fa-cog"><a data-tip="Click to edit process definitions" onClick={(e) => { e.preventDefault(); this.openActionContainerForm(1) }} href="/">Commercial loan request</a></LinkWithIcon>
                                <LinkWithIcon dataUI={getDataUI()} icon="fal fa-eye"><a onClick={(e) => { e.preventDefault(); this.openActionContainerForm(2) }} href="/">Collateral Fields mode</a></LinkWithIcon>
                                <Button dataUI={getDataUI()} disabled={this.state.isLockDisabled} data-tip="All changes are allowed" onClick={() => this.toggleActionContainerLocked()} btnType="icon" icon={this.state.isActionContainerLocked ? 'fal fa-lock' : 'fal fa-lock-open'}></Button>
                            </ActionSettings>
                            <ActionSettingsForm dataUI={getDataUI()} isActive={this.state.actionContainerActiveFormIndex === 1}>
                                <p>Process definitions:</p>
                                <Select
                                    dataUI={getDataUI()}
                                    className="width-m"
                                    value={this.state.selectValue1}
                                    onChange={(obj: any) => this.setState({ selectValue1: obj.value })}
                                    options={[{ value: '1', label: 'Commercial loan request' }, { value: '2', label: 'test 2' }]}
                                />
                                <Button dataUI={getDataUI()} onClick={() => this.toggleLockDisabled()} icon="fal fa-save">Update</Button>
                                <Button dataUI={getDataUI()} onClick={() => this.openActionContainerForm(-1)} btnType="secondary" icon="fal fa-times">Cancel</Button>
                            </ActionSettingsForm>
                            <ActionSettingsForm dataUI={getDataUI()} isActive={this.state.actionContainerActiveFormIndex === 2}>
                                <p>Process definitions:</p>
                                <Select
                                    dataUI={getDataUI()}
                                    className="width-m"
                                    value={this.state.selectValue2}
                                    onChange={(obj: any) => this.setState({ selectValue2: obj.value })}
                                    options={[{ value: '1', label: 'Disable the previous action control' }, { value: '2', label: 'test 2' }]}
                                />
                                <Button dataUI={getDataUI()} icon="fal fa-save" onClick={() => this.toggleActiveActionSetting()}>Update</Button>
                                <Button dataUI={getDataUI()} onClick={() => this.openActionContainerForm(-1)} btnType="secondary" icon="fal fa-times">Cancel</Button>
                            </ActionSettingsForm>
                        </ActionContainerHeader>
                        <Button dataUI={getDataUI()} icon="fal fa-pen">Edit</Button>
                        <Button dataUI={getDataUI()} btnType="secondary" icon="fal fa-percent">LTV</Button>
                        <Button dataUI={getDataUI()} btnType="secondary" icon="fal fa-expand">Spreading</Button>
                        <Button dataUI={getDataUI()} btnType="secondary" icon="fal fa-coins">Appraisal</Button>
                        <Button dataUI={getDataUI()} btnType="secondary" icon="fal fa-map-marker-alt">Visit</Button>
                    </ActionContainer>
                </MainContainerHeader>

                <MainContainerScrollable>
                    <DemoLocationPage className="mt-m" />
                    <Collapse
                        dataUI={getDataUI()}
                        title="Colapse 2"
                        expanded={this.state.collapseDemo2}
                        onClick={() => this.setState({ collapseDemo2: !this.state.collapseDemo2 })}
                    >
                        <p>Colapse 2 Content...</p>
                    </Collapse>
                    <Collapse
                        dataUI={getDataUI()}
                        title="Colapse 3"
                        expanded={this.state.collapseDemo3}
                        onClick={() => this.setState({ collapseDemo3: !this.state.collapseDemo3 })}
                    >
                        <p>Colapse 3 Content...</p>
                    </Collapse>
                    <Collapse
                        dataUI={getDataUI()}
                        title="Colapse 4"
                        expanded={this.state.collapseDemo4}
                        onClick={() => this.setState({ collapseDemo4: !this.state.collapseDemo4 })}
                    >
                        <p>Colapse 4 Content...</p>
                    </Collapse>
                </MainContainerScrollable>

                <div className="mt-m">
                    <h1>Static bottom part</h1>
                    <p>Lorem</p>
                </div>
            </ReactAux>
        );
    }
}

export default DemoScroll5;