import React, { useState, useEffect } from 'react';

import {
    Header, HeaderMenu, DropdownMenu, MenuItem, HeaderButtonIcon,
    HeaderRightSection, HeaderInfo, themeChangerHelpers, ThemeChanger
} from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../helpers/helpers';

interface DemoHeaderProps {
    simple?: boolean
}

const DemoHeaderAdmin = (props: DemoHeaderProps) => {

    const { simple } = props;

    const [theme, setTheme] = useState('navy');

    const [isOpenIndex, setIsOpenIndex] = useState([
        false, false, false, false, false, false, false, false, false, false, false
    ])
    const onToggleDropDown = (index: any) => {
        const copyOpenIndexes = [...isOpenIndex];
        if (copyOpenIndexes[index]) {
            copyOpenIndexes[index] = false;
        } else {
            copyOpenIndexes.forEach((el, i) => {
                if (i === index) {
                    copyOpenIndexes[i] = true;
                } else {
                    copyOpenIndexes[i] = false;
                }
            });
        }
        setIsOpenIndex(copyOpenIndexes)
    }

    useEffect(() => {
        setTheme('navy');
    }, [])

    return (
        <>
            <Header>
                <DropdownMenu
                    dataUI={getDataUI()}
                    onToggleDropdownMenu={() => onToggleDropDown(0)}
                    isOpen={isOpenIndex[0]}
                    icon='fal fa-bars'
                >
                    <MenuItem dataUI={getDataUI()} title={'Hello, VSG'} avatar={{ src: 'https://www.w3schools.com/howto/img_avatar2.png', alt: 'avatarTest' }} noHoverEffect />
                    <MenuItem dataUI={getDataUI()} title={'My Settings'} />
                    <MenuItem dataUI={getDataUI()} title={'Sign Out'} icon={{ name: 'fal fa-sign-out-alt' }} />
                    <MenuItem dataUI={getDataUI()} title={'Approval Log'} />
                    <MenuItem dataUI={getDataUI()} title={'Dashboard'} />
                    <MenuItem dataUI={getDataUI()} title={'FPC'} />
                    <MenuItem dataUI={getDataUI()} title={'Change Theme'} icon={{ name: 'fal fa-palette', color: 'text-primary' }}>
                        {themeChangerHelpers.THEMES.map((t: any, i: any) => {
                            return <MenuItem dataUI={getDataUI()}
                                key={i}
                                className={`lv-theme-menu-${t.base}`}
                                title={t.title}
                                icon={{ name: `fas fa-${theme === t.name ? 'check-circle' : 'circle'}`, color: `lv-theme-${t.name}-primary` }}
                                onClick={() => setTheme(t.name)}
                            />
                        })}
                    </MenuItem>
                </DropdownMenu>

                {!simple &&
                    <HeaderMenu dataUI={getDataUI()}>
                        <DropdownMenu
                            dataUI={getDataUI()}
                            title={'Users Access'}
                            onToggleDropdownMenu={() => onToggleDropDown(1)}
                            isOpen={isOpenIndex[1]}
                        >
                            <MenuItem dataUI={getDataUI()} title={'Level 1'} />
                            <MenuItem dataUI={getDataUI()} title={'Level 1'} >
                                <MenuItem dataUI={getDataUI()} title={'Level 2'} />
                                <MenuItem dataUI={getDataUI()} title={'Level 2'} />
                            </MenuItem>
                            <MenuItem dataUI={getDataUI()} title={'Level 1'} />
                        </DropdownMenu>


                        <DropdownMenu
                            dataUI={getDataUI()}
                            title={'File Management'}
                            onToggleDropdownMenu={() => onToggleDropDown(2)}
                            isOpen={isOpenIndex[2]}
                        >
                            <MenuItem dataUI={getDataUI()} title={'Level 1'} />
                            <MenuItem dataUI={getDataUI()} title={'Level 1'} >
                                <MenuItem dataUI={getDataUI()} title={'Level 2'} />
                                <MenuItem dataUI={getDataUI()} title={'Level 2'} />
                            </MenuItem>
                            <MenuItem dataUI={getDataUI()} title={'Level 1'} />
                        </DropdownMenu>

                        <DropdownMenu
                            dataUI={getDataUI()}
                            title={'System Settings'}
                            onToggleDropdownMenu={() => onToggleDropDown(3)}
                            isOpen={isOpenIndex[3]}
                        >
                            <MenuItem dataUI={getDataUI()} title={'Level 1'} />
                            <MenuItem dataUI={getDataUI()} title={'Level 1'} >
                                <MenuItem dataUI={getDataUI()} title={'Level 2'} />
                                <MenuItem dataUI={getDataUI()} title={'Level 2'} />
                            </MenuItem>
                            <MenuItem dataUI={getDataUI()} title={'Level 1'} />
                        </DropdownMenu>

                        <DropdownMenu
                            dataUI={getDataUI()}
                            title={'Tracking Items'}
                            onToggleDropdownMenu={() => onToggleDropDown(4)}
                            isOpen={isOpenIndex[4]}
                        >
                            <MenuItem dataUI={getDataUI()} title={'Level 1'} />
                            <MenuItem dataUI={getDataUI()} title={'Level 1'} >
                                <MenuItem dataUI={getDataUI()} title={'Level 2'} />
                                <MenuItem dataUI={getDataUI()} title={'Level 2'} />
                            </MenuItem>
                            <MenuItem dataUI={getDataUI()} title={'Level 1'} />
                        </DropdownMenu>

                        <DropdownMenu
                            dataUI={getDataUI()}
                            title={'Work Flow'}
                            onToggleDropdownMenu={() => onToggleDropDown(5)}
                            isOpen={isOpenIndex[5]}
                        >
                            <MenuItem dataUI={getDataUI()} title={'Level 1'} />
                            <MenuItem dataUI={getDataUI()} title={'Level 1'} >
                                <MenuItem dataUI={getDataUI()} title={'Level 2'} />
                                <MenuItem dataUI={getDataUI()} title={'Level 2'} />
                            </MenuItem>
                            <MenuItem dataUI={getDataUI()} title={'Level 1'} />
                        </DropdownMenu>

                        <DropdownMenu
                            dataUI={getDataUI()}
                            title={'Integrations'}
                            onToggleDropdownMenu={() => onToggleDropDown(6)}
                            isOpen={isOpenIndex[6]}
                        >
                            <MenuItem dataUI={getDataUI()} title={'Level 1'} />
                            <MenuItem dataUI={getDataUI()} title={'Level 1'} >
                                <MenuItem dataUI={getDataUI()} title={'Level 2'} />
                                <MenuItem dataUI={getDataUI()} title={'Level 2'} />
                            </MenuItem>
                            <MenuItem dataUI={getDataUI()} title={'Level 1'} />
                        </DropdownMenu>

                        <DropdownMenu
                            dataUI={getDataUI()}
                            title={'Approval'}
                            onToggleDropdownMenu={() => onToggleDropDown(7)}
                            isOpen={isOpenIndex[7]}
                        >
                            <MenuItem dataUI={getDataUI()} title={'Level 1'} />
                            <MenuItem dataUI={getDataUI()} title={'Level 1'} >
                                <MenuItem dataUI={getDataUI()} title={'Level 2'} />
                                <MenuItem dataUI={getDataUI()} title={'Level 2'} />
                            </MenuItem>
                            <MenuItem dataUI={getDataUI()} title={'Level 1'} />
                        </DropdownMenu>

                        <DropdownMenu
                            dataUI={getDataUI()}
                            title={'Organization'}
                            onToggleDropdownMenu={() => onToggleDropDown(8)}
                            isOpen={isOpenIndex[8]}
                        >
                            <MenuItem dataUI={getDataUI()} title={'Level 1'} />
                            <MenuItem dataUI={getDataUI()} title={'Level 1'} >
                                <MenuItem dataUI={getDataUI()} title={'Level 2'} />
                                <MenuItem dataUI={getDataUI()} title={'Level 2'} />
                            </MenuItem>
                            <MenuItem dataUI={getDataUI()} title={'Level 1'} />
                        </DropdownMenu>

                        <DropdownMenu
                            dataUI={getDataUI()}
                            title={'Add-On Applications'}
                            onToggleDropdownMenu={() => onToggleDropDown(9)}
                            isOpen={isOpenIndex[9]}
                        >
                            <MenuItem dataUI={getDataUI()} title={'Level 1'} />
                            <MenuItem dataUI={getDataUI()} title={'Level 1'} >
                                <MenuItem dataUI={getDataUI()} title={'Level 2'} />
                                <MenuItem dataUI={getDataUI()} title={'Level 2'} />
                            </MenuItem>
                            <MenuItem dataUI={getDataUI()} title={'Level 1'} />
                        </DropdownMenu>

                        <DropdownMenu
                            dataUI={getDataUI()}
                            title={'Logs'}
                            onToggleDropdownMenu={() => onToggleDropDown(10)}
                            isOpen={isOpenIndex[10]}
                        >
                            <MenuItem dataUI={getDataUI()} title={'Level 1'} />
                            <MenuItem dataUI={getDataUI()} title={'Level 1'} >
                                <MenuItem dataUI={getDataUI()} title={'Level 2'} />
                                <MenuItem dataUI={getDataUI()} title={'Level 2'} />
                            </MenuItem>
                            <MenuItem dataUI={getDataUI()} title={'Level 1'} />
                        </DropdownMenu>
                    </HeaderMenu>

                }

                <HeaderRightSection dataUI={getDataUI()}>
                    <HeaderButtonIcon dataUI={getDataUI()} icon="fal fa-lock" notification={{ title: "3", type: 'danger' }} />
                    <HeaderButtonIcon dataUI={getDataUI()} icon="fal fa-stamp" notification={{ title: "9", type: 'danger' }} />
                    <HeaderInfo dataUI={getDataUI()} sync="03-01" user="IBS\Stoil" version="3.19.25" />
                </HeaderRightSection>
            </Header>
            <ThemeChanger theme={theme as any} />
        </>
    )
}


export default DemoHeaderAdmin