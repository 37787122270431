import React from 'react';
import Highlight from 'react-highlight';

import {
    WidgetContainer, SimpleTableContainer
} from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';


const WidgetContainerPage: React.FC = () => {

    return (
        <div>
            <h1 className="mb-l">Widget Container</h1>

            <p className="mb-m">Container for tables, charts...</p>

            <WidgetContainer dataUI={getDataUI()} className="mb-m" title={<a href='/'>Combined Ratio</a>}>
                Lorem Ipsum
            </WidgetContainer>

            <WidgetContainer dataUI={getDataUI()} title="Trade Line Summary">
                <SimpleTableContainer dataUI={getDataUI()}>
                    <table>
                        <thead>
                            <tr>
                                <th>Debt Type</th>
                                <th>Payment</th>
                                <th>Balance</th>
                            </tr>

                        </thead>
                        <tbody>
                            <tr>
                                <td>Installment</td>
                                <td className="text-right">$412</td>
                                <td className="text-right">$15,588</td>
                            </tr>
                            <tr>
                                <td>Mortage</td>
                                <td className="text-right">$2,143</td>
                                <td className="text-right">$255,079</td>
                            </tr>
                            <tr>
                                <td>Revolving</td>
                                <td className="text-right">$70</td>
                                <td className="text-right">$2,444</td>
                            </tr>
                            <tr>
                                <td className="text-bold">Total</td>
                                <td className="text-right text-bold">$2,625</td>
                                <td className="text-right text-bold">$273,111</td>
                            </tr>
                        </tbody>
                    </table>
                </SimpleTableContainer>
            </WidgetContainer>


            <Highlight className="React">
                {
                    `
import React, { useState } from 'react';

import { WidgetContainer, SimpleTableContainer } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

class Example extends React.Component {

    render() {
        return (
            <WidgetContainer dataUI={getDataUI()} className="mb-m" title={<a href='/'>Combined Ratio</a>}>
                Lorem Ipsum
            </WidgetContainer>

            <WidgetContainer dataUI={getDataUI()} title="Trade Line Summary">
                <SimpleTableContainer dataUI={getDataUI()}>
                    <table>
                        <thead>
                            <tr>
                                <th>Debt Type</th>
                                <th>Payment</th>
                                <th>Balance</th>
                            </tr>

                        </thead>
                        <tbody>
                            <tr>
                                <td>Installment</td>
                                <td className="text-right">$412</td>
                                <td className="text-right">$15,588</td>
                            </tr>
                            <tr>
                                <td>Mortage</td>
                                <td className="text-right">$2,143</td>
                                <td className="text-right">$255,079</td>
                            </tr>
                            <tr>
                                <td>Revolving</td>
                                <td className="text-right">$70</td>
                                <td className="text-right">$2,444</td>
                            </tr>
                            <tr>
                                <td className="text-bold">Total</td>
                                <td className="text-right text-bold">$2,625</td>
                                <td className="text-right text-bold">$273,111</td>
                            </tr>
                        </tbody>
                    </table>
                </SimpleTableContainer>
            </WidgetContainer>   
        );
    }
}

export default Example;
        `}
            </Highlight>


            <h2 className="mb-m mt-l">API</h2>

            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>title</td>
                        <td>string || JSX.Element(only 'a' and 'span' will be styled correctly)</td>
                        <td className="text-center">-</td>
                        <td>Set the title.</td>
                    </tr>
                    <tr>
                        <td>className</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set class to the wrapper.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
};

export default WidgetContainerPage;
