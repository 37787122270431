import React from 'react';
import {
    WizardNav, LeftBarCrumbs, ScrollableContainer, WizardNavBottomSection, WizardNavStepsControls,
    Button, WizardNavStep, Toolbar, InfoBox,
} from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../helpers/helpers';

const defaultWizardNavSteps = [false, false, false, false, false, false, false, false, false, false, false, false, false, false]

export default class DemoWizardNav extends React.Component {

    state = {
        wizardNavSteps: [...defaultWizardNavSteps],
        expanded: [false, false, false, false],
    }

    toggleSteps = (...steps: number[]) => {
        const updatedSteps = [...defaultWizardNavSteps];

        steps.map(s => {
            return updatedSteps[s] = true;
        });

        this.setState({ wizardNavSteps: updatedSteps });
    }

    toggleExpand = (index: number) => {
        const update = [...this.state.expanded];
        update[index] = !this.state.expanded[index];
        this.setState({ expanded: update })
    }

    closeExpands = (...indexs: number[]) => {
        const updatedExpanded = [...this.state.expanded];

        indexs.map(s => {
            return updatedExpanded[s] = false;
        });

        this.setState({ expanded: updatedExpanded });
    }

    render() {
        const successBox = <InfoBox dataUI={getDataUI()} >
            Review or edit party profile information and save any chages.
        </InfoBox>;

        return (
            <WizardNav dataUI={getDataUI()}>
                <LeftBarCrumbs
                    dataUI={getDataUI()}
                    className="mb-m lv-side-bar-neggative-margin"
                    links={[<a href="/">Fallow Fruits</a>, <a href="/">36465: Loan Request</a>]}
                />

                <Toolbar dataUI={getDataUI()} 
                    className="mb-m"
                    leftSide={
                        <>
                            <Button dataUI={getDataUI()}  className="mr-s" icon="fal fa-save">Save</Button>
                            <Button dataUI={getDataUI()}  icon="fal fa-plus" btnType="secondary">Add Facility</Button>
                        </>
                    }
                />

                <ScrollableContainer>
                    <WizardNavStep dataUI={getDataUI()} icon="fal fa-users" isActive={this.state.wizardNavSteps[0]} title="Step one" status="success" successBox={successBox} onTitleClick={() => { this.toggleSteps(0); this.closeExpands(0, 1) }} />
                    <WizardNavStep dataUI={getDataUI()} icon="fal fa-user" isActive={this.state.wizardNavSteps[1]} title="Step two" status="warning" successBox={successBox} onTitleClick={() => { this.toggleSteps(1); this.closeExpands(0, 1) }} />
                    <WizardNavStep dataUI={getDataUI()} icon="fal fa-th-list" isActive={this.state.wizardNavSteps[2]} title="Sally Cernich" expanded={this.state.expanded[0]} successBox={successBox} onTitleClick={() => this.toggleExpand(0)}>
                        <WizardNavStep dataUI={getDataUI()} icon="fal fa-file-invoice-dollar" isActive={this.state.wizardNavSteps[3]} title="Profile" successBox={successBox} onTitleClick={() => { this.toggleSteps(2, 3); this.closeExpands(1) }} />
                        <WizardNavStep dataUI={getDataUI()} icon="fal fa-building" isActive={this.state.wizardNavSteps[4]} title="PFS" status="success" successBox={successBox} onTitleClick={() => { this.toggleSteps(2, 4); this.closeExpands(1) }} />
                    </WizardNavStep>
                    <WizardNavStep dataUI={getDataUI()} icon="fal fa-clipboard-list" isActive={this.state.wizardNavSteps[5]} title="Step four collapsable" expanded={this.state.expanded[1]} successBox={successBox} onTitleClick={() => this.toggleExpand(1)} >
                        <WizardNavStep dataUI={getDataUI()} icon="fal fa-clipboard-check" isActive={this.state.wizardNavSteps[6]} title="Profile" onTitleClick={() => { this.toggleSteps(5, 6); this.closeExpands(0) }} />
                        <WizardNavStep dataUI={getDataUI()} icon="fal fa-users" disabled isActive={this.state.wizardNavSteps[7]} title="PFS" status="success" onTitleClick={() => { this.toggleSteps(5, 7); this.closeExpands(0) }} />
                    </WizardNavStep>
                    <WizardNavStep dataUI={getDataUI()} icon="fal fa-clipboard-list" isActive={this.state.wizardNavSteps[9]} title="Facility 44792-2" expanded={this.state.expanded[2]} onTitleClick={() => this.toggleExpand(2)} >
                        <WizardNavStep dataUI={getDataUI()} icon="fal fa-clipboard-check" isActive={this.state.wizardNavSteps[10]} title="Conformance Technology" onTitleClick={() => { this.toggleSteps(9, 10); this.closeExpands(0, 1) }} />
                        <WizardNavStep dataUI={getDataUI()} icon="fal fa-users" isActive={this.state.wizardNavSteps[11]} title="Sally Cernich" expanded={this.state.expanded[3]} onTitleClick={() => { this.toggleExpand(3) }}>
                            <WizardNavStep dataUI={getDataUI()} icon="fal fa-clipboard-check" isActive={this.state.wizardNavSteps[12]} title="Profile" onTitleClick={() => { this.toggleSteps(9, 11, 12); }} />
                            <WizardNavStep dataUI={getDataUI()} icon="fal fa-users" isActive={this.state.wizardNavSteps[13]} title="PFS" onTitleClick={() => { this.toggleSteps(9, 11, 13); }} />
                        </WizardNavStep>
                    </WizardNavStep>
                    <WizardNavStep dataUI={getDataUI()} icon="fal fa-users" isActive={this.state.wizardNavSteps[8]} title="Step five - final step" successBox={successBox} onTitleClick={() => { this.toggleSteps(8); this.closeExpands(0, 1, 2, 3) }} infoText="Review or edit party profile information and save any chages." />
                </ScrollableContainer>

                <WizardNavStepsControls
                    dataUI={getDataUI()}
                    className="mb-m"
                    previousStep={<a href="/">Previous</a>}
                    nextStep={<a href="/">Next</a>}
                />

                <WizardNavBottomSection dataUI={getDataUI()}>
                    <h3 className="mb-s">Prerequisite conditions:</h3>
                    <p className="mb-l"><a className="lv-link-styles" href="/">View Prerequisite conditions</a></p>
                    <h3 className="mb-s">Narratives:</h3>
                    <p className="mb-s"><a className="lv-link-styles" href="/">Wizard Notes</a></p>
                    <p className="mb-s"><a className="lv-link-styles" href="/">Underwriter to banker</a></p>
                    <p><a className="lv-link-styles" href="/">Banker to Underwriter</a></p>
                </WizardNavBottomSection>
            </WizardNav>
        )
    }
}