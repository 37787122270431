import React from 'react';
import Highlight from 'react-highlight';
import { Link } from 'react-router-dom';
import { InfoBox } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const LayoutPage: React.FC = () => {

    return <div>
        <h1 className="mb-s">Layout</h1>
        <p className="mb-l">There is a specific UI structure for <strong>Loan Vantage</strong> and the design system provides
            the necessary building blocks for it. It is crucial to implement strictly the showcased structure of layout components.</p>

        <h2 className="mb-m">Layout components:</h2>
        <p className="mb-s"><strong><Link to="/docs/header">Header</Link></strong> - Complex component for the page header.</p>

        <p className="mb-s"><strong>Main</strong> - Wrapper for <Link to="/docs/left-side-bar">LeftSideBar</Link>  and MainContainer components.</p>

        <p className="mb-s"><strong><Link to="/docs/left-side-bar">LeftSideBar</Link></strong> - Specific wrapper for the sidebar.</p>

        <p className="mb-s"><strong>MainContainer</strong> - Wrapper for the main page content.</p>

        <p className="mb-s"><strong>MainContainerHeader</strong> - Wrapper for specific components that represent the header of the whole container.</p>

        <p className="mb-s"><strong>MainContainerScrollable</strong> - Wrapper component which enables scroll when content is longer than the viewport height. Should be placed at the bottom of <strong>MainContainer</strong>. <a href="#scroll-demo">See examples</a>.</p>

        <p className="mb-l"><strong>MainContainerRemainingPart</strong> - Wrapper component which calculate and set remaining height. Should be placed at the bottom of <strong>MainContainer</strong>. <a href="/work-page-4">See examples</a>.</p>

        <InfoBox dataUI={getDataUI()} className="mb-l" type="warning" title="IMPORTANT:">
            When you have custom element (like 'div' or etc.) before 'MainContainerScrollable', 'ModalScrollableContainer' or 'Tabs' you need to provide special styles: (display: flex; flex-direction: column; flex: auto;). Or you can use the utilitie class '<strong>.flex-full-height</strong>' which provides it. <br /><br />
            If you have element in 'MainContainerScrollable' or 'ModalScrollableContainer' which will be 100% height and will use scroll from himself (KendoGrid). It's required to don't have 'margins' because they will 'overflow'.
        </InfoBox>

        <Highlight className="React mb-l">
            {
                `
import React from 'react';
import { Header, Main, MainContainer, MainContainerScrollable, ChatContainer } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

class Layout extends React.Component{
    render(){
        return (
            <Header>
                ...
            </Header>

            <Main dataUI={getDataUI()}>
                <LeftSideBar dataUI={getDataUI()}>
                    ...
                </LeftSideBar>
                <MainContainer dataUI={getDataUI()}>
                    <MainContainerHeader dataUI={getDataUI()}>...</MainContainerHeader>
                    <MainContainerScrollable>Long content...</MainContainerScrollable>
                </MainContainer>
                <ChatContainer dataUI={getDataUI()}>
                    ...
                </ChatContainer>
            </Main>
        );
    }
}
export default Layout;
    `}
        </Highlight>

        <p id="scroll-demo">Here are a few examples of MainContainerScrollable in action:</p>
        <Highlight className="React">
            {
                `
    <MainContainer>
        <MainContainerHeader>
            <ActionContainer>...</ActionContainer>
        </MainContainerHeader>
        <MainContainerScrollable>Long content...</MainContainerScrollable>
    </MainContainer>

    <MainContainer>
        <MainContainerScrollable>Long content...</MainContainerScrollable>
    </MainContainer>

    <MainContainer>
        <div className="flex-full-height">
            <MainContainerScrollable>Long content...</MainContainerScrollable>
        </div>
    </MainContainer>

    <MainContainer>
        <MainContainerHeader>
            <ActionContainer>...</ActionContainer>
        </MainContainerHeader>
        <Tabs>
            <TabList>
                <TabItem>
                    <TabItemTitle>Overview</TabItemTitle>
                </TabItem>
                <TabItem>
                    <TabItemTitle>Miscellaneous</TabItemTitle>
                </TabItem>
            </TabList>
            <TabContent>
                <TabContentItem>
                    <MainContainerScrollable>
                        ...
                    </MainContainerScrollable>
                </TabContentItem>
                <TabContentItem>Miscellaneous tab content</TabContentItem>
            </TabContent>
        </Tabs>
    </MainContainer>
    `}
        </Highlight>

    </div>;
}

export default LayoutPage;
