import React from 'react';
import Highlight from 'react-highlight';
import { Row, Col } from '@jkhy/vsg-loanvantage-design-system';

const Grid: React.FC = () => {

    return <div>
        <h1 className="mb-s">Grid</h1>
        <p className="mb-s">If there are cases where the the content of the given view should be placed in rows and columns,
        there are components in LoanVantage Design System that handle such scenarios - <strong>Row</strong> and <strong>Col</strong>.</p>
        <p className="mb-l">The whole approach for these rows and columns is inspired by the famous <a href="https://getbootstrap.com/docs/4.4/layout/grid/"
            target="_blank" rel="noopener noreferrer">Bootstrap grid</a>.</p>

        <Row>
            <Col xs="6" style={{ backgroundColor: 'var(--color-primary)', color: 'var(--color-1)' }}>First column</Col>
            <Col xs="3" style={{ backgroundColor: 'var(--color-select)', color: 'var(--color-text)' }}>Second column</Col>
            <Col xs="3" style={{ backgroundColor: 'var(--color-primary)', color: 'var(--color-1)' }}>Third column</Col>
        </Row>

        <Highlight className="React mb-l">
            {
                `
import React from 'react';
import { Row, Col } from '@jkhy/vsg-loanvantage-design-system';

class Grid extends React.Component{
    render(){
        return (
            // Column color styles are only for demo purpose
            <Row>
                <Col xs="6">First column</Col>
                <Col xs="3">Second column</Col>
                <Col xs="3">Third column</Col>
            </Row>
        );
    }
}
export default Grid;
    `}
        </Highlight>
    </div>;
}

export default Grid;
