import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Highlight from 'react-highlight';
import { Button, DynamicScrollableContainer, Modal, } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const DynamicScrollableContainerPage: React.FC = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    return (
        <div>
            <h1 className="mb-s">DynamicScrollableContainer</h1>

            <p className="mb-l">
                Provides flexible scrollable area, which expands and collapses, depending on its outer container size. Suitable
                for scroll sections
                inside <Link to="/docs/modal">Modal</Link>, <Link to="/docs/tabs#vertical-tabs">VerticalTabs</Link>, <Link to="/docs/drag-n-drop">Drag-n-Drop</Link>, etc.
            </p>

            <div className="mb-l">
                <Button dataUI={getDataUI()} onClick={() => setIsModalVisible(true)}>Show demo modal</Button>
                <Modal title="Demo Modal" isVisible={isModalVisible} dataUI={getDataUI()} onClose={() => setIsModalVisible(false)}>
                    <h2 className="mb-xl">Example title</h2>
                    <DynamicScrollableContainer dataUI={getDataUI()}>
                        {Array.from({ length: 150 }, (_, i) => <p key={`text ${i + 1}`}>text {i + 1}</p>)}
                    </DynamicScrollableContainer>
                </Modal>
            </div>

            <p>
                Example pages for various scrollable scenarios:
            </p>
            <ul className="mb-l">
                <li><Link to="/components/business-rules/">Business Rules Condition Detail Modal</Link></li>
                <li><Link to="/demo/resizable-sections">Resizable Sections Role, Assignment Tab</Link></li>
                <li><Link to="/components/dnd/process-definition-modal">Process Definition Modal, Tasks Tab and Declarations Tab</Link></li>
            </ul>


            <Highlight className="React">
                {
                    `
import React, { useState } from 'react';

import { Button, Modal, DynamicScrollableContainer } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const Example = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    return (
        <>
            <Button dataUI={getDataUI()} onClick={()=>setIsModalVisible(true)}>Show demo modal</Button>
            <Modal title="Demo Modal" isVisible={isModalVisible} dataUI={getDataUI()} onClose={()=>setIsModalVisible(false)}>
                <h2 className="mb-xl">Example title</h2>
                <DynamicScrollableContainer dataUI={getDataUI()}>
                    {Array.from({ length: 150 }, (_, i)=> <p key={\`text $\{i+1}\`}>text {i+1}</p>)}
                </DynamicScrollableContainer>
            </Modal>
        </>
    );
}

export default Example;
        `}
            </Highlight>
        </div>
    )
};

export default DynamicScrollableContainerPage;
