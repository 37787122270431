import { TabItem, TabList, Tabs, TabItemTitle, TabContent, TabContentItem, Button, Modal } from '@jkhy/vsg-loanvantage-design-system';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { getDataUI } from '../helpers/helpers';

const DemoTabs = () => {
  const [mainTabsIndex, setMainTabsIndex] = useState(0);
  const [modalTabsIndex, setModalTabsIndex] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <>
      <h1 className="mb-l">Demo tabs page</h1>
      <div className="mb-l">
        <Button dataUI={getDataUI()} onClick={() => setIsModalVisible(true)}>Tabs in Modal</Button>
      </div>
      <Tabs dataUI={getDataUI()} onTabIndexChange={(index: number) => setMainTabsIndex(index)} currentlyActiveTabIndex={mainTabsIndex}>
        <TabList dataUI={getDataUI()}>
          <TabItem dataUI={getDataUI()}>
            <TabItemTitle dataUI={getDataUI()}>Request</TabItemTitle>
          </TabItem>
          <TabItem dataUI={getDataUI()}>
            <TabItemTitle dataUI={getDataUI()}>Borrowers with long content</TabItemTitle>
          </TabItem>
          <TabItem dataUI={getDataUI()}>
            <TabItemTitle dataUI={getDataUI()}>Terms</TabItemTitle>
          </TabItem>
          <TabItem dataUI={getDataUI()}>
            <TabItemTitle dataUI={getDataUI()}>ARM Information</TabItemTitle>
          </TabItem>
          <TabItem dataUI={getDataUI()}>
            <TabItemTitle dataUI={getDataUI()}>Schedules</TabItemTitle>
          </TabItem>
          <TabItem dataUI={getDataUI()}>
            <TabItemTitle dataUI={getDataUI()}>Participations</TabItemTitle>
          </TabItem>
          <TabItem dataUI={getDataUI()}>
            <TabItemTitle dataUI={getDataUI()}><Link to="?compliance">Compliance</Link></TabItemTitle>
          </TabItem>
        </TabList>
        <TabContent dataUI={getDataUI()}>
          <TabContentItem dataUI={getDataUI()}>Request tab content</TabContentItem>
          <TabContentItem dataUI={getDataUI()}>Borrowers tab content</TabContentItem>
          <TabContentItem dataUI={getDataUI()}>Terms tab content</TabContentItem>
          <TabContentItem dataUI={getDataUI()}>ARM Information tab content</TabContentItem>
          <TabContentItem dataUI={getDataUI()} isPrerendered>
            Schedules tab content
            (when the tab is not active, this is rendered and invisible)
          </TabContentItem>
          <TabContentItem dataUI={getDataUI()}>Participations tab content</TabContentItem>
          <TabContentItem dataUI={getDataUI()}>Compliance tab content</TabContentItem>
        </TabContent>
      </Tabs>

      <Modal dataUI={getDataUI()} size="L" title="Tabs in Modal" isVisible={isModalVisible} onClose={() => setIsModalVisible(false)}>
        <Tabs dataUI={getDataUI()} onTabIndexChange={(index: number) => setModalTabsIndex(index)} currentlyActiveTabIndex={modalTabsIndex}>
          <TabList dataUI={getDataUI()}>
            <TabItem dataUI={getDataUI()}>
              <TabItemTitle dataUI={getDataUI()}>Request</TabItemTitle>
            </TabItem>
            <TabItem dataUI={getDataUI()}>
              <TabItemTitle dataUI={getDataUI()}>Borrowers with long content</TabItemTitle>
            </TabItem>
            <TabItem dataUI={getDataUI()}>
              <TabItemTitle dataUI={getDataUI()}>Terms</TabItemTitle>
            </TabItem>
            <TabItem dataUI={getDataUI()}>
              <TabItemTitle dataUI={getDataUI()}>ARM Information</TabItemTitle>
            </TabItem>
            <TabItem dataUI={getDataUI()}>
              <TabItemTitle dataUI={getDataUI()}>Schedules</TabItemTitle>
            </TabItem>
            <TabItem dataUI={getDataUI()}>
              <TabItemTitle dataUI={getDataUI()}>Participations</TabItemTitle>
            </TabItem>
            <TabItem dataUI={getDataUI()}>
              <TabItemTitle dataUI={getDataUI()}><Link to="?compliance">Compliance</Link></TabItemTitle>
            </TabItem>
          </TabList>
          <TabContent dataUI={getDataUI()}>
            <TabContentItem dataUI={getDataUI()}>Request tab content</TabContentItem>
            <TabContentItem dataUI={getDataUI()}>Borrowers tab content</TabContentItem>
            <TabContentItem dataUI={getDataUI()}>Terms tab content</TabContentItem>
            <TabContentItem dataUI={getDataUI()}>ARM Information tab content</TabContentItem>
            <TabContentItem dataUI={getDataUI()} isPrerendered={false}>
              Schedules tab content
              (when the tab is not active, this is rendered and invisible)
            </TabContentItem>
            <TabContentItem dataUI={getDataUI()}>Participations tab content</TabContentItem>
            <TabContentItem dataUI={getDataUI()}>Compliance tab content</TabContentItem>
          </TabContent>
        </Tabs>
      </Modal>
    </>
  )
};

export default DemoTabs;