import React, { useState } from 'react';
import Highlight from 'react-highlight';
import { Textarea, ValidationMessage } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const TextareaPage: React.FC = () => {
    const [value, setValue] = useState("Start");

    setTimeout(() => {
        setValue("Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.");
    }, 3000)

    return (
        <div>
            <h1 className="mb-s">Textarea</h1>
            <p className="mb-l">Textarea component in LV Design System extend the native Teaxtarea element.</p>

            <div className="mb-xl">
                <Textarea dataUI={getDataUI()} className="mb-s" name="textarea" />
                <Textarea dataUI={getDataUI()} placeholder={'Textarea Placeholder'} name="textareaPlaceholder" className="mb-s" />
                <Textarea dataUI={getDataUI()} value="Textarea Disabled" name="textareaDisabled" disabled className="mb-s" onChange={() => { }} />
                <Textarea dataUI={getDataUI()} value={value} className="mb-s" name="textareaDynamic" />
                <Textarea dataUI={getDataUI()} className="mb-s" name="textareaMax" maxLength={22} />
            </div>

            <h3 className="mb-s">Textarea with dark border.</h3>
            <Textarea
                darkBorder
                name="textarea"
                className='mb-m'
                dataUI={getDataUI()}
            />

            <h3 className="mb-s">Textarea invalid</h3>
            <Textarea 
              invalid 
              // other way to set invalid
              className='invalid' 
              name="textarea"
              dataUI={getDataUI()}
            />
            <ValidationMessage type='error' message='Invalid message' dataUI={getDataUI()}/>

            <Highlight className="React">
                {
                    `
import React from 'react';
import { Textarea } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const Example: React.FC = (props) => {
    const [value, setValue] = useState("Start");

    setTimeout(() => {
        setValue("Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.");
    }, 3000)

    return (
      <div>
            <Textarea dataUI={getDataUI()} name="textarea" onChange={() => {} }/>
            <Textarea dataUI={getDataUI()} name="textareaPlaceholeder" placeholder={'Textarea Placeholder'}  onChange={() => {} }/>
            <Textarea dataUI={getDataUI()} name="textareaDisabled" value="Textarea Disabled" disabled onChange={() => {} } />
            <Textarea dataUI={getDataUI()} name="textareaDynamic" value={value} />
            <Textarea dataUI={getDataUI()} name="textareaMax" maxLength={22} />

            // Textarea with dark border
            <Textarea
                darkBorder
                name="textarea"
                className='mb-m'
                dataUI={getDataUI()}
            />

            // Textarea invalid
            <Textarea 
              invalid 
              // other way to set invalid
             // className='invalid' 
              name="textarea"
              dataUI={getDataUI()}
            />
            <ValidationMessage type='error' message='Invalid message' dataUI={getDataUI()}/>
      </div>
    );
}

export default Example;
    `}
            </Highlight>

            <h2 className="mb-m">API</h2>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>invalid</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Sets component invalid styles. You can set component invalid styles and by adding class 'invalid' </td>
                    </tr>
                    <tr>
                        <td>darkBorder</td>
                        <td className='text-center'>boolean</td>
                        <td className='text-center'>-</td>
                        <td>Sets component border color</td>
                    </tr>
                    <tr>
                        <td>all native textarea attributes</td>
                        <td className='text-center'>-</td>
                        <td className='text-center'>-</td>
                        <td>You can use all native textarea attributes like: "rows", "cols", "disabled", "name"...</td>
                    </tr>
                </tbody>
            </table>

        </div>
    )
}

export default TextareaPage;
