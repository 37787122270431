import React, { useState, useRef, useEffect } from 'react';

import {
    MainContainerHeader, ReactAux, ActionContainer, ActionContainerHeader, ActionContainerCrumbs,
    ActionSettings, LinkWithIcon, Button,
    MainContainerScrollable, Col, Label, Row, Icon, Toolbar, Collapse,
    SimpleTableContainer, Input, Checkbox, Textarea, Radio, NumericInput, Tooltip,
} from '@jkhy/vsg-loanvantage-design-system';
import { Link } from 'react-router-dom';
import { getDataUI } from '../helpers/helpers';

const toolbarData = [
    { title: 'Loan Amount:', desc: '$100,000' },
    { title: 'Principal Amount:', desc: '$ 100,525.00 ' },
    { title: 'Payment Amount:', desc: '$ 6,955.54' },
    { title: 'Interest Rate:', desc: '5.555 %' },
    { title: 'Amorthization:', desc: '24 Months' },
    { title: 'APR:', desc: '6.046 %' }
];

const EditableCell = (props: { value?: string, align?: 'left' | 'right' }) => {
    const inputRef = useRef() as any;
    const { value, align } = props;

    const [cellValue, setCellValue] = useState(value || '');
    const [isEditMode, setEditMode] = useState(false);

    useEffect(() => {
        if (isEditMode && inputRef) {
            inputRef.current.focus();
        }
    }, [isEditMode, inputRef]);

    const onInputChange = (e: any) => {
        setCellValue(e.target.value)
    }

    const cellClasses = [
        align === 'right' ? 'text-right' : '',
        !value ? 'simple-editable-cell' : ''
    ];
    return (
        <td
            className={cellClasses.join(' ').trim()}
            onClick={() => {
                if (!isEditMode) setEditMode(true);
            }}
        >
            {isEditMode ? <Input
                dataUI={getDataUI()}
                ref={inputRef}
                onBlur={(e) => {
                    setEditMode(false)
                }}
                onChange={onInputChange}
                value={cellValue}

            /> : <span className="lv-link-styles">{cellValue}</span>
            }
        </td>
    )
}

const DemoFeesClosing = () => {

    const [collapsOne, setCollapsOne] = useState(false);
    const [collapsTwo, setCollapsTwo] = useState(false);
    const [collapsThree, setCollapsThree] = useState(false);
    const [collapsFour, setCollapsFour] = useState(false);

    const [isEditMode, setEditMode] = useState(false);

    const renderToolbarData = (data: any[]) => {
        return data.map((obj, i) => {
            const index = i;
            return (
                <ReactAux key={index}>
                    <Label dataUI={getDataUI()} className="text-bold mr-xs text-small">{obj.title}</Label>
                    <Label dataUI={getDataUI()} className={`text-small${!(index === data.length - 1) ? ' mr-m' : ''}`}>{obj.desc}</Label>
                </ReactAux>
            )
        })
    }
    return (
        <ReactAux>
            <MainContainerHeader dataUI={getDataUI()}>
                <ActionContainer dataUI={getDataUI()}>
                    <ActionContainerHeader dataUI={getDataUI()}>
                        <ActionContainerCrumbs dataUI={getDataUI()} crumbs={[<Link to='/components/facility-details' title="Facility Details 4449">Facility Details 4449</Link>, 'Fees/Closing (Schedule: Testing 888)']} />
                        <ActionSettings dataUI={getDataUI()}>
                            <LinkWithIcon dataUI={getDataUI()} icon="fal fa-cogs"><a href="/" title="Robin - Commercial">Robin - Commercial</a></LinkWithIcon>
                        </ActionSettings>
                    </ActionContainerHeader>
                    <Button
                        dataUI={getDataUI()}
                        icon={isEditMode ? "fal fa-save" : "fal fa-pen"}
                        onClick={() => setEditMode(!isEditMode)}
                    >
                        {isEditMode ? 'Save' : 'Edit'}
                    </Button>
                    {
                        isEditMode ? (
                            <ReactAux>
                                <Button
                                    dataUI={getDataUI()}
                                    btnType="secondary"
                                    icon="fal fa-times-circle"
                                    onClick={() => setEditMode(false)}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    dataUI={getDataUI()} btnType="secondary" icon="fal fa-sync">Regenerate Fees</Button>
                            </ReactAux>
                        ) : (
                            <ReactAux>
                                <Button
                                    dataUI={getDataUI()} btnType="secondary" icon="fal fa-copy">Copy</Button>
                                <Button
                                    dataUI={getDataUI()} btnType="secondary" icon="fal fa-print">Print</Button>
                            </ReactAux>
                        )
                    }
                </ActionContainer>
            </MainContainerHeader>

            <Toolbar
                dataUI={getDataUI()}
                className="mt-m mb-m"
                leftSide={<ReactAux> {renderToolbarData(toolbarData)}</ReactAux>}
            />
            <MainContainerScrollable>
                <Collapse
                    dataUI={getDataUI()}
                    title="Reccuring and Upfront"
                    expanded={collapsOne}
                    onClick={() => setCollapsOne(!collapsOne)}
                >
                    {/* 
                        **************************************************************************************
                             START DEMO TABLE WITH EDITABLE CELLS 
                        *** DO NOT USE THE EXAMPLE FOR IMPLAMENTATION
                        *** USE KENDO GRID
                        *** !!!!IMPORTATNT!!! REFFER TO FIGNMA DESIGN FOR TABLE CONTENT: 
                         https://www.figma.com/file/kVXBhETWcfSE0GX6VUmqdz/LV-Production?node-id=9066%3A245801
                        ***************************************************************************************
                    */}
                    <SimpleTableContainer dataUI={getDataUI()}>
                        <table>
                            <thead>
                                <tr>
                                    <th style={{ width: 1 }}></th>
                                    <th style={{ width: '10%' }}>Fee Name</th>
                                    <th style={{ width: '10%' }}>Finance Category</th>
                                    <th style={{ width: '20%' }}>Fee Paid By</th>
                                    <th style={{ width: '10%' }}>Collection</th>
                                    <th style={{ width: '20%' }}>Cost Center</th>
                                    <th style={{ width: '10%' }}>GL Account #</th>
                                    <th style={{ width: '10%' }} className="text-right">Fee Amount</th>
                                    <th style={{ width: '10%' }}>Fee Percent</th>
                                    <th style={{ width: 1 }}>Cash</th>
                                </tr>

                            </thead>
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td colSpan={9}><span className="text-primary text-bold">Reccuring</span></td>
                                </tr>
                                <tr>
                                    <td><Checkbox dataUI={getDataUI()} /></td>
                                    <td>Annual Fee</td>
                                    <EditableCell value="Financed" />
                                    <EditableCell value="Borrower Financed" />
                                    <EditableCell value="Yearly" />
                                    <EditableCell value="100 Loan Operations" />
                                    <EditableCell value="15521" />
                                    <EditableCell value="$ 25.00" align='right' />
                                    <EditableCell />
                                    <td><Checkbox dataUI={getDataUI()} /></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td colSpan={9}><span className="text-primary text-bold">Upfront</span></td>
                                </tr>
                                <tr>
                                    <td><Checkbox dataUI={getDataUI()} /></td>
                                    <td>S-Freq</td>
                                    <EditableCell value="Prepaid" />
                                    <EditableCell value="Borrower Cash" />
                                    <EditableCell value="Monthly" />
                                    <EditableCell value="100 Loan Operations" />
                                    <EditableCell value="15521" />
                                    <EditableCell value="$ 75.00" align='right' />
                                    <EditableCell />
                                    <td><Checkbox dataUI={getDataUI()} /></td>
                                </tr>
                            </tbody>
                        </table>
                    </SimpleTableContainer>
                    {/* END DEMO TABLE */}

                    <div className="d-flex align-items-start mt-m ml-m">
                        <Label dataUI={getDataUI()} className="mr-s">Fee Notes</Label>
                        <Textarea dataUI={getDataUI()} className="width-l" />
                    </div>
                </Collapse>

                <Collapse
                    dataUI={getDataUI()}
                    title="Closing Fees"
                    expanded={collapsTwo}
                    onClick={() => setCollapsTwo(!collapsTwo)}
                >
                    <Toolbar
                        dataUI={getDataUI()}
                        className="mb-m"
                        leftSide={
                            <div className="d-flex">
                                <Label dataUI={getDataUI()} className="text-bold">Closing Type:</Label>
                                <div className="ml-s">
                                    <Radio dataUI={getDataUI()} name="radio" className="mr-s">In-House</Radio>
                                    <Radio dataUI={getDataUI()} name="radio" >Third Party</Radio>
                                </div>
                            </div>
                        }
                    />
                    {/* 
                        **************************************************************************************
                             START DEMO TABLE WITH EDITABLE CELLS 
                        *** DO NOT USE THE EXAMPLE FOR IMPLAMENTATION
                        *** USE KENDO GRID
                        *** !!!!IMPORTATNT!!! REFFER TO FIGNMA DESIGN FOR TABLE CONTENT: 
                         https://www.figma.com/file/kVXBhETWcfSE0GX6VUmqdz/LV-Production?node-id=9066%3A245801
                        ***************************************************************************************
                    */}
                    <SimpleTableContainer dataUI={getDataUI()}>
                        <table>
                            <thead>
                                <tr>
                                    <th style={{ width: '11%' }}>Fee Name</th>
                                    <th style={{ width: '11%' }}>Finance Category</th>
                                    <th style={{ width: '11%' }}>Fee Paid By</th>
                                    <th style={{ width: '11%' }}>Cost Center</th>
                                    <th style={{ width: '11%' }}>GL Account #</th>
                                    <th style={{ width: '11%' }}>Description</th>
                                    <th style={{ width: '14%' }} className="text-right">Total</th>
                                    <th style={{ width: '10%' }} className="text-right">Paid</th>
                                    <th style={{ width: '10%' }} className="text-right">Balance Due</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Annual Fee</td>
                                    <EditableCell value="Prepaid" />
                                    <EditableCell value="Borrower Cash" />
                                    <EditableCell value="Borrower Cash" />
                                    <EditableCell value="15521" />
                                    <EditableCell />
                                    <td>
                                        <div className="d-flex align-items-center flex-auto justify-content-end">
                                            <Tooltip title="Estimated value">
                                                <Icon dataUI={getDataUI()} size="S" icon="custom-icon-estimate-s" />
                                            </Tooltip>
                                            <span className="mr-s">$ 250.00</span>
                                            <Tooltip title="Edit">
                                                <Button
                                                    dataUI={getDataUI()} btnType="icon" icon='fal fa-pen' />
                                            </Tooltip>
                                        </div>
                                    </td>
                                    <EditableCell value="$ 250.00" align="right" />
                                    <EditableCell value="$ 250.00" align="right" />
                                </tr>
                                <tr>
                                    <td>Commitment Fee</td>
                                    <EditableCell value="Financed" />
                                    <EditableCell value="Borrower Cash" />
                                    <EditableCell value="Borrower Cash" />
                                    <EditableCell value="15521" />
                                    <EditableCell />
                                    <td>
                                        <div className="d-flex align-items-center flex-auto justify-content-end">
                                            <Tooltip title="Estimated value">
                                                <Icon dataUI={getDataUI()} size="S" icon="custom-icon-estimate-s" />
                                            </Tooltip>
                                            <span className="mr-s">$ 250.00</span>
                                            <Tooltip title="Edit">
                                                <Button
                                                    dataUI={getDataUI()} btnType="icon" icon='fal fa-pen' />
                                            </Tooltip>
                                        </div>
                                    </td>
                                    <EditableCell value="$ 0.00" align="right" />
                                    <EditableCell value=" $ 250.00" align="right" />
                                </tr>
                                <tr>
                                    <td colSpan={5}></td>
                                    <td className="text-bold">Total Fees:</td>
                                    <td className="text-right text-bold">$ 750.00</td>
                                    <td className="text-right text-bold">$ 250.00</td>
                                    <td className="text-right text-bold">$ 500.00</td>
                                </tr>
                            </tbody>
                        </table>
                    </SimpleTableContainer>
                    {/* END DEMO TABLE */}


                    <SimpleTableContainer dataUI={getDataUI()} className='mt-l'>
                        <table>
                            <thead>
                                <tr>
                                    <th style={{ width: '66%' }}>Fee Summary</th>
                                    <th className="text-right" style={{ width: '14%' }}>Total</th>
                                    <th className="text-right" style={{ width: '20%' }}>Balance Due</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Non-Financed Fees</td>
                                    <td className="text-right">$ 500.00</td>
                                    <td className="text-right">$ 250.00 </td>
                                </tr>
                                <tr>
                                    <td>Financed Fees</td>
                                    <td className="text-right">$ 250.00</td>
                                    <td className="text-right">$ 250.00 </td>
                                </tr>
                                <tr>
                                    <td>Paid Outside of Closing</td>
                                    <td className="text-right">$ 500.00</td>
                                    <td className="text-right">$ 250.00 </td>
                                </tr>
                            </tbody>
                        </table>
                    </SimpleTableContainer>
                    <div className="d-flex align-items-start mt-m ml-m">
                        <Label dataUI={getDataUI()} className="mr-s">Fee Notes</Label>
                        <Textarea dataUI={getDataUI()} className="width-l" />
                    </div>
                </Collapse>

                <Collapse
                    dataUI={getDataUI()}
                    title="Interest/Escrow"
                    expanded={collapsThree}
                    onClick={() => setCollapsThree(!collapsThree)}
                >
                    {/* 
                        **************************************************************************************
                             START DEMO TABLE WITH EDITABLE CELLS 
                        *** DO NOT USE THE EXAMPLE FOR IMPLAMENTATION
                        *** USE KENDO GRID
                        *** !!!!IMPORTATNT!!! REFFER TO FIGNMA DESIGN FOR TABLE CONTENT: 
                         https://www.figma.com/file/kVXBhETWcfSE0GX6VUmqdz/LV-Production?node-id=9066%3A245801
                        ***************************************************************************************
                    */}
                    <SimpleTableContainer dataUI={getDataUI()}>
                        <table>
                            <thead>
                                <tr>
                                    <th>Interest</th>
                                    <th className="text-right" style={{ width: '114px' }}>Days</th>
                                    <th className="text-right" style={{ width: '180px' }}>Per Diem</th>
                                    <th style={{ width: 1, whiteSpace: 'nowrap' }}>Balance Due</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>First Month's Interest</td>
                                    <EditableCell value="3" align="right" />
                                    <td>
                                        <div className="d-flex">
                                            <NumericInput dataUI={getDataUI()} type="currency" />
                                            <Tooltip title="Calculate">
                                                <Button
                                                    dataUI={getDataUI()} className="ml-xs" btnType="icon" icon='fal fa-calculator' />
                                            </Tooltip>
                                        </div>
                                    </td>
                                    <td className="text-right">$10.00</td>

                                </tr>
                            </tbody>
                        </table>
                    </SimpleTableContainer>
                    {/* END DEMO TABLE */}

                    {/* 
                        **************************************************************************************
                             START DEMO TABLE WITH EDITABLE CELLS 
                        *** DO NOT USE THE EXAMPLE FOR IMPLAMENTATION
                        *** USE KENDO GRID
                        *** !!!!IMPORTATNT!!! REFFER TO FIGNMA DESIGN FOR TABLE CONTENT: 
                         https://www.figma.com/file/kVXBhETWcfSE0GX6VUmqdz/LV-Production?node-id=9066%3A245801
                        ***************************************************************************************
                    */}
                    <SimpleTableContainer dataUI={getDataUI()}>
                        <table>
                            <thead>
                                <tr>
                                    <th>Escrow</th>
                                    <th className="text-right" style={{ width: '114px' }}>Months</th>
                                    <th className="text-right" style={{ width: '180px' }}>Annual Tax</th>
                                    <th className="text-right" style={{ width: 1, whiteSpace: 'nowrap' }}>Balance Due</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Prepaid Tax Escrow</td>
                                    <EditableCell value="3" align="right" />
                                    <td>
                                        <NumericInput dataUI={getDataUI()} type="currency" />
                                    </td>
                                    <td className="text-right">$15.00</td>

                                </tr>
                                <tr>
                                    <td>Prepaid Restricted Escrow</td>
                                    <EditableCell align="right" />
                                    <td>
                                        <NumericInput dataUI={getDataUI()} type="currency" />
                                    </td>
                                    <td className="text-right">$20.00</td>
                                </tr>
                                <tr>
                                    <td colSpan={2}></td>
                                    <td className='text-bold text-right'>Total Interest/Escrow:</td>
                                    <td className='text-right text-bold'>$45.00</td>
                                </tr>
                            </tbody>
                        </table>
                    </SimpleTableContainer>
                </Collapse>
                {/* END DEMO TABLE */}

                <Collapse
                    dataUI={getDataUI()}
                    title="Disbursements"
                    expanded={collapsFour}
                    onClick={() => setCollapsFour(!collapsFour)}
                >
                    <div className='section-container mb-m'>
                        <h2>Credits</h2>
                        <hr className='mb-m mt-m' />

                        <Toolbar
                            dataUI={getDataUI()}
                            className="mb-m"
                            leftSide={
                                <div className="d-flex">
                                    <Button
                                        dataUI={getDataUI()} btnType="secondary" icon='fal fa-plus' >Add Credit</Button>
                                    <Label dataUI={getDataUI()} className="ml-s text-bold">Principal Amount Financed: $1,000,500.00</Label>
                                </div>
                            }
                        />

                        {/* 
                        **************************************************************************************
                             START DEMO TABLE WITH EDITABLE CELLS 
                        *** DO NOT USE THE EXAMPLE FOR IMPLAMENTATION
                        *** USE KENDO GRID
                        *** !!!!IMPORTATNT!!! REFFER TO FIGNMA DESIGN FOR TABLE CONTENT: 
                         https://www.figma.com/file/kVXBhETWcfSE0GX6VUmqdz/LV-Production?node-id=9066%3A245801
                        ***************************************************************************************
                    */}
                        <SimpleTableContainer dataUI={getDataUI()}>
                            <table>
                                <thead>
                                    <tr>
                                        <th style={{ minWidth: '43px', maxWidth: '43px' }}></th>
                                        <th style={{ width: '30%' }}>Payee</th>
                                        <th style={{ width: '15%' }} className="text-right">Amount</th>
                                        <th style={{ width: '15%' }}>Cost Center</th>
                                        <th style={{ width: '10%' }}>GL Account #</th>
                                        <th style={{ width: '10%' }}>Description</th>
                                        <th style={{ width: '20%' }}>Funds Received Via</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td></td>
                                        <td>Prepaid Tax Escrow</td>
                                        <EditableCell value="$20,000.00" align="right" />
                                        <EditableCell />
                                        <EditableCell />
                                        <EditableCell />
                                        <EditableCell value="Cash, Check, Wire" />
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td className="text-bold">Credits Total:</td>
                                        <td className="text-bold text-right">$45.00</td>
                                        <td colSpan={4}></td>
                                    </tr>
                                </tbody>
                            </table>
                        </SimpleTableContainer>
                    </div>
                    {/* END DEMO TABLE */}

                    <div className="section-container">
                        <h2>Funds Dirbursed at Closing</h2>
                        <hr className='mb-m mt-m' />

                        <Toolbar
                            dataUI={getDataUI()}
                            className="mb-m"
                            leftSide={
                                <Button
                                    dataUI={getDataUI()} btnType="secondary" icon='fal fa-plus' >Add Payment to Others</Button>
                            }
                        />


                        {/* 
                        **************************************************************************************
                             START DEMO TABLE WITH EDITABLE CELLS 
                        *** DO NOT USE THE EXAMPLE FOR IMPLAMENTATION
                        *** USE KENDO GRID
                        *** !!!!IMPORTATNT!!! REFFER TO FIGNMA DESIGN FOR TABLE CONTENT: 
                         https://www.figma.com/file/kVXBhETWcfSE0GX6VUmqdz/LV-Production?node-id=9066%3A245801
                        ***************************************************************************************
                    */}
                        <SimpleTableContainer dataUI={getDataUI()} className="mb-m">
                            <table>
                                <thead>
                                    <tr>
                                        <th style={{ minWidth: '43px', maxWidth: '43px' }}></th>
                                        <th style={{ width: '30%' }}>Payee</th>
                                        <th style={{ width: '15%' }} className="text-right">Amount</th>
                                        <th style={{ width: '15%' }}>Cost Center</th>
                                        <th style={{ width: '10%' }}>GL Account #</th>
                                        <th style={{ width: '10%' }}>Description</th>
                                        <th style={{ width: '10%' }}>Funds Paid Via</th>
                                        <th style={{ width: '10%' }}>Paid To</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td></td>
                                        <td className="text-bold">Net Funded Fees (-)</td>
                                        <td className="text-bold text-right">$500.00</td>
                                        <td colSpan={5}></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td className="text-bold">Funds Available for Disbursement</td>
                                        <td className="text-bold text-right">$1,020,000.00</td>
                                        <td colSpan={5}></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td colSpan={7} className="text-bold text-primary">Paid To Others</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Tooltip title="Delete">
                                                <Button
                                                    dataUI={getDataUI()} disabled btnType="icon" icon='fal fa-trash' />
                                            </Tooltip>
                                        </td>
                                        <td>Protection Coverage</td>
                                        <EditableCell value="$1,000.00" align="right" />
                                        <EditableCell />
                                        <EditableCell />
                                        <EditableCell />
                                        <EditableCell />
                                        <EditableCell value="Others" />
                                    </tr>
                                    <tr>
                                        <td>
                                            <Tooltip title="Delete">
                                                <Button
                                                    dataUI={getDataUI()} disabled btnType="icon" icon='fal fa-trash' />
                                            </Tooltip>
                                        </td>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                <span className="mr-s">Debts from Credit Bureau</span>
                                                <Tooltip title="Import Debts">
                                                    <Button
                                                        dataUI={getDataUI()} btnType="icon" icon='fal fa-file-import' />
                                                </Tooltip>
                                            </div>
                                        </td>
                                        <EditableCell value="$1,000.00" align="right" />
                                        <EditableCell />
                                        <EditableCell />
                                        <EditableCell />
                                        <EditableCell />
                                        <EditableCell value="Others" />
                                    </tr>
                                    <tr>
                                        <td>
                                            <Tooltip title="Delete">
                                                <Button
                                                    dataUI={getDataUI()} btnType="icon" icon='fal fa-trash' />
                                            </Tooltip>
                                        </td>
                                        <td>
                                            SM Servicing
                                        </td>
                                        <EditableCell value="$30,933.00" align="right" />
                                        <EditableCell />
                                        <EditableCell />
                                        <EditableCell value="David Testcase" />
                                        <EditableCell />
                                        <EditableCell value="Others" />
                                    </tr>
                                    <tr>
                                        <td>
                                            <Tooltip title="Delete">
                                                <Button
                                                    dataUI={getDataUI()} btnType="icon" icon='fal fa-trash' />
                                            </Tooltip>
                                        </td>
                                        <td>
                                            HOA Due
                                        </td>
                                        <EditableCell value="$200.00" align="right" />
                                        <EditableCell />
                                        <EditableCell />
                                        <EditableCell value="David Testcase" />
                                        <EditableCell />
                                        <EditableCell value="Others" />
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td className="text-bold">
                                            Sub Total
                                        </td>
                                        <td className='text-bold text-right'>
                                            $32,133.00
                                        </td>
                                        <td colSpan={5}></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td colSpan={7} className="text-bold text-primary">Paid To Borrower</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>John Jones</td>
                                        <EditableCell value="$600,000.00" align="right" />
                                        <td colSpan={5}></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td className="text-primary text-bold">Sub Total</td>
                                        <td className="text-bold text-right">$600,000.00</td>
                                        <td colSpan={5}></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td className="text-bold">Total Funds Disbursed:</td>
                                        <td className="text-bold text-right">$632,133</td>
                                        <td colSpan={5}></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td className="text-bold">Remaining Funds to be Disbursed:</td>
                                        <td className="text-bold text-right">$387,867</td>
                                        <td colSpan={5}></td>
                                    </tr>
                                </tbody>
                            </table>
                        </SimpleTableContainer>
                        {/* END DEMO TABLE */}

                        <Row>
                            <Col xs="2">
                                <Label dataUI={getDataUI()} className="justify-content-end text-right mb-s">Loan Date</Label>
                            </Col>
                            <Col xs="3">
                                <div className="d-flex mb-s">
                                    <Input
                                        dataUI={getDataUI()} className="width-full" />
                                    <Tooltip title="Date">
                                        <Button
                                            dataUI={getDataUI()} className="ml-s" btnType="icon" icon='fal fa-calendar-alt' />
                                    </Tooltip>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="2">
                                <Label dataUI={getDataUI()} className="justify-content-end text-right mb-s">Recision Date</Label>
                            </Col>
                            <Col xs="3">
                                <div className="d-flex mb-s">
                                    <Input
                                        dataUI={getDataUI()} className="width-full" />
                                    <Tooltip title="Date">
                                        <Button
                                            dataUI={getDataUI()} className="ml-s" btnType="icon" icon='fal fa-calendar-alt' />
                                    </Tooltip>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="2">
                                <Label dataUI={getDataUI()} className="justify-content-end text-right mb-s">Funding Date</Label>
                            </Col>
                            <Col xs="3">
                                <div className="d-flex mb-s">
                                    <Input
                                        dataUI={getDataUI()} className="width-full" />
                                    <Tooltip title="Date">
                                        <Button
                                            dataUI={getDataUI()} className="ml-s" btnType="icon" icon='fal fa-calendar-alt' />
                                    </Tooltip>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="2">
                                <Label dataUI={getDataUI()} className="justify-content-end text-right mb-s">Initial Advance</Label>
                            </Col>
                            <Col xs="3">
                                <NumericInput dataUI={getDataUI()} type="currency" />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="2">
                                <Label dataUI={getDataUI()} className="justify-content-end text-right mb-s">Closing Notes</Label>
                            </Col>
                            <Col xs="6">
                                <Textarea dataUI={getDataUI()} className='mb-s' />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="2">
                                <Label dataUI={getDataUI()} className="justify-content-end text-right">Amount from Borrower</Label>
                            </Col>
                            <Col xs="3">
                                <NumericInput dataUI={getDataUI()} type="currency" />
                            </Col>
                        </Row>
                    </div>
                </Collapse>
            </MainContainerScrollable>
        </ReactAux >
    );
}

export default DemoFeesClosing;