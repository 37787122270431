import React, { useState } from 'react';

import {
    MainContainerHeader, ReactAux, ActionContainer, ActionContainerHeader, ActionContainerCrumbs,
    Button, Toolbar, MainContainerScrollable, Modal, ModalScrollableContainer,
    Row, Col, Icon, Badge, Timeline, TimelineItem, TimelineItemProps
} from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../helpers/helpers';


const timelineMockData: TimelineItemProps[] = [
    {
        title: 'Today',
        dataUI: getDataUI(),
        timelineItemData: [
            {
                badge: {
                    title: '1',
                    color: 'pink',
                    tooltip: 'Company Prepared for Q1, Q2, Q3 for 1st FYE 12/31/2022 with 30 Grace Days'
                },
                left: {
                    title: 'Statemet Date:',
                    value: '09/30/2022'
                },
                mid: {
                    title: 'Due Date:',
                    value: '13/31/2022'
                },
                right: {
                    title: 'Create Date:',
                    value: '13/31/2022'
                },
                rightBadge: {
                    type: 'check',
                    tooltip: 'Existing Match',
                },
                contentItems: [
                    {
                        title: 'Frequency:',
                        value: 'Quarterly'
                    },
                    {
                        title: 'Quality:',
                        value: 'CPA Prepared'
                    },
                    {
                        title: 'Grace days:',
                        value: '30'
                    }
                ]
            },
            {
                badge: {
                    title: '2',
                    color: 'blue',
                    tooltip: 'CPA Prepared for every 1 year beginning on 12/31/2022 with 30 Grace Days'
                },
                left: {
                    title: 'Statemet Date:',
                    value: '12/31/2022'
                },
                mid: {
                    title: 'Due Date:',
                    value: '13/31/2022'
                },
                right: {
                    title: 'Create Date:',
                    value: '04/30/2022'
                },
                rightBadge: {
                    type: 'clock',
                    tooltip: 'TBD',
                },
                contentItems: [
                    {
                        title: 'Frequency:',
                        value: 'Annually'
                    },
                    {
                        title: 'Quality:',
                        value: 'CPA Prepared'
                    },
                    {
                        title: 'Grace days:',
                        value: '120'
                    },
                ]
            }
        ]
    },
    {
        title: '2023',
        dataUI: getDataUI(),
        timelineItemData: [
            {
                badge: {
                    title: '1',
                    color: 'pink',
                    tooltip: 'Company Prepared for Q1, Q2, Q3 for 1st FYE 12/31/2022 with 30 Grace Days'
                },
                left: {
                    title: 'Statemet Date:',
                    value: '03/31/2023'
                },
                mid: {
                    title: 'Due Date:',
                    value: '13/31/2022'
                },
                right: {
                    title: 'Create Date:',
                    value: '04/30/2023'
                },
                rightBadge: {
                    type: 'check',
                    tooltip: 'Existing Match',
                },
                contentItems: [
                    {
                        title: 'Frequency:',
                        value: 'Annually'
                    },
                    {
                        title: 'Quality:',
                        value: 'CPA Prepared'
                    },
                    {
                        title: 'Grace days:',
                        value: '30'
                    },
                ]
            },
            {
                badge: {
                    title: '1',
                    color: 'pink',
                    tooltip: 'Company Prepared for Q1, Q2, Q3 for 1st FYE 12/31/2022 with 30 Grace Days'
                },
                left: {
                    title: 'Statemet Date:',
                    value: '06/30/2023'
                },
                mid: {
                    title: 'Due Date:',
                    value: '13/31/2022'
                },
                right: {
                    title: 'Create Date:',
                    value: '04/30/2023'
                },
                rightBadge: {
                    type: 'clock',
                    tooltip: 'TBD',
                },
                contentItems: [
                    {
                        title: 'Frequency:',
                        value: 'Annually'
                    },
                    {
                        title: 'Quality:',
                        value: 'CPA Prepared'
                    },
                    {
                        title: 'Grace days:',
                        value: '30'
                    },
                ]
            },
            {
                badge: {
                    title: '1',
                    color: 'pink',
                    tooltip: 'Company Prepared for Q1, Q2, Q3 for 1st FYE 12/31/2022 with 30 Grace Days'
                },
                left: {
                    title: 'Statemet Date:',
                    value: '09/30/2023'
                },
                mid: {
                    title: 'Due Date:',
                    value: '13/31/2022'
                },
                right: {
                    title: 'Create Date:',
                    value: '04/30/2023'
                },
                rightBadge: {
                    type: 'check',
                    tooltip: 'Existing Match',
                },
                contentItems: [
                    {
                        title: 'Frequency:',
                        value: 'Annually'
                    },
                    {
                        title: 'Quality:',
                        value: 'CPA Prepared'
                    },
                    {
                        title: 'Grace days:',
                        value: '30'
                    },
                ]
            },
            {
                badge: {
                    title: '2',
                    color: 'blue',
                    tooltip: 'CPA Prepared for every 1 year beginning on 12/31/2022 with 30 Grace Days'
                },
                left: {
                    title: 'Statemet Date:',
                    value: '06/30/2023'
                },
                mid: {
                    title: 'Due Date:',
                    value: '13/31/2022'
                },
                right: {
                    title: 'Create Date:',
                    value: '04/30/2023'
                },
                rightBadge: {
                    type: 'clock',
                    tooltip: 'TBD',
                },
                contentItems: [
                    {
                        title: 'Frequency:',
                        value: 'Quarterly'
                    },
                    {
                        title: 'Quality:',
                        value: 'CPA Prepared'
                    },
                    {
                        title: 'Grace days:',
                        value: '30'
                    },
                ]
            }
        ]
    },
    {
        title: '2024',
        dataUI: getDataUI(),
        timelineItemData: [
            {
                badge: {
                    title: '1',
                    color: 'pink',
                    tooltip: 'Company Prepared for Q1, Q2, Q3 for 1st FYE 12/31/2022 with 30 Grace Days'
                },
                left: {
                    title: 'Statemet Date:',
                    value: '12/31/2024'
                },
                mid: {
                    title: 'Due Date:',
                    value: '13/31/2022'
                },
                right: {
                    title: 'Create Date:',
                    value: '04/30/2024'
                },
                rightBadge: {
                    type: 'check',
                    tooltip: 'Existing Match',
                },
                contentItems: [
                    {
                        title: 'Frequency:',
                        value: 'Annually'
                    },
                    {
                        title: 'Quality:',
                        value: 'CPA Prepared'
                    },
                    {
                        title: 'Grace days:',
                        value: '30'
                    },
                ]
            },
            {
                badge: {
                    title: '2',
                    color: 'blue',
                    tooltip: 'CPA Prepared for every 1 year beginning on 12/31/2022 with 30 Grace Days'
                },
                left: {
                    title: 'Statemet Date:',
                    value: '05/30/2024'
                },  mid: {
                    title: 'Due Date:',
                    value: '13/31/2022'
                },
                right: {
                    title: 'Create Date:',
                    value: '06/30/2024'
                },
                rightBadge: {
                    type: 'clock',
                    tooltip: 'TBD',
                },
                contentItems: [
                    {
                        title: 'Frequency:',
                        value: 'Annually'
                    },
                    {
                        title: 'Quality:',
                        value: 'CPA Prepared'
                    },
                    {
                        title: 'Grace days:',
                        value: '120'
                    },
                ]
            },
            {
                badge: {
                    title: '2',
                    color: 'blue',
                    tooltip: 'CPA Prepared for every 1 year beginning on 12/31/2022 with 30 Grace Days'
                },
                left: {
                    title: 'Statemet Date:',
                    value: '09/30/2024'
                },  
                mid: {
                    title: 'Due Date:',
                    value: '13/31/2022'
                },
                right: {
                    title: 'Create Date:',
                    value: '11/30/2024'
                },
                rightBadge: {
                    type: 'check',
                    tooltip: 'Existing Match',
                },
                contentItems: [
                    {
                        title: 'Frequency:',
                        value: 'Annually'
                    },
                    {
                        title: 'Quality:',
                        value: 'CPA Prepared'
                    },
                    {
                        title: 'Grace days:',
                        value: '120'
                    },
                ]
            }
        ]
    },
    {
        title: '2025',
        dataUI: getDataUI(),
        timelineItemData: [
            {
                badge: {
                    title: '1',
                    color: 'pink',
                    tooltip: 'Company Prepared for Q1, Q2, Q3 for 1st FYE 12/31/2022 with 30 Grace Days'
                },
                left: {
                    title: 'Statemet Date:',
                    value: '12/31/2025'
                },
                mid: {
                    title: 'Due Date:',
                    value: '13/31/2022'
                },
                right: {
                    title: 'Create Date:',
                    value: '04/30/2025'
                },
                rightBadge: {
                    type: 'clock',
                    tooltip: 'TBD',
                },
                contentItems: [
                    {
                        title: 'Frequency:',
                        value: 'Annually'
                    },
                    {
                        title: 'Quality:',
                        value: 'CPA Prepared'
                    },
                    {
                        title: 'Grace days:',
                        value: '30'
                    },
                ]
            },
            {
                badge: {
                    title: '2',
                    color: 'blue',
                    tooltip: 'CPA Prepared for every 1 year beginning on 12/31/2022 with 30 Grace Days'
                },
                left: {
                    title: 'Statemet Date:',
                    value: '05/30/2025'
                },
                mid: {
                    title: 'Due Date:',
                    value: '13/31/2022'
                },
                right: {
                    title: 'Create Date:',
                    value: '06/30/2025'
                },
                rightBadge: {
                    type: 'check',
                    tooltip: 'Existing Match',
                },
                contentItems: [
                    {
                        title: 'Frequency:',
                        value: 'Annually'
                    },
                    {
                        title: 'Quality:',
                        value: 'CPA Prepared'
                    },
                    {
                        title: 'Grace days:',
                        value: '120'
                    },
                ]
            },
            {
                badge: {
                    title: '2',
                    color: 'blue',
                    tooltip: 'CPA Prepared for every 1 year beginning on 12/31/2022 with 30 Grace Days'
                },
                left: {
                    title: 'Statemet Date:',
                    value: '09/30/2025'
                },
                mid: {
                    title: 'Due Date:',
                    value: '13/31/2022'
                },
                right: {
                    title: 'Create Date:',
                    value: '11/30/2025'
                },
                rightBadge: {
                    type: 'clock',
                    tooltip: 'TBD',
                },
                contentItems: [
                    {
                        title: 'Frequency:',
                        value: 'Annually'
                    },
                    {
                        title: 'Quality:',
                        value: 'CPA Prepared'
                    },
                    {
                        title: 'Grace days:',
                        value: '120'
                    },
                ]
            }
        ]
    },
    {
        title: '2026',
        dataUI: getDataUI(),
        timelineItemData: [
            {
                badge: {
                    title: '1',
                    color: 'pink',
                    tooltip: 'Company Prepared for Q1, Q2, Q3 for 1st FYE 12/31/2022 with 30 Grace Days'
                },
                left: {
                    title: 'Statemet Date:',
                    value: '03/31/2026'
                },
                mid: {
                    title: 'Due Date:',
                    value: '13/31/2022'
                },
                right: {
                    title: 'Create Date:',
                    value: '04/30/2026'
                },
                rightBadge: {
                    type: 'check',
                    tooltip: 'Existing Match',
                },
                contentItems: [
                    {
                        title: 'Frequency:',
                        value: 'Annually'
                    },
                    {
                        title: 'Quality:',
                        value: 'CPA Prepared'
                    },
                    {
                        title: 'Grace days:',
                        value: '30'
                    },
                ]
            },
            {
                badge: {
                    title: '1',
                    color: 'pink',
                    tooltip: 'Company Prepared for Q1, Q2, Q3 for 1st FYE 12/31/2022 with 30 Grace Days'
                },
                left: {
                    title: 'Statemet Date:',
                    value: '06/30/2026'
                },
                mid: {
                    title: 'Due Date:',
                    value: '13/31/2022'
                },
                right: {
                    title: 'Create Date:',
                    value: '04/30/2026'
                },
                rightBadge: {
                    type: 'clock',
                    tooltip: 'TBD',
                },
                contentItems: [
                    {
                        title: 'Frequency:',
                        value: 'Annually'
                    },
                    {
                        title: 'Quality:',
                        value: 'CPA Prepared'
                    },
                    {
                        title: 'Grace days:',
                        value: '30'
                    },
                ]
            },
            {
                badge: {
                    title: '1',
                    color: 'pink',
                    tooltip: 'Company Prepared for Q1, Q2, Q3 for 1st FYE 12/31/2022 with 30 Grace Days'
                },
                left: {
                    title: 'Statemet Date:',
                    value: '09/30/2026'
                },
                mid: {
                    title: 'Due Date:',
                    value: '13/31/2022'
                },
                right: {
                    title: 'Create Date:',
                    value: '04/30/2026'
                },
                rightBadge: {
                    type: 'check',
                    tooltip: 'Existing Match',
                },
                contentItems: [
                    {
                        title: 'Frequency:',
                        value: 'Annually'
                    },
                    {
                        title: 'Quality:',
                        value: 'CPA Prepared'
                    },
                    {
                        title: 'Grace days:',
                        value: '30'
                    },
                ]
            },
            {
                badge: {
                    title: '2',
                    color: 'blue',
                    tooltip: 'CPA Prepared for every 1 year beginning on 12/31/2022 with 30 Grace Days'
                },
                left: {
                    title: 'Statemet Date:',
                    value: '06/30/2026'
                },
                mid: {
                    title: 'Due Date:',
                    value: '13/31/2022'
                },
                right: {
                    title: 'Create Date:',
                    value: '04/30/2026'
                },
                rightBadge: {
                    type: 'clock',
                    tooltip: 'TBD',
                },
                contentItems: [
                    {
                        title: 'Frequency:',
                        value: 'Quarterly'
                    },
                    {
                        title: 'Quality:',
                        value: 'CPA Prepared'
                    },
                    {
                        title: 'Grace days:',
                        value: '30'
                    },
                ]
            }
        ]
    },
];

const getWQSystemColor = (color: 'blue' | 'green' | 'yellow' | 'orange' | 'pink') => {
    return getComputedStyle(document.documentElement).getPropertyValue(`--wq-item-color-${color}`);
}

const calcOccurences = () => {
    let count = 0
    timelineMockData.forEach(({ timelineItemData }) => {
        if (timelineItemData)  count += timelineItemData.length;
    });

    return count;
}

const DemoUpcomingOccurrences = () => {
    const [showModal, setShowModal] = useState(false);

    return (
        <ReactAux>
            <MainContainerHeader dataUI={getDataUI()}>
                <ActionContainer dataUI={getDataUI()}>
                    <ActionContainerHeader dataUI={getDataUI()}>
                        <ActionContainerCrumbs dataUI={getDataUI()} crumbs={["Upcomming Occurencess"]} />
                    </ActionContainerHeader>
                </ActionContainer>
            </MainContainerHeader>

            <MainContainerScrollable>
                <Button dataUI={getDataUI()} className="mt-m" onClick={() => setShowModal(true)}>Upcoming Occurrences ‘Covenant Name’</Button>

            </MainContainerScrollable>

            <Modal dataUI={getDataUI()} title="Upcoming Occurrences ‘Covenant Name’" isVisible={showModal} size="L" onClose={() => { setShowModal(false) }}>
                <Toolbar
                    dataUI={getDataUI()}
                    className="mb-m"
                    leftSide={
                        <div>
                            <h5 className='mb-m'>Current Frequency Rules</h5>
                            <div className='d-flex align-items-center lv-small mb-s'> <Icon dataUI={getDataUI()}  className='mr-s' size='S' icon='fas fa-circle' style={{ color: getWQSystemColor('pink') }} /> Company Prepared for Q1, Q2, Q3 for 1st FYE 12/31/2022 with 30 Grace Days</div>
                            <div className='d-flex align-items-center lv-small'> <Icon dataUI={getDataUI()} className='mr-s' size='S' icon='fas fa-circle' style={{ color: getWQSystemColor('blue') }} /> CPA Prepared for every 1 year beginning  on 12/31/2022 with 30 Grace Days</div>
                        </div>
                    }
                />
                <ModalScrollableContainer>
                    <div className='section-container'>

                        <div className='d-flex align-items-center'>
                            <h2 className='mr-s'>Upcoming</h2>
                            <Badge dataUI={getDataUI()} type='info'>{calcOccurences()} Occurences</Badge>
                        </div>

                        <hr className='mt-m mb-m' />

                        <Row>
                            <Col xs={{ offset: 1, size: 10 }}>
                                <Timeline dataUI={getDataUI()}>
                                    {timelineMockData.map(({title , timelineItemData, dataUI}) => {
                                        return <TimelineItem dataUI={dataUI} title={title} timelineItemData={timelineItemData} key={title} />
                                    })}
                                </Timeline>
                            </Col>
                        </Row>
                    </div>
                </ModalScrollableContainer>
            </Modal>
        </ReactAux>
    );
}

export default DemoUpcomingOccurrences;