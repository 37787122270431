import React, { useState } from 'react';
import { Button, Toolbar, InfoBox, Input, Row, Col, Select } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

import './businessRulesStyles.scss';

const selectOptions = [
    { value: '1', label: 'Option 1' },
    { value: '2', label: 'Option 2' },
    { value: '3', label: 'Option 3' },
    { value: '4', label: 'Option 4' }
];

const TestConditionsBox = () => {
    const [facilityVal, setFacilityVal] = useState('');
    const [collateralVal, setCollateralVal] = useState('');
    const [partyVal, setPartyVal] = useState('');
    return (<div className="section-container">
        <h2>Test Conditions</h2>
        <hr className='mb-m mt-m' />
        <Toolbar className="mb-m" dataUI={getDataUI()} leftSide={(
            <>
                <Button className="mr-s" btnType="secondary" icon="fal fa-flask" dataUI={getDataUI()}>Test</Button>
                <InfoBox dataUI={getDataUI()}>
                    Last test ran on: 24/08/22 10:22 AM
                </InfoBox>
            </>
        )} />
        <div>
            <Row className="mb-m">
                <Col xs="4" className="align-self-center">
                    <p className="text-right">File</p>
                </Col>
                <Col xs="6">
                    <Input className="width-full required-field" type="text" dataUI={getDataUI()} />
                </Col>
            </Row>
            <Row className="mb-m">
                <Col xs="4" className="align-self-center">
                    <p className="text-right">Facility</p>
                </Col>
                <Col xs="6">
                    <Select
                        className="width-full required-field"
                        options={selectOptions}
                        value={facilityVal}
                        onChange={(obj: any) => {
                            setFacilityVal(obj.value);
                        }}
                        dataUI={getDataUI()}
                    />
                </Col>
            </Row>
            <Row className="mb-m">
                <Col xs="4" className="align-self-center">
                    <p className="text-right">Collateral</p>
                </Col>
                <Col xs="6">
                    <Select
                        className="width-full required-field"
                        options={selectOptions}
                        value={collateralVal}
                        onChange={(obj: any) => {
                            setCollateralVal(obj.value);
                        }}
                        dataUI={getDataUI()}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs="4" className="align-self-center">
                    <p className="text-right">Party</p>
                </Col>
                <Col xs="6">
                    <Select
                        className="width-full required-field"
                        options={selectOptions}
                        value={partyVal}
                        onChange={(obj: any) => {
                            setPartyVal(obj.value);
                        }}
                        dataUI={getDataUI()}
                    />
                </Col>
            </Row>
        </div>
    </div>
    );
}


export default TestConditionsBox;