import React from 'react';

import { Icon, ThemeChanger, themeChangerHelpers, Select } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from './helpers';

const options = themeChangerHelpers.THEMES.map((t: any) => {
    return { value: t.name, label: t.title, base: t.base }
})

class DocsThemeChanger extends React.Component {

    state = {
        theme: undefined,
    }

    componentDidMount() {
        this.setState({ theme: 'navy' });
    }

    render() {
        const { theme } = this.state;

        return (
            <div style={{ position: 'fixed', top: '16px', right: '16px', zIndex: 9999 }}>
                <Icon dataUI={getDataUI()}  className="mr-s text-primary" icon="fal fa-palette" size="M" />
                <Select
                    className="d-inline-block width-m"
                    value={theme || ''}
                    onChange={(obj: any) => this.setState({ theme: obj.value })}
                    options={options} 
                    dataUI={getDataUI()}
                />
                <ThemeChanger theme={theme} />
            </div>
        );
    }
}

export default DocsThemeChanger;
