import React from 'react';

import {
    MainContainerScrollable, Tabs, TabList, TabItem,
    TabContent, TabContentItem, TabItemTitle,
    Button, Input, DragNDropSection, Droparea, DraggableItem, DragNDropContext,
    DraggableItemActions, DraggableItemTitle, DragNDropUtils,
    Modal, DragNDropSectionHeader, DynamicScrollableContainer,
    Row, Col, Toolbar, Tooltip, Label, Select
} from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

type DnDProcessDefinitionModalState = {
    isModalVisible: boolean,
    isFetching: boolean,
    currentTabIndex: number,
    availableTaskItems: { title: string, id: string, type: string }[],
    assignedTaskItems: { title: string, id: string, type: string }[],
    availableDeclarationItems: { title: string, id: string, type: string }[],
    assignedDeclarationItems: { title: string, id: string, type: string }[],
    dragStartEvent: Event | null,
    dragUpdateEvent: Event | null,
    availableTasksSearch: string,
    availableDeclarationsSearch: string,
    assignedTasksSearch: string,
    assignedDeclarationsSearch: string,
}

// NOTE: It is important to provide unique id values for each item
const defaultTasksAvailable = [
    { title: '2nd Task', id: 'item-task-1' }, { title: '2000', id: 'item-task-2' }, { title: 'Action Plan', id: 'item-task-3' }, { title: 'Angel Test', id: 'item-task-4' },
    { title: 'Approval Days in Decisioning Approval a veeery long title that has to get ellipsis', id: 'item-task-5' }, { title: 'Application Submission', id: 'item-task-6' }, { title: 'Appraisal', id: 'item-task-7' }, { title: 'Approval', id: 'item-task-8' },
    { title: 'Approval Days in Decisioning', id: 'item-task-9' }, { title: 'Bal Sheet', id: 'item-task-10' }, { title: 'Reason', id: 'item-task-11' }, { title: 'Exceed Policy', id: 'item-task-12' },
];

const defaultDeclarationsAvailable = [
    { title: '1236', id: 'item-decl-1' }, { title: '2000', id: 'item-decl-2' }, { title: '325235', id: 'item-decl-3' }, { title: '400', id: 'item-decl-4' },
    { title: '4000', id: 'item-decl-5' }, { title: '5000', id: 'item-decl-6' }, { title: '6000', id: 'item-decl-7' }, { title: '8000', id: 'item-decl-8' },
    { title: '9000', id: 'item-decl-9' }, { title: 'Bal Sheet', id: 'item-decl-10' }, { title: 'Reason', id: 'item-decl-11' }, { title: 'Exceed Policy', id: 'item-decl-12' },
];

const defaultDeclarationsAssigned = [{ title: '001', id: 'item-decl-13' }, { title: '7000', id: 'item-decl-14' }, { title: '004', id: 'item-decl-15' }, { title: '040', id: 'item-decl-16' }];


class DnDProcessDefinitionModal extends React.Component {
    private static readonly AVAILABLE_TASKS_SECTION_NAME = 'available-tasks';
    private static readonly AVAILABLE_DECLARATIONS_SECTION_NAME = 'available-declarations';
    private static readonly ASSIGNED_TASKS_SECTION_NAME = 'assigned-tasks';
    private static readonly ASSIGNED_DECLARATIONS_SECTION_NAME = 'assigned-declarations';

    state: DnDProcessDefinitionModalState = {
        isModalVisible: false,
        isFetching: true,
        currentTabIndex: 0,
        availableTaskItems: [],
        assignedTaskItems: [],
        availableDeclarationItems: defaultDeclarationsAvailable.map(e => { return { ...e, type: DnDProcessDefinitionModal.AVAILABLE_DECLARATIONS_SECTION_NAME } }),
        assignedDeclarationItems: defaultDeclarationsAssigned.map(e => { return { ...e, type: DnDProcessDefinitionModal.ASSIGNED_DECLARATIONS_SECTION_NAME } }),
        dragStartEvent: null,
        dragUpdateEvent: null,
        availableTasksSearch: '',
        availableDeclarationsSearch: '',
        assignedTasksSearch: '',
        assignedDeclarationsSearch: '',
    };

    updateTabIndex(newIndex: number) {
        if (this.state.currentTabIndex !== newIndex) {
            if (newIndex === 1) {
                if (this.state.isFetching) {
                    setTimeout(() => {
                        this.setState({
                            isFetching: false,
                            availableTaskItems: defaultTasksAvailable.map(e => { return { ...e, type: DnDProcessDefinitionModal.AVAILABLE_TASKS_SECTION_NAME } }),
                        });
                    }, 2000);
                }
            }
            this.setState({ currentTabIndex: newIndex })
        }
    }

    moveAvailableItemToAssigned(type: string, index: number) {
        if (type === DnDProcessDefinitionModal.AVAILABLE_TASKS_SECTION_NAME) {
            const { availableTaskItems, assignedTaskItems } = this.state;
            const assignedClone = Array.from(assignedTaskItems);
            const availableClone = Array.from(availableTaskItems);
            const [removed] = availableClone.splice(index, 1);
            assignedClone.push(removed);
            this.setState({ availableTaskItems: availableClone, assignedTaskItems: assignedClone });
        } else if (type === DnDProcessDefinitionModal.AVAILABLE_DECLARATIONS_SECTION_NAME) {
            const { availableDeclarationItems, assignedDeclarationItems } = this.state;
            const assignedClone = Array.from(assignedDeclarationItems);
            const availableClone = Array.from(availableDeclarationItems);
            const [removed] = availableClone.splice(index, 1);
            assignedClone.push(removed);
            this.setState({ availableDeclarationItems: availableClone, assignedDeclarationItems: assignedClone });
        }
    }

    returnItemToAvailable(type: string, index: number) {
        if (type === DnDProcessDefinitionModal.AVAILABLE_TASKS_SECTION_NAME) {
            const { availableTaskItems, assignedTaskItems } = this.state;
            const assignedClone = Array.from(assignedTaskItems);
            const availableClone = Array.from(availableTaskItems);
            const [removed] = assignedClone.splice(index, 1);
            availableClone.push(removed);
            this.setState({ availableTaskItems: availableClone, assignedTaskItems: assignedClone });
        } else if (type === DnDProcessDefinitionModal.AVAILABLE_DECLARATIONS_SECTION_NAME) {
            const { availableDeclarationItems, assignedDeclarationItems } = this.state;
            const assignedClone = Array.from(assignedDeclarationItems);
            const availableClone = Array.from(availableDeclarationItems);
            const [removed] = assignedClone.splice(index, 1);
            availableClone.push(removed);
            this.setState({ availableDeclarationItems: availableClone, assignedDeclarationItems: assignedClone });
        }
    }

    addAllToAssigned(destinationType: string) {
        if (destinationType === DnDProcessDefinitionModal.ASSIGNED_TASKS_SECTION_NAME) {
            const { availableTaskItems, assignedTaskItems } = this.state;
            let assignedClone = Array.from(assignedTaskItems);
            let availableClone = Array.from(availableTaskItems);
            assignedClone = [...assignedClone, ...availableClone];
            availableClone = [];
            this.setState({ availableTaskItems: availableClone, assignedTaskItems: assignedClone });
        } else if (destinationType === DnDProcessDefinitionModal.ASSIGNED_DECLARATIONS_SECTION_NAME) {
            const { availableDeclarationItems, assignedDeclarationItems } = this.state;
            let assignedClone = Array.from(assignedDeclarationItems);
            let availableClone = Array.from(availableDeclarationItems);
            assignedClone = [...assignedClone, ...availableClone];
            availableClone = [];
            this.setState({ availableDeclarationItems: availableClone, assignedDeclarationItems: assignedClone });
        }
    }

    removeAllAssigned(destinationType: string) {
        if (destinationType === DnDProcessDefinitionModal.AVAILABLE_TASKS_SECTION_NAME) {
            const { availableTaskItems, assignedTaskItems } = this.state;
            let availableClone = Array.from(availableTaskItems);
            let assignedClone = Array.from(assignedTaskItems);
            for (const item of assignedClone) {
                availableClone.push(item);
            }
            this.setState({
                availableTaskItems: availableClone,
                assignedTaskItems: []
            });
        } else if (destinationType === DnDProcessDefinitionModal.AVAILABLE_DECLARATIONS_SECTION_NAME) {
            const { availableDeclarationItems, assignedDeclarationItems } = this.state;
            let availableClone = Array.from(availableDeclarationItems);
            let assignedClone = Array.from(assignedDeclarationItems);
            for (const item of assignedClone) {
                availableClone.push(item);
            }
            this.setState({
                availableDeclarationItems: availableClone,
                assignedDeclarationItems: []
            });
        }
    }

    getList(listId: string) {
        if (listId === DnDProcessDefinitionModal.AVAILABLE_TASKS_SECTION_NAME) {
            return this.state.availableTaskItems;
        } else if (listId === DnDProcessDefinitionModal.AVAILABLE_DECLARATIONS_SECTION_NAME) {
            return this.state.availableDeclarationItems;
        } else if (listId === DnDProcessDefinitionModal.ASSIGNED_TASKS_SECTION_NAME) {
            return this.state.assignedTaskItems;
        } else if (listId === DnDProcessDefinitionModal.ASSIGNED_DECLARATIONS_SECTION_NAME) {
            return this.state.assignedDeclarationItems;
        }
        return null;
    }

    onDragEnd(result: any) {
        const { source, destination } = result;
        if (!result.destination) {
            this.setState({
                dragStartEvent: null,
                dragUpdateEvent: null,
            });
            return;
        }

        if (source.droppableId === destination.droppableId) {
            if (source.droppableId === DnDProcessDefinitionModal.AVAILABLE_TASKS_SECTION_NAME) {
                const items = DragNDropUtils.reorderList(
                    this.state.availableTaskItems,
                    source.index,
                    destination.index
                );
                this.setState({ availableTaskItems: items });
            } else if (source.droppableId === DnDProcessDefinitionModal.ASSIGNED_TASKS_SECTION_NAME) {
                const items = DragNDropUtils.reorderList(
                    this.state.assignedTaskItems,
                    source.index,
                    destination.index
                );
                this.setState({ assignedTaskItems: items });
            } else if (source.droppableId === DnDProcessDefinitionModal.AVAILABLE_DECLARATIONS_SECTION_NAME) {
                const items = DragNDropUtils.reorderList(
                    this.state.availableDeclarationItems,
                    source.index,
                    destination.index
                );
                this.setState({ availableDeclarationItems: items });
            } else if (source.droppableId === DnDProcessDefinitionModal.ASSIGNED_DECLARATIONS_SECTION_NAME) {
                const items = DragNDropUtils.reorderList(
                    this.state.assignedDeclarationItems,
                    source.index,
                    destination.index
                );
                this.setState({ assignedDeclarationItems: items });
            }
        } else {
            const result: any = DragNDropUtils.moveBetweenLists(
                this.getList(source.droppableId) || [],
                this.getList(destination.droppableId) || [],
                source.index,
                destination.index
            );

            if (source.droppableId === DnDProcessDefinitionModal.AVAILABLE_TASKS_SECTION_NAME) {
                this.setState({ availableTaskItems: result[0], assignedTaskItems: result[1], })
            } else if (source.droppableId === DnDProcessDefinitionModal.AVAILABLE_DECLARATIONS_SECTION_NAME) {
                this.setState({ availableDeclarationItems: result[0], assignedDeclarationItems: result[1], })
            } else if (source.droppableId === DnDProcessDefinitionModal.ASSIGNED_TASKS_SECTION_NAME) {
                this.setState({ assignedTaskItems: result[0], availableTaskItems: result[1], })
            } else if (source.droppableId === DnDProcessDefinitionModal.ASSIGNED_DECLARATIONS_SECTION_NAME) {
                this.setState({ assignedDeclarationItems: result[0], availableDeclarationItems: result[1], })
            }
        }

        this.setState({
            dragStartEvent: null,
            dragUpdateEvent: null
        });
    }

    handleDragStart(event: any) {
        this.setState({
            dragStartEvent: event,
            dragUpdateEvent: null,
        });
    }

    handleDragUpdate(event: any) {
        this.setState({
            dragStartEvent: null,
            dragUpdateEvent: event,
        });
    }

    setSearchValue(sectionName: string, value: string) {
        let statePropName = '';
        switch (sectionName) {
            case DnDProcessDefinitionModal.AVAILABLE_TASKS_SECTION_NAME:
                statePropName = 'availableTasksSearch';
                break;
            case DnDProcessDefinitionModal.AVAILABLE_DECLARATIONS_SECTION_NAME:
                statePropName = 'availableDeclarationsSearch';
                break;
            case DnDProcessDefinitionModal.ASSIGNED_TASKS_SECTION_NAME:
                statePropName = 'assignedTasksSearch';
                break;
            case DnDProcessDefinitionModal.ASSIGNED_DECLARATIONS_SECTION_NAME:
                statePropName = 'assignedDeclarationsSearch';
                break;
            default:
                return;
        }
        this.setState({ [statePropName]: value })
    }

    render() {

        return (
            <MainContainerScrollable>
                <Button dataUI={getDataUI()} className="mb-m" icon="fal fa-grip-vertical" onClick={() => this.setState({ isModalVisible: true })}>Drag-n-Drop Selection Modal</Button>
                <Modal dataUI={getDataUI()} title="Select columns" isVisible={this.state.isModalVisible} size="L" onClose={() => this.setState({ isModalVisible: false })}>
                    <Toolbar
                        dataUI={getDataUI()}
                        className="mb-m"
                        leftSide={
                            <>
                                <Button dataUI={getDataUI()} icon="fal fa-save">Save</Button>
                            </>
                        }
                    />
                    <DragNDropContext onDragStart={this.handleDragStart.bind(this)} onDragUpdate={this.handleDragUpdate.bind(this)} onDragEnd={this.onDragEnd.bind(this)}>
                        <Tabs
                            dataUI={getDataUI()}
                            onTabIndexChange={(index: number) => this.updateTabIndex(index)}
                            currentlyActiveTabIndex={this.state.currentTabIndex}
                        >
                            <TabList dataUI={getDataUI()}>
                                <TabItem dataUI={getDataUI()}>
                                    <TabItemTitle dataUI={getDataUI()}>Details</TabItemTitle>
                                </TabItem>
                                <TabItem dataUI={getDataUI()}>
                                    <TabItemTitle dataUI={getDataUI()}>Tasks (prerendered & async)</TabItemTitle>
                                </TabItem>
                                <TabItem dataUI={getDataUI()}>
                                    <TabItemTitle dataUI={getDataUI()}>Narratives</TabItemTitle>
                                </TabItem>
                                <TabItem dataUI={getDataUI()}>
                                    <TabItemTitle dataUI={getDataUI()}>Declarations</TabItemTitle>
                                </TabItem>
                            </TabList>
                            <TabContent dataUI={getDataUI()}>
                                <TabContentItem dataUI={getDataUI()}>Details tab content</TabContentItem>
                                <TabContentItem dataUI={getDataUI()} isPrerendered>
                                    {this.state.isFetching && (
                                        <div className="lv-loader-container">
                                            <div className="lv-loader-big">
                                                <div /><div /><div /><div /><div /><div /><div /><div />
                                            </div>
                                        </div>
                                    )}
                                    <Row className="height-full">
                                        <Col xs={6}>
                                            <DynamicScrollableContainer dataUI={getDataUI()}>
                                                <DragNDropSection dataUI={getDataUI()}>
                                                    <DragNDropSectionHeader dataUI={getDataUI()}>
                                                        <div className="d-flex justify-content-between align-items-center mb-m">
                                                            <h2>Available ({this.state.availableTaskItems.length})</h2>
                                                            <Button dataUI={getDataUI()} onClick={() => this.addAllToAssigned(DnDProcessDefinitionModal.ASSIGNED_TASKS_SECTION_NAME)} btnType="secondary" icon="fal fa-file-import">Add all</Button>
                                                        </div>
                                                        <Input
                                                            dataUI={getDataUI()}
                                                            name="input-icon"
                                                            value={this.state.availableTasksSearch}
                                                            onChange={(ev) => this.setSearchValue(DnDProcessDefinitionModal.AVAILABLE_TASKS_SECTION_NAME, ev.target.value)}
                                                            icon='fal fa-search'
                                                        />
                                                    </DragNDropSectionHeader>
                                                    <Droparea dataUI={getDataUI()} dropareaID={DnDProcessDefinitionModal.AVAILABLE_TASKS_SECTION_NAME} allowedItems={[DnDProcessDefinitionModal.AVAILABLE_TASKS_SECTION_NAME, DnDProcessDefinitionModal.ASSIGNED_TASKS_SECTION_NAME]}
                                                        dragStartEvent={this.state.dragStartEvent}
                                                        dragUpdateEvent={this.state.dragUpdateEvent}
                                                    >
                                                        {this.state.availableTaskItems.map((item, index) => {
                                                            return (
                                                                !this.state.availableTasksSearch || (this.state.availableTasksSearch && item.title.includes(this.state.availableTasksSearch)) ?
                                                                    <DraggableItem dataUI={getDataUI()} key={item.title} index={index} draggableID={item.id}>
                                                                        <DraggableItemActions dataUI={getDataUI()}>
                                                                            <DraggableItemTitle dataUI={getDataUI()}><a href="./process-definition-modal" className="lv-link-styles">{item.title}</a></DraggableItemTitle>
                                                                        </DraggableItemActions>
                                                                        <DraggableItemActions dataUI={getDataUI()}>
                                                                            <Tooltip title="Move">
                                                                                <Button dataUI={getDataUI()} onClick={() => this.moveAvailableItemToAssigned(DnDProcessDefinitionModal.AVAILABLE_TASKS_SECTION_NAME, index)} btnType="icon" icon="fal fa-arrow-right" />
                                                                            </Tooltip>
                                                                        </DraggableItemActions>
                                                                    </DraggableItem>
                                                                    :
                                                                    ''
                                                            )
                                                        })}
                                                    </Droparea>
                                                </DragNDropSection>
                                            </DynamicScrollableContainer>
                                        </Col>

                                        <Col xs={6}>
                                            <DynamicScrollableContainer dataUI={getDataUI()}>
                                                <DragNDropSection dataUI={getDataUI()}>
                                                    <DragNDropSectionHeader dataUI={getDataUI()}>
                                                        <div className="d-flex justify-content-between align-items-center mb-m">
                                                            <h2>Assigned ({this.state.assignedTaskItems.length})</h2>
                                                            <Button dataUI={getDataUI()} onClick={() => this.removeAllAssigned(DnDProcessDefinitionModal.AVAILABLE_TASKS_SECTION_NAME)} btnType="secondary" icon="fal fa-file-import">Remove all</Button>
                                                        </div>
                                                        <Input
                                                            dataUI={getDataUI()}
                                                            name="input-icon"
                                                            value={this.state.assignedTasksSearch}
                                                            onChange={(ev) => this.setSearchValue(DnDProcessDefinitionModal.ASSIGNED_TASKS_SECTION_NAME, ev.target.value)}
                                                            icon='fal fa-search'
                                                        />
                                                    </DragNDropSectionHeader>
                                                    <Droparea
                                                        dataUI={getDataUI()}
                                                        dropareaID={DnDProcessDefinitionModal.ASSIGNED_TASKS_SECTION_NAME}
                                                        allowedItems={[DnDProcessDefinitionModal.ASSIGNED_TASKS_SECTION_NAME, DnDProcessDefinitionModal.AVAILABLE_TASKS_SECTION_NAME]}
                                                        callToActionText="Drop your files here."
                                                        dragStartEvent={this.state.dragStartEvent}
                                                        dragUpdateEvent={this.state.dragUpdateEvent}
                                                    >
                                                        {this.state.assignedTaskItems.map((item, index) => {
                                                            return (
                                                                !this.state.assignedTasksSearch || (this.state.assignedTasksSearch && item.title.includes(this.state.assignedTasksSearch)) ?
                                                                    <DraggableItem dataUI={getDataUI()} key={item.title} index={index} draggableID={item.id}>
                                                                        <DraggableItemActions dataUI={getDataUI()}>
                                                                            <DraggableItemTitle dataUI={getDataUI()}>{item.title}</DraggableItemTitle>
                                                                        </DraggableItemActions>
                                                                        <DraggableItemActions dataUI={getDataUI()}>
                                                                            <Tooltip title="Remove">
                                                                                <Button dataUI={getDataUI()} onClick={() => this.returnItemToAvailable(DnDProcessDefinitionModal.AVAILABLE_TASKS_SECTION_NAME, index)} btnType="icon" icon="fal fa-times" />
                                                                            </Tooltip>
                                                                        </DraggableItemActions>
                                                                    </DraggableItem>
                                                                    :
                                                                    ''
                                                            )
                                                        })}
                                                    </Droparea>
                                                </DragNDropSection>
                                            </DynamicScrollableContainer>
                                        </Col>
                                    </Row>

                                </TabContentItem>
                                <TabContentItem dataUI={getDataUI()}>Narratives tab content</TabContentItem>
                                <TabContentItem dataUI={getDataUI()}>
                                    <Row className="height-full">
                                        <Col xs={6}>
                                            <DynamicScrollableContainer dataUI={getDataUI()}>
                                                <DragNDropSection dataUI={getDataUI()}>
                                                    <DragNDropSectionHeader dataUI={getDataUI()}>
                                                        <div className="d-flex justify-content-between align-items-center mb-m">
                                                            <h2>Available ({this.state.availableDeclarationItems.length})</h2>
                                                            <Button dataUI={getDataUI()} onClick={() => this.addAllToAssigned(DnDProcessDefinitionModal.ASSIGNED_DECLARATIONS_SECTION_NAME)} btnType="secondary" icon="fal fa-file-import">Add all</Button>
                                                        </div>
                                                        <Input
                                                            dataUI={getDataUI()}
                                                            name="input-icon"
                                                            value={this.state.availableDeclarationsSearch}
                                                            onChange={(ev) => this.setSearchValue(DnDProcessDefinitionModal.AVAILABLE_DECLARATIONS_SECTION_NAME, ev.target.value)}
                                                            icon='fal fa-search'
                                                        />
                                                    </DragNDropSectionHeader>
                                                    <Droparea dataUI={getDataUI()} dropareaID={DnDProcessDefinitionModal.AVAILABLE_DECLARATIONS_SECTION_NAME} allowedItems={[DnDProcessDefinitionModal.AVAILABLE_DECLARATIONS_SECTION_NAME, DnDProcessDefinitionModal.ASSIGNED_DECLARATIONS_SECTION_NAME]}
                                                        dragStartEvent={this.state.dragStartEvent}
                                                        dragUpdateEvent={this.state.dragUpdateEvent}
                                                    >

                                                        {this.state.availableDeclarationItems.map((item, index) => {
                                                            return (
                                                                !this.state.availableDeclarationsSearch || (this.state.availableDeclarationsSearch && item.title.includes(this.state.availableDeclarationsSearch)) ?
                                                                    <DraggableItem dataUI={getDataUI()} key={item.title} index={index} draggableID={item.id}>
                                                                        <DraggableItemActions dataUI={getDataUI()}>
                                                                            <DraggableItemTitle dataUI={getDataUI()}>{item.title}</DraggableItemTitle>
                                                                        </DraggableItemActions>
                                                                        <DraggableItemActions dataUI={getDataUI()}>
                                                                            <Tooltip title="Move">
                                                                                <Button dataUI={getDataUI()} onClick={() => this.moveAvailableItemToAssigned(DnDProcessDefinitionModal.AVAILABLE_DECLARATIONS_SECTION_NAME, index)} btnType="icon" icon="fal fa-arrow-right" />
                                                                            </Tooltip>
                                                                        </DraggableItemActions>
                                                                    </DraggableItem>
                                                                    :
                                                                    ''
                                                            )
                                                        })}
                                                    </Droparea>
                                                </DragNDropSection>
                                            </DynamicScrollableContainer>
                                        </Col>

                                        <Col xs={6}>
                                            <DynamicScrollableContainer dataUI={getDataUI()}>
                                                <DragNDropSection dataUI={getDataUI()}>
                                                    <DragNDropSectionHeader dataUI={getDataUI()}>
                                                        <div className="d-flex justify-content-between align-items-center mb-m">
                                                            <h2>Assigned ({this.state.assignedDeclarationItems.length})</h2>
                                                            <Button dataUI={getDataUI()} onClick={() => this.removeAllAssigned(DnDProcessDefinitionModal.AVAILABLE_DECLARATIONS_SECTION_NAME)} btnType="secondary" icon="fal fa-file-import">Remove all</Button>
                                                        </div>
                                                        <Input
                                                            dataUI={getDataUI()}
                                                            name="input-icon"
                                                            value={this.state.assignedDeclarationsSearch}
                                                            onChange={(ev) => this.setSearchValue(DnDProcessDefinitionModal.ASSIGNED_DECLARATIONS_SECTION_NAME, ev.target.value)}
                                                            icon='fal fa-search'
                                                        />
                                                    </DragNDropSectionHeader>
                                                    <Droparea
                                                        dataUI={getDataUI()}
                                                        dropareaID={DnDProcessDefinitionModal.ASSIGNED_DECLARATIONS_SECTION_NAME}
                                                        allowedItems={[DnDProcessDefinitionModal.ASSIGNED_DECLARATIONS_SECTION_NAME, DnDProcessDefinitionModal.AVAILABLE_DECLARATIONS_SECTION_NAME]}
                                                        callToActionText="Drop your files here."
                                                        dragStartEvent={this.state.dragStartEvent}
                                                        dragUpdateEvent={this.state.dragUpdateEvent}
                                                    >
                                                        {this.state.assignedDeclarationItems.map((item, index) => {
                                                            return (
                                                                !this.state.assignedDeclarationsSearch || (this.state.assignedDeclarationsSearch && item.title.includes(this.state.assignedDeclarationsSearch)) ?
                                                                    <DraggableItem dataUI={getDataUI()} key={item.title} index={index} draggableID={item.id}>
                                                                        <DraggableItemActions dataUI={getDataUI()}>
                                                                            <DraggableItemTitle dataUI={getDataUI()}>{item.title}</DraggableItemTitle>
                                                                        </DraggableItemActions>
                                                                        <DraggableItemActions dataUI={getDataUI()}>
                                                                            <Label dataUI={getDataUI()} className="text-muted">Label</Label>
                                                                            <Select dataUI={getDataUI()} className="width-m" placeholder="Select" options={[{ label: 'Test 1', value: 'test 1' }, { label: 'Test 2', value: 'test 2' }]} value="" />
                                                                            <Tooltip title="Remove">
                                                                                <Button dataUI={getDataUI()} onClick={() => this.returnItemToAvailable(DnDProcessDefinitionModal.AVAILABLE_DECLARATIONS_SECTION_NAME, index)} btnType="icon" icon="fal fa-times" />
                                                                            </Tooltip>
                                                                        </DraggableItemActions>
                                                                    </DraggableItem>
                                                                    :
                                                                    ''
                                                            )
                                                        })}
                                                    </Droparea>
                                                </DragNDropSection>
                                            </DynamicScrollableContainer>
                                        </Col>
                                    </Row>
                                </TabContentItem>
                            </TabContent>
                        </Tabs>
                    </DragNDropContext>
                </Modal>
            </MainContainerScrollable>);
    };
}

export default DnDProcessDefinitionModal;
