import React, { useState } from 'react';
import Highlight from 'react-highlight';
import { NumericInputSpinner, ValidationMessage } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const NumericInputSpinnerPage: React.FC = () => {
    const [value, setValue] = useState(100);
    return (
        <div>
            <h1 className="mb-s">NumericInputSpinner</h1>
            <p className="mb-l">Custom component to be used instead of input type number.</p>

            <h3 className="mb-s">Default</h3>
            <NumericInputSpinner
                dataUI={getDataUI()}
                className='mb-m'
            />

            <h3 className="mb-s">Disabled</h3>

            <NumericInputSpinner
                dataUI={getDataUI()}
                disabled
                className='mb-m'
            />

            <h3 className="mb-s">With set min=7 and max=20</h3>
            <NumericInputSpinner
                dataUI={getDataUI()}
                min={7}
                max={20}
                value={value}
                onChange={(currentValue: any) => {
                    console.log('change value', currentValue);
                    setValue(currentValue);
                }}
                className="mb-m"
            />

            <h3 className="mb-s">NumericInputSpinner dark border.</h3>
            <NumericInputSpinner
                darkBorder
                className='mb-m'
                dataUI={getDataUI()}
            />

            <h3 className="mb-s">NumericInputSpinner invalid.</h3>
            <NumericInputSpinner
                invalid
                dataUI={getDataUI()}
            />
            <ValidationMessage type='error' message='Invalid message' dataUI={getDataUI()}/>

            <Highlight className="React">
                {
                    `
import React from 'react';
import { NumericInput } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const Example: React.FC = (props) => {
    const [value, setValue] = useState(10);
    return (
      <div>
        <NumericInputSpinner
            dataUI={getDataUI()}
            min={7}
            max={20}
            value={value}
            onChange={(currentValue: any) => {
                console.log('change value', currentValue);
                setValue(currentValue);
            }}

            // Set disabled state
            disabled

            // Set dark border
            darkBorder

            // Set invalid state
            invalid
        />
      </div>
    );
}

export default Example;
    `}
            </Highlight>

            <h2 className="mb-m">API</h2>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>value</td>
                        <td>number</td>
                        <td className="text-center">-</td>
                        <td>Set the input value. If value is smaller than min value, min value will be set as value. If value is bigger than max value, max value will be set as value</td>
                    </tr>
                    <tr>
                        <td>min</td>
                        <td>number</td>
                        <td>
                            0
                        </td>
                        <td>Set input min value.</td>
                    </tr>
                    <tr>
                        <td>max</td>
                        <td>number</td>
                        <td>
                            99
                        </td>
                        <td>Set input max value.</td>
                    </tr>
                    <tr>
                        <td>onChange</td>
                        <td>(value:number) {'=>'} {'{}'}</td>
                        <td className="text-center">-</td>
                        <td>Receive input value. It is triggered then click on arrows, blur input or use ArrowUp/ArrowDown key form keyboard</td>
                    </tr>
                    <tr>
                        <td>invalid</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Sets component invalid styles. You can set component invalid styles and by adding class 'invalid' </td>
                    </tr>
                    <tr>
                        <td>darkBorder</td>
                        <td className='text-center'>boolean</td>
                        <td className='text-center'>-</td>
                        <td>Sets component border color</td>
                    </tr>
                    <tr>
                        <td>all native Input attributes</td>
                        <td className="text-center">-</td>
                        <td className="text-center">-</td>
                        <td>You can use all native Input attributes like: "id", "disabled", "name"...</td>
                    </tr>
                </tbody>
            </table>

        </div>
    )
}

export default NumericInputSpinnerPage;
