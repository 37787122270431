import React from 'react';
import Highlight from 'react-highlight';
import { Editor, EditorTools, EditorContainer, Tooltip, Button } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';


const DatepickerPage: React.FC = () => {

    const {
        Bold, Italic, Underline, Strikethrough, Subscript, Superscript,
        ForeColor, BackColor,
        AlignLeft, AlignCenter, AlignRight, AlignJustify,
        Indent, Outdent, OrderedList, UnorderedList,
        Undo, Redo, FontSize, FontName, FormatBlock,
        InsertImage, ViewHtml,
        InsertTable,
        AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter,
        DeleteRow, DeleteColumn, DeleteTable,
        MergeCells, SplitCell, Unlink,
    } = EditorTools;
    return (
        <div>
            <h1 className="mb-s">Editor</h1>
            <p className="mb-m">The Editor component export <a  rel="noopener noreferrer" title='Kendo React Editor' target='_blank' href='https://www.telerik.com/kendo-react-ui/components/editor/get-started/'>Kendo React Editor</a> and apply design system styles on it.</p>
            <p className="mb-s">
                <strong>Note</strong>: Always wrap <strong>Editor</strong> component in <strong>EditorContainer</strong> to prevent styling issues.
            </p>

            <div className="mb-m">
                <EditorContainer dataUI={getDataUI()}>
                    <Editor
                        tools={[
                            [Bold, Italic, Underline, Strikethrough,
                                Subscript, Superscript,
                                ForeColor, BackColor,
                                AlignLeft, AlignCenter, AlignRight, AlignJustify,
                                Indent, Outdent,
                                OrderedList, UnorderedList,
                                FontSize, FontName, FormatBlock,
                                Undo, Redo,
                                InsertImage, ViewHtml,
                                InsertTable, AddRowBefore, AddRowAfter,
                                AddColumnBefore, AddColumnAfter,
                                DeleteRow, DeleteColumn, DeleteTable,
                                MergeCells, SplitCell,
                                EditorTools.Link, Unlink,
                                () => <Tooltip title="DSO"><Button dataUI={getDataUI()} btnType="narrow">DSO</Button></Tooltip>,
                                () => <Tooltip title="UCF"><Button dataUI={getDataUI()} btnType="narrow">UCF</Button></Tooltip>,
                                () => <Tooltip title="TCF"><Button dataUI={getDataUI()} btnType="narrow">TCF</Button></Tooltip>
                            ]
                        ]}
                        contentStyle={{ height: 300 }}

                    />
                </EditorContainer>
            </div>


            <Highlight className="React">
                {
                    `
import React from 'react';
import { Editor, EditorTools, EditorContainer, Tooltip, Button } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const Example: React.FC = (props) => {
    return (
        const {
            Bold, Italic, Underline, Strikethrough, Subscript, Superscript,
            ForeColor, BackColor,
            AlignLeft, AlignCenter, AlignRight, AlignJustify,
            Indent, Outdent, OrderedList, UnorderedList,
            Undo, Redo, FontSize, FontName, FormatBlock,
            InsertImage, ViewHtml,
            InsertTable,
            AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter,
            DeleteRow, DeleteColumn, DeleteTable,
            MergeCells, SplitCell, Unlink,
        } = EditorTools;
      <div>     
           <EditorContainer dataUI={getDataUI()}>
                    <Editor
                        tools={[
                            [Bold, Italic, Underline, Strikethrough,
                                Subscript, Superscript,
                                ForeColor, BackColor,
                                AlignLeft, AlignCenter, AlignRight, AlignJustify,
                                Indent, Outdent,
                                OrderedList, UnorderedList,
                                FontSize, FontName, FormatBlock,
                                Undo, Redo,
                                InsertImage, ViewHtml,
                                InsertTable, AddRowBefore, AddRowAfter,
                                AddColumnBefore, AddColumnAfter,
                                DeleteRow, DeleteColumn, DeleteTable,
                                MergeCells, SplitCell,
                                EditorTools.Link, Unlink,
                                () => <Tooltip title="DSO"><Button dataUI={getDataUI()} btnType="narrow">DSO</Button></Tooltip>,
                                () => <Tooltip title="UCF"><Button dataUI={getDataUI()} btnType="narrow">UCF</Button></Tooltip>,
                                () => <Tooltip title="TCF"><Button dataUI={getDataUI()} btnType="narrow">TCF</Button></Tooltip>
                            ]
                        ]}
                        contentStyle={{ height: 300 }}

                    />
                </EditorContainer>

      </div>
    );
}

export default Example;
    `}
            </Highlight>

            <h2 className="mb-m">EditorContainer API</h2>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>all native div attributes</td>
                        <td className='text-center'>-</td>
                        <td className='text-center'>-</td>
                        <td>You can use all native div attributes like: "style", "className", "data", etc.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default DatepickerPage;