import React, { forwardRef } from 'react';
import Highlight from 'react-highlight';

import { Tooltip } from '@jkhy/vsg-loanvantage-design-system';

class CustomComponent extends React.Component<any> {
    render() {
        return <p {...this.props} >Tooltip Class component</p>;
    }
}

const CustomFuncComponent = forwardRef((props: any, ref: any) => {
    return <p {...props} ref={ref}>Tooltip Function component</p>;
})

const TooltipPage: React.FC = () => {

    return (
        <div>
            <h1 className="mb-s">Tooltip</h1>

            <p className="mb-l">Tooltips are small info boxes with additional information, usually displayed after hovering a given element.
                <br /> LV Design System Tooltip components use "material-ui/tooltip" library.
                Check demo and documentation <a href="https://material-ui.com/components/tooltips/" target="_blank" rel="noopener noreferrer">here</a>.
            </p>
            <p className="mb-xl">
                <strong style={{ color: 'var(--color-warning)' }}>IMPORTANT:</strong> The tooltip needs to apply DOM event listeners to its child element. If the child is a custom React element, you need to make sure that it spreads its properties to the underlying DOM element. <a href="https://material-ui.com/components/tooltips/#custom-child-element" target="_blank" rel="noopener noreferrer">See here.</a>
            </p>

            <div className="mb-xl">
                <Tooltip title="Info text here" placement="right">
                    <p className="d-inline-block mr-l mb-s">Tooltip right</p>
                </Tooltip>

                <Tooltip title="Lorem Ipsum is simply dummy text of the printing and typesetting industry.">
                    <p className="d-inline-block mr-l mb-s">Tooltip top</p>
                </Tooltip>

                <Tooltip title="Tooltip" placement="bottom">
                    <p className="d-inline-block mr-l mb-s">Tooltip bottom</p>
                </Tooltip>

                <Tooltip title="Info text here" placement="left">
                    <p className="d-inline-block mr-l mb-s">Tooltip left</p>
                </Tooltip>

                <Tooltip title={<><i>Tooltip</i> <strong>with HTML</strong></>}>
                    <p className="d-inline-block mr-l mb-s">Tooltip with HTML</p>
                </Tooltip>

                <Tooltip title="<i>Tooltip</i> <strong>with HTML</strong>">
                    <p className="d-inline-block mr-l mb-s">Tooltip with HTML as string</p>
                </Tooltip>

                <Tooltip title="Custom Class component">
                    <CustomComponent className="d-inline-block mr-l mb-s" />
                </Tooltip>

                <Tooltip title="Custom Function component">
                    <CustomFuncComponent className="d-inline-block mr-l mb-s" />
                </Tooltip>
            </div>

            <Highlight className="React">
                {
                    `
import React, { forwardRef } 'react';
import { Tooltip } from '@jkhy/vsg-loanvantage-design-system';

class CustomComponent extends React.Component<any> {
    render() {
        return <p {...this.props} >Tooltip Class component</p>;
    }
}

const CustomFuncComponent = forwardRef((props: any, ref: any) => {
    return <p {...props} ref={ref}>Tooltip Function component</p>;
})

class Example extends React.Component {

    render() {
        return (
            <Tooltip title="Info text here" placement="right">
                <p className="d-inline-block mr-l mb-s">Tooltip right</p>
            </Tooltip>

            <Tooltip title="Lorem Ipsum is simply dummy text of the printing and typesetting industry.">
                <p className="d-inline-block mr-l mb-s">Tooltip top</p>
            </Tooltip>

            <Tooltip title="Tooltip" placement="bottom">
                <p className="d-inline-block mr-l mb-s">Tooltip bottom</p>
            </Tooltip>

            <Tooltip title="Info text here" placement="left">
                <p className="d-inline-block mr-l mb-s">Tooltip left</p>
            </Tooltip>

            <Tooltip title={<><i>Tooltip</i> <strong>with HTML</strong></>}>
                <p className="d-inline-block mr-l mb-s">Tooltip with HTML</p>
            </Tooltip>

            <Tooltip title="<i>Tooltip</i> <strong>with HTML</strong>">
                <p className="d-inline-block mr-l mb-s">Tooltip with HTML as string</p>
            </Tooltip>

            <Tooltip title="Custom Class component">
                <CustomComponent className="d-inline-block mr-l mb-s" />
            </Tooltip>

            <Tooltip title="Custom Function component">
                <CustomFuncComponent className="d-inline-block mr-l mb-s" />
            </Tooltip>
        );
    }
}

export default Example;
        `}
            </Highlight>

            <h2 className="mb-m">API</h2>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>title</td>
                        <td>string | JSX.Element</td>
                        <td className="text-center">-</td>
                        <td>Tooltip title. Zero-length titles string are never displayed.</td>
                    </tr>
                    <tr>
                        <td>placement</td>
                        <td>'top' | 'bottom' | 'left' | 'right'</td>
                        <td>'top'</td>
                        <td>Tooltip placement.</td>
                    </tr>
                    <tr>
                        <td>id</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>This prop is used to help implement the accessibility logic. If you don't provide this prop. It falls back to a randomly generated id.</td>
                    </tr>
                    <tr>
                        <td>open</td>
                        <td>bool</td>
                        <td className="text-center">-</td>
                        <td>If <strong>true</strong>, the tooltip is shown.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
};

export default TooltipPage;
