import React from 'react';
import Highlight from 'react-highlight';

import { Icon } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const IconPage: React.FC = () => {
    return (
        <div>
            <h1 className="mb-s">Icon</h1>

            <p className="mb-l">This component renders <a href="https://fontawesome.com/icons?d=gallery" title="fa" target="_blank" rel="noopener noreferrer">Font awesome</a> icon
             by the passed icon name. Icons have four sizes 'XS', 'S', 'M' & 'L'.</p>

             <div>
                <Icon dataUI={getDataUI()} icon="fal fa-angle-down" size="XS" />
                <Icon dataUI={getDataUI()} icon="fal fa-angle-up" size="XS" />
                <Icon dataUI={getDataUI()} icon="fal fa-angle-right" size="XS" />
                <Icon dataUI={getDataUI()} icon="fal fa-bars" size="XS" />
                <Icon dataUI={getDataUI()} icon="fal fa-home" size="XS" />
                <Icon dataUI={getDataUI()} icon="fas fa-exclamation-circle" size="XS" style={{ color: '#E33E3C' }} />
            </div>
            <div>
                <Icon dataUI={getDataUI()} icon="fal fa-angle-down" size="S" />
                <Icon dataUI={getDataUI()} icon="fal fa-angle-up" size="S" />
                <Icon dataUI={getDataUI()} icon="fal fa-angle-right" size="S" />
                <Icon dataUI={getDataUI()} icon="fal fa-bars" size="S" />
                <Icon dataUI={getDataUI()} icon="fal fa-home" size="S" />
                <Icon dataUI={getDataUI()} icon="fas fa-exclamation-circle" size="S" style={{ color: '#E33E3C' }} />
            </div>
            <div>
                <Icon dataUI={getDataUI()} icon="fal fa-angle-down" size="M" />
                <Icon dataUI={getDataUI()} icon="fal fa-angle-up" size="M" />
                <Icon dataUI={getDataUI()} icon="fal fa-angle-right" size="M" />
                <Icon dataUI={getDataUI()} icon="fal fa-bars" size="M" />
                <Icon dataUI={getDataUI()} icon="fal fa-home" size="M" />
                <Icon dataUI={getDataUI()} icon="fas fa-exclamation-circle" size="M" style={{ color: '#E33E3C' }} />
            </div>
            <div className="mb-s">
                <Icon dataUI={getDataUI()} icon="fal fa-angle-down" size="L" />
                <Icon dataUI={getDataUI()} icon="fal fa-angle-up" size="L" />
                <Icon dataUI={getDataUI()} icon="fal fa-angle-right" size="L" />
                <Icon dataUI={getDataUI()} icon="fal fa-bars" size="L" />
                <Icon dataUI={getDataUI()} icon="fal fa-home" size="L" />
                <Icon dataUI={getDataUI()} icon="fas fa-exclamation-circle" size="L" style={{ color: '#E33E3C' }} />
            </div>

            <h2 className="mb-s">Custom Icons</h2>
            <div className="mb-l">
                <Icon dataUI={getDataUI()} icon="custom-icon-application-call-s" size="M" />
                <Icon dataUI={getDataUI()} icon="custom-icon-calculator-locked-s" size="M" />
                <Icon dataUI={getDataUI()} icon="custom-icon-calculator-unlocked-s" size="M" />
                <Icon dataUI={getDataUI()} icon="custom-icon-clock-exclamation-s" size="M" />
                <Icon dataUI={getDataUI()} icon="custom-icon-collapse-width-s" size="M" />
                <Icon dataUI={getDataUI()} icon="custom-icon-expand-alt-s" size="M" />
                <Icon dataUI={getDataUI()} icon="custom-icon-expand-width-s" size="M" />
                <Icon dataUI={getDataUI()} icon="custom-icon-file-check-s" size="M" />
                <Icon dataUI={getDataUI()} icon="custom-icon-flag-s" size="M" />
                <Icon dataUI={getDataUI()} icon="custom-icon-flag-solid-s" size="M" />
                <Icon dataUI={getDataUI()} icon="custom-icon-map-pin-s" size="M" />
                <Icon dataUI={getDataUI()} icon="custom-icon-map-pinned-s" size="M" />
                <Icon dataUI={getDataUI()} icon="custom-icon-stamp-locked-s" size="M" />
                <Icon dataUI={getDataUI()} icon="custom-icon-stamp-unlocked-s" size="M" />
                <Icon dataUI={getDataUI()} icon="custom-icon-unpin-s" size="M" />
                <Icon dataUI={getDataUI()} icon="custom-icon-wam-s" size="M" />
                <Icon dataUI={getDataUI()} icon="custom-icon-partial-match-s" size="M" />
                <Icon dataUI={getDataUI()} icon="custom-icon-estimate-s" size="M" />
                <Icon dataUI={getDataUI()} icon="custom-icon-expand-height-s" size="M" />
                <Icon dataUI={getDataUI()} icon="custom-icon-user-core-active-s" size="M" />
                <Icon dataUI={getDataUI()} icon="custom-icon-users-core-active-s" size="M" />
            </div>

            <h3 className="mb-s">Steps for generating custom icons font:</h3>
            <div>
                <p>1. On your PC create folder to hold all custom SVG icons. The name of the SVG file will be the second part of the custom icon name. File name should not contain "custom-icon-" in it's name.</p>
                <p>2. Go to <a target="_blank" rel="noopener noreferrer" href="https://fontello.com/">https://fontello.com/</a></p>
                <p>3. Click on settings (<Icon dataUI={getDataUI()} icon="fas fa-wrench" size='S' />). From dropdown menu edit "CSS prefix" to "custom-icon-".</p>
                <p>4. Set font name to "loanvantage_v#"(input after settings btn).</p>
                <p>5. Select all custom SVG icons from the folder, created in step 1, and drag and drop them in the field Custom Icons in fontello.</p>
                <p>5.1. <strong>Add new Icon to existing </strong>- add it in folder created in step 1 and drag and drop all icons in fontello.</p>
                <p>5.2. <strong>Remove Icon </strong>- remove the icon from the folder created in step 1 and drag and drop all icons in fontello.</p>
                <p>6. Select all custom icons in fontello by clicking on them.</p>
                <p>7. Download webfont.</p>
            </div>

            <Highlight className="React">
                {
                    `
import React from 'react';
import { Icon } from '@jkhy/vsg-loanvantage-design-system';

const Example: React.FC = (props) => {
    return (
      <div>
        <Icon dataUI={getDataUI()} icon="fal fa-angle-down" size="XS"/>
        <Icon dataUI={getDataUI()} icon="fal fa-angle-up" size="XS" />
        <Icon dataUI={getDataUI()} icon="fal fa-angle-right" size="XS" />
        <Icon dataUI={getDataUI()} icon="fal fa-bars" size="XS" />
        <Icon dataUI={getDataUI()} icon="fal fa-home" size="XS" />
        <Icon dataUI={getDataUI()} icon="fas fa-exclamation-circle" size="XS" style={{color:'#E33E3C'}}/>

        <Icon dataUI={getDataUI()} icon="fal fa-angle-down" size="S"/>
        <Icon dataUI={getDataUI()} icon="fal fa-angle-up" size="S"/>
        <Icon dataUI={getDataUI()} icon="fal fa-angle-right" size="S" />
        <Icon dataUI={getDataUI()} icon="fal fa-bars" size="S"/>
        <Icon dataUI={getDataUI()} icon="fal fa-home" size="S" />
        <Icon dataUI={getDataUI()} icon="fas fa-exclamation-circle" size="S" style={{color:'#E33E3C'}}/>
        
        <Icon dataUI={getDataUI()} icon="fal fa-angle-down" size="M"/>
        <Icon dataUI={getDataUI()} icon="fal fa-angle-up" size="M" />
        <Icon dataUI={getDataUI()} icon="fal fa-angle-right" size="M" />
        <Icon dataUI={getDataUI()} icon="fal fa-bars" size="M" />
        <Icon dataUI={getDataUI()} icon="fal fa-home" size="M" />
        <Icon dataUI={getDataUI()} icon="fas fa-exclamation-circle" size="M" style={{color:'#E33E3C'}}/>
        
        <Icon dataUI={getDataUI()} icon="fal fa-angle-down" size="L"/>
        <Icon dataUI={getDataUI()} icon="fal fa-angle-up" size="L" />
        <Icon dataUI={getDataUI()} icon="fal fa-angle-right" size="L" />
        <Icon dataUI={getDataUI()} icon="fal fa-bars" size="L" />
        <Icon dataUI={getDataUI()} icon="fal fa-home" size="L" />
        <Icon dataUI={getDataUI()} icon="fas fa-exclamation-circle" size="L" style={{color:'#E33E3C'}}/>

        // Custom Icons
        <Icon dataUI={getDataUI()} icon="custom-icon-application-call-s" size="M" />
        <Icon dataUI={getDataUI()} icon="custom-icon-calculator-locked-s" size="M" />
        <Icon dataUI={getDataUI()} icon="custom-icon-calculator-unlocked-s" size="M" />
        <Icon dataUI={getDataUI()} icon="custom-icon-clock-exclamation-s" size="M" />
        <Icon dataUI={getDataUI()} icon="custom-icon-collapse-width-s" size="M" />
        <Icon dataUI={getDataUI()} icon="custom-icon-expand-alt-s" size="M" />
        <Icon dataUI={getDataUI()} icon="custom-icon-expand-width-s" size="M" />
        <Icon dataUI={getDataUI()} icon="custom-icon-file-check-s" size="M" />
        <Icon dataUI={getDataUI()} icon="custom-icon-flag-s" size="M" />
        <Icon dataUI={getDataUI()} icon="custom-icon-flag-solid-s" size="M" />
        <Icon dataUI={getDataUI()} icon="custom-icon-map-pin-s" size="M" />
        <Icon dataUI={getDataUI()} icon="custom-icon-map-pinned-s" size="M" />
        <Icon dataUI={getDataUI()} icon="custom-icon-stamp-locked-s" size="M" />
        <Icon dataUI={getDataUI()} icon="custom-icon-stamp-unlocked-s" size="M" />
        <Icon dataUI={getDataUI()} icon="custom-icon-unpin-s" size="M" />
        <Icon dataUI={getDataUI()} icon="custom-icon-wam-s" size="M" />
        <Icon dataUI={getDataUI()} icon="custom-icon-partial-match-s" size="M" />
        <Icon dataUI={getDataUI()} icon="custom-icon-estimate-s" size="M" />
        <Icon dataUI={getDataUI()} icon="custom-icon-expand-height-s" size="M" />
        <Icon dataUI={getDataUI()} icon="custom-icon-user-core-active-s" size="M" />
        <Icon dataUI={getDataUI()} icon="custom-icon-users-core-active-s" size="M" />
      </div>
    );
}

export default Example;
    `}
            </Highlight>

            <h2 className="mb-m">API</h2>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>icon</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Sets the icon. Check from font awesome doc.</td>
                    </tr>
                    <tr>
                        <td>size</td>
                        <td>'XS', 'S', 'M' or 'L'</td>
                        <td className="text-center">-</td>
                        <td>Sets the icon size.</td>
                    </tr>
                    <tr>
                        <td>className</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Sets the icon wrapper class.</td>
                    </tr>
                    <tr>
                        <td>style</td>
                        <td>React.CSSProperties</td>
                        <td className="text-center">-</td>
                        <td>Sets the icon wrapper style.</td>
                    </tr>
                </tbody>
            </table>

        </div>
    )
}

export default IconPage;
