/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
    ReactAux, Main, MainContainer, MainContainerScrollable,
    Row, Col, CardList, CardListItem, Input, Button, ButtonGroup
} from '@jkhy/vsg-loanvantage-design-system';

import DemoHeaderAdmin from './DemoHeaderAdmin';
import { getDataUI } from '../helpers/helpers';

const HomepageAdmin = () => {
    const [showAll, setShowAll] = useState(false);

    return (
        <ReactAux>
            <DemoHeaderAdmin
                simple
            />
            <Main dataUI={getDataUI()}>
                <MainContainer dataUI={getDataUI()}>
                    <MainContainerScrollable>
                        <ButtonGroup dataUI={getDataUI()} className='d-flex justify-content-end mr-m'>
                            <Button dataUI={getDataUI()} btnType={!showAll ? 'primary' : 'secondary'} onClick={() => setShowAll(false)}>My Admin</Button>
                            <Button dataUI={getDataUI()} btnType={showAll ? 'primary' : 'secondary'} onClick={() => setShowAll(true)}>All</Button>
                        </ButtonGroup>

                        <div className='lv-custom-admin-container'>

                            <div className='d-flex justify-content-center mb-xxl'>
                                <Input dataUI={getDataUI()} placeholder='Search' name="input-icon" className="mb-s width-l" onChange={() => { }} icon='fal fa-search' />
                            </div>

                            <Row>
                                <Col>
                                    {/* First demo CardList show that: 
                                     1. If you pass to CardListItem as child text only. Set onClick to CardListItem. 
                                     2. If you need some "span", "a" or "router link" as child to CardListItem, set onClick to your element.
                                    */}
                                    <CardList dataUI={getDataUI()} colSpacing cardTitle='Users Access' icon="fal fa-user-shield" className="mb-xxl">
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click li')}>Authentication</CardListItem>
                                        <CardListItem dataUI={getDataUI()} ><span onClick={() => console.log('click span')}>Roles</span></CardListItem>
                                        <CardListItem dataUI={getDataUI()} ><a onClick={(e) => { e.preventDefault(); console.log('click a') }} href='/' title="test">Teams</a></CardListItem>
                                        <CardListItem dataUI={getDataUI()} ><Link to='/'>Users</Link></CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click li')} >User Devices (Devices)</CardListItem>
                                    </CardList>

                                    <CardList dataUI={getDataUI()} colSpacing cardTitle='FIle Management' icon="fal fa-folder-tree" active>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Process Definitions</CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Loan Wizard</CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Report Definition</CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>HTML Narrative</CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Interest Rate Matrix</CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Score Cards</CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Declarations (Questions)</CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Credit Decision Ratios</CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Fee Schedules</CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Rate Exceptions</CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Loan Review Automated Setup</CardListItem>
                                    </CardList>
                                </Col>

                                <Col>
                                    <CardList dataUI={getDataUI()} colSpacing cardTitle='System Settings' icon="fal fa-cogs" >
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>General Settings</CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Schedules</CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Industry Groups</CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Historical Periods</CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Pick Lists</CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Product Groups</CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Products</CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Sales Goals</CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Email Templates</CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Merge Document Templates </CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Risk Rating Matrix</CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Dual Risk Rating Matrix</CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Spreading</CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Mini Financial Designer</CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Work Queues</CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Field Settings</CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Default Field Templates</CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Custom Hyperlinks</CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Widgets</CardListItem>
                                    </CardList>
                                </Col>

                                <Col>
                                    <CardList dataUI={getDataUI()} colSpacing cardTitle='Tracking Items' icon="fal fa-map-marked-alt" className="mb-xxl">
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Tracking Settings </CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Prerequisite Conditions</CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Reporting Requirements</CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Historical Periods</CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Covenants</CardListItem>
                                    </CardList>

                                    <CardList dataUI={getDataUI()} colSpacing cardTitle='Work Flow' icon="fal fa-tasks">
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Business Rules </CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Task Definitions</CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Activity Definitions</CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Task/Acivity Routing</CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Lead Routes</CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Approval Matrix</CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Policy Exception Matrix</CardListItem>
                                    </CardList>
                                </Col>

                                <Col>
                                    <CardList dataUI={getDataUI()} colSpacing cardTitle='Integrations' icon="fal fa-tools">
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Interface Transformations</CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>CRA Wiz Setup</CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Credit Bureau</CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Customer Verification</CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Doc Prep</CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>E-Signature</CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Flood Setup</CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Identity Verification</CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Imaging</CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Loan Calculation Setup</CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Loan Origination</CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>NAICS Servics</CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Policy Exception Import</CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Short Term Maturity Extensions</CardListItem>
                                        <CardListItem dataUI={getDataUI()} hidden={!showAll} muted className='cursor-not-allowed'>Small Business Administration Setup</CardListItem>
                                        <CardListItem dataUI={getDataUI()} hidden={!showAll} muted className='cursor-not-allowed'>Tax Return Reader Setup</CardListItem>
                                        <CardListItem dataUI={getDataUI()} hidden={!showAll} muted className='cursor-not-allowed'>Tax Verufication</CardListItem>
                                        <CardListItem dataUI={getDataUI()} hidden={!showAll} muted className='cursor-not-allowed'>Vehicle Valuation Setup</CardListItem>
                                        <CardListItem dataUI={getDataUI()} hidden={!showAll} muted className='cursor-not-allowed'>Tax Verufication</CardListItem>
                                    </CardList>
                                </Col>

                                <Col>
                                    <CardList dataUI={getDataUI()} colSpacing cardTitle='Organization' icon="fal fa-sitemap" className="mb-xxl">
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Bank</CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Bank Holidays</CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Markets</CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Branches</CardListItem>
                                    </CardList>

                                    <CardList dataUI={getDataUI()} colSpacing cardTitle='Add-On Applications' icon="fal fa-sitemap" className="mb-xxl">
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Design Teplates</CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Online Application Business Rules</CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Online Application Models</CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Online Application Disclosures</CardListItem>
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>Online Portal</CardListItem>
                                    </CardList>

                                    <CardList dataUI={getDataUI()} colSpacing cardTitle='Logs' icon="fal fa-clipboard-list" >
                                        <CardListItem dataUI={getDataUI()} onClick={() => console.log('click item')}>All logs</CardListItem>
                                    </CardList>
                                </Col>
                            </Row>
                        </div>
                    </MainContainerScrollable>
                </MainContainer>
            </Main>
        </ReactAux>
    );
}

export default HomepageAdmin;