
import React, { useState, useEffect, useRef } from "react";
import {
    Button, Label, SimpleTableContainer,
    Toolbar, Checkbox, NumericInputSpinner, Badge,
    Modal, ModalScrollableContainer, InfoBox, Radio,
    Icon,
    Tooltip, Input
} from "@jkhy/vsg-loanvantage-design-system";
import { getDataUI } from "../helpers/helpers";


const EditableCell = (props: { value?: string, align?: 'left' | 'right' }) => {
    const inputRef = useRef() as any;
    const { value, align } = props;

    const [cellValue, setCellValue] = useState(value || '');
    const [savedValue, setSavedValue] = useState(cellValue);
    const [isEditMode, setEditMode] = useState(false);

    useEffect(() => {
        if (isEditMode && inputRef) {
            inputRef.current.focus();
        }
    }, [isEditMode, inputRef]);

    const onInputChange = (e: any) => {
        setCellValue(e.target.value)
    }

    const cellClasses = [
        align === 'right' ? 'text-right' : '',
        !value ? 'simple-editable-cell' : ''
    ];

    const onClickEditBtn = () => {
        if (!isEditMode) setEditMode(true);
    }
    const onClickSaveBtn = () => {
        setSavedValue(cellValue);
        setEditMode(false)
    }

    const onClickCancelBtn = () => {
        setCellValue(savedValue);
        setEditMode(false)
    }
    return (
        <td
            className={cellClasses.join(' ').trim()}
        >
            <div className="d-flex">
                {isEditMode ?
                    <>
                        <Button dataUI={getDataUI()} onClick={onClickSaveBtn} btnType="icon" icon="fal fa-save" className="mr-s" />
                        <Button dataUI={getDataUI()} onClick={onClickCancelBtn} btnType="icon" icon="fal fa-times-circle" className="mr-s" />
                        <Input
                            dataUI={getDataUI()}
                            className="width-full"
                            ref={inputRef}
                            onChange={onInputChange}
                            value={cellValue}

                        />
                    </>
                    : <>
                        <Button dataUI={getDataUI()} onClick={onClickEditBtn} btnType="icon" icon="fal fa-pen" className="mr-s" />
                        <span className="width-full">{cellValue}</span>
                    </>
                }
            </div>
        </td>
    )
}


const DemoDocPrep = () => {
    const [showModal, setShowModal] = useState(false);
    const [showModalSE, setShowModalSE] = useState(false);
    const [checkedLP, setCheckedLP] = useState(false);
    const [checkedP, setCheckedP] = useState(false);
    const [checkedM, setCheckedM] = useState(false);

    const [spinnerLP, setSpinnerLP] = useState(0);
    const [spinnerP, setSpinnerP] = useState(0);
    const [spinnerM, setSpinnerM] = useState(0);


    const onCheck = (checked: boolean, callBackCheck: any, spinnerValue: number, callBackSpinner: any) => {
        if (!checked && spinnerValue !== 0) {
            callBackSpinner(0);
        }

        if (checked && spinnerValue === 0) {
            callBackSpinner(1);
        }

        callBackCheck(checked);
    }

    const isChecked = () => {
        return ![checkedLP, checkedM, checkedP].includes(true)
    }
    return (
        <>
            <Toolbar
                dataUI={getDataUI()}
                className='mb-m'
                leftSide={
                    <>
                        <div className='d-flex'>
                            <Button dataUI={getDataUI()} className="mr-s">Start New Session</Button>
                            <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-bell-slash" btnType="secondary">Resume Session</Button>
                            <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-plus" btnType="secondary">Add Merge Doc</Button>
                            <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-sync" btnType="secondary">Refresh</Button>
                            <Button dataUI={getDataUI()} className='mr-l' icon="fal fa-file-code" btnType="secondary">Export</Button>
                        </div>
                        <div className='d-flex'>
                            <Label dataUI={getDataUI()} className='text-muted mr-s' >Select Documents To</Label>
                            <Button dataUI={getDataUI()} disabled={isChecked()} className="mr-s" icon="fal fa-print" btnType="secondary">Print</Button>
                            <Button dataUI={getDataUI()} disabled={isChecked()} icon="fal fa-envelope" btnType="secondary">Email</Button>
                        </div>
                    </>
                }
            />

            <SimpleTableContainer dataUI={getDataUI()}>
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            <th></th>
                            <th>Copies</th>
                            <th>Document Name</th>
                            <th>Status</th>
                            <th></th>
                            <th>Doc Version</th>
                            <th>Date Created</th>
                            <th>Date Printed</th>
                            <th>Facility Status</th>
                            <th>User Name</th>
                            <th>Package Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><Button dataUI={getDataUI()} disabled btnType="icon" icon="fal fa-trash" /></td>
                            <td>
                                <Checkbox
                                    dataUI={getDataUI()}
                                    checked={checkedLP}
                                    onChange={(e) => {
                                        onCheck(e.target.checked, setCheckedLP, spinnerLP, setSpinnerLP);
                                    }}

                                />
                            </td>
                            <td>
                                <NumericInputSpinner
                                    dataUI={getDataUI()}
                                    min={0}
                                    max={5}
                                    value={spinnerLP}
                                    onChange={(currentValue: any) => {
                                        setSpinnerLP(currentValue);
                                    }}
                                />
                            </td>
                            <td>Loan Package</td>
                            <td><Badge dataUI={getDataUI()} fullWidth type="success" icon="fal fa-check" className="mr-s">Success</Badge></td>
                            <td><Button dataUI={getDataUI()} btnType="icon" icon="fal fa-file-signature" onClick={() => setShowModal(true)} /></td>
                            <td>Final</td>
                            <td className="text-right">10/22/2021</td>
                            <td className="text-right">10/22/2021</td>
                            <td>Approve</td>
                            <td>Randall</td>
                            <td><Badge dataUI={getDataUI()} type="warning" fullWidth icon="fal fa-file-export" className="mr-s">Incomplete-Sent</Badge></td>
                        </tr>
                        <tr>
                            <td><Button dataUI={getDataUI()} disabled btnType="icon" icon="fal fa-trash" /></td>
                            <td>
                                <Checkbox
                                    dataUI={getDataUI()}
                                    checked={checkedP}
                                    onChange={(e) => {
                                        onCheck(e.target.checked, setCheckedP, spinnerP, setSpinnerP);
                                    }}
                                />
                            </td>
                            <td>
                                <NumericInputSpinner
                                    dataUI={getDataUI()}
                                    min={0}
                                    max={5}
                                    value={spinnerP}
                                    onChange={(currentValue: any) => {
                                        setSpinnerP(currentValue);
                                    }}
                                />
                            </td>
                            <td>Package</td>
                            <td><Badge dataUI={getDataUI()} fullWidth type="info" icon="fal fa-clock" className="mr-s">Pending</Badge></td>
                            <td><Button dataUI={getDataUI()} btnType="icon" disabled icon="fal fa-file-signature" onClick={() => setShowModal(true)} /></td>
                            <td>Preview</td>
                            <td className="text-right">10/22/2021</td>
                            <td className="text-right"></td>
                            <td></td>
                            <td></td>
                            <td><Badge dataUI={getDataUI()} type="info" fullWidth icon="fal fa-file" className="mr-s">Incomplete-Sent</Badge></td>
                        </tr>
                        <tr>
                            <td><Button dataUI={getDataUI()} btnType="icon" icon="fal fa-trash" /></td>
                            <td>
                                <Checkbox
                                    dataUI={getDataUI()}
                                    checked={checkedM}
                                    onChange={(e) => {
                                        onCheck(e.target.checked, setCheckedM, spinnerM, setSpinnerM);
                                    }}
                                />
                            </td>
                            <td>
                                <NumericInputSpinner
                                    dataUI={getDataUI()}
                                    min={0}
                                    max={5}
                                    value={spinnerM}
                                    onChange={(currentValue: any) => {
                                        setSpinnerM(currentValue);
                                    }}
                                />
                            </td>
                            <td>Merge Document</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </SimpleTableContainer>


            <Modal dataUI={getDataUI()} title="Send for Esign" isVisible={showModal} size="L" onClose={() => setShowModal(false)}>
                <Toolbar
                    dataUI={getDataUI()}
                    className="mb-m"
                    leftSide={
                        <>
                            <Button dataUI={getDataUI()} icon="fal fa-share-square" className="mr-s" onClick={() => setShowModalSE(true)}>Send</Button>
                            <Button dataUI={getDataUI()} btnType="secondary" icon="fal fa-times-circle">Cancel</Button>
                        </>
                    }
                />
                <ModalScrollableContainer>
                    <InfoBox dataUI={getDataUI()} icon="fal fa-check" title="Package Status: Not Sent" className="mb-m">
                        The documents listed below will be sent to the following parties for electronic signature. Confirm that all needed documents are included and verify that the email addresses are accurate
                    </InfoBox>
                    <div className="section-container">
                        <Toolbar
                            dataUI={getDataUI()}
                            className="mb-s"
                            leftSide={<>
                                <Radio dataUI={getDataUI()} className="mr-s" name="esing-radio">Signers can sign in any order</Radio>
                                <Radio dataUI={getDataUI()} name="esing-radio">Signers must sign in order</Radio>

                            </>}
                        />

                        <SimpleTableContainer dataUI={getDataUI()}>
                            <table>
                                <thead>
                                    <tr>
                                        <th style={{ width: '1px' }}>
                                            <Tooltip title="Signing Order">
                                                <Icon dataUI={getDataUI()} size='M' icon="fal fa-list-ol" />
                                            </Tooltip>
                                        </th>
                                        <th style={{ width: '50%' }}>Name</th>
                                        <th style={{ width: '50%' }}>E-mail</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="text-right">1</td>
                                        <td>Jaqueline J Testcase JR.</td>
                                        <EditableCell value="azelyamova@profitstars.com" />
                                    </tr>
                                    <tr>
                                        <td className="text-right">2</td>
                                        <td>Sally Testcase</td>
                                        <EditableCell value="sally-test@jhacorp.com" />
                                    </tr>
                                </tbody>

                            </table>
                        </SimpleTableContainer>

                    </div>
                </ModalScrollableContainer>
            </Modal>

            <Modal dataUI={getDataUI()} title="Send for Esign" isVisible={showModalSE} size="L" onClose={() => setShowModalSE(false)}>
                <ModalScrollableContainer>
                    <InfoBox dataUI={getDataUI()} type="warning" icon="fal fa-file-export" title="Package Status: Not Sent" className="mb-m">
                        The documents listed below will be sent to the following parties for electronic signature. Confirm that all needed documents are included and verify that the email addresses are accurate
                    </InfoBox>
                    <SimpleTableContainer dataUI={getDataUI()}>
                        <table>
                            <thead>
                                <tr>
                                    <th style={{ width: '1px' }}>
                                        <Tooltip title="Signing Order">
                                            <Icon dataUI={getDataUI()} size='M' icon="fal fa-list-ol" />
                                        </Tooltip>
                                    </th>
                                    <th>Name</th>
                                    <th>E-mail</th>
                                    <th>Reciepent Status</th>
                                    <th className="text-right">Date Sent</th>
                                    <th className="text-right">Date Signed</th>
                                    <th>Signing Method</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="text-right">1</td>
                                    <td>Jaqueline J Testcase JR.</td>
                                    <td>blhomme@jhacorp.com</td>
                                    <td><Badge dataUI={getDataUI()} type="info" icon="fal fa-times" className="mr-s">Unsigned</Badge></td>
                                    <td className="text-right">09/06/2021 12:17 PM	</td>
                                    <td className="text-right">12/07/2021 12:17 PM</td>
                                    <td>Email</td>
                                </tr>
                            </tbody>

                        </table>
                    </SimpleTableContainer>

                </ModalScrollableContainer>
            </Modal>
        </>
    )
}


export default DemoDocPrep