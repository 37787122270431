import React from 'react';
import { createPortal } from 'react-dom';

import {
    MainContainerHeader, ReactAux, ActionContainer, ActionContainerHeader, ActionContainerCrumbs,
    Button, Toolbar, MainContainerScrollable, SimpleTableContainer, Checkbox, Icon, ContextMenu,
    Tooltip,
} from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../helpers/helpers';

const engagementsInitial: any = [
    { check: false, type: 'sticky-note', reason: '30 Days Retail Sales Manager', category: 'blue', flag: 'today', contactDate: '07/20/20', followUp: '', banker: 'DanielVsgBg', notes: '' },
    { check: false, type: 'calendar-check', reason: '', category: '', flag: '', contactDate: '07/16/20', followUp: '', banker: 'Joro GG', notes: '' },
    { check: true, type: 'phone', reason: 'Write some details', category: '', flag: 'today', contactDate: '07/20/20', followUp: '07/20/20', banker: 'Yana', notes: '' },
    { check: false, type: 'calendar-check', reason: '', category: '', flag: 'thisWeek', contactDate: '', followUp: '', banker: 'Joro GG', notes: '' },
    { check: false, type: 'sticky-note', reason: '30 Days Retail Sales Manager', category: 'blue', flag: 'tomorrow', contactDate: '07/20/20', followUp: '', banker: 'DanielVsgBg', notes: '' },
    { check: false, type: 'calendar-check', reason: '', category: '', flag: 'complete', contactDate: '07/16/20', followUp: '', banker: 'Joro GG', notes: '' },
    { check: true, type: 'phone', reason: 'Write some details', category: 'yellow', flag: 'today', contactDate: '07/20/20', followUp: '07/20/20', banker: 'Yana', notes: '' },
    { check: false, type: 'calendar-check', reason: '', category: '', flag: '', contactDate: '07/16/20', followUp: '', banker: 'Joro GG', notes: '' },
    { check: false, type: 'sticky-note', reason: '30 Days Retail Sales Manager', category: 'blue', flag: 'today', contactDate: '07/20/20', followUp: '', banker: 'DanielVsgBg', notes: '' },
    { check: false, type: 'calendar-check', reason: '', category: '', flag: 'complete', contactDate: '07/16/20', followUp: '', banker: 'Joro GG', notes: '' },
    { check: true, type: 'phone', reason: 'Write some details', category: 'yellow', flag: 'today', contactDate: '07/20/20', followUp: '07/20/20', banker: 'Yana', notes: '' },
    { check: false, type: 'calendar-check', reason: '', category: 'purple', flag: 'thisWeek', contactDate: '', followUp: '', banker: 'Joro GG', notes: '' },
];


class DemoEngagements extends React.Component {
    private contextMenuRef = React.createRef<HTMLDivElement>();

    state = {
        engagements: engagementsInitial,
        clientX: 0,
        clientY: 0,
        showContextMenu: false,
        currentItemIndex: -1,
        isContextMenuFlag: true,
        prevIsOpen: false,
    }

    componentDidMount = () => {
        document.addEventListener('click', this.handleClick, false);
    };

    componentWillUnmount = () => {
        document.removeEventListener('click', this.handleClick, false);
    };

    handleClick = (e: any) => {
        const { showContextMenu, prevIsOpen } = this.state;

        if (showContextMenu && prevIsOpen) {
            this.onCloseContextMenu();
        } else if (showContextMenu) {
            this.setState({ prevIsOpen: true });
        }
    };

    renderFlag(flag: any) {
        switch (flag) {
            case 'today':
                return <Tooltip title="Today">
                    <Icon dataUI={getDataUI()} className="text-danger" icon="fas fa-flag" size="S" />
                </Tooltip>;
            case 'tomorrow':
                return <Tooltip title="Tomorrow">
                    <Icon dataUI={getDataUI()} className="text-danger" icon="fas fa-flag" size="S" />
                </Tooltip>;
            case 'thisWeek':
                return <Tooltip title="This Week">
                    <Icon dataUI={getDataUI()} className="text-warning" icon="fas fa-flag" size="S" />
                </Tooltip>;
            case 'nextWeek':
                return <Tooltip title="Next Week">
                    <Icon dataUI={getDataUI()} className="text-warning" icon="fas fa-flag" size="S" />
                </Tooltip>;
            case 'noDate':
                return <Tooltip title="No Date">
                    <Icon dataUI={getDataUI()} icon="fal fa-flag" size="S" />
                </Tooltip>;
            case 'complete':
                return <Tooltip title="Complete">
                    <Icon dataUI={getDataUI()} className="text-success" icon="far fa-check" size="S" />
                </Tooltip>;
            default:
                return null;
        }
    }

    renderCategory(category: any) {
        switch (category) {
            case 'red':
                return <Tooltip title="Red Category">
                    <Icon dataUI={getDataUI()} className="text-red" icon="fas fa-circle" size="S" />
                </Tooltip>;
            case 'blue':
                return <Tooltip title="Blue Category">
                    <Icon dataUI={getDataUI()} className="text-blue" icon="fas fa-circle" size="S" />
                </Tooltip>;
            case 'green':
                return <Tooltip title="Green Category">
                    <Icon dataUI={getDataUI()} className="text-green" icon="fas fa-circle" size="S" />
                </Tooltip>;
            case 'orange':
                return <Tooltip title="Orange Category">
                    <Icon dataUI={getDataUI()} className="text-orange" icon="fas fa-circle" size="S" />
                </Tooltip>;
            case 'purple':
                return <Tooltip title="Purple Category">
                    <Icon dataUI={getDataUI()} className="text-pink" icon="fas fa-circle" size="S" />
                </Tooltip>;
            case 'yellow':
                return <Tooltip title="Yellow Category">
                    <Icon dataUI={getDataUI()} className="text-yellow" icon="fas fa-circle" size="S" />
                </Tooltip>;
            default:
                return null;
        }
    }

    toggleCheckAll() {
        const { engagements } = this.state;

        if (this.isAllChecked()) {
            engagements.forEach((c: any, i: any) => {
                engagements[i].check = false;
            });
        } else {
            engagements.forEach((c: any, i: any) => {
                engagements[i].check = true;
            });
        }

        this.setState({ engagements });
    }

    isAllChecked() {
        const { engagements } = this.state;

        for (let i = 0; i < engagements.length; i++) {
            if (engagements[i].check === false) return false;
        }

        return true;
    }

    onClickCheck(index: any) {
        const { engagements } = this.state;

        engagements[index].check = !engagements[index].check;
        this.forceUpdate();
    }

    changeCategory(category: any) {
        const { engagements, currentItemIndex } = this.state;

        if (category !== 'clear') {
            engagements[currentItemIndex].category = category;
        } else {
            engagements[currentItemIndex].category = '';
        }

        this.forceUpdate();
        this.onCloseContextMenu();
    }

    changeFlag(flag: any) {
        const { engagements, currentItemIndex } = this.state;

        if (flag !== 'clear') {
            engagements[currentItemIndex].flag = flag;
        } else {
            engagements[currentItemIndex].flag = '';
        }

        this.forceUpdate();
        this.onCloseContextMenu();
    }

    onShowContextMenu(event: any, index: any) {

        const elementHeight = this.contextMenuRef.current?.clientHeight;
        let topCur = event.clientY;
        const leftCur = event.clientX + 5;

        if (elementHeight && event.clientY + elementHeight >= window.innerHeight) {
            topCur = window.innerHeight - elementHeight - 26;
        }

        this.setState({
            showContextMenu: true,
            currentItemIndex: index,
        }, () => {
            this.setState({
                clientX: leftCur,
                clientY: topCur,
            })
        });
    }

    onCloseContextMenu = () => {
        this.setState({
            showContextMenu: false,
            currentItemIndex: -1,
            prevIsOpen: false,
        });
    };

    render() {
        const { engagements, showContextMenu, clientX, clientY, isContextMenuFlag, currentItemIndex } = this.state

        const currentItemCategory = engagements[currentItemIndex]?.category;

        const contextMenuCategoryItems = [
            { title: 'Red Category', icon: { name: 'fas fa-circle', color: 'text-red' }, onClick: () => this.changeCategory('red'), isActive: currentItemCategory === 'red' },
            { title: 'Blue Category', icon: { name: 'fas fa-circle', color: 'text-blue' }, onClick: () => this.changeCategory('blue'), isActive: currentItemCategory === 'blue' },
            { title: 'Green Category', icon: { name: 'fas fa-circle', color: 'text-green' }, onClick: () => this.changeCategory('green'), isActive: currentItemCategory === 'green' },
            { title: 'Orange Category', icon: { name: 'fas fa-circle', color: 'text-orange' }, onClick: () => this.changeCategory('orange'), isActive: currentItemCategory === 'orange' },
            { title: 'Purple Category', icon: { name: 'fas fa-circle', color: 'text-pink' }, onClick: () => this.changeCategory('purple'), isActive: currentItemCategory === 'purple' },
            { title: 'Yellow Category', icon: { name: 'fas fa-circle', color: 'text-yellow' }, onClick: () => this.changeCategory('yellow'), isActive: currentItemCategory === 'yellow' },
            { title: 'Clear Category', icon: { name: 'far fa-trash' }, onClick: () => this.changeCategory('clear') },
        ];

        const currentItemFlag = engagements[currentItemIndex]?.flag;

        const contextMenuFlagItems = [
            { title: 'Today', icon: { name: 'fas fa-flag', color: 'text-danger' }, onClick: () => this.changeFlag('today'), isActive: currentItemFlag === 'today' },
            { title: 'Tomorrow', icon: { name: 'fas fa-flag', color: 'text-danger' }, onClick: () => this.changeFlag('tomorrow'), isActive: currentItemFlag === 'tomorrow' },
            { title: 'This Week', icon: { name: 'fas fa-flag', color: 'text-warning' }, onClick: () => this.changeFlag('thisWeek'), isActive: currentItemFlag === 'thisWeek' },
            { title: 'Next Week', icon: { name: 'fas fa-flag', color: 'text-warning' }, onClick: () => this.changeFlag('nextWeek'), isActive: currentItemFlag === 'nextWeek' },
            { title: 'No Date', icon: { name: 'fal fa-flag' }, onClick: () => this.changeFlag('noDate'), isActive: currentItemFlag === 'noDate' },
            { title: 'Mark Complete', icon: { name: 'far fa-check', color: 'text-success' }, onClick: () => this.changeFlag('complete'), isActive: currentItemFlag === 'complete' },
            { title: 'Clear flag', icon: { name: 'far fa-trash' }, onClick: () => this.changeFlag('clear') },
        ];

        return (
            <ReactAux>
                <MainContainerHeader dataUI={getDataUI()}>
                    <ActionContainer dataUI={getDataUI()}>
                        <ActionContainerHeader dataUI={getDataUI()}>
                            <ActionContainerCrumbs dataUI={getDataUI()} crumbs={['Engagements']} />
                        </ActionContainerHeader>
                    </ActionContainer>
                </MainContainerHeader>

                <Toolbar
                    dataUI={getDataUI()}
                    className="mt-m mb-s"
                    leftSide={
                        <>
                            <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-envelope">Email</Button>
                            <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-mail-bulk" btnType="secondary">Letter</Button>
                            <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-phone" btnType="secondary">Phone</Button>
                            <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-calendar-check" btnType="secondary">Meeting</Button>
                            <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-sticky-note" btnType="secondary">Note</Button>
                            <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-trash" btnType="secondary">Delete</Button>
                            <Button dataUI={getDataUI()} icon="fal fa-print" btnType="secondary">Print</Button>
                        </>
                    }
                />

                <MainContainerScrollable>
                    <SimpleTableContainer dataUI={getDataUI()}>
                        <table>
                            <thead>
                                <tr>
                                    <th className="text-center"><Checkbox dataUI={getDataUI()} checked={this.isAllChecked()} onClick={() => this.toggleCheckAll()} onChange={(e: any) => { }} /></th>
                                    <th className="text-center">Type</th>
                                    <th>Reason</th>
                                    <th className="text-center"><Icon dataUI={getDataUI()} icon="fal fa-palette" size="S" /></th>
                                    <th className="text-center"><Icon dataUI={getDataUI()} icon="fal fa-flag" size="S" /></th>
                                    <th className="text-right">Contact Date</th>
                                    <th className="text-right">Follow Up</th>
                                    <th>Banker</th>
                                    <th>Notes</th>
                                </tr>
                            </thead>
                            <tbody>
                                {engagements.map((engagement: any, i: any) => {
                                    const index = i;

                                    return (<tr key={index}>
                                        <td className="text-center"><Checkbox dataUI={getDataUI()} checked={engagement.check} onClick={() => this.onClickCheck(i)} onChange={(e: any) => { }} /></td>
                                        <td className="text-center">
                                            <Tooltip title="Some text">
                                                <Button dataUI={getDataUI()} btnType="icon" icon={`fal fa-${engagement.type}`} />
                                            </Tooltip>
                                        </td>
                                        <td>{engagement.reason}</td>
                                        <td className="text-center cursor-pointer" onClick={(e) => { this.setState({ isContextMenuFlag: false }); this.onShowContextMenu(e, index); }}>{this.renderCategory(engagement.category)}</td>
                                        <td className="text-center cursor-pointer" onClick={(e) => { this.setState({ isContextMenuFlag: true }); this.onShowContextMenu(e, index); }}>{this.renderFlag(engagement.flag)}</td>
                                        <td className="text-right">{engagement.contactDate}</td>
                                        <td className="text-right">{engagement.followUp}</td>
                                        <td>{engagement.banker}</td>
                                        <td>{engagement.notes}</td>
                                    </tr>);
                                })}
                            </tbody>
                        </table>
                    </SimpleTableContainer>
                </MainContainerScrollable>

                {showContextMenu &&
                    createPortal(<div
                        ref={this.contextMenuRef}
                        style={{ position: 'fixed', left: clientX, top: clientY, zIndex: 100 }}
                    >
                        <ContextMenu
                            dataUI={getDataUI()}
                            items={isContextMenuFlag ? contextMenuFlagItems : contextMenuCategoryItems}
                        />
                    </div>, document.querySelector('body') as any,
                    )}
            </ReactAux>
        );
    }
}

export default DemoEngagements;