import React, { useState } from 'react';
import Highlight from 'react-highlight';
import { Radio } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const RadioPage: React.FC = () => {
    const [radiosChecked, setCheckedRadios] = useState({
        'radio-1': [{ value: 0, checked: true }, { value: 1, checked: false }],
        'radio-3': [{ value: 0, checked: true }, { value: 1, checked: false }],
        'radio-4': [{ value: 0, checked: true }, { value: 1, checked: false }],
        'radio-6': [{ value: 0, checked: true }, { value: 1, checked: false }],
        'radio-7': [{ value: 0, checked: true }, { value: 1, checked: false }],
    });

    const onRadioChange = (e: any) => {
        let updatedRadios = { ...radiosChecked };
        let inputName: keyof typeof updatedRadios = e.target.name;
        const inputValue = +e.target.value;
        updatedRadios[inputName] = radiosChecked[inputName].map((radio, i) => {
            if (inputValue === i) {
                radio.checked = true;
            } else {
                radio.checked = false;
            }
            return radio;
        });

        setCheckedRadios(updatedRadios);
    }
    return (
        <div>
            <h1 className="mb-s">Radio</h1>
            <p className="mb-l">The Radio component extends the native <i>input type="radio"</i> element.</p>

            <h3 className="mb-s">Default</h3>
            <div className="mb-m">
                <Radio dataUI={getDataUI()} name="radio-1" checked={radiosChecked['radio-1'][0].checked} onChange={(e) => { onRadioChange(e) }} value={radiosChecked['radio-1'][0].value}>Radio 1</Radio>
                <Radio dataUI={getDataUI()} name="radio-1" checked={radiosChecked['radio-1'][1].checked} onChange={(e) => { onRadioChange(e) }} value={radiosChecked['radio-1'][1].value}>Radio 2</Radio>
            </div>

            <h3 className="mb-s">Default readonly</h3>
            <div className="mb-m">
                <Radio dataUI={getDataUI()} readOnly name="radio-8" checked >Radio 1</Radio>
                <Radio dataUI={getDataUI()} readOnly name="radio-8" >Radio 2</Radio>
            </div>

            <h3 className="mb-s">Default disabled</h3>
            <div className="mb-m">
                <Radio dataUI={getDataUI()} name="radio-2" onChange={() => { }} disabled checked>Radio 1 Disabled</Radio>
                <Radio dataUI={getDataUI()} name="radio-2" onChange={() => { }} disabled>Radio 2 Disabled</Radio>
            </div>

            <h3 className="mb-s">Default no text</h3>
            <div className="mb-m">
                <Radio dataUI={getDataUI()} name="radio-4" checked={radiosChecked['radio-4'][0].checked} onChange={(e) => { onRadioChange(e) }} value={radiosChecked['radio-4'][0].value} />
                <Radio dataUI={getDataUI()} name="radio-4" checked={radiosChecked['radio-4'][1].checked} onChange={(e) => { onRadioChange(e) }} value={radiosChecked['radio-4'][1].value} />
            </div>

            <h3 className="mb-s">Default readonly no text</h3>
            <div className="mb-m">
                <Radio dataUI={getDataUI()} readOnly name="radio-9" checked />
                <Radio dataUI={getDataUI()} readOnly name="radio-9" />
            </div>

            <h3 className="mb-s">Default disabled no text</h3>
            <div className="mb-m">
                <Radio dataUI={getDataUI()} disabled name="radio-11" checked />
                <Radio dataUI={getDataUI()} disabled name="radio-11" />
            </div>

            <h3 className="mb-s">Block with text</h3>
            <div className="mb-m">
                <Radio dataUI={getDataUI()} className="d-block" name="radio-3" checked={radiosChecked['radio-3'][0].checked} onChange={(e) => { onRadioChange(e) }} value={radiosChecked['radio-3'][0].value}>Radio 1</Radio>
                <Radio dataUI={getDataUI()} className="d-block" name="radio-3" checked={radiosChecked['radio-3'][1].checked} onChange={(e) => { onRadioChange(e) }} value={radiosChecked['radio-3'][1].value}>Radio 2</Radio>
            </div>

            <h3 className="mb-s">Block readonly</h3>
            <div className="mb-m">
                <Radio dataUI={getDataUI()} readOnly className="d-block" name="radio-7" checked={radiosChecked['radio-7'][0].checked} onChange={(e) => { onRadioChange(e) }} value={radiosChecked['radio-7'][0].value}>Radio 1</Radio>
                <Radio dataUI={getDataUI()} readOnly className="d-block" name="radio-7" checked={radiosChecked['radio-7'][1].checked} onChange={(e) => { onRadioChange(e) }} value={radiosChecked['radio-7'][1].value}>Radio 2</Radio>
            </div>

            <h3 className="mb-s">Block with text disabled</h3>
            <div className="mb-m">
                <Radio dataUI={getDataUI()} className="d-block" name="radio-5" onChange={() => { }} disabled>Radio 1</Radio>
                <Radio dataUI={getDataUI()} className="d-block" name="radio-5" onChange={() => { }} disabled checked>Radio 2</Radio>
            </div>

            <h3 className="mb-s">Block no text</h3>
            <div className="mb-m">
                <Radio dataUI={getDataUI()} className="d-block" name="radio-6" checked={radiosChecked['radio-6'][0].checked} onChange={(e) => { onRadioChange(e) }} value={radiosChecked['radio-6'][0].value} />
                <Radio dataUI={getDataUI()} className="d-block" name="radio-6" checked={radiosChecked['radio-6'][1].checked} onChange={(e) => { onRadioChange(e) }} value={radiosChecked['radio-6'][1].value} />
            </div>

            <h3 className="mb-s">Block no text readonly</h3>
            <div className="mb-m">
                <Radio dataUI={getDataUI()} className="d-block" name="radio-10" checked readOnly />
                <Radio dataUI={getDataUI()} className="d-block" name="radio-10" readOnly />
            </div>

            <h3 className="mb-s">Block no text disabled</h3>
            <div className="mb-xl">
                <Radio dataUI={getDataUI()} className="d-block" name="radio-12" onChange={() => { }} disabled />
                <Radio dataUI={getDataUI()} className="d-block" name="radio-12" onChange={() => { }} disabled checked />
            </div>

            <Highlight className="React">
                {
                    `
import React, {useState} from 'react';
import { Radio } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const Example: React.FC = (props) => {
    const [radiosChecked, setCheckedRadios] = useState({
        'radio-1': [{ value: 0, checked: true }, { value: 1, checked: false }],
        'radio-3': [{ value: 0, checked: true }, { value: 1, checked: false }],
        'radio-4': [{ value: 0, checked: true }, { value: 1, checked: false }],
        'radio-6': [{ value: 0, checked: true }, { value: 1, checked: false }],
        'radio-7': [{ value: 0, checked: true }, { value: 1, checked: false }],
    });
    return (
      <div>
            // Default
            <div className="mb-m">
                <Radio dataUI={getDataUI()} name="radio-1" checked={radiosChecked['radio-1'][0].checked} onChange={(e) => { onRadioChange(e) }} value={radiosChecked['radio-1'][0].value}>Radio 1</Radio>
                <Radio dataUI={getDataUI()} name="radio-1" checked={radiosChecked['radio-1'][1].checked} onChange={(e) => { onRadioChange(e) }} value={radiosChecked['radio-1'][1].value}>Radio 2</Radio>
            </div>

            // Default readonly
            <div className="mb-m">
                <Radio dataUI={getDataUI()} readOnly name="radio-8" checked >Radio 1</Radio>
                <Radio dataUI={getDataUI()} readOnly name="radio-8" >Radio 2</Radio>
            </div>

            // Default disabled
            <div className="mb-m">
                <Radio dataUI={getDataUI()} name="radio-2" onChange={() => { }} disabled checked>Radio 1 Disabled</Radio>
                <Radio dataUI={getDataUI()} name="radio-2" onChange={() => { }} disabled>Radio 2 Disabled</Radio>
            </div>

            // Default no text
            <div className="mb-m">
                <Radio dataUI={getDataUI()} name="radio-4" checked={radiosChecked['radio-4'][0].checked} onChange={(e) => { onRadioChange(e) }} value={radiosChecked['radio-4'][0].value} />
                <Radio dataUI={getDataUI()} name="radio-4" checked={radiosChecked['radio-4'][1].checked} onChange={(e) => { onRadioChange(e) }} value={radiosChecked['radio-4'][1].value} />
            </div>

            // Default readonly no text
            <div className="mb-m">
                <Radio dataUI={getDataUI()} readOnly name="radio-9" checked />
                <Radio dataUI={getDataUI()} readOnly name="radio-9" />
            </div>

            // Default disabled no text
            <div className="mb-m">
                <Radio dataUI={getDataUI()} disabled name="radio-11" checked />
                <Radio dataUI={getDataUI()} disabled name="radio-11" />
            </div>

            // Block with text
            <div className="mb-m">
                <Radio dataUI={getDataUI()} className="d-block" name="radio-3" checked={radiosChecked['radio-3'][0].checked} onChange={(e) => { onRadioChange(e) }} value={radiosChecked['radio-3'][0].value}>Radio 1</Radio>
                <Radio dataUI={getDataUI()} className="d-block" name="radio-3" checked={radiosChecked['radio-3'][1].checked} onChange={(e) => { onRadioChange(e) }} value={radiosChecked['radio-3'][1].value}>Radio 2</Radio>
            </div>

            // Block readonly
            <div className="mb-m">
                <Radio dataUI={getDataUI()} readOnly className="d-block" name="radio-7" checked={radiosChecked['radio-7'][0].checked} onChange={(e) => { onRadioChange(e) }} value={radiosChecked['radio-7'][0].value}>Radio 1</Radio>
                <Radio dataUI={getDataUI()} readOnly className="d-block" name="radio-7" checked={radiosChecked['radio-7'][1].checked} onChange={(e) => { onRadioChange(e) }} value={radiosChecked['radio-7'][1].value}>Radio 2</Radio>
            </div>

            // Block with text disabled
            <div className="mb-m">
                <Radio dataUI={getDataUI()} className="d-block" name="radio-5" onChange={() => { }} disabled>Radio 1</Radio>
                <Radio dataUI={getDataUI()} className="d-block" name="radio-5" onChange={() => { }} disabled checked>Radio 2</Radio>
            </div>

            // Block no text
            <div className="mb-m">
                <Radio dataUI={getDataUI()} className="d-block" name="radio-6" checked={radiosChecked['radio-6'][0].checked} onChange={(e) => { onRadioChange(e) }} value={radiosChecked['radio-6'][0].value} />
                <Radio dataUI={getDataUI()} className="d-block" name="radio-6" checked={radiosChecked['radio-6'][1].checked} onChange={(e) => { onRadioChange(e) }} value={radiosChecked['radio-6'][1].value} />
            </div>

            // Block no text readonly
            <div className="mb-m">
                <Radio dataUI={getDataUI()} className="d-block" name="radio-10" checked readOnly />
                <Radio dataUI={getDataUI()} className="d-block" name="radio-10" readOnly />
            </div>

            // Block no text disabled
            <div className="mb-xl">
                <Radio dataUI={getDataUI()} className="d-block" name="radio-12" onChange={() => { }} disabled />
                <Radio dataUI={getDataUI()} className="d-block" name="radio-12" onChange={() => { }} disabled checked />
            </div>
      </div>
    );
}

export default Example;
    `}
            </Highlight>

            <h2 className="mb-m">API</h2>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>all native input attributes</td>
                        <td className='text-center'>-</td>
                        <td className='text-center'>-</td>
                        <td>You can use all native input attributes like: "disabled", "name", "checked"...</td>
                    </tr>
                </tbody>
            </table>

        </div>
    )
}

export default RadioPage;