import React from 'react';
import Highlight from 'react-highlight';

import { Label } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const LabelPage: React.FC = () => {
    return (
        <div>
            <h1 className="mb-s">Label</h1>
            <p className="mb-l">The Label component extends the native label element.</p>

            <Label dataUI={getDataUI()}>Label text</Label>

            <Highlight className="React">
                {
                    `
import React from 'react';
import { Label } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const Example: React.FC = (props) => {
    return (
      <div>
        <Label dataUI={getDataUI()}>Label text</Label>
      </div>
    );
}

export default Example;
    `}
            </Highlight>

            <h2 className="mb-m">API</h2>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>all native label attributes</td>
                        <td className="text-center">-</td>
                        <td className="text-center">-</td>
                        <td>You can use all native Label attributes like: "htmlFor", "id", etc.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default LabelPage;
