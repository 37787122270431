import React, { useState } from 'react';
import {
  Button, Row, Col, VerticalTabs, VerticalTabList,
  VerticalTabItem, VerticalTabContent, VerticalTabContentItem, DynamicScrollableContainer, Input,
  Label, Datepicker
} from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';


const boilerplateTabs = [
  'Facility', 'Facility Fund', 'Facility UDF', 'User', 'Loan Options', 'Collateral', 'Collateral UDF',
  'Party', 'Party UDF', 'Ratios', 'Business Rules', 'Online Application', 'Complience - Facility', 'Complience - File',
  'Demo tab 1', 'Demo tab 2', 'Demo tab 3', 'Demo tab 4', 'Demo tab 5', 'Demo tab 6'
];

const options = [
  'Age Of Newest Deposit in Days', 'Age Of Newest Loan in Days', 'Age Of Oldest Deposit in Days',
  'Age Of Oldest Loan in Days', 'All File Credit Scores Average', 'Analyst', 
  'Average Balance of All Deposits long Average Balance of All Deposits test Average Balance of All Deposits test Average Balance of All Deposits',
  'Branch', 'Business Line', 'Create Date', 'Credit Report Returned', 'Days Since Last Review', 'Days Until Next Review',
  'Decision Calculation Result', 'E-Sign Complete', 'E-Sign Date Received', 'E-Sign Date Sent',
  'E-Sign Status', 'E-Signature Enabled', 'File Credit Grade ', 'File Party Credit Score', 'Demo option 1',
  'Demo option 2', 'Demo option 3', 'Demo option 4', 'Demo option 5'
];

const conditions = [
  'Is Equal To', 'Is Not Equal To', 'Is In List', 'Is Not In List', 'Is Current User', 'Is Not Current User',
  'Date Between'
];

const repeatedConditions = [...conditions, ...conditions, ...conditions, ...conditions];

const ConditionWithSubForm = ({ text, selected, onConditionClick }: { text: string, selected: boolean, onConditionClick: () => void, }) => (
  <>
    <Button
      dataUI={getDataUI()}
      btnType="link"
      onClick={onConditionClick}
      className={selected ? 'text-bold text-base' : ''}>
      {text}
    </Button>
    {selected && text !== 'Date Between' && <Input autoFocus dataUI={getDataUI()} className="width-full mb-s" darkBorder />}
    {selected && text === 'Date Between' && (
      <div className="d-flex align-items-center mb-s">
        <Datepicker
          darkBorder
          name="datepicker-start"
          format="MM/DD/YYYY"
          dataUI={getDataUI()}
          autoFocus
        />
        <span className="ml-xs mr-xs">-</span>
        <Datepicker
          darkBorder
          name="datepicker-end"
          format="MM/DD/YYYY"
          dataUI={getDataUI()}
        />
      </div>
    )}
  </>
);

const ConditionDetailTabs = () => {
  const [verticalTabIndex, setVerticalTabindex] = useState(0);
  const [selectedOption, setSelectedOption] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [selectedConditionIndex, setSelectedConditionIndex] = useState(-1);

  return (
    <Row className="height-full">
      <Col xs={7}>
        <DynamicScrollableContainer dataUI={getDataUI()}>
          <VerticalTabs
            dataUI={getDataUI()}
            currentlyActiveTabIndex={verticalTabIndex}
            onTabIndexChange={index => setVerticalTabindex(index)}
          >
            <VerticalTabList dataUI={getDataUI()}>
              <VerticalTabItem dataUI={getDataUI()}>File UDF</VerticalTabItem>
              {boilerplateTabs.map((t, i) => <VerticalTabItem key={`${i}-${t}`} dataUI={getDataUI()}>{t}</VerticalTabItem>)}
            </VerticalTabList>
            <VerticalTabContent dataUI={getDataUI()}>
              <VerticalTabContentItem dataUI={getDataUI()}>
                <div className="height-full">
                  <div className="p-m height-full d-flex flex-column">
                    <Input
                      className="mb-m"
                      dataUI={getDataUI()}
                      name="input-icon"
                      icon='fal fa-search'
                      darkBorder
                      value={searchValue}
                      onChange={(event) => setSearchValue(event.target.value)}
                    />

                    <div className="d-flex flex-column pr-xs" style={{ overflowY: 'auto' }}>
                      {options.filter(o => searchValue ? o.includes(searchValue) : true).map((o: string, i: number) => (
                        <Button
                          key={`${o}-${i}`}
                          dataUI={getDataUI()}
                          btnType="link"
                          onClick={() => {
                            setSelectedConditionIndex(-1);
                            setSelectedOption(o);
                          }}
                          className={selectedOption === o ? 'text-bold text-base' : ''}>{o}</Button>
                      ))
                      }
                    </div>
                  </div>
                </div>
              </VerticalTabContentItem>
              {boilerplateTabs.map((t, i) => <VerticalTabContentItem key={`${i}-${t}`} dataUI={getDataUI()}>{t}</VerticalTabContentItem>)}
            </VerticalTabContent>
          </VerticalTabs>
        </DynamicScrollableContainer>

      </Col>
      <Col xs={5}>
        <DynamicScrollableContainer dataUI={getDataUI()}>
          <div className="height-full background-color-2 radius-small">
            <div className="p-m height-full d-flex flex-column">
              <Label dataUI={getDataUI()} className="mb-l">
                <span className="text-bold">Selected:</span>
                <span>&nbsp;</span>
                <span className={`text-normal ${!selectedOption ? 'text-muted' : ''}`}>
                  {selectedOption ? selectedOption : 'Please select an option from left container.'}
                </span>
              </Label>

              <div className="d-flex flex-column pr-xs height-full" style={{ overflowY: 'auto' }}>
                {/* The check before mapping is added only for demo purpose */}
                {selectedOption && selectedOption.startsWith('Age') && repeatedConditions.map((c: string, i: number) => (
                  <ConditionWithSubForm
                    key={`${c}-${i}`}
                    selected={selectedConditionIndex === i}
                    text={c}
                    onConditionClick={() => setSelectedConditionIndex(i)}
                  />
                ))
                }
                {selectedOption && selectedOption.startsWith('E') && conditions.map((c: string, i: number) => (
                  <ConditionWithSubForm
                    key={`${c}-${i}`}
                    selected={selectedConditionIndex === i}
                    text={c}
                    onConditionClick={() => setSelectedConditionIndex(i)}
                  />
                ))
                }
              </div>
            </div>
          </div>
        </DynamicScrollableContainer>
      </Col>
    </Row>
  );
};

export default ConditionDetailTabs;