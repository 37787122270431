import React from 'react';
import { Link } from 'react-router-dom';
import Highlight from 'react-highlight';
import { ReactAux } from '@jkhy/vsg-loanvantage-design-system';

const StyleUtils: React.FC = () => {
    return (
        <ReactAux>
            <h1 className="mb-s">Style Utilities</h1>
            <p className="mb-l">
                VSG LoanVantage Design System provides a variety of utility CSS classes for consistent
                styling of common cases. Most of those classes' names and functionalities  are inspired by <a href="https://getbootstrap.com/"
                    target="_blank"  rel="noopener noreferrer">Bootstrap</a>, but there are considerable modifications.
            </p>

            <h2 className="mb-s">Display</h2>
            <p className="mb-l">
                The display utility classes are the same as Bootstrap's <a
                    href="https://getbootstrap.com/docs/4.4/utilities/display/"
                    target="_blank"  rel="noopener noreferrer">display utils</a>.
            </p>

            <h2 className="mb-s">Grid</h2>
            <p className="mb-l">
                Instead of using utility classes for row-column layout, there are
                specific <Link to="/docs/grid">Grid components</Link> available in the design system.
            </p>

            <h2 className="mb-s">Spacing</h2>
            <p className="mb-m">
                Spacing utilities in Loan Vantage Design System are available as explicit
                margin or padding classes. Their naming is by the following pattern: <i>.&#123;spacing&#125;&#123;position&#125;-&#123;size&#125;</i> where the
                spacing is "m" for margin and "p" for padding, position is a letter betweeen 't', 'r', 'b', 'l' (top, right, bottom, left) or empty to assign
                value to all directions, and the size is among '0', 'xs', 's', 'm', 'l', 'xl'. Margins can be use with negative values <i>.&#123;spacing&#125;&#123;position&#125;-n-&#123;size&#125;</i>.
            </p>
            <div className="mb-s">
                <div className="mb-0" style={{ color: 'var(--color-text)', background: 'var(--color-select)' }}>Margin bottom 0</div>
                <div className="mb-xs" style={{ color: 'var(--color-text)', background: 'var(--color-select)' }}>Margin bottom XS</div>
                <div className="mb-s" style={{ color: 'var(--color-text)', background: 'var(--color-select)' }}>Margin bottom S</div>
                <div className="mb-m" style={{ color: 'var(--color-text)', background: 'var(--color-select)' }}>Margin bottom M</div>
                <div className="mb-l" style={{ color: 'var(--color-text)', background: 'var(--color-select)' }}>Margin bottom L</div>
                <div className="mb-xl" style={{ color: 'var(--color-text)', background: 'var(--color-select)' }}>Margin bottom XL</div>
                <div className="mb-xxl" style={{ color: 'var(--color-text)', background: 'var(--color-select)' }}>Margin bottom XXL</div>
            </div>

            <Highlight className="mb-l">
                {` // Color styles are only for demo purpose
<div>
    <div className="mb-0">Margin bottom 0</div>
    <div className="mb-xs">Margin bottom XS</div>
    <div className="mb-s">Margin bottom S</div>
    <div className="mb-m">Margin bottom M</div>
    <div className="mb-l">Margin bottom L</div>
    <div className="mb-xl">Margin bottom XL</div>
    <div className="mb-xxl">Margin bottom XXL</div>
</div>
`}
            </Highlight>

            <h2 className="mb-s">Other styling utility classes</h2>
            <p className="mb-xs"><strong>.text-primary</strong> - Makes the host element's text color primary.</p>
            <p className="mb-xs"><strong>.text-success</strong> - Makes the host element's text color success.</p>
            <p className="mb-xs"><strong>.text-warning</strong> - Makes the host element's text color warning.</p>
            <p className="mb-xs"><strong>.text-danger</strong> - Makes the host element's text color danger.</p>
            <p className="mb-xs"><strong>.text-base</strong> - Makes the host element's text color with the default Design System text color.</p>
            <p className="mb-xs"><strong>.text-muted</strong> - Makes the host element's text color muted.</p>
            <p className="mb-xs"><strong>.text-red</strong> - Makes the host element's text color red.</p>
            <p className="mb-xs"><strong>.text-blue</strong> - Makes the host element's text color blue.</p>
            <p className="mb-xs"><strong>.text-green</strong> - Makes the host element's text color green.</p>
            <p className="mb-xs"><strong>.text-orange</strong> - Makes the host element's text color orange.</p>
            <p className="mb-xs"><strong>.text-pink</strong> - Makes the host element's text color pink.</p>
            <p className="mb-xs"><strong>.text-yellow</strong> - Makes the host element's text color yellow.</p>
            <p className="mb-xs"><strong>.text-center</strong> - Does center text alignment of the host's content.</p>
            <p className="mb-xs"><strong>.text-left</strong> - Does left text alignment of the host's content.</p>
            <p className="mb-xs"><strong>.text-right</strong> - Does right text alignment of the host's content.</p>
            <p className="mb-xs"><strong>.text-no-break</strong> - Prevents text wrapping on white space inside the host element.</p>
            <p className="mb-xs"><strong>.text-truncated</strong> - Prevents text wrapping on white space and sets ellipsis when the host element's text content overflows.</p>
            <p className="mb-xs"><strong>.text-small</strong> - Makes the host element's text font size 12px.</p>
            <p className="mb-xs"><strong>.lv-link-styles</strong> - Applies the specific styles for link element to the given host.</p>
            <p className="mb-xs"><strong>.background-color-1</strong> - Sets the background color of the given host element to <i>color-1</i>.</p>
            <p className="mb-xs"><strong>.background-color-2</strong> - Sets the background color of the given host element to <i>color-2</i>.</p>
            <p className="mb-xs"><strong>.background-wq-item-bg-color-blue</strong> - Sets the background color of the given host element to <i>wq-item-bg-color-blue</i>.</p>
            <p className="mb-xs"><strong>.lv-download-link-btn</strong> - Sets styles to "a" element in button.</p>
            <p className="mb-xs"><strong>.selection-container</strong> - Sets container styles with background <i>color-2</i>.</p>
            <p className="mb-xs"><strong>.radius-small</strong> - Sets the border radius to all sides of the container to <i>3px</i>.</p>
            <p className="mb-xs"><strong>.border-color-text</strong> - Sets border color to<i>color-text</i>.</p>
        </ReactAux>
    )
}

export default StyleUtils;
