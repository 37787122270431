import React, { useState } from 'react';

import { getDataUI } from '../helpers/helpers';
import { GridContainer, Grid, GridColumn, ActionContainer, ActionContainerCrumbs, ActionContainerHeader, MainContainerHeader, MainContainerScrollable, GridHeaderCellProps, Modal, ModalScrollableContainer, SimpleTableContainer, Button, WizardStep, WizardStepsContainer, Toolbar, Col, Input, Row, Label, Select, Datepicker, Collapse, NumericInput, Checkbox, Tooltip, Radio } from '@jkhy/vsg-loanvantage-design-system';

import products from './KendoTable/products.json';

interface CustomHeaderCellProps extends GridHeaderCellProps {
    children: any;
}

const CustomHeaderCell: any = (props: CustomHeaderCellProps) => {
    return (
        <a href="/" className="k-link text-center" onClick={props.onClick}>
            <span className="text-primary">{props.title}</span>
            {props.children}
        </a>
    );
};

const selectOptions = [{ value: "1", label: "Option 1" }, { value: "2", label: "Option 2" }, { value: "3", label: "Option 3" }, { value: "4", label: "Option 4" }]

const DemoPreWizard = () => {

    const [isVisible, setIsVisible] = useState(true);
    const [currentStepIndex, setCurrentStepIndex] = useState(1);
    const [collapseDemo1, setCollapseDemo1] = useState(true);
    const [collapseDemo2, setCollapseDemo2] = useState(true);
    const [collapseDemo3, setCollapseDemo3] = useState(false);
    const [collapseDemo4, setCollapseDemo4] = useState(false);
    const [collapseDemo5, setCollapseDemo5] = useState(false);
    const [collapseDemo6, setCollapseDemo6] = useState(true);

    return (
        <>
            <MainContainerHeader dataUI={getDataUI()}>
                <ActionContainer dataUI={getDataUI()}>
                    <ActionContainerHeader dataUI={getDataUI()}>
                        <ActionContainerCrumbs dataUI={getDataUI()} crumbs={['Pre Wizard']} />
                    </ActionContainerHeader>
                </ActionContainer>
            </MainContainerHeader>

            <MainContainerScrollable>
                <Button onClick={() => { setIsVisible(true); }} dataUI={getDataUI()}>Open PreWizard</Button>
            </MainContainerScrollable>

            <Modal dataUI={getDataUI()} title="New Loan Request" isVisible={isVisible} size="L" onClose={() => setIsVisible(false)}>
                <WizardStepsContainer
                    dataUI={getDataUI()}
                    currentStepIndex={currentStepIndex}
                >
                    <WizardStep dataUI={getDataUI()} title="1. Loan Wizard" status="success" icon="fal fa-magic" onClick={() => setCurrentStepIndex(0)} />
                    <WizardStep dataUI={getDataUI()} title="2. File" status="success" icon="fal fa-file-alt" onClick={() => setCurrentStepIndex(1)} />
                    <WizardStep dataUI={getDataUI()} title="3. Facility" icon="fal fa-file-alt" onClick={() => setCurrentStepIndex(2)} />
                    <WizardStep dataUI={getDataUI()} title="4. File Parties" icon="fal fa-file-user" onClick={() => setCurrentStepIndex(3)} />
                    <WizardStep dataUI={getDataUI()} title="5. Parties" icon="fal fa-users" onClick={() => setCurrentStepIndex(4)} />
                    <WizardStep dataUI={getDataUI()} title="6. Collateral" icon="fal fa-building" onClick={() => setCurrentStepIndex(5)} />
                </WizardStepsContainer>

                <Toolbar dataUI={getDataUI()} className="mb-m"
                    leftSide={
                        <>
                            <Button
                                dataUI={getDataUI()}
                                className="mr-s"
                                icon="fal fa-arrow-circle-left"
                                btnType="secondary"
                                disabled={currentStepIndex === 0}
                                onClick={() => setCurrentStepIndex(currentStepIndex - 1)}
                            >
                                Previous
                            </Button>
                            {currentStepIndex !== 5 &&
                                <Button
                                    dataUI={getDataUI()}
                                    icon="fal fa-arrow-circle-right"
                                    btnType="secondary"
                                    onClick={() => setCurrentStepIndex(currentStepIndex + 1)}
                                >
                                    Next
                                </Button>
                            }
                            {currentStepIndex === 5 &&
                                <Button
                                    dataUI={getDataUI()}
                                    icon="fal fa-plus-circle"
                                >
                                    Create Loan Request
                                </Button>
                            }
                        </>
                    }
                    rightSide={
                        currentStepIndex === 2 || currentStepIndex === 3 || currentStepIndex === 5 ?
                            <Button
                                dataUI={getDataUI()}
                                icon="fal fa-plus"
                                btnType="secondary"
                            >
                                Add {currentStepIndex === 2 && 'Facility'} {currentStepIndex === 3 && 'Party'} {currentStepIndex === 5 && 'Collateral'}
                            </Button>
                            :
                            undefined
                    }
                />

                {currentStepIndex === 0 && <>
                    <ModalScrollableContainer>
                        1. Loan Wizard
                    </ModalScrollableContainer>
                </>}
                {currentStepIndex === 1 && <>
                    <ModalScrollableContainer>
                        {/* TODO This structure is only for demo/test. A real structure must be devised. */}
                        <Row className="mb-s">
                            <Col xs="5">
                                <Label dataUI={getDataUI()} className="justify-content-end">File Name</Label>
                            </Col>
                            <Col xs="7">
                                <Input dataUI={getDataUI()} className="width-m" type="text" />
                            </Col>
                        </Row>
                        <Row className="mb-s">
                            <Col xs="5">
                                <Label dataUI={getDataUI()} className="justify-content-end">Purpose</Label>
                            </Col>
                            <Col xs="7">
                                <Select
                                    dataUI={getDataUI()}
                                    className="width-m"
                                    options={selectOptions}
                                    value=""
                                    placeholder="Select..."
                                />
                            </Col>
                        </Row>
                        <Row className="mb-s">
                            <Col xs="5">
                                <Label dataUI={getDataUI()} className="justify-content-end">File Status</Label>
                            </Col>
                            <Col xs="7">
                                <Select
                                    dataUI={getDataUI()}
                                    className="width-m"
                                    options={selectOptions}
                                    value=""
                                    placeholder="Select..."
                                />
                            </Col>
                        </Row>
                        <Row className="mb-s">
                            <Col xs="5">
                                <Label dataUI={getDataUI()} className="justify-content-end">Analyst</Label>
                            </Col>
                            <Col xs="7">
                                <Select
                                    dataUI={getDataUI()}
                                    className="width-m"
                                    options={selectOptions}
                                    value=""
                                    placeholder="Select..."
                                />
                            </Col>
                        </Row>
                        <Row className="mb-s">
                            <Col xs="5">
                                <Label dataUI={getDataUI()} className="justify-content-end">Analyst Assigned Date</Label>
                            </Col>
                            <Col xs="7">
                                <Datepicker
                                    name="datepicker"
                                    placeholder="MM/DD/YY"
                                    format="MM/DD/YYYY"
                                    className="width-m"
                                    dataUI={getDataUI()}
                                />
                            </Col>
                        </Row>
                        <Row className="mb-s">
                            <Col xs="5">
                                <Label dataUI={getDataUI()} className="justify-content-end">Officer</Label>
                            </Col>
                            <Col xs="7">
                                <Input dataUI={getDataUI()} className="width-m" type="text" />
                            </Col>
                        </Row>
                        <Row className="mb-s">
                            <Col xs="5">
                                <Label dataUI={getDataUI()} className="justify-content-end">Officer 2</Label>
                            </Col>
                            <Col xs="7">
                                <Select
                                    dataUI={getDataUI()}
                                    className="width-m"
                                    options={selectOptions}
                                    value=""
                                    placeholder="Select..."
                                />
                            </Col>
                        </Row>
                        <Row className="mb-s">
                            <Col xs="5">
                                <Label dataUI={getDataUI()} className="justify-content-end">Create Date</Label>
                            </Col>
                            <Col xs="7">
                                <Datepicker
                                    name="datepicker"
                                    placeholder="MM/DD/YY"
                                    format="MM/DD/YYYY"
                                    className="width-m"
                                    dataUI={getDataUI()}
                                />
                            </Col>
                        </Row>
                    </ModalScrollableContainer>
                </>}
                {currentStepIndex === 2 &&
                    <ModalScrollableContainer>
                        <Collapse
                            dataUI={getDataUI()}
                            title="Facility 1"
                            expanded={collapseDemo1}
                            onClick={() => setCollapseDemo1(!collapseDemo1)}
                        >
                            <Collapse
                                dataUI={getDataUI()}
                                title="Facility Request"
                                expanded={collapseDemo2}
                                onClick={() => setCollapseDemo2(!collapseDemo2)}
                            >
                                <Row>
                                    <Col xs={5} className="text-right">
                                        <Label dataUI={getDataUI()} className="mb-s justify-content-end">Loan Number/Description</Label>
                                        <Label dataUI={getDataUI()} className="mb-s justify-content-end">Facility Type</Label>
                                        <Label dataUI={getDataUI()} className="mb-s justify-content-end">Action</Label>
                                        <Label dataUI={getDataUI()} className="mb-s justify-content-end">Requested Loan Amount</Label>
                                        <Label dataUI={getDataUI()} className="mb-s justify-content-end">New Money/Increase</Label>
                                        <Label dataUI={getDataUI()} className="mb-s justify-content-end">Total Loan Amout</Label>
                                    </Col>
                                    <Col xs={7}>
                                        <Input dataUI={getDataUI()} className="mb-s width-m" />
                                        <Select
                                            dataUI={getDataUI()}
                                            className="mb-s width-m"
                                            value="1"
                                            options={[
                                                { value: '1', label: 'Note' },
                                                { value: '2', label: 'Option 2' }
                                            ]}
                                        />
                                        <Select
                                            dataUI={getDataUI()}
                                            className="mb-s width-m"
                                            value="1"
                                            options={[
                                                { value: '1', label: 'New Money' },
                                                { value: '2', label: 'Option 2' }
                                            ]}
                                        />
                                        <NumericInput dataUI={getDataUI()} className="mb-s width-m" type="currency" />
                                        <Label dataUI={getDataUI()} className="mb-s"><strong>$ 0.00</strong></Label>
                                        <Label dataUI={getDataUI()} className="mb-s"><strong>$ 0.00</strong></Label>
                                    </Col>
                                </Row>
                            </Collapse>
                            <Collapse
                                dataUI={getDataUI()}
                                title="Master/Tranche Information"
                                expanded={collapseDemo3}
                                onClick={() => setCollapseDemo3(!collapseDemo3)}
                            >
                                Content ...
                            </Collapse>
                            <Collapse
                                dataUI={getDataUI()}
                                title="Counteroffer"
                                expanded={collapseDemo4}
                                onClick={() => setCollapseDemo4(!collapseDemo4)}
                            >
                                Content ...
                            </Collapse>
                            <Collapse
                                dataUI={getDataUI()}
                                title="Purpose"
                                expanded={collapseDemo5}
                                onClick={() => setCollapseDemo5(!collapseDemo5)}
                            >
                                Content ...
                            </Collapse>
                        </Collapse>
                        <Collapse
                            dataUI={getDataUI()}
                            title="Facility 2"
                            elementRenderRight={() => <Button dataUI={getDataUI()} btnType="icon" icon="fal fa-trash" />}
                            expanded={collapseDemo6}
                            onClick={() => setCollapseDemo6(!collapseDemo6)}
                        >
                            <Row>
                                <Col xs={5} className="text-right">
                                    <Label dataUI={getDataUI()} className="mb-s justify-content-end">Loan Number/Description</Label>
                                    <Label dataUI={getDataUI()} className="mb-s justify-content-end">Facility Type</Label>
                                    <Label dataUI={getDataUI()} className="mb-s justify-content-end">Action</Label>
                                    <Label dataUI={getDataUI()} className="mb-s justify-content-end">Requested Loan Amount</Label>
                                    <Label dataUI={getDataUI()} className="mb-s justify-content-end">New Money/Increase</Label>
                                    <Label dataUI={getDataUI()} className="mb-s justify-content-end">Total Loan Amout</Label>
                                </Col>
                                <Col xs={7}>
                                    <Input dataUI={getDataUI()} className="mb-s width-m" />
                                    <Select
                                        dataUI={getDataUI()}
                                        className="mb-s width-m"
                                        value="1"
                                        options={[
                                            { value: '1', label: 'Note' },
                                            { value: '2', label: 'Option 2' }
                                        ]}
                                    />
                                    <Select
                                        dataUI={getDataUI()}
                                        className="mb-s width-m"
                                        value="1"
                                        options={[
                                            { value: '1', label: 'New Money' },
                                            { value: '2', label: 'Option 2' }
                                        ]}
                                    />
                                    <NumericInput dataUI={getDataUI()} className="mb-s width-m" type="currency" />
                                    <Label dataUI={getDataUI()} className="mb-s"><strong>$ 0.00</strong></Label>
                                    <Label dataUI={getDataUI()} className="mb-s"><strong>$ 0.00</strong></Label>
                                </Col>
                            </Row>
                        </Collapse>
                    </ModalScrollableContainer>
                }
                {currentStepIndex === 3 &&
                    <ModalScrollableContainer>
                        <SimpleTableContainer dataUI={getDataUI()}>
                            <table>
                                <thead>
                                    <tr>
                                        <th style={{ width: '0px' }}></th>
                                        <th style={{ width: '64px' }} className="text-center">Primary</th>
                                        <th style={{ width: '96px' }} className="text-center">
                                            Include In File
                                            <div className="mt-s"><Checkbox dataUI={getDataUI()} /></div>
                                        </th>
                                        <th>Party Name</th>
                                        <th>Party Type</th>
                                        <th style={{ width: '76px' }}>Birth Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array(4).fill('a').map((a, i) => {
                                        return <React.Fragment key={i}>
                                            <tr>
                                                <td className="text-center">
                                                    <Tooltip title="Some text">
                                                        <Button dataUI={getDataUI()} btnType="icon" icon="fal fa-bars" disabled={i === 0} />
                                                    </Tooltip>
                                                </td>
                                                <td className="text-center"><Radio dataUI={getDataUI()} /></td>
                                                <td className="text-center"><Checkbox dataUI={getDataUI()} disabled={i === 0} /></td>
                                                <td>R Team</td>
                                                <td>Personal</td>
                                                <td>01/01/70</td>
                                            </tr>
                                            <tr>
                                                <td className="text-center">
                                                    <Tooltip title="Some text">
                                                        <Button dataUI={getDataUI()} btnType="icon" icon="fal fa-bars" />
                                                    </Tooltip>
                                                </td>
                                                <td className="text-center"><Radio dataUI={getDataUI()} /></td>
                                                <td className="text-center"><Checkbox dataUI={getDataUI()} /></td>
                                                <td>360 Team</td>
                                                <td>Business</td>
                                                <td></td>
                                            </tr>
                                        </React.Fragment>
                                    })}
                                </tbody>
                            </table>
                        </SimpleTableContainer>
                    </ModalScrollableContainer>
                }
                {currentStepIndex === 4 &&
                    <GridContainer dataUI={getDataUI()} style={{ height: '384px', maxHeight: '384px' }}>
                        <Grid
                            style={{ height: '384px', maxHeight: '384px' }}
                            data={products}
                        >
                            <GridColumn width="120px" locked={true}>
                                <GridColumn field="ProductID" title="Party Name" width="120px" locked={true} />
                            </GridColumn>
                            <GridColumn width="120px" locked={true}>
                                <GridColumn field="ProductName" width="120px" title="Party Type" locked={true} />
                            </GridColumn>
                            <GridColumn headerClassName="cell-border-color-sticky" width="120px" locked={true}>
                                <GridColumn headerClassName="cell-border-color-sticky" className="cell-border-color-sticky" field="SupplierID" width="100px" title="Birth Date" locked={true} />
                            </GridColumn>

                            {Array(6).fill('a').map((a, i) => {
                                return <GridColumn
                                    key={i}
                                    title={`Facility ${i + 1} - $50,000`}
                                    headerCell={CustomHeaderCell}
                                    headerClassName="cell-border-color cell-border-color-bottom"
                                >
                                    <GridColumn className="cell-border-color" field="CategoryID" title="Primary" width="80px" />
                                    <GridColumn headerClassName="cell-border-color" field="QuantityPerUnit" title="Relationship" width="120px" />
                                </GridColumn>
                            })}
                        </Grid>
                    </GridContainer>
                }
                {currentStepIndex === 5 &&
                    <GridContainer dataUI={getDataUI()} style={{ height: '384px', maxHeight: '384px' }}>
                        <Grid
                            style={{ height: '384px', maxHeight: '384px' }}
                            data={products}
                        >
                            <GridColumn width="260px" locked={true}>
                                <GridColumn field="ProductName" width="260px" title="Type" locked={true} />
                            </GridColumn>
                            <GridColumn width="140px" locked={true}>
                                <GridColumn field="ProductID" title="Type" width="140px" locked={true} />
                            </GridColumn>
                            <GridColumn width="140px" locked={true}>
                                <GridColumn field="UnitPrice" title="Value" width="140px" locked={true} />
                            </GridColumn>
                            <GridColumn headerClassName="cell-border-color-sticky" width="150px" locked={true}>
                                <GridColumn headerClassName="cell-border-color-sticky" className="cell-border-color-sticky" field="QuantityPerUnit" width="150px" title="Valuation Type" locked={true} />
                            </GridColumn>

                            {Array(6).fill('a').map((a, i) => {
                                return <GridColumn
                                    key={i}
                                    title={`Facility ${i + 1}`}
                                    headerCell={CustomHeaderCell}
                                    headerClassName="cell-border-color cell-border-color-bottom"
                                >
                                    <GridColumn className="cell-border-color" field="CategoryID" title="Assign" width="80px" />
                                </GridColumn>
                            })}
                        </Grid>
                    </GridContainer>
                }
            </Modal>
        </>
    )
}

export default DemoPreWizard
