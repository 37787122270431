import React from 'react';
import Highlight from 'react-highlight';
import { NumericInput, ValidationMessage } from '@jkhy/vsg-loanvantage-design-system';

import { getDataUI } from '../../helpers/helpers';

const NumericInputPage: React.FC = () => {
    return (
        <div>
            <h1 className="mb-s">NumericInput</h1>
            <p className="mb-l">The NumericInput component have 4 types: 'currency', 'percent', 'float' and 'integer'.</p>
            <p className="mb-l">Events 'onChange', 'onBlur', 'onFocus', 'onEnter' and 'onEscape' return the <strong>value</strong>.</p>

            <NumericInput dataUI={getDataUI()} className="mb-m" type="currency" onChange={(value) => console.log(value, ' ~~~currency value')} />
            <NumericInput dataUI={getDataUI()} className="mb-m"
                type="percent"
                onChange={(value) => console.log(value, ' ~~~onChange percent value')}
                onFocus={(value) => console.log(value, ' ~~~onFocus percent value')}
                onBlur={(value) => console.log(value, ' ~~~onBlur percent value')}
                onEnter={(value) => console.log(value, ' ~~~onEnter percent value')}
                onEscape={(value) => console.log(value, ' ~~~onEscape percent value')}
                />
            <NumericInput dataUI={getDataUI()} className="mb-m" type="float" onChange={(value) => console.log(value, ' ~~~float value')} />
            <NumericInput dataUI={getDataUI()} className="mb-xl" type="integer" onChange={(value) => console.log(value, ' ~~~integer value')} />

            <h3 className="mb-s">NumericInput with dark border.</h3>
            <NumericInput darkBorder type="currency"  className='mb-m' dataUI={getDataUI()}/>

            <h3 className="mb-s">NumericInput Invalid</h3>
            <NumericInput invalid type="currency" dataUI={getDataUI()}/>
            <ValidationMessage type='error' message='Invalid message' dataUI={getDataUI()}/>
            <NumericInput
                invalid
                type="percent"
                dataUI={getDataUI()}
             />
            <ValidationMessage type='error' message='Invalid message' dataUI={getDataUI()}/>
            <NumericInput invalid type="float" dataUI={getDataUI()}/>
            <ValidationMessage type='error' message='Invalid message' dataUI={getDataUI()}/>
            <NumericInput invalid type="integer" dataUI={getDataUI()}/>
            <ValidationMessage type='error' message='Invalid message' dataUI={getDataUI()}/>

            <Highlight className="React">
                {
                    `
import React from 'react';
import { NumericInput } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const Example: React.FC = (props) => {
    return (
      <div>
        <NumericInput dataUI={getDataUI()} type="currency" onChange={(value) => console.log(value, ' ~~~currency value')} />
        
        <NumericInput dataUI={getDataUI()}
            type="percent"
            onChange={(value) => console.log(value, ' ~~~onChange percent value')}
            onFocus={(value) => console.log(value, ' ~~~onFocus percent value')}
            onBlur={(value) => console.log(value, ' ~~~onBlur percent value')}
            onEnter={(value: any) => console.log(value, ' ~~~onEnter percent value')}
            onEscape={(value: any) => console.log(value, ' ~~~onEscape percent value')} />
        
        <NumericInput dataUI={getDataUI()} type="float" onChange={(value) => console.log(value, ' ~~~float value')} />

        <NumericInput dataUI={getDataUI()} type="integer" onChange={(value) => console.log(value, ' ~~~integer value')} />

        // NumericInput with dark border.
        <NumericInput darkBorder type="currency" dataUI={getDataUI()}/>

        // NumericInput Invalid
        <NumericInput
         invalid  
         // other way to set invalid
         className='invalid'
         type="currency" 
         dataUI={getDataUI()}
        />
        <ValidationMessage type='error' message='Invalid message' dataUI={getDataUI()}/>

        <NumericInput
            invalid
            // other way to set invalid
            className='invalid'
            type="percent"
            dataUI={getDataUI()}
         />
        <ValidationMessage type='error' message='Invalid message' dataUI={getDataUI()}/>

        <NumericInput 
            invalid 
            // other way to set invalid
            className='invalid'
            type="float"
            dataUI={getDataUI()}
         />
        <ValidationMessage type='error' message='Invalid message' dataUI={getDataUI()}/>

        <NumericInput 
            invalid
            // other way to set invalid
            className='invalid'
            type="integer"
            dataUI={getDataUI()}
        />
        <ValidationMessage type='error' message='Invalid message' dataUI={getDataUI()}/>
      </div>
    );
}

export default Example;
    `}
            </Highlight>

            <h2 className="mb-m">API</h2>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>type</td>
                        <td>'currency' | 'percent' | 'float' | 'integer'</td>
                        <td className="text-center">-</td>
                        <td>Set the type. Each type set default values.</td>
                    </tr>
                    <tr>
                        <td>decimals</td>
                        <td>number</td>
                        <td>
                            For 'currency', 'percent' and 'float' is 2
                            <hr className="mb-s mt-s" />
                            For 'integer' is 0
                        </td>
                        <td>Set how many digits can be type after the decimal dot. When the input is on focus.</td>
                    </tr>
                    <tr>
                        <td>format</td>
                        <td>string</td>
                        <td>
                            For 'currency' is "0,0.00"
                            <hr className="mb-s mt-s" />
                            For 'percent' and 'float' is "0.00"
                            <hr className="mb-s mt-s" />
                            For 'integer' is "0"
                        </td>
                        <td>Set the format that will show when the input is not on focus.</td>
                    </tr>
                    <tr>
                        <td>onEnter</td>
                        <td>Function</td>
                        <td className="text-center">-</td>
                        <td>Callback when the 'enter' key is clicked.</td>
                    </tr>
                    <tr>
                        <td>onEscape</td>
                        <td>Function</td>
                        <td className="text-center">-</td>
                        <td>Callback when the 'escape' key is clicked.</td>
                    </tr>
                    <tr>
                        <td>invalid</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Sets component invalid styles. You can set component invalid styles and by adding class 'invalid' </td>
                    </tr>
                    <tr>
                        <td>darkBorder</td>
                        <td className='text-center'>boolean</td>
                        <td className='text-center'>-</td>
                        <td>Sets component border color</td>
                    </tr>
                    <tr>
                        <td>all native Input attributes</td>
                        <td className="text-center">-</td>
                        <td className="text-center">-</td>
                        <td>You can use all native Input attributes like: "id", "disabled", "name"...</td>
                    </tr>
                </tbody>
            </table>

        </div>
    )
}

export default NumericInputPage;
