import React from 'react';
import Highlight from 'react-highlight';

import { Button, Card, CardSection, Row, Col, Input, Badge, InfoBox } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const CardPage: React.FC = () => {

    // TODO This structure is only for demo/test. A real structure must be devised.
    const renderAddresForm = () => {
        return <Row>
            <Col xs={3} className="align-self-center">
                <p className="text-right mb-m">Address Line 1</p>
            </Col>
            <Col xs={3}>
                <Input className="mb-m" type="text" dataUI={getDataUI()} />
            </Col>
            <Col xs={3} className="align-self-center">
                <p className="text-right mb-m">County</p>
            </Col>
            <Col xs={3}>
                <Input className="mb-m" type="text" dataUI={getDataUI()} />
            </Col>
            <Col xs={3} className="align-self-center">
                <p className="text-right mb-m">Address Line 2</p>
            </Col>
            <Col xs={3}>
                <Input className="mb-m" type="text" dataUI={getDataUI()} />
            </Col>
            <Col xs={3} className="align-self-center">
                <p className="text-right mb-m">County</p>
            </Col>
            <Col xs={3}>
                <Input className="mb-m" type="text" dataUI={getDataUI()} />
            </Col>
        </Row>;

    }


    const renderAddresFormVertical = () => {
        return <Row>
            <Col xs={12}>
                <p className="mb-s">Address Line 1</p>
            </Col>
            <Col xs={12}>
                <Input className="mb-m" type="text" dataUI={getDataUI()} />
            </Col>
            <Col xs={12}>
                <p className="mb-s">County</p>
            </Col>
            <Col xs={12}>
                <Input className="mb-m" type="text" dataUI={getDataUI()} />
            </Col>
            <Col xs={12}>
                <p className="mb-s">Address Line 2</p>
            </Col>
            <Col xs={12}>
                <Input className="mb-m" type="text" dataUI={getDataUI()} />
            </Col>
            <Col xs={12}>
                <p className="mb-s">County</p>
            </Col>
            <Col xs={12}>
                <Input type="text" dataUI={getDataUI()} />
            </Col>
        </Row>;

    }


    return (
        <div>
            <h1 className="mb-l">Card</h1>

            <h2 className="mb-m">Card module components:</h2>
            <p className="mb-s"><strong><a href="#card">Card</a></strong> - The main wrapper component</p>
            <p className="mb-l"><strong><a href="#card-section">CardSection</a></strong> - The content wrapper that separates content.</p>

            <h2 className="mb-m">Default layout</h2>
            <div className='mb-xl'>
                <Card className="mb-s" dataUI={getDataUI()} actionButton={<Button icon="fal fa-trash" btnType="secondary" dataUI={getDataUI()}>Delete</Button>}>
                    <CardSection icon="fal fa-briefcase" title="Section 1 Title" dataUI={getDataUI()}>{renderAddresForm()}</CardSection>
                </Card>
                <Card dataUI={getDataUI()} className="mb-l" actionButton={<Button icon="fal fa-trash" btnType="secondary" dataUI={getDataUI()}>Delete</Button>}>
                    <CardSection title="Section 1 Title" dataUI={getDataUI()}>{renderAddresForm()}</CardSection>
                    <CardSection icon="fal fa-home" title="Section 2 Title" dataUI={getDataUI()}>{renderAddresForm()}</CardSection>
                </Card>
                <Card className="mb-s" fullWidth dataUI={getDataUI()} actionButton={
                    <>
                        <Button className="mr-s" icon="fal fa-history" btnType="secondary" dataUI={getDataUI()}>History</Button>
                        <Button icon="fal fa-trash" btnType="secondary" dataUI={getDataUI()}>Delete</Button>
                    </>
                }>
                    <CardSection title="Very long long loooong title" dataUI={getDataUI()}>{renderAddresForm()}</CardSection>
                </Card>

                <Card title="Title" className="mb-s" fullWidth dataUI={getDataUI()} actionButton={
                    <>
                        <Badge type="info" noMaxWidth icon="fal fa-file-upload" className="mr-s" dataUI={getDataUI()}>Basic badge</Badge>
                        <Button icon="fal fa-trash" btnType="secondary" dataUI={getDataUI()}>Delete</Button>
                    </>
                }>
                    <CardSection icon='fal fa-briefcase' title="Single Card Full Width" subtitle=":Additional Text" dataUI={getDataUI()}>{renderAddresForm()}</CardSection>
                </Card>
            </div>

            <h2 className="mb-m">Full height layout</h2>
            <InfoBox className="mb-s" dataUI={getDataUI()} type="warning" icon="fal fa-exclamation-triangle">
                When using this type of cards alignment don't set "action Button" prop !
            </InfoBox>
            <div className='d-flex'>
                <Card dataUI={getDataUI()} fullWidth fullHeight className='mr-s'>
                    <CardSection icon="fal fa-briefcase" title="Single Card Full Width" subtitle=":Additional Text" dataUI={getDataUI()}>
                        {renderAddresFormVertical()}
                    </CardSection>
                </Card>

                <Card dataUI={getDataUI()} fullWidth fullHeight className='mr-s'>
                    <CardSection icon="" title="Section 1 Title" dataUI={getDataUI()}>
                        {renderAddresFormVertical()}
                    </CardSection>
                </Card>

                <Card dataUI={getDataUI()} fullWidth fullHeight>
                    <CardSection icon="" title="Section 1 Title" dataUI={getDataUI()}>
                        {renderAddresFormVertical()}
                    </CardSection>

                    <CardSection icon="" title="Section 2 Title" dataUI={getDataUI()}>
                        {renderAddresFormVertical()}
                    </CardSection>
                </Card>
            </div>

            <Highlight className="React">
                {
                    `
import React from 'react';

import { Button, Card, CardSection, Row, Col, Input } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

class Example extends React.Component {
    // Demo structures
    const renderAddresForm = () => {
        return <Row>
            <Col xs={3} className="align-self-center">
                <p className="text-right mb-m">Address Line 1</p>
            </Col>
            <Col xs={3}>
                <Input className="mb-m" type="text" dataUI={getDataUI()} />
            </Col>
            <Col xs={3} className="align-self-center">
                <p className="text-right mb-m">County</p>
            </Col>
            <Col xs={3}>
                <Input className="mb-m" type="text" dataUI={getDataUI()} />
            </Col>
            <Col xs={3} className="align-self-center">
                <p className="text-right mb-m">Address Line 2</p>
            </Col>
            <Col xs={3}>
                <Input className="mb-m" type="text" dataUI={getDataUI()} />
            </Col>
            <Col xs={3} className="align-self-center">
                <p className="text-right mb-m">County</p>
            </Col>
            <Col xs={3}>
                <Input className="mb-m" type="text" dataUI={getDataUI()} />
            </Col>
        </Row>;

    }


    const renderAddresFormVertical = () => {
        return <Row>
            <Col xs={12}>
                <p className="mb-s">Address Line 1</p>
            </Col>
            <Col xs={12}>
                <Input className="mb-m" type="text" dataUI={getDataUI()} />
            </Col>
            <Col xs={12}>
                <p className="mb-s">County</p>
            </Col>
            <Col xs={12}>
                <Input className="mb-m" type="text" dataUI={getDataUI()} />
            </Col>
            <Col xs={12}>
                <p className="mb-s">Address Line 2</p>
            </Col>
            <Col xs={12}>
                <Input className="mb-m" type="text" dataUI={getDataUI()} />
            </Col>
            <Col xs={12}>
                <p className="mb-s">County</p>
            </Col>
            <Col xs={12}>
                <Input type="text" dataUI={getDataUI()} />
            </Col>
        </Row>;
    }

    render() {
        return (
            // Default layout
            <Card dataUI={getDataUI()} className="mb-s" dataUI={getDataUI()} actionButton={<Button icon="fal fa-trash" btnType="secondary" dataUI={getDataUI()}>Delete</Button>}>
                <CardSection icon="fal fa-briefcase" title="Section 1 Title" dataUI={getDataUI()}>{renderAddresForm()}</CardSection>
            </Card>

            <Card dataUI={getDataUI()} className="mb-l" dataUI={getDataUI()} actionButton={<Button icon="fal fa-trash" btnType="secondary" dataUI={getDataUI()}>Delete</Button>}>
                <CardSection title="Section 1 Title" dataUI={getDataUI()}>{renderAddresForm()}</CardSection>
                <CardSection icon="fal fa-home" title="Section 2 Title" dataUI={getDataUI()}>{renderAddresForm()}</CardSection>
            </Card>

            <Card className="mb-s" fullWidth dataUI={getDataUI()} actionButton={
                <>
                    <Button className="mr-s" icon="fal fa-history" btnType="secondary" dataUI={getDataUI()}>History</Button>
                    <Button icon="fal fa-trash" btnType="secondary" dataUI={getDataUI()}>Delete</Button>
                </>
            }>
                <CardSection title="Very long long loooong title" dataUI={getDataUI()}>{renderAddresForm()}</CardSection>
            </Card>

            <Card title="Title" className="mb-s" fullWidth dataUI={getDataUI()} actionButton={
                <>
                    <Badge noMaxWidth type="info" icon="fal fa-file-upload" className="mr-s" dataUI={getDataUI()}>Basic badge</Badge>
                    <Button icon="fal fa-trash" btnType="secondary" dataUI={getDataUI()}>Delete</Button>
                </>
            }>
                <CardSection icon='fal fa-briefcase' title="Single Card Full Width" subtitle=":Additional Text" dataUI={getDataUI()}>{renderAddresForm()}</CardSection>
            </Card>


            // Full height layout
            <div className='d-flex'>
                <Card dataUI={getDataUI()} fullWidth fullHeight className='mr-s'>
                    <CardSection icon="fal fa-briefcase" title="Single Card Full Width" subtitle=":Additional Text" dataUI={getDataUI()}>
                        {renderAddresFormVertical()}
                    </CardSection>
                </Card>

                <Card dataUI={getDataUI()} fullWidth fullHeight className='mr-s'>
                    <CardSection icon="" title="Section 1 Title" dataUI={getDataUI()}>
                        {renderAddresFormVertical()}
                    </CardSection>
                </Card>

                <Card dataUI={getDataUI()} fullWidth fullHeight>
                    <CardSection icon="" title="Section 1 Title" dataUI={getDataUI()}>
                        {renderAddresFormVertical()}
                    </CardSection>

                    <CardSection icon="" title="Section 2 Title" dataUI={getDataUI()}>
                        {renderAddresFormVertical()}
                    </CardSection>
                </Card>
            </div>
        );
    }
}

export default Example;
        `}
            </Highlight>

            <h2 className="mb-m">API</h2>
            <p className="mb-xl">CardSection component in Card module have React properties
                which are prefixed with an underscore. <strong style={{ color: 'var(--color-warning)' }}>They must not be taken as public API.</strong></p>

            <h3 id="card">Card</h3>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>title</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set title.</td>
                    </tr>
                    <tr>
                        <td>actionButton</td>
                        <td>JSX.Element</td>
                        <td className="text-center">-</td>
                        <td>Sets the element for actionButton.</td>
                    </tr>
                    <tr>
                        <td>fullWidth</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Makes the CardSection expand to the full width of its container.</td>
                    </tr>
                    <tr>
                        <td>fullHeight</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Makes the CardSection expand to the full height of its container.</td>
                    </tr>
                </tbody>
            </table>

            <h3 id="card-section">CardSection</h3>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>title</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Sets the title.</td>
                    </tr>
                    <tr>
                        <td>subtitle</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Sets the subtitle.</td>
                    </tr>
                    <tr>
                        <td>icon</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Sets the icon.</td>
                    </tr>
                </tbody>
            </table>

        </div >
    )
};

export default CardPage;
