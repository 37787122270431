import React, { useState } from 'react';

import {
    MainContainerHeader, ReactAux, ActionContainer, ActionContainerHeader, ActionContainerCrumbs,
    ActionSettings, LinkWithIcon, Button,
    MainContainerScrollable, Tabs, TabList, TabItem, TabItemTitle, TabContent, TabContentItem, Collapse,

} from '@jkhy/vsg-loanvantage-design-system';

import DemoDocPrep from './DemoDocPrep';
import { useHistory } from 'react-router-dom';
import { getDataUI } from '../helpers/helpers';


const DemoFacilityDetails = (props: any) => {
    const history = useHistory();

    const [tabIndex, setTabindex] = useState(0);
    const [collapsOne, setCollapsOne] = useState(false);
    const [collapsTwo, setCollapsTwo] = useState(false);
    const [collapsThree, setCollapsThree] = useState(false);

    return (
        <ReactAux>
            <MainContainerHeader dataUI={getDataUI()}>
                <ActionContainer dataUI={getDataUI()}>
                    <ActionContainerHeader dataUI={getDataUI()}>
                        <ActionContainerCrumbs dataUI={getDataUI()} crumbs={['Facility Details 4449']} />
                        <ActionSettings dataUI={getDataUI()}>
                            <LinkWithIcon dataUI={getDataUI()} icon="fal fa-cogs"><a href="/" title="Robin - Commercial">Robin - Commercial</a></LinkWithIcon>
                        </ActionSettings>
                    </ActionContainerHeader>
                    <Button dataUI={getDataUI()} icon="fal fa-pen">Edit</Button>
                    <Button dataUI={getDataUI()} btnType="secondary" icon="fal fa-building">Collateral</Button>
                    <Button dataUI={getDataUI()} btnType="secondary" icon="fal fa-clipboard-check">Complience</Button>
                    <Button
                        dataUI={getDataUI()}
                        btnType="secondary"
                        icon="fal fa-file-invoice-dollar"
                        onClick={() => {
                            history.push('/components/fees-closing')
                        }}
                    >Fees/Closing
                    </Button>
                    <Button dataUI={getDataUI()} btnType="secondary">SBA</Button>
                    <Button dataUI={getDataUI()} btnType="secondary" icon="fal fa-copy">Copy</Button>
                    <Button dataUI={getDataUI()} btnType="secondary" icon="fal fa-print">Print</Button>
                </ActionContainer>
            </MainContainerHeader>


            <Tabs
                dataUI={getDataUI()}
                className="mb-xl mt-m"
                onTabIndexChange={(index: number) => setTabindex(index)}
                currentlyActiveTabIndex={tabIndex}
            >
                <TabList dataUI={getDataUI()}>
                    <TabItem dataUI={getDataUI()}>
                        <TabItemTitle dataUI={getDataUI()}>Request</TabItemTitle>
                    </TabItem>
                    <TabItem dataUI={getDataUI()}>
                        <TabItemTitle dataUI={getDataUI()}>Borrowers</TabItemTitle>
                    </TabItem>
                    <TabItem dataUI={getDataUI()}>
                        <TabItemTitle dataUI={getDataUI()}>Terms</TabItemTitle>
                    </TabItem>
                    <TabItem dataUI={getDataUI()}>
                        <TabItemTitle dataUI={getDataUI()}>Schedules</TabItemTitle>
                    </TabItem>
                    <TabItem dataUI={getDataUI()}>
                        <TabItemTitle dataUI={getDataUI()}>Lettter of credit</TabItemTitle>
                    </TabItem>
                    <TabItem dataUI={getDataUI()}>
                        <TabItemTitle dataUI={getDataUI()}>Miscellaneous</TabItemTitle>
                    </TabItem>
                    <TabItem dataUI={getDataUI()}>
                        <TabItemTitle dataUI={getDataUI()}>Doc-Prep</TabItemTitle>
                    </TabItem>
                </TabList>
                <TabContent dataUI={getDataUI()}>
                    <TabContentItem dataUI={getDataUI()}> 
                        <MainContainerScrollable>
                            <Collapse
                                dataUI={getDataUI()}
                                title="Facility Request"
                                expanded={collapsOne}
                                onClick={() => setCollapsOne(!collapsOne)}
                            >
                                <p>Content...</p>
                            </Collapse>

                            <Collapse
                                dataUI={getDataUI()}
                                title="Counteroffer"
                                expanded={collapsTwo}
                                onClick={() => setCollapsTwo(!collapsTwo)}
                            >
                                <p>Content...</p>
                            </Collapse>

                            <Collapse
                                dataUI={getDataUI()}
                                title="Purpose"
                                expanded={collapsThree}
                                onClick={() => setCollapsThree(!collapsThree)}
                            >
                                <p>Content...</p>
                            </Collapse>
                        </MainContainerScrollable>
                    </TabContentItem>
                    <TabContentItem dataUI={getDataUI()}>Borrowers tab content</TabContentItem>
                    <TabContentItem dataUI={getDataUI()}>Terms tab content</TabContentItem>
                    <TabContentItem dataUI={getDataUI()}>Schedules tab content</TabContentItem>
                    <TabContentItem dataUI={getDataUI()}>Lettter of credit tab content</TabContentItem>
                    <TabContentItem dataUI={getDataUI()}>Miscellaneous tab content</TabContentItem>
                    <TabContentItem dataUI={getDataUI()}>
                        <DemoDocPrep />
                    </TabContentItem>
                </TabContent>
            </Tabs>
        </ReactAux>
    );
}

export default DemoFacilityDetails;