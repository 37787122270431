import React from 'react';
import { useHistory } from 'react-router-dom';

import {
    MainContainerHeader, ReactAux, ActionContainer, ActionContainerHeader, ActionContainerCrumbs,
    MainContainerScrollable, ErrorStatus
} from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../helpers/helpers';

const DemoPageErrorsInner = ({ status }: { status: '401' | '403' | '404' | '500' }) => {
    const history = useHistory();

    return (
        <ReactAux>
            <MainContainerHeader dataUI={getDataUI()}>
                <ActionContainer dataUI={getDataUI()}>
                    <ActionContainerHeader dataUI={getDataUI()}>
                        <ActionContainerCrumbs dataUI={getDataUI()} crumbs={['Approval']} />
                    </ActionContainerHeader>
                </ActionContainer>
            </MainContainerHeader>

            <MainContainerScrollable>
                <ErrorStatus
                    status={status}
                    onButtonClick={() => {
                        history.push('/components')
                    }}
                />
            </MainContainerScrollable>
        </ReactAux>
    );
}

export default DemoPageErrorsInner;