import React from 'react';
import Highlight from 'react-highlight';
import { ReactAux } from '@jkhy/vsg-loanvantage-design-system';

const IntroductionPage: React.FC = () => {
    return (
        <ReactAux>
            <h1 className="mb-s">Introduction</h1>
            <p className="mb-l">
                VSG LoanVantage Design System is a React component library, enhanced with a CSS framework,
                providing components, structures and guidelines for consistent UIs across <strong>Loan Vantage</strong>.
            </p>
            <h2 className="mb-s">Component Modules and Styles</h2>
            <p className="mb-m">
                The VSG LoanVantage Design System npm package exposes React component modules with TypeScript definitions, as well as
                a single stylesheet, which adds the CSS framework.
            </p>
            <Highlight className="mb-l">
                {`// Import the styles in the root component of your React application
import '@jkhy/vsg-loanvantage-design-system/styles.css';

// Import components
import { Button, Input, Loader } from '@jkhy/vsg-loanvantage-design-system';
`}
            </Highlight>
            <h2 className="mb-s">Environment</h2>
            <p className="mb-m">
                A key part of the successful integration of VSG LoanVantage Design System is to use an
                environment <strong>without any additional style rules</strong>. If there are outer/global CSS rules and
                variables, this would potentially break the design system. Even if those outer styles target
                specific class or id selectors, it is still not advisable to use such stylesheets.
            </p>
            <h2 className="mb-s">Fonts</h2>
            <p className="mb-m">
                The only exception with external stylesheets is the fonts configuration. <strong>Roboto</strong> and <strong>Roboto Condensed</strong> have
                to be imported in the root HTML file.
            </p>
            <Highlight className="mb-l">
                {`<link href="https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css?family=Roboto:300i,400,500,700&display=swap" rel="stylesheet">
`}
            </Highlight>
        </ReactAux>
    )
}

export default IntroductionPage;
