import React from 'react';
import Highlight from 'react-highlight';
import { Datepicker, DatepickerUtils, ValidationMessage } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';


const DatepickerPage: React.FC = () => {
    return (
        <div>
            <h1 className="mb-s">Datepicker</h1>
            <p className="mb-m">The Datepicker component modifies Kendo React DateTimePicker and Kendo React DatePicker.</p>
            <p className="mb-l"> Datepicker component accepts valid Date object and return Date object.</p>

            <h3 className="mb-s">Default</h3>
            <div className="mb-m">
                <Datepicker
                    name="datepicker"
                    placeholder="MM/DD/YY"
                    format="MM/DD/YYYY"
                    onInvalidDate={(isValid: any, isDateInRange: any) => {
                        console.log(`Is Date valid:${isValid}\nIs Date in range: ${isDateInRange}`)
                    }}
                    value={DatepickerUtils.dateFromString('/Date(603064800000+0200)')}
                    onChange={(e) => {
                        console.log(e.target.value, e.target.name)
                    }}
                    className="mb-m"
                    id='dpSimple'
                    dataUI={getDataUI()}
                />

                <Datepicker
                    placeholder="MM/DD/YY"
                    disabled
                    value={new Date()}
                    className="mb-m"
                    id='dpDisabled'
                    dataUI={getDataUI()}
                />
            </div>

            <h3 className="mb-s">DateTimePicker</h3>
            <div className="mb-m">
                <Datepicker
                    placeholder="MM/DD/YYYY hh:mm"
                    format="MM/DD/YYYY hh:mm"
                    name="datetime-picker"
                    dateTime={true}
                    onInvalidDate={(isValid: any, isDateInRange: any) => {
                        console.log(`Is Date valid:${isValid}\nIs Date in range: ${isDateInRange}`)
                    }}
                    value={DatepickerUtils.dateFromString('080920', 'MM/DD/YYYY')}
                    onChange={(e: any) => {
                        console.log(e.target.value, e.target.name)
                    }}
                    className="mb-m"
                    id='dateTimeDP'
                    dataUI={getDataUI()}
                />

                <Datepicker
                    placeholder="MM/DD/YYYY hh:mm"
                    format="MM/DD/YYYY hh:mm"
                    dateTime={true}
                    disabled
                    value={new Date()}
                    className="mb-m"
                    id='dateTimeDisabled'
                    dataUI={getDataUI()}
                />
            </div>

            <h3 className="mb-s">Datepicker with range value</h3>
            <div className="mb-m">
                <Datepicker
                    name="datepicker-range"
                    placeholder="MM/DD/YY"
                    format="MM/DD/YYYY"
                    onInvalidDate={(isValid: any, isDateInRange: any) => {
                        console.log(`Is Date valid:${isValid}\nIs Date in range: ${isDateInRange}`)
                    }}
                    rangeStart={new Date("2020/03/10")}
                    rangeEnd={new Date("2020/08/10")}
                    onChange={(e) => {
                        console.log(e.target.value, e.target.name)
                    }}
                    className="mb-m"
                    id='dateRange'
                    dataUI={getDataUI()}
                />

                <Datepicker
                    placeholder="MM/DD/YYYY hh:mm"
                    format="MM/DD/YYYY hh:mm"
                    name="datetime-picker"
                    dateTime={true}
                    onInvalidDate={(isValid: any, isDateInRange: any) => {
                        console.log(`Is Date valid:${isValid}\nIs Date in range: ${isDateInRange}`)
                    }}
                    rangeStart={new Date("2020/03/10")}
                    rangeEnd={new Date("2020/08/10")}
                    onChange={(e: any) => {
                        console.log(e.target.value, e.target.name)
                    }}
                    className="mb-m"
                    id='dateTimeRange'
                    dataUI={getDataUI()}
                />
            </div>

            <h3 className="mb-s">Datepicker dark border</h3>
            <div className='mb-m'>
                <Datepicker
                    darkBorder
                    placeholder="MM/DD/YY"
                    value={new Date()}
                    id='dpDarkBorder'
                    dataUI={getDataUI()}
                />
            </div>

            <h3 className="mb-s">Datepicker invalid</h3>
            <div className='mb-m'>
                <Datepicker
                    invalid
                    placeholder="MM/DD/YY"
                    value={new Date()}
                    id='dpInvalid'
                    dataUI={getDataUI()}
                />
                <ValidationMessage type='error' message='Invalid message' dataUI={getDataUI()} />
            </div>

            <h3 className="mb-s">Datepicker utils</h3>
            <p>
                <strong>dateFromString</strong>: Use this utility function to format string to date. Depending on the string you pass you may need to pass and format you want.
            </p>
            <p>
                <strong>formatedDateString</strong>: Fromat date to formated string.
            </p>


            <Highlight className="React">
                {
                    `
import React from 'react';
import { Datepicker, DatepickerUtils, ValidationMessage } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const Example: React.FC = (props) => {
    return (
      <div>     
        <Datepicker
            name="datepicker"
            placeholder="MM/DD/YY"
            format="MM/DD/YYYY"
            value={DatepickerUtils.dateFromString('/Date(603064800000+0200)')}
            onChange={(e) => {
                console.log(e.target.value, e.target.name)
            }}
            onInvalidDate={(isValid: any, isDateInRange: any) => {
                console.log("Is Date valid:"+ isValid + "\\n Is Date in range:" + isDateInRange")
            }}
            className="mb-m"
            dataUI={getDataUI()}
        />

        <Datepicker
            placeholder="MM/DD/YY"
            disabled
            value={new Date()}
            className="mb-m"
            dataUI={getDataUI()}
        />

        // DateTimePicker
        
        <Datepicker
            placeholder="MM/DD/YYYY hh:mm"
            format="MM/DD/YYYY hh:mm"
            name="datetime-picker"
            dateTime={true}
            onInvalidDate={(isValid: any, isDateInRange: any) => {
                console.log("Is Date valid:" + isValid + "\\n Is Date in range:" + isDateInRange")
            }}
            value={DatepickerUtils.dateFromString('080920', 'MM/DD/YYYY')}
            onChange={(e: any) => {
                console.log(e.target.value, e.target.name)
            }}
            className="mb-m"
            dataUI={getDataUI()}
        />

        <Datepicker
            placeholder="MM/DD/YYYY hh:mm"
            format="MM/DD/YYYY hh:mm"
            dateTime={true}
            disabled
            value={new Date()}
            className="mb-m"
            dataUI={getDataUI()}
        />

        // Datepicker with range
        <Datepicker
            name="datepicker"
            placeholder="MM/DD/YY"
            format="MM/DD/YYYY"
            onChange={(e) => {
                console.log(e.target.value, e.target.name)
            }}
            onInvalidDate={(isValid: any, isDateInRange: any) => {
                console.log("Is Date valid:"+ isValid + "\\n Is Date in range:" + isDateInRange")
            }}
            rangeStart={new Date("2020/03/10")}
            rangeEnd={new Date("2020/08/10")}
            className="mb-m"
            dataUI={getDataUI()}
        />

        <Datepicker
            placeholder="MM/DD/YYYY hh:mm"
            format="MM/DD/YYYY hh:mm"
            name="datetime-picker"
            dateTime={true}
            onInvalidDate={(isValid: any, isDateInRange: any) => {
                console.log("Is Date valid:" + isValid + "\\n Is Date in range:" + isDateInRange")
            }}
            onChange={(e: any) => {
                console.log(e.target.value, e.target.name)
            }}
            rangeStart={new Date("2020/03/10")}
            rangeEnd={new Date("2020/08/10")}
            className="mb-m"
            dataUI={getDataUI()}
         />
      </div>

      // Datepicker dark border
      <Datepicker
            darkBorder
            placeholder="MM/DD/YY"
            value={new Date()}
            id='dpDarkBorder'
            dataUI={getDataUI()}
        />
        
      // Datepicker invalid
        <Datepicker
            invalid
            placeholder="MM/DD/YY"
            value={new Date()}
            id='dpInvalid'
            dataUI={getDataUI()}
        />
        <ValidationMessage type='error' message='Invalid message' dataUI={getDataUI()} />
    );
}

export default Example;
    `}
            </Highlight>

            <h2 className="mb-m">API</h2>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>dateTime</td>
                        <td className='text-center'>boolean</td>
                        <td className='text-center'>false</td>
                        <td>Set the datepicker to have time picker.</td>
                    </tr>
                    <tr>
                        <td>format</td>
                        <td className='text-center'>string</td>
                        <td className='text-center'>'MM/DD/YYYY'</td>
                        <td>Set date format.</td>
                    </tr>
                    <tr>
                        <td>placeholder</td>
                        <td className='text-center'>string</td>
                        <td className='text-center'>'MM/DD/YYYY'</td>
                        <td>Set date input placeholder.</td>
                    </tr>
                    <tr>
                        <td>rangeEnd</td>
                        <td className='text-center'>date</td>
                        <td className='text-center'>'-</td>
                        <td>Set datepicker end point.</td>
                    </tr>
                    <tr>
                        <td>rangeStart</td>
                        <td className='text-center'>date</td>
                        <td className='text-center'>-</td>
                        <td>Set datepicker start point.</td>
                    </tr>
                    <tr>
                        <td>onInvalidDate</td>
                        <td className='text-center'>function</td>
                        <td className='text-center'>-</td>
                        <td>Return two parameters : "isValid" and "isDateInRange". This function is triggered when you try to set date invalid date and date out of range</td>
                    </tr>
                    <tr>
                        <td>value</td>
                        <td className='text-center'>date</td>
                        <td className='text-center'>-</td>
                        <td>Set datepicker and input value</td>
                    </tr>
                    <tr>
                        <td>onChange</td>
                        <td className='text-center'>function</td>
                        <td className='text-center'>-</td>
                        <td>Triggered when select date from the datepicker or onblur when write on input. </td>
                    </tr>
                    <tr>
                        <td>id</td>
                        <td>string</td>
                        <td className='text-center'>-</td>
                        <td>Set <strong>id</strong> due to WCAG 2.0. Id is also used in DP component to fix close btn func due to know KendoreactDP issue.</td>
                    </tr>
                    <tr>
                        <td>notClearableOnInvalid</td>
                        <td>boolean</td>
                        <td className='text-center'>-</td>
                        <td>Set input to not clear it's value onInvalid date.</td>
                    </tr>
                    <tr>
                        <td>invalid</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Sets component invalid styles.</td>
                    </tr>
                    <tr>
                        <td>darkBorder</td>
                        <td className='text-center'>boolean</td>
                        <td className='text-center'>-</td>
                        <td>Sets component border color</td>
                    </tr>
                    <tr>
                        <td>all native input attributes</td>
                        <td className='text-center'>-</td>
                        <td className='text-center'>-</td>
                        <td>You can use all native input attributes like: "disabled", "name"...</td>
                    </tr>
                </tbody>
            </table>

            <h2 id="api-datepicker-utils" className="mb-3">Datepicker Utils API</h2>
            <table className="doc-table mb-3">
                <thead>
                    <tr>
                        <th colSpan={4}>Functions</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Params</strong>
                        </td>
                        <td>
                            <strong>Return type</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>dateFromString</td>
                        <td>(date:string, format?:string)</td>
                        <td>Date</td>
                        <td>Converts string to date. The default format is "MM/DD/YYYY".</td>
                    </tr>
                    <tr>
                        <td>formatedDateString</td>
                        <td>(date:Date, format:string)</td>
                        <td>string</td>
                        <td>Applies the target formatting to the given date.</td>
                    </tr>
                    <tr>
                        <td>dateRangeFormat</td>
                        <td>(date:Date, isEndRange?:boolean)</td>
                        <td>string</td>
                        <td>Format start and end day of range.</td>
                    </tr>
                </tbody>
            </table>


        </div>
    )
}

export default DatepickerPage;