import React from 'react';
import Highlight from 'react-highlight';
import { Link } from 'react-router-dom';

const ChatPage: React.FC = () => {

    return <div>
        <h1 className="mb-s">Chat</h1>
        <p className="mb-m">Chat component is an additional module to the <Link to="/docs/layout">Layout</Link>.</p>
        <p className="mb-l">Example of Chat can be seen <strong><Link target="blank" to="/components">here</Link></strong>.</p>

        <h2 className="mb-m">Chat module components:</h2>
        <p className="mb-s"><strong><a href="#chat-container">ChatContainer</a></strong> - The main wrapper component. Will be displayed like container or popup (before 1650px width).</p>
        <p className="mb-s"><strong><a href="#chat-contacts-container">ChatContactsContainer</a></strong> - Wrapper component of the content for top side (contacts).</p>
        <p className="mb-s"><strong><a href="#chat-contacts-section">ChatContactsSection</a></strong> - Wrapper component for making different sections with collapse.</p>
        <p className="mb-s"><strong><a href="#chat-contact">ChatContact</a></strong> - Used to show Contact with all the related information.</p>
        <p className="mb-s"><strong><a href="#chat-person-container">ChatPersonContainer</a></strong> - Wrapper component of the content for bottom side (person with messages).</p>
        <p className="mb-s"><strong><a href="#chat-person-messages">ChatPersonMessages</a></strong> - Wrapper component of the consequent messages, sent by one person.</p>
        <p className="mb-s"><strong><a href="#chat-message">ChatMessage</a></strong> - Component for displaying message.</p>
        <p className="mb-xl"><strong><a href="#chat-date-separator">ChatDateSeparator</a></strong> - Component that display the dateTime. Use it for separate different days.</p>

        <Highlight className="React">
            {
                `
import React, { useRef } from 'react';
import { 
    Header, MainContainer, LeftSideBar, ChatContainer, ChatContactsContainer, ChatContact,
    ChatPersonContainer, ChatPersonMessages, ChatMessage, ChatDateSeparator,
} from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const chatContainerRef = useRef();

class Layout extends React.Component{
    render(){
        return (
            <Header dataUI={getDataUI()}>
                ...
            </Header>

            <Main dataUI={getDataUI()}>
                <LeftSideBar dataUI={getDataUI()}>
                    ...
                </LeftSideBar>
                <MainContainer dataUI={getDataUI()}>
                    Content...
                </MainContainer>

                <ChatContainer
                    ref={chatContainerRef}
                    isExpanded={boolean}
                    newNotification
                    onExpandBtnClick={() => {}}
                    dataUI={getDataUI()}
                >
                    <ChatContactsContainer
                        title="Contacts"
                        btnElements={...} // Render buttons actions for start search/filter.
                        titleRender={...} // Render element for search/filter (input). You can see the demo.
                        dataUI={getDataUI()}
                    >
                        {searchName && <>
                            <h2 className="text-muted text-center mt-m mb-m">
                                No results found for “{searchName}”
                            </h2>
                            <hr className="mb-s" />
                        </>}
                        <ChatContact
                            isActive={boolean}
                            device={...}
                            avatar={...}
                            name={...}
                            searchName={searchName}
                            messageText={...}
                            newMessagesCount={...}
                            onClick={() => {}}
                            dataUI={getDataUI()}
                        />
                            <ChatContactsSection
                                title="Pinned"
                                expanded={boolean}
                                onClick={() => {}}
                                dataUI={getDataUI()}
                            >
                                <ChatContact ... />
                                <ChatContact ... />
                            </ChatContactsSection>

                            <ChatContactsSection
                                title="Recent"
                                expanded={boolean}
                                onClick={() => {}}
                                dataUI={getDataUI()}
                            >
                                <ChatContact ... />
                                <ChatContact ... />
                            </ChatContactsSection>
                    </ChatContactsContainer>
                    
                    <ChatPersonContainer
                        ref={...}
                        isExpanded={...}
                        avatar={...}
                        name={...}
                        onCloseClick={() => { ... }}
                        bottomSection={...} // Render element for send message.. You can see the demo.
                        dataUI={getDataUI()}
                    >

                        <ChatPersonMessages from={...} avatar={...} status={...} dataUI={getDataUI()}>
                            <ChatMessage text={...} timestamp={...}  dataUI={getDataUI()}/>
                            <ChatMessage text={...} timestamp={...} dataUI={getDataUI()} />
                            <ChatMessage text={...} timestamp={...} onClick={() => {}} dataUI={getDataUI()} />
                        </ChatPersonMessages>

                        <ChatDateSeparator dateTime={...} dataUI={getDataUI()}/>

                        <ChatPersonMessages from={...} avatar={...} status={...} dataUI={getDataUI()}>
                            <ChatMessage text={...} timestamp={...}  dataUI={getDataUI()}/>
                        </ChatPersonMessages>

                        <ChatPersonMessages from={...} avatar={...} status={...} dataUI={getDataUI()}>
                            <ChatMessage text={...} timestamp={...} dataUI={getDataUI()}/>
                        </ChatPersonMessages>

                </ChatPersonContainer>

                </ChatContainer>

            </Main>
        );
    }
}
export default Layout;
    `}
        </Highlight>

        <h2 className="mb-m">API</h2>

        <h3 id="chat-container">ChatContainer</h3>
        <p className="mb-s"><span className="text-warning text-bold">Important:</span> ChatContainer has <strong>ref</strong> which contains <strong>'triggerNotificationPulse()'</strong>.</p>
        <p className="mb-xs"><strong>triggerNotificationPulse()</strong>: method that will trigger notification pulse.</p>
        <table className="doc-table mb-l">
            <thead>
                <tr>
                    <th colSpan={4}>Props</th>
                </tr>
                <tr>
                    <td>
                        <strong>Name</strong>
                    </td>
                    <td>
                        <strong>Options</strong>
                    </td>
                    <td>
                        <strong>Default</strong>
                    </td>
                    <td>
                        <strong>Description</strong>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>isExpanded</td>
                    <td>boolean</td>
                    <td>false</td>
                    <td>Set whether the Chat container/popup is currently expanded.</td>
                </tr>
                <tr>
                    <td>newNotification</td>
                    <td>boolean</td>
                    <td>false</td>
                    <td>Show that there is a new notification.</td>
                </tr>
                <tr>
                    <td>isPortalPage</td>
                    <td>boolean</td>
                    <td>false</td>
                    <td>Use it when chat is on page with PortalHeader and Header. This will prevent overlapping.</td>
                </tr>
                <tr>
                    <td>onExpandBtnClick</td>
                    <td>() {'=>'} void</td>
                    <td className="text-center">-</td>
                    <td>Callback when expand button is clicked.</td>
                </tr>
            </tbody>
        </table>

        <h3 id="chat-contacts-container">ChatContactsContainer</h3>
        <table className="doc-table mb-l">
            <thead>
                <tr>
                    <th colSpan={4}>Props</th>
                </tr>
                <tr>
                    <td>
                        <strong>Name</strong>
                    </td>
                    <td>
                        <strong>Options</strong>
                    </td>
                    <td>
                        <strong>Default</strong>
                    </td>
                    <td>
                        <strong>Description</strong>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>title</td>
                    <td>string</td>
                    <td className="text-center">-</td>
                    <td>Set the title.</td>
                </tr>
                <tr>
                    <td>btnElements</td>
                    <td>JSX.Element</td>
                    <td className="text-center">-</td>
                    <td>Render buttons element right from title.</td>
                </tr>
                <tr>
                    <td>titleRender</td>
                    <td>JSX.Element</td>
                    <td className="text-center">-</td>
                    <td>This will render element and will hide 'title' and 'btnElements'.</td>
                </tr>
            </tbody>
        </table>

        <h3 id="chat-contacts-section">ChatContactsSection</h3>
        <table className="doc-table mb-l">
            <thead>
                <tr>
                    <th colSpan={4}>Props</th>
                </tr>
                <tr>
                    <td>
                        <strong>Name</strong>
                    </td>
                    <td>
                        <strong>Options</strong>
                    </td>
                    <td>
                        <strong>Default</strong>
                    </td>
                    <td>
                        <strong>Description</strong>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>title</td>
                    <td>string</td>
                    <td className="text-center">-</td>
                    <td>Set the title.</td>
                </tr>
                <tr>
                    <td>expanded</td>
                    <td>boolean</td>
                    <td>false</td>
                    <td>Shows the expanded content.</td>
                </tr>
                <tr>
                    <td>onClick</td>
                    <td>void</td>
                    <td className="text-center">-</td>
                    <td>Callback when the component is clicked.</td>
                </tr>
            </tbody>
        </table>

        <h3 id="chat-contact">ChatContact</h3>
        <table className="doc-table mb-l">
            <thead>
                <tr>
                    <th colSpan={4}>Props</th>
                </tr>
                <tr>
                    <td>
                        <strong>Name</strong>
                    </td>
                    <td>
                        <strong>Options</strong>
                    </td>
                    <td>
                        <strong>Default</strong>
                    </td>
                    <td>
                        <strong>Description</strong>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>isActive</td>
                    <td>boolean</td>
                    <td>false</td>
                    <td>Set if that contact is currently active.</td>
                </tr>
                <tr>
                    <td>device</td>
                    <td>'mobile' | 'desktop'</td>
                    <td className="text-center">-</td>
                    <td>Set the device.</td>
                </tr>
                <tr>
                    <td>avatar</td>
                    <td>
                        Object with props: <br />
                        'src': string, 'alt': string, 'text': string, 'status': "online" | "offline"
                    </td>
                    <td className="text-center">-</td>
                    <td>Set the avatar.</td>
                </tr>
                <tr>
                    <td>name</td>
                    <td>string</td>
                    <td className="text-center">-</td>
                    <td>Set the name.</td>
                </tr>
                <tr>
                    <td>searchName</td>
                    <td>string</td>
                    <td className="text-center">-</td>
                    <td>Will bold this part from 'name' prop.</td>
                </tr>
                <tr>
                    <td>messageText</td>
                    <td>
                        Object with props: <br />
                        'message': string, 'dateTime': string
                    </td>
                    <td className="text-center">-</td>
                    <td>Set the messageText.</td>
                </tr>
                <tr>
                    <td>newMessagesCount</td>
                    <td>number</td>
                    <td className="text-center">-</td>
                    <td>Will show dot with messages count.</td>
                </tr>
                <tr>
                    <td>contextMenu</td>
                    <td><Link to="/docs/context-menu">ContextMenu</Link></td>
                    <td className="text-center">-</td>
                    <td>Set the contextMenu.</td>
                </tr>
                <tr>
                    <td>onClick</td>
                    <td>() {'=>'} void</td>
                    <td className="text-center">-</td>
                    <td>Callback when is clicked.</td>
                </tr>
            </tbody>
        </table>

        <h3 id="chat-person-container" className="mb-xs">ChatPersonContainer</h3>
        <p className="mb-s"><span className="text-warning text-bold">Important:</span> ChatPersonContainer has <strong>ref</strong> which contains <strong>'containerMessagesScrollableRef'</strong> and <strong>'scrollChatPersonToBottom()'</strong>.</p>
        <p className="mb-xs"><strong>containerMessagesScrollableRef</strong>: is a ref for messages scrollable container. (Used to track of when scrolled to the top.)</p>
        <p className="mb-xs"><strong>scrollChatPersonToBottom()</strong>: method that will scroll the messages scrollable container to bottom.</p>
        <table className="doc-table mb-l">
            <thead>
                <tr>
                    <th colSpan={4}>Props</th>
                </tr>
                <tr>
                    <td>
                        <strong>Name</strong>
                    </td>
                    <td>
                        <strong>Options</strong>
                    </td>
                    <td>
                        <strong>Default</strong>
                    </td>
                    <td>
                        <strong>Description</strong>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>isExpanded</td>
                    <td>boolean</td>
                    <td>false</td>
                    <td>Set whether the ChatPersonContainer is currently expanded.</td>
                </tr>
                <tr>
                    <td>name</td>
                    <td>string</td>
                    <td className="text-center">-</td>
                    <td>Set the name.</td>
                </tr>
                <tr>
                    <td>avatar</td>
                    <td>
                        Object with props: <br />
                        'src': string, 'alt': string, 'text': string, 'status': "online" | "offline"
                    </td>
                    <td className="text-center">-</td>
                    <td>Set the avatar.</td>
                </tr>
                <tr>
                    <td>bottomSection</td>
                    <td>JSX.Element</td>
                    <td className="text-center">-</td>
                    <td>Render element for send message.</td>
                </tr>
                <tr>
                    <td>onCloseClick</td>
                    <td>() {'=>'} void</td>
                    <td className="text-center">-</td>
                    <td>Callback when close button is clicked.</td>
                </tr>
            </tbody>
        </table>

        <h3 id="chat-person-messages">ChatPersonMessages</h3>
        <table className="doc-table mb-l">
            <thead>
                <tr>
                    <th colSpan={4}>Props</th>
                </tr>
                <tr>
                    <td>
                        <strong>Name</strong>
                    </td>
                    <td>
                        <strong>Options</strong>
                    </td>
                    <td>
                        <strong>Default</strong>
                    </td>
                    <td>
                        <strong>Description</strong>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>from</td>
                    <td>'me' | 'otherPerson'</td>
                    <td className="text-center">-</td>
                    <td>Set the author of the current messages. That will style them.</td>
                </tr>
                <tr>
                    <td>avatar</td>
                    <td>
                        Object with props: <br />
                        'src': string, 'alt': string, 'text': string
                    </td>
                    <td className="text-center">-</td>
                    <td>Set the avatar.</td>
                </tr>
                <tr>
                    <td>status</td>
                    <td>
                        Object with props: <br />
                        'dateTime': string, 'type': "notDelivered" | "delivered" | "seen"
                    </td>
                    <td className="text-center">-</td>
                    <td>Display the status.</td>
                </tr>
            </tbody>
        </table>

        <h3 id="chat-message">ChatMessage</h3>
        <table className="doc-table mb-l">
            <thead>
                <tr>
                    <th colSpan={4}>Props</th>
                </tr>
                <tr>
                    <td>
                        <strong>Name</strong>
                    </td>
                    <td>
                        <strong>Options</strong>
                    </td>
                    <td>
                        <strong>Default</strong>
                    </td>
                    <td>
                        <strong>Description</strong>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>text</td>
                    <td>string</td>
                    <td className="text-center">-</td>
                    <td>Set the message text.</td>
                </tr>
                <tr>
                    <td>timestamp</td>
                    <td>string</td>
                    <td className="text-center">-</td>
                    <td>Set the timestamp. This will be set as html 'title' and shown on hover.</td>
                </tr>
                <tr>
                    <td>onClick</td>
                    <td>() {'=>'} void</td>
                    <td className="text-center">-</td>
                    <td>Callback when the message is click. This also will set icon and styles for link.</td>
                </tr>
            </tbody>
        </table>

        <h3 id="chat-date-separator">ChatDateSeparator</h3>
        <table className="doc-table mb-l">
            <thead>
                <tr>
                    <th colSpan={4}>Props</th>
                </tr>
                <tr>
                    <td>
                        <strong>Name</strong>
                    </td>
                    <td>
                        <strong>Options</strong>
                    </td>
                    <td>
                        <strong>Default</strong>
                    </td>
                    <td>
                        <strong>Description</strong>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>dateTime</td>
                    <td>string</td>
                    <td className="text-center">-</td>
                    <td>Set the dateTime.</td>
                </tr>
            </tbody>
        </table>
    </div>;
}

export default ChatPage;
