import React from 'react';

import {
    MainContainerHeader, ReactAux, ActionContainer, ActionContainerHeader, ActionContainerCrumbs,
    Button, MainContainerScrollable, DrillDownContainer, DrillDown, Card, CardSection,
    Row, Col, Label, Textarea, Tooltip,
} from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../helpers/helpers';


const cardSection = <CardSection dataUI={getDataUI()} title="Account">
    <Row>
        <Col xs={4}>
            <div className="d-flex mb-m">
                <Label dataUI={getDataUI()} className="mr-s">Account Number</Label>
                <Label dataUI={getDataUI()} className="mr-s"><a className="lv-link-styles" href="/">Criticized Indy</a></Label>
            </div>
            <div className="d-flex mb-m">
                <Label dataUI={getDataUI()} className="mr-s">Account Type</Label>
                <Label dataUI={getDataUI()}><strong>LN</strong></Label>
            </div>
            <div className="d-flex mb-m">
                <Label dataUI={getDataUI()} className="mr-s">Account Description</Label>
                <Label dataUI={getDataUI()} className="mr-s"><a className="lv-link-styles" href="/">Debt Service Ration</a></Label>
            </div>
        </Col>
        <Col xs={4}>
            <div className="d-flex mb-m">
                <Label dataUI={getDataUI()} className="mr-s">Account Name</Label>
                <Label dataUI={getDataUI()}><strong>Test Owner</strong></Label>
            </div>
            <div className="d-flex mb-m">
                <Label dataUI={getDataUI()} className="mr-s">Loan date</Label>
                <Label dataUI={getDataUI()}><strong>12/02/12</strong></Label>
            </div>
            <div className="d-flex mb-m">
                <Label dataUI={getDataUI()} className="mr-s">Current balance</Label>
                <Label dataUI={getDataUI()}><strong>46200</strong></Label>
            </div>
        </Col>
        <Col xs={4}>
            <div className="d-flex mb-m">
                <Label dataUI={getDataUI()} className="mr-s">Available balance</Label>
                <Label dataUI={getDataUI()}><strong>45000</strong></Label>
            </div>
            <div className="d-flex mb-m">
                <Label dataUI={getDataUI()} className="mr-s">Other Liens</Label>
                <Label dataUI={getDataUI()}><strong>0</strong></Label>
            </div>
            <div className="d-flex mb-m">
                <Label dataUI={getDataUI()} className="mr-s">Exposed amount</Label>
                <Label dataUI={getDataUI()}><strong>91700</strong></Label>
            </div>
        </Col>
    </Row>
</CardSection>;

const drillDowns = [
    {
        cols: ['Flood Test Collateral', '', 'Market As Is', '350,000', '', '350,000', '455,000', '462,000', '970,000'],
        content:
            <Card
                dataUI={getDataUI()}
                className="mb-s"
                actionButton={<Tooltip title="View documents">
                    <Button dataUI={getDataUI()} btnType="icon" icon="fal fa-file-alt" />
                </Tooltip>
                }
                fullWidth
            >
                {cardSection}
            </Card>,
    },
    {
        cols: ['Expanded Flood Test', '', 'Market As Is', '350,000', '', '350,000', '455,000', '462,000', '970,000'],
        content:
            <Card
                dataUI={getDataUI()}
                className="mb-s"
                actionButton={<Tooltip title="View documents">
                    <Button dataUI={getDataUI()} btnType="icon" icon="fal fa-file-alt" />
                </Tooltip>
                }
                fullWidth
            >
                {cardSection}
            </Card>
    },
    {
        cols: ['White House', '', 'Market As Is', '350,000', '', '350,000', '455,000', '462,000', '970,000'],
        content:
            <Card
                dataUI={getDataUI()}
                className="mb-s"
                actionButton={<Tooltip title="View documents">
                    <Button dataUI={getDataUI()} btnType="icon" icon="fal fa-file-alt" />
                </Tooltip>
                }
                fullWidth
            >
                {cardSection}
            </Card>
    },
    {
        cols: ['Flood Test', '', 'Market As Is', '350,000', '', '350,000', '455,000', '462,000', '970,000'],
        content:
            <Card
                dataUI={getDataUI()}
                className="mb-s"
                actionButton={<Tooltip title="View documents">
                    <Button dataUI={getDataUI()} btnType="icon" icon="fal fa-file-alt" />
                </Tooltip>
                }
                fullWidth
            >
                {cardSection}
            </Card>
    },
];

class DemoFileCollateral extends React.Component {

    state = {
        drillDownsState: [
            { isAcitve: false, expanded: false },
            { isAcitve: true, expanded: true },
            { isAcitve: false, expanded: false },
            { isAcitve: false, expanded: false },
        ],
        textareaDisabled: true,
        texareaValue: 'You can add your note here',
    }

    onExpand(index: number) {
        const { drillDownsState } = this.state;

        drillDownsState[index].isAcitve = !drillDownsState[index].isAcitve;
        drillDownsState[index].expanded = !drillDownsState[index].expanded;

        this.setState({ drillDownsState });
    }

    toggleExpandAll() {
        const { drillDownsState } = this.state;

        if (this.isAllExpanded()) {
            drillDownsState.forEach((c, i) => {
                drillDownsState[i].isAcitve = false;
                drillDownsState[i].expanded = false;
            });
        } else {
            drillDownsState.forEach((c, i) => {
                drillDownsState[i].isAcitve = true;
                drillDownsState[i].expanded = true;
            });
        }

        this.setState({ drillDownsState });
    }

    isAllExpanded() {
        const { drillDownsState } = this.state;

        for (let i = 0; i < drillDownsState.length; i++) {
            if (drillDownsState[i].expanded === false) return false;
        }

        return true;
    }

    render() {

        const { drillDownsState, textareaDisabled, texareaValue } = this.state;

        return (
            <ReactAux>
                <MainContainerHeader dataUI={getDataUI()}>
                    <ActionContainer dataUI={getDataUI()}>
                        <ActionContainerHeader dataUI={getDataUI()}>
                            <ActionContainerCrumbs dataUI={getDataUI()} crumbs={['FileCollateral']} />
                        </ActionContainerHeader>
                    </ActionContainer>
                </MainContainerHeader>

                <MainContainerScrollable>
                    <DrillDownContainer
                        dataUI={getDataUI()}
                        className="mt-m mb-m"
                        actionBtnRender={() => <Tooltip title={this.isAllExpanded() ? 'Compress' : 'Expand'}>
                            <Button dataUI={getDataUI()} btnType="icon" icon={`fal fa-${this.isAllExpanded() ? 'compress' : 'expand'}-alt`} onClick={() => this.toggleExpandAll()} />
                        </Tooltip>
                        }
                        cols={[
                            { title: 'Collateral descriotion', percentage: 13, textAlign: 'left' },
                            { title: 'Collateral ID', percentage: 8, textAlign: 'left' },
                            { title: 'Valuation Type', percentage: 11, textAlign: 'left' },
                            { title: 'Value', percentage: 8, textAlign: 'right' },
                            { title: 'Date of Valuation', percentage: 11, textAlign: 'left' },
                            { title: 'Max Allowable Security', percentage: 13, textAlign: 'right' },
                            { title: 'Current Balance', percentage: 11, textAlign: 'right' },
                            { title: 'Available Balance', percentage: 11, textAlign: 'right' },
                            { title: 'Current Loans', percentage: 11, textAlign: 'right' },
                        ]}
                        footerCols={['Totals', '', '', '', '', '', '1,820,000', '1,848,000', '3,880,000']}
                    >
                        {drillDowns.map((d, i) =>
                            <DrillDown
                                key={i}
                                cols={d.cols}
                                isActive={drillDownsState[i].isAcitve}
                                expanded={drillDownsState[i].expanded}
                                onArrowClick={() => this.onExpand(i)}
                                dataUI={i + ''}
                            >
                                {d.content}
                            </DrillDown>
                        )}
                    </DrillDownContainer>

                    <div className="d-flex mb-s">
                        <Label dataUI={getDataUI()} className="mr-s"><strong>Notes</strong></Label>
                        {textareaDisabled && <Tooltip title="Edit">
                            <Button dataUI={getDataUI()} btnType="icon" icon="fal fa-pen" onClick={() => this.setState({ textareaDisabled: false })} />
                        </Tooltip>
                        }
                        {!textareaDisabled && <>
                            <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-save" onClick={() => this.setState({ textareaDisabled: true })}>Save</Button>
                            <Button dataUI={getDataUI()} btnType="secondary" icon="fal fa-times-circle" onClick={() => this.setState({ textareaDisabled: true })}>Cancel</Button>
                        </>}
                    </div>
                    <Textarea dataUI={getDataUI()} disabled={textareaDisabled} value={texareaValue} onChange={(e) => this.setState({ texareaValue: e.target.value })} />
                </MainContainerScrollable>
            </ReactAux>
        );
    }
}

export default DemoFileCollateral;