import React from 'react';
import Highlight from 'react-highlight';
import { CardVertical, Button } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const CardVerticalPage: React.FC = () => {

    return (
        <div>
            <h1 className="mb-s">CardVertical</h1>

            <div className="d-flex">
                <CardVertical
                    className="mr-s"
                    title={"1 Year Term"}
                    contentItems={[
                        { title: 'Loan Amount', description: ['US $ 960,000.00'] },
                        { title: 'Base Payment', description: ['N/A'] },
                        { title: 'Term', description: ['36 Monthly'] },
                        { title: 'Interest Rate', description: [<strong>3.450%</strong>, "1.900%(Variable) (0.00%) 3.200%(Celling) 1.900%(Floor)"] }
                    ]}
                    actionButton={<Button dataUI={getDataUI()} btnType="secondary" icon="fal fa-check">Select Loan</Button>}
                    dataUI={getDataUI()}
                />

                <CardVertical
                    selected
                    className="mr-s"
                    title={"1 Year Term"}
                    actionButton={<Button dataUI={getDataUI()} btnType="primary" icon="fal fa-pen">Edit Selected</Button>}
                    contentItems={[
                        { title: 'Loan Amount', description: ['US $ 960,000.00'] },
                        { title: 'Base Payment', description: ['N/A'] },
                        { title: 'Term', description: ['36 Monthly'] },
                        { title: 'Interest Rate', description: ['5.000 % (Variable)', 'WSJ Prime + 5.800 %)', '5.0000 % (Ceiling)', '3.000 % (Floor)'] }
                    ]}
                    dataUI={getDataUI()}
                />

                <CardVertical
                    title={"1 Year Term"}
                    className="mr-s"
                    contentItems={[
                        { title: 'Loan Amount', description: ['US $ 960,000.00'] },
                        { title: 'Base Payment', description: ['N/A'] },
                        { title: 'Term', description: ['36 Monthly'] },
                        { title: 'Interest Rate', description: [<strong>3.450%</strong>] }
                    ]}
                    actionButton={<Button dataUI={getDataUI()} btnType="secondary" icon="fal fa-check">Select Loan</Button>}
                    dataUI={getDataUI()}
                />

                <CardVertical
                    title={"1 Year Term"}
                    actionButton={<Button dataUI={getDataUI()} btnType="secondary" icon="fal fa-check">Select Loan</Button>}
                    contentItems={[
                        { title: 'Loan Amount', description: ['US $ 960,000.00'] },
                        { title: 'Base Payment', description: ['N/A'] },
                        { title: 'Term', description: ['36 Monthly'] },
                        { title: 'Interest Rate', description: ['5.000 % (Variable)', 'WSJ Prime + 5.800 %)', '5.0000 % (Ceiling)', '3.000 % (Floor)'] }
                    ]}
                    dataUI={getDataUI()}
                />
            </div>


            <Highlight className="React">
                {
                    `
import React from 'react';
import { CardVertical,  Button } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const Example: React.FC = (props) => {
    return (
        <div className="d-flex">
        <CardVertical
            className="mr-s"
            title={"1 Year Term"}
            contentItems={[
                { title: 'Loan Amount', description: ['US $ 960,000.00'] },
                { title: 'Base Payment', description: ['N/A'] },
                { title: 'Term', description: ['36 Monthly'] },
                // Demo with long string
                { title: 'Interest Rate', description: [<strong>3.450%</strong>, "1.900%(Variable) (0.00%) 3.200%(Celling) 1.900%(Floor)"] }
            ]}
            actionButton={<Button dataUI={getDataUI()} btnType="secondary" icon="fal fa-check">Select Loan</Button>}
            dataUI={getDataUI()}
            />

        <CardVertical
            selected
            className="mr-s"
            title={"1 Year Term"}
            actionButton={<Button dataUI={getDataUI()} btnType="primary" icon="fal fa-pen">Edit Selected</Button>}
            contentItems={[
                { title: 'Loan Amount', description: ['US $ 960,000.00'] },
                { title: 'Base Payment', description: ['N/A'] },
                { title: 'Term', description: ['36 Monthly'] },
                { title: 'Interest Rate', description: ['5.000 % (Variable)', 'WSJ Prime + 5.800 %)', '5.0000 % (Ceiling)', '3.000 % (Floor)'] }
            ]}
            dataUI={getDataUI()}
            />

        <CardVertical
            title={"1 Year Term"}
            className="mr-s"
            contentItems={[
                { title: 'Loan Amount', description: ['US $ 960,000.00'] },
                { title: 'Base Payment', description: ['N/A'] },
                { title: 'Term', description: ['36 Monthly'] },
                { title: 'Interest Rate', description: [<strong>3.450%</strong>] }
            ]}
            actionButton={<Button dataUI={getDataUI()} btnType="secondary" icon="fal fa-check">Select Loan</Button>}
            dataUI={getDataUI()}
            />

        <CardVertical
            title={"1 Year Term"}
            actionButton={<Button dataUI={getDataUI()} btnType="secondary" icon="fal fa-check">Select Loan</Button>}
            contentItems={[
                { title: 'Loan Amount', description: ['US $ 960,000.00'] },
                { title: 'Base Payment', description: ['N/A'] },
                { title: 'Term', description: ['36 Monthly'] },
                { title: 'Interest Rate', description: ['5.000 % (Variable)', 'WSJ Prime + 5.800 %)', '5.0000 % (Ceiling)', '3.000 % (Floor)'] }
            ]}
            dataUI={getDataUI()}
            />
    </div>
    );
}

export default Example;
    `}
            </Highlight>

            <h2 className="mb-m">API</h2>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>title</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Sets the title of the card.</td>
                    </tr>
                    <tr>
                        <td>contentItems</td>
                        <td>
                            array of objects <br />
                            "title": string, <br />
                            "description": array of string || JSX.Element,
                        </td>
                        <td className="text-center">-</td>
                        <td>Set card info data.</td>
                    </tr>
                    <tr>
                        <td>actionButton</td>
                        <td>
                            JSX.Element
                        </td>
                        <td className="text-center">-</td>
                        <td>Set card action button. Set btnType to 'primary' when card is selected</td>
                    </tr>
                    <tr>
                        <td>selected</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Sets card selected state.</td>
                    </tr>
                </tbody>
            </table>

        </div>
    )
}

export default CardVerticalPage;
