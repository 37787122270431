import React, { useState, useEffect, useRef } from 'react';

import {
    MainContainerHeader, ReactAux, Tabs, TabList,
    TabItem, TabItemTitle, TabContent, TabContentItem,
    ActionContainer, ActionContainerHeader, ActionContainerCrumbs,
    ActionSettings, LinkWithIcon, Button, Select,
    MainContainerScrollable, Checkbox, Tooltip, Row, Col, Label, Toolbar,
    SimpleTableContainer,
    TabItemDetails,
    TabItemRow,
    Modal,
    ModalScrollableContainer,
    WizardStep,
    WizardStepsContainer,
    Radio, Input, NumericInput
} from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../helpers/helpers';


const selectOptionsLW = [
    { value: '1', label: 'BL SBA' },
];

const selectOptionsFActions = [
    { value: '1', label: 'New Money' },
    { value: '2', label: 'Renewal' },
    { value: '3', label: 'Refinance' },
];

const wizardStepsBase = [
    {
        title: '1. Loan Wizard',
        status: 'success',
        icon: 'fal fa-magic',
    },
    {
        title: '2. File',
        status: 'success',
        icon: 'fal fa-file',
    }
];

const wizardStepsMoneyRef = [
    {
        title: '3. Parties',
        status: 'success',
        icon: 'fal fa-tasks',
    },
    {
        title: '4. Collateral',
        status: 'success',
        icon: 'fal fa-building',
    },
];

const EditableCell = (props: { value?: string, align?: 'left' | 'right', focused?: boolean }) => {
    const inputWrapperRef = useRef() as any;
    const { value, align, focused } = props;

    const [cellValue, setCellValue] = useState(value || '');
    const [isEditMode, setEditMode] = useState(false);


    useEffect(() => {
        setCellValue(value || '');
    }, [value])

    useEffect(() => {
        if (focused) {
            setEditMode(true);
        }
    }, [focused]);

    useEffect(() => {
        if (isEditMode && inputWrapperRef) {
            const inputElement = inputWrapperRef.current.querySelector('input');
            inputElement.focus();
        }
    }, [isEditMode, inputWrapperRef]);

    const onInputChange = (value: any) => {
        setCellValue(value)
    }

    const cellClasses = [
        align === 'right' ? 'text-right' : '',
        !value ? 'simple-editable-cell' : ''
    ];
    return (
        <td
            className={cellClasses.join(' ').trim()}
            onClick={() => {
                if (!isEditMode) setEditMode(true);
            }}
        >
            {isEditMode ? <div ref={inputWrapperRef}> <NumericInput dataUI={getDataUI()}
                type="currency"
                onBlur={(e) => {
                    setEditMode(false)
                }}
                onChange={onInputChange}
                value={+cellValue}

            /> </div> : <span className="lv-link-styles">{cellValue ? `$ ${cellValue}` : cellValue}</span>
            }
        </td>
    )
}

const tabelChecks = [
    false,
    false,
    false
]


const DemoRenewalsAndRefinance = () => {

    const [tabIndex, setTabIndex] = useState(0)
    const [tabIndexLarge, setTabIndexLarge] = useState(0)
    const [showModal, setShowModal] = useState(false)
    const [currentStepIndex, setCurrentStepIndex] = useState(0);

    const [wizardSteps, setWizardSteps] = useState(wizardStepsBase);

    const [selectLW, setSelectLW] = useState('');
    const [selectFA, setSelecFA] = useState('');
    const [radioLw, setRadioLW] = useState([{ value: 0, checked: true }, { value: 1, checked: false }]);

    const [checks, setChecks] = useState(tabelChecks);


    const onChangeTableCheck = (value: boolean, index: number) => {
        const copyChecks = [...checks];
        copyChecks[index] = value;
        setChecks(copyChecks)
    }

    const isCheckedTableRow = () => {
        return checks.includes(true);
    }

    const previousStep = currentStepIndex === 0 ? undefined : (
        <Button dataUI={getDataUI()} icon="fal fa-arrow-circle-left"
            btnType="secondary"
            onClick={() => {
                if (currentStepIndex - 1 === 0) {
                    setSelecFA('')
                    setChecks(tabelChecks)
                }
                setCurrentStepIndex(currentStepIndex - 1)

            }}
            disabled={selectLW === '' || currentStepIndex === 0}
        >
            Previous step
        </Button>)

    const nextStep = (currentStepIndex === wizardSteps.length - 1 && currentStepIndex !== 1) ?
        undefined
        :
        selectFA !== '2' ?
            <Button
                dataUI={getDataUI()}
                className="ml-s"
                btnType="secondary"
                icon="fal fa-arrow-circle-right"
                onClick={() => {
                    setCurrentStepIndex(currentStepIndex + 1);
                    setChecks(tabelChecks)
                }}
                disabled={currentStepIndex === 0 ? selectLW === '' : selectFA === ''}
            >
                Next step
            </Button>
            : undefined
        ;

    const onSelectFA = (obj: any) => {
        setWizardSteps([
            ...wizardStepsBase,
            ...((obj.value === '1' || obj.value === '3') ? wizardStepsMoneyRef : [])
        ])
        setSelecFA(obj.value);
        setChecks(tabelChecks)
    };

    const renderFacilityDemoTable = () => {
        return (
            <>
                <Toolbar dataUI={getDataUI()} className="mt-m mb-m" leftSide={<h3>Facilities to be Renewed</h3>} rightSide={<Checkbox dataUI={getDataUI()}>Show paid-off loans</Checkbox>} />
                <SimpleTableContainer dataUI={getDataUI()} className='mb-m'>
                    <table>
                        <thead>
                            <tr>
                                <th style={{ width: '1px' }}></th>
                                <th style={{ width: '1px' }}><Button dataUI={getDataUI()} btnType="icon" icon="fal fa-minus" /></th>
                                <th>Appl Type</th>
                                <th>Loan #</th>
                                <th>Account Nam</th>
                                <th>Description</th>
                                <th style={{ width: '12%' }} className='text-right'>Curent + Avail</th>
                                <th style={{ width: '12%' }} className='text-right'>Curent Balance</th>
                                <th className='text-right' style={{ width: '12%' }}>Requested Amount</th>
                                <th className='text-right'>New Money/<br />Increase</th>
                                <th>Decrease</th>
                            </tr>

                        </thead>
                        <tbody>
                            {[1, 2, 3].map((_, i) => {
                                return (
                                    <tr key={i}>
                                        <td><Checkbox dataUI={getDataUI()} onChange={(e) => {
                                            onChangeTableCheck(e.target.checked, i);
                                        }} checked={checks[i]} /></td>
                                        <td><Button dataUI={getDataUI()} btnType="icon" icon="fal fa-minus" /></td>
                                        <td>LNCMT</td>
                                        <td>200505-001</td>
                                        <td>Joe Smith</td>
                                        <td>Commitment</td>
                                        <EditableCell value='500000.00' align='right' />
                                        <EditableCell value='500000.00' align='right' />
                                        <EditableCell align='right' focused={checks[i]} value={checks[i] ? '500000' : ''} />
                                        <td className="text-right">{checks[i] ? '$0.00' : ''}</td>
                                        <td className="text-right">{checks[i] ? '$0.00' : ''}</td>
                                    </tr>
                                )
                            })}
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td><Label dataUI={getDataUI()} className="text-bold justify-content-end text-right">$ 1 535,000.00</Label></td>
                                <td><Label dataUI={getDataUI()} className="text-bold justify-content-end text-right">$ 1 535,000.00</Label></td>
                                <td></td>
                                <td><Label dataUI={getDataUI()} className="text-bold justify-content-end text-right">$ 0.00</Label></td>
                                <td><Label dataUI={getDataUI()} className="text-bold justify-content-end text-right">$ 0.00</Label></td>
                            </tr>
                        </tbody>
                    </table>
                </SimpleTableContainer>

                <Row className="mb-s">
                    <Col xs={{ offset: 2, size: 3 }}>
                        <Label dataUI={getDataUI()} className="justify-content-end text-right">Requested Loan Amount</Label>
                    </Col>
                    <Col xs='3'>
                        <Label dataUI={getDataUI()} className="text-bold">$0.00</Label>
                    </Col>
                </Row>
                <Row className="mb-s">
                    <Col xs={{ offset: 2, size: 3 }}>
                        <Label dataUI={getDataUI()} className="justify-content-end text-right">Decrease</Label>
                    </Col>
                    <Col xs='3'>
                        <Label dataUI={getDataUI()} className="text-bold">$0.00</Label>
                    </Col>
                </Row>
                <Row className="mb-s">
                    <Col xs={{ offset: 2, size: 3 }}>
                        <Label dataUI={getDataUI()} className="justify-content-end text-right">Decrease</Label>
                    </Col>
                    <Col xs='3'>
                        <Label dataUI={getDataUI()} className="text-bold">$0.00</Label>
                    </Col>
                </Row>
                <Row className="mb-s">
                    <Col xs={{ offset: 2, size: 3 }}>
                        <Label dataUI={getDataUI()} className="justify-content-end text-right">New Money/Increase</Label>
                    </Col>
                    <Col xs='3'>
                        <Label dataUI={getDataUI()} className="text-bold">$0.00</Label>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ offset: 2, size: 3 }}>
                        <Label dataUI={getDataUI()} className="justify-content-end text-right">Total Loan Amout</Label>
                    </Col>
                    <Col xs='3'>
                        <Label dataUI={getDataUI()} className="text-bold">$0.00</Label>
                    </Col>
                </Row>
            </>
        )
    }

    const renderFacilityContent = () => {
        if (selectFA === '1') {
            return (
                <>
                    <Row className="mb-s mt-s">
                        <Col xs={{ offset: 2, size: 3 }}>
                            <Label dataUI={getDataUI()} className="justify-content-end text-right">Loan Number/Description</Label>
                        </Col>
                        <Col xs='3'>
                            <div className='d-flex'>
                                <Input dataUI={getDataUI()} className='width-full' />
                                <span className='text-danger ml-xs'>*</span>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mb-s">
                        <Col xs={{ offset: 2, size: 3 }}>
                            <Label dataUI={getDataUI()} className="justify-content-end text-right">Facility Type</Label>
                        </Col>
                        <Col xs='3'>
                            <div className='d-flex'>
                                <Select
                                    dataUI={getDataUI()}
                                    className='width-full'
                                    options={[
                                        { value: '1', label: 'Note' },
                                    ]}
                                    value={'1'}
                                    placeholder="Select..."
                                />
                                <span className='text-danger ml-xs'>*</span>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mb-s">
                        <Col xs={{ offset: 2, size: 3 }}>
                            <Label dataUI={getDataUI()} className="justify-content-end text-right">Requested Loan Amount</Label>
                        </Col>
                        <Col xs='3'>
                            <div className='d-flex'>
                                <NumericInput dataUI={getDataUI()} type="currency" className='width-full' />
                                <span className='text-danger ml-xs'>*</span>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mb-s">
                        <Col xs={{ offset: 2, size: 3 }}>
                            <Label dataUI={getDataUI()} className="justify-content-end text-right">New Money/Increase</Label>
                        </Col>
                        <Col xs='3'>
                            <div className='d-flex'>
                                <Label dataUI={getDataUI()} className="justify-content-end text-right text-bold">$ 0.00</Label>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{ offset: 2, size: 3 }}>
                            <Label dataUI={getDataUI()} className="justify-content-end text-right">Total Loan Amout</Label>
                        </Col>
                        <Col xs='3'>
                            <div className='d-flex'>
                                <Label dataUI={getDataUI()} className="justify-content-end text-right text-bold">$ 0.00</Label>
                            </div>
                        </Col>
                    </Row>
                </>
            )
        } else if (selectFA === '2') {
            return (
                <>
                    {renderFacilityDemoTable()}
                </>
            )
        } else if (selectFA === '3') {
            return (<>
                <Row className="mb-s mt-s">
                    <Col xs={{ offset: 2, size: 3 }}>
                        <Label dataUI={getDataUI()} className="justify-content-end text-right">Loan Number/Description</Label>
                    </Col>
                    <Col xs='3'>
                        <div className='d-flex'>
                            <Input dataUI={getDataUI()} className='width-full' />
                            <span className='text-danger ml-xs'>*</span>
                        </div>
                    </Col>
                </Row>
                <Row className="mb-s">
                    <Col xs={{ offset: 2, size: 3 }}>
                        <Label dataUI={getDataUI()} className="justify-content-end text-right">Facility Type</Label>
                    </Col>
                    <Col xs='3'>
                        <div className='d-flex'>
                            <Select
                                dataUI={getDataUI()}
                                className='width-full'
                                options={[
                                    { value: '1', label: 'Note' },
                                ]}
                                value={'1'}
                                placeholder="Select..."
                            />
                            <span className='text-danger ml-xs'>*</span>
                        </div>
                    </Col>
                </Row>
                {renderFacilityDemoTable()}
            </>)
        }

        return null
    }

    const renderColateralContent = () => {
        if (selectFA === '1' || selectFA === '3') {
            return (
                <div className='section-container mt-m'>
                    <h2>Collateral</h2>
                    <hr className='mt-m mb-m' />
                    <Toolbar dataUI={getDataUI()} className='mb-m' leftSide={<Button dataUI={getDataUI()} btnType='secondary' icon="fal fa-plus">Add New Collateral</Button>} />
                    <SimpleTableContainer dataUI={getDataUI()}>
                        <table>
                            <thead>
                                <tr>
                                    <th style={{ width: '1px' }}><Checkbox dataUI={getDataUI()} /></th>
                                    <th>Description</th>
                                    <th>Type</th>
                                    <th style={{ width: '1px' }} className='text-right'>Value</th>
                                    <th>Valuation Type</th>
                                </tr>

                            </thead>
                            <tbody>
                                <tr>
                                    <td><Checkbox dataUI={getDataUI()} /></td>
                                    <td>2020 BMW 4 Series</td>
                                    <td>Motor Vehicle-Personal</td>
                                    <td className="text-right white-space-nowrap">$ 46,700</td>
                                    <td>Market As Is</td>
                                </tr>
                                <tr>
                                    <td><Checkbox dataUI={getDataUI()} /></td>
                                    <td>2020 Alfa Romeo Stelvio</td>
                                    <td>Motor Vehicle-Personal</td>
                                    <td className="text-right white-space-nowrap">09/06/2021 12:17 PM</td>
                                    <td>Market As Is</td>
                                </tr>
                            </tbody>
                        </table>
                    </SimpleTableContainer>
                </div>
            )
        }


        return null
    }

    return (
        <ReactAux>
            <MainContainerHeader dataUI={getDataUI()} className='mb-m'>
                <ActionContainer dataUI={getDataUI()}>
                    <ActionContainerHeader dataUI={getDataUI()}>
                        <ActionContainerCrumbs dataUI={getDataUI()} crumbs={['Relationship File Chloe & Bella Cosmetics (File 14641)']} />
                        <ActionSettings dataUI={getDataUI()} isActive={true}>
                            <LinkWithIcon dataUI={getDataUI()} icon="fal fa-eye"><a onClick={(e) => { e.preventDefault() }} href="/" title=''>Relationship Fil Fields Default</a></LinkWithIcon>
                            <LinkWithIcon dataUI={getDataUI()} icon="fal fa-ban"><a onClick={(e) => { e.preventDefault() }} href="/" title=''>Restricted Balances</a></LinkWithIcon>
                        </ActionSettings>
                    </ActionContainerHeader>
                    <Button dataUI={getDataUI()} icon="fal fa-pen">Edit</Button>
                    <Button dataUI={getDataUI()} btnType="secondary" icon="fal fa-sticky-note">Notes</Button>
                    <Button dataUI={getDataUI()} btnType="secondary">Guidance Line $0.00</Button>
                </ActionContainer>
            </MainContainerHeader>
            <MainContainerScrollable>
                <Row>
                    <Col xs='4'>
                        <Row className='mb-s'>
                            <Col xs="6"><Label dataUI={getDataUI()} className='justify-content-end text-right'>Full Name</Label></Col>
                            <Col xs="6"><Label dataUI={getDataUI()} className='text-bold'>Chloe {'&'} Bella Cosmetics</Label></Col>
                        </Row>
                        <Row className='mb-s' >
                            <Col xs="6"><Label dataUI={getDataUI()} className='justify-content-end text-right'>Industry</Label></Col>
                            <Col xs="6"><Label dataUI={getDataUI()} className='text-bold'></Label></Col>
                        </Row>
                        <Row className='mb-s'>
                            <Col xs="6"><Label dataUI={getDataUI()} className='justify-content-end text-right'>Officer</Label></Col>
                            <Col xs="6"><Label dataUI={getDataUI()} className='text-bold'></Label></Col>
                        </Row>
                        <Row className='mb-s'>
                            <Col xs="6"><Label dataUI={getDataUI()} className='justify-content-end text-right'>Officer 6</Label></Col>
                            <Col xs="6"><Label dataUI={getDataUI()} className='text-bold'></Label></Col>
                        </Row>
                        <Row className='mb-s'>
                            <Col xs="6"><Label dataUI={getDataUI()} className='justify-content-end text-right'>Loan Assistant</Label></Col>
                            <Col xs="6"><Label dataUI={getDataUI()} className='text-bold'></Label></Col>
                        </Row>
                        <Row className='mb-s'>
                            <Col xs="6"><Label dataUI={getDataUI()} className='justify-content-end text-right'>Processor</Label></Col>
                            <Col xs="6"><Label dataUI={getDataUI()} className='text-bold'></Label></Col>
                        </Row>
                        <Row className='mb-s'>
                            <Col xs="6"><Label dataUI={getDataUI()} className='justify-content-end text-right'>Portfolio Manager</Label></Col>
                            <Col xs="6"><Label dataUI={getDataUI()} className='text-bold'></Label></Col>
                        </Row>
                        <Row className='mb-s'>
                            <Col xs="6"><Label dataUI={getDataUI()} className='justify-content-end text-right'>Analyst</Label></Col>
                            <Col xs="6"><Label dataUI={getDataUI()} className='text-bold'>Barbara</Label></Col>
                        </Row>
                        <Row className='mb-s'>
                            <Col xs="6"><Label dataUI={getDataUI()} className='justify-content-end text-right'>Branch</Label></Col>
                            <Col xs="6"><Label dataUI={getDataUI()} className='text-bold'></Label></Col>
                        </Row>
                        <Row>
                            <Col xs="6"><Label dataUI={getDataUI()} className='justify-content-end text-right'>Region</Label></Col>
                            <Col xs="6"><Label dataUI={getDataUI()} className='text-bold'></Label></Col>
                        </Row>
                    </Col>
                    <Col xs='2'>
                        <Row className='mb-s'>
                            <Col xs="6"><Label dataUI={getDataUI()} className='justify-content-end text-right'>Status</Label></Col>
                            <Col xs="6"><Label dataUI={getDataUI()} className='text-bold'>Active</Label></Col>
                        </Row>
                        <Row className='mb-s'>
                            <Col xs="6"><Label dataUI={getDataUI()} className='justify-content-end text-right'>Rating</Label></Col>
                            <Col xs="6"><Label dataUI={getDataUI()} className='text-bold'></Label></Col>
                        </Row>
                        <Row className='mb-s'>
                            <Col xs="6"><Label dataUI={getDataUI()} className='justify-content-end text-right'>Trend</Label></Col>
                            <Col xs="6"><Label dataUI={getDataUI()} className='text-bold'></Label></Col>
                        </Row>
                        <Row className='mb-s'>
                            <Col xs="6"><Label dataUI={getDataUI()} className='justify-content-end text-right'>Last Review</Label></Col>
                            <Col xs="6"><Label dataUI={getDataUI()} className='text-bold'></Label></Col>
                        </Row>
                        <Row>
                            <Col xs="6"><Label dataUI={getDataUI()} className='justify-content-end text-right'>Next Review</Label></Col>
                            <Col xs="6"><Label dataUI={getDataUI()} className='text-bold'></Label></Col>
                        </Row>
                    </Col>
                    <Col xs='6'>

                        <Tabs
                            dataUI={getDataUI()}
                            onTabIndexChange={(index: number) => setTabIndex(index)}
                            currentlyActiveTabIndex={tabIndex}
                        >
                            <TabList dataUI={getDataUI()}>
                                <TabItem dataUI={getDataUI()}>
                                    <TabItemTitle dataUI={getDataUI()}>Loan Request</TabItemTitle>
                                </TabItem>
                                <TabItem dataUI={getDataUI()}>
                                    <TabItemTitle dataUI={getDataUI()}>Annual Review</TabItemTitle>
                                </TabItem>
                                <TabItem dataUI={getDataUI()}>
                                    <TabItemTitle dataUI={getDataUI()}>WAM</TabItemTitle>
                                </TabItem>
                                <TabItem dataUI={getDataUI()}>
                                    <TabItemTitle dataUI={getDataUI()}>CAR</TabItemTitle>
                                </TabItem>
                                <TabItem dataUI={getDataUI()}>
                                    <TabItemTitle dataUI={getDataUI()}>Service Request</TabItemTitle>
                                </TabItem>
                            </TabList>
                            <TabContent dataUI={getDataUI()}>
                                <TabContentItem dataUI={getDataUI()}>
                                    <Toolbar dataUI={getDataUI()}
                                        leftSide={<Button dataUI={getDataUI()} onClick={() => setShowModal(true)} icon="fal fa-plus" btnType="secondary">New Loan Request</Button>}
                                    />

                                    <SimpleTableContainer dataUI={getDataUI()}>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th style={{ width: '1px' }}></th>
                                                    <th>Date</th>
                                                    <th>Request</th>
                                                    <th>Status</th>
                                                    <th>Borrower</th>
                                                    <th>File</th>
                                                    <th>Business Line</th>
                                                </tr>

                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <Tooltip title="Invoice">
                                                            <Button dataUI={getDataUI()} btnType="icon" icon="fal fa-file-invoice-dollar" />
                                                        </Tooltip>
                                                    </td>
                                                    <td className="text-right">02/25/04</td>
                                                    <td className="text-right">$1</td>
                                                    <td></td>
                                                    <td>Test Personal AG</td>
                                                    <td className="text-right">45606</td>
                                                    <td className="text-right">C{'&'}I</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Tooltip title="Invoice">
                                                            <Button dataUI={getDataUI()} btnType="icon" icon="fal fa-file-invoice-dollar" />
                                                        </Tooltip>
                                                    </td>
                                                    <td className="text-right">02/25/04</td>
                                                    <td className="text-right">$1</td>
                                                    <td>Analysis</td>
                                                    <td>Business Owner</td>
                                                    <td className="text-right">45606</td>
                                                    <td className="text-right"></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </SimpleTableContainer>
                                </TabContentItem>
                                <TabContentItem dataUI={getDataUI()}>Annual Review tab content</TabContentItem>
                                <TabContentItem dataUI={getDataUI()}>WAM Information tab content</TabContentItem>
                                <TabContentItem dataUI={getDataUI()}>CAR tab content</TabContentItem>
                                <TabContentItem dataUI={getDataUI()}>Service Request tab content</TabContentItem>
                            </TabContent>
                        </Tabs>
                    </Col>
                </Row>

                <hr className='mt-m mb-m' />

                <Tabs
                    largeTabs onTabIndexChange={(index: number) => setTabIndexLarge(index)}
                    currentlyActiveTabIndex={tabIndexLarge}
                    dataUI={getDataUI()}
                >
                    <TabList dataUI={getDataUI()}>
                        <TabItem isSummaryTabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>Exposure</TabItemTitle>
                            <TabItemDetails dataUI={getDataUI()}>
                                <TabItemRow dataUI={getDataUI()} leftCol="Current Exposure:" rightCol="$2,250,000"></TabItemRow>
                                <TabItemRow dataUI={getDataUI()} leftCol="Plus Pending Approvals:" rightCol="$0"></TabItemRow>
                                <TabItemRow dataUI={getDataUI()} leftCol="Total Proposed Exposure:" rightCol="$2,250,000"></TabItemRow>
                            </TabItemDetails>
                        </TabItem>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()} icon={
                                <Tooltip title="File">
                                    <Button dataUI={getDataUI()} btnType="icon" icon="fal fa-file-alt"
                                        onClick={(e) => { e.stopPropagation(); }} />
                                </Tooltip>
                            }
                            >
                                Loans
                            </TabItemTitle>
                            <TabItemDetails dataUI={getDataUI()}>
                                <TabItemRow dataUI={getDataUI()} leftCol="Used:" rightCol="123456"></TabItemRow>
                                <TabItemRow dataUI={getDataUI()} leftCol="Available:" rightCol="123456"></TabItemRow>
                                <TabItemRow dataUI={getDataUI()} leftCol="Exposure:" rightCol="123456"></TabItemRow>
                            </TabItemDetails>
                        </TabItem>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>Deposits</TabItemTitle>
                            <TabItemDetails dataUI={getDataUI()}>
                                <TabItemRow dataUI={getDataUI()} leftCol="Curr:" rightCol="$0"></TabItemRow>
                                <TabItemRow dataUI={getDataUI()} leftCol="Avg:" rightCol="$0"></TabItemRow>
                                <TabItemRow dataUI={getDataUI()} leftCol="Exp:" rightCol="$0"></TabItemRow>
                            </TabItemDetails>
                        </TabItem>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>Other products</TabItemTitle>
                            <TabItemDetails dataUI={getDataUI()}>
                                <TabItemRow dataUI={getDataUI()} leftCol="Curr Bal:" rightCol="$0"></TabItemRow>
                                <TabItemRow dataUI={getDataUI()} leftCol="Exposure:" rightCol="$0"></TabItemRow>
                            </TabItemDetails>
                        </TabItem>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>Details</TabItemTitle>
                        </TabItem>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>Parties</TabItemTitle>
                        </TabItem>
                    </TabList>
                    <TabContent dataUI={getDataUI()}>
                        <TabContentItem dataUI={getDataUI()}>
                            <Toolbar dataUI={getDataUI()}
                                className='mb-s'
                                leftSide={
                                    <LinkWithIcon dataUI={getDataUI()} icon='fal fa-sync'><a onClick={(e) => { e.preventDefault() }} href="/" title=''>Recalculate Exposures</a></LinkWithIcon>
                                }
                                rightSide={
                                    <Checkbox dataUI={getDataUI()}>Show paid-off loans</Checkbox>
                                }
                            />
                            <SimpleTableContainer dataUI={getDataUI()} className='mb-m'>
                                <table>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '1px' }}>
                                                <Tooltip title="Add">
                                                    <Button dataUI={getDataUI()} btnType="icon" icon="fal fa-plus" />
                                                </Tooltip>
                                            </th>
                                            <th>Appl Type</th>
                                            <th style={{ width: '1px' }}></th>
                                            <th>Loan #</th>
                                            <th>Type</th>
                                            <th>Desc</th>
                                            <th style={{ width: '1px' }}>Paid Off Loan</th>
                                            <th style={{ width: '1px' }}>Letter Of Credit</th>
                                            <th>Account Name</th>
                                            <th className='text-right' style={{ width: '1px' }}>Total Exposure</th>
                                            <th className='text-right' style={{ width: '1px' }}>Commit/Note Amount</th>
                                            <th>Used</th>
                                            <th className='text-right' style={{ width: '1px' }}>Maturity/Expiration Date</th>
                                            <th>Rating</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <Tooltip title="Add">
                                                    <Button dataUI={getDataUI()} btnType="icon" icon="fal fa-plus" />
                                                </Tooltip>
                                            </td>
                                            <td>LNCMT</td>
                                            <td>
                                                <Tooltip title="File">
                                                    <Button dataUI={getDataUI()} btnType="icon" icon="fal fa-file" />
                                                </Tooltip>
                                            </td>
                                            <td className="text-right text-no-break">
                                                <span className='lv-link-styles'>200505-001</span>
                                            </td>
                                            <td>JRC</td>
                                            <td>Credit Card</td>
                                            <td><Checkbox dataUI={getDataUI()} /></td>
                                            <td><Checkbox dataUI={getDataUI()} /></td>
                                            <td><span className='lv-link-styles'>Kathryn Gallagher</span></td>
                                            <td className='text-right'>967,000</td>
                                            <td className='text-right'>967,000</td>
                                            <td className='text-right'>967,000</td>
                                            <td className='text-right'>02/25/04</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Tooltip title="Add">
                                                    <Button dataUI={getDataUI()} btnType="icon" icon="fal fa-plus" />
                                                </Tooltip>
                                            </td>
                                            <td>LNCMT</td>
                                            <td>
                                                <Tooltip title="File">
                                                    <Button dataUI={getDataUI()} btnType="icon" icon="fal fa-file" />
                                                </Tooltip>
                                            </td>
                                            <td className="text-right text-no-break">
                                                <span className='lv-link-styles'>200505-001</span>
                                            </td>
                                            <td>JRC</td>
                                            <td>Credit Card</td>
                                            <td><Checkbox dataUI={getDataUI()} /></td>
                                            <td><Checkbox dataUI={getDataUI()} /></td>
                                            <td><span className='lv-link-styles'>Kathryn Gallagher</span></td>
                                            <td className='text-right'>967,000</td>
                                            <td className='text-right'>967,000</td>
                                            <td className='text-right'>967,000</td>
                                            <td className='text-right'>02/25/04</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Tooltip title="Add">
                                                    <Button dataUI={getDataUI()} btnType="icon" icon="fal fa-plus" />
                                                </Tooltip>
                                            </td>
                                            <td>LNCMT</td>
                                            <td>
                                                <Tooltip title="File">
                                                    <Button dataUI={getDataUI()} btnType="icon" icon="fal fa-file" />
                                                </Tooltip>
                                            </td>
                                            <td className="text-right text-no-break">
                                                <span className='lv-link-styles'>200505-001</span>
                                            </td>
                                            <td>JRC</td>
                                            <td>Credit Card</td>
                                            <td><Checkbox dataUI={getDataUI()} /></td>
                                            <td><Checkbox dataUI={getDataUI()} /></td>
                                            <td><span className='lv-link-styles'>Kathryn Gallagher</span></td>
                                            <td className='text-right'>967,000</td>
                                            <td className='text-right'>967,000</td>
                                            <td className='text-right'>967,000</td>
                                            <td className='text-right'>02/25/04</td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </SimpleTableContainer>

                            <div className='section-container'>
                                <Toolbar dataUI={getDataUI()}
                                    className='mb-m'
                                    leftSide={
                                        <LinkWithIcon dataUI={getDataUI()} icon='fal fa-pen'><a onClick={(e) => { e.preventDefault() }} href="/" title=''>Comments</a></LinkWithIcon>
                                    }
                                />
                                <p>Your comment here.</p>
                            </div>
                        </TabContentItem>
                        <TabContentItem dataUI={getDataUI()}>Deposits tab content</TabContentItem>
                        <TabContentItem dataUI={getDataUI()}>Checklist content</TabContentItem>
                        <TabContentItem dataUI={getDataUI()}>Other products tab content</TabContentItem>
                        <TabContentItem dataUI={getDataUI()}>Parties tab content</TabContentItem>
                    </TabContent>
                </Tabs>
            </MainContainerScrollable >
            <Modal dataUI={getDataUI()} title="New Loan Request" isVisible={showModal} size="L" onClose={() => setShowModal(false)}>
                <WizardStepsContainer
                    dataUI={getDataUI()}
                    currentStepIndex={currentStepIndex}>
                    {
                        wizardSteps.map(((step, i) => (
                            <WizardStep dataUI={getDataUI()} key={i} disabled={i === 1 && selectLW === ''} title={step.title} status={step.status as any} icon={step.icon} onClick={() => setCurrentStepIndex(i)} />
                        )))
                    }
                </WizardStepsContainer>
                <Toolbar dataUI={getDataUI()}
                    leftSide={
                        <>
                            {previousStep}
                            {nextStep}
                            {currentStepIndex === wizardSteps.length - 1 && selectFA !== '' && <Button dataUI={getDataUI()} disabled={!isCheckedTableRow()} className="ml-s" icon="fal fa-plus-circle">Create Loan Request</Button>}
                        </>}
                />
                <ModalScrollableContainer>
                    {currentStepIndex === 0 &&
                        <>
                            <Row className="mb-s mt-m">
                                <Col xs={{ offset: 2, size: 3 }}>
                                    <Label dataUI={getDataUI()} className="justify-content-end text-right">Wizard Type</Label>
                                </Col>
                                <Col xs='3'>
                                    <div className='d-flex justify-content-between'>
                                        <Radio dataUI={getDataUI()} name="radio-lw" checked={radioLw[0].checked} onChange={() => { setRadioLW([{ value: 0, checked: true }, { value: 1, checked: false }]) }} value={radioLw[0].value}>Commercial</Radio>
                                        <Radio dataUI={getDataUI()} name="radio-lw" checked={radioLw[1].checked} onChange={() => { setRadioLW([{ value: 0, checked: false }, { value: 1, checked: true }]) }} value={radioLw[1].value}>Consumer</Radio>
                                        <span className='text-danger ml-xs'>*</span>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={{ offset: 2, size: 3 }} >
                                    <Label dataUI={getDataUI()} className="justify-content-end text-right">Wizard</Label></Col>
                                <Col xs='3'>
                                    <div className='d-flex'>
                                        <Select
                                            dataUI={getDataUI()}
                                            className='width-full'
                                            options={selectOptionsLW}
                                            value={selectLW}
                                            placeholder="Select..."
                                            onChange={(obj: any) => {
                                                setSelectLW(obj.value);
                                            }}
                                        />
                                        <span className='text-danger ml-xs'>*</span>
                                    </div>
                                </Col>
                            </Row>
                        </>
                    }

                    {
                        currentStepIndex === 1 && (
                            <>
                                <div className='section-container mb-m mt-m'>
                                    <h2>File</h2>
                                    <hr className='mt-m mb-m' />

                                    <Row className="mb-s mt-m">
                                        <Col xs={{ offset: 2, size: 3 }}>
                                            <Label dataUI={getDataUI()} className="justify-content-end text-right">File Name</Label>
                                        </Col>
                                        <Col xs='3'>
                                            <div className='d-flex justify-content-between'>
                                                <Input dataUI={getDataUI()} className='width-full' />
                                                <span className='text-danger ml-xs'>*</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mb-s">
                                        <Col xs={{ offset: 2, size: 3 }} >
                                            <Label dataUI={getDataUI()} className="justify-content-end text-right">File Status</Label></Col>
                                        <Col xs='3'>
                                            <div className='d-flex'>
                                                <Select
                                                    dataUI={getDataUI()}
                                                    className='width-full'
                                                    options={[
                                                        { value: '1', label: 'Analysis' },
                                                    ]}
                                                    value={'1'}
                                                    placeholder="Select..."
                                                />
                                                <span className='text-danger ml-xs'>*</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mb-s">
                                        <Col xs={{ offset: 2, size: 3 }}>
                                            <Label dataUI={getDataUI()} className="justify-content-end text-right">Officer</Label>
                                        </Col>
                                        <Col xs='3'>
                                            <div className='d-flex'>
                                                <Input dataUI={getDataUI()} className='width-full' />
                                                <span className='text-danger ml-xs'>*</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mb-s">
                                        <Col xs={{ offset: 2, size: 3 }}>
                                            <Label dataUI={getDataUI()} className="justify-content-end text-right">Processor</Label>
                                        </Col>
                                        <Col xs='3'>
                                            <div className='d-flex'>
                                                <Select
                                                    dataUI={getDataUI()}
                                                    className='width-full'
                                                    options={[]}
                                                    value={''}
                                                    placeholder=""
                                                />
                                                <span className='text-danger ml-xs'>*</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mb-s">
                                        <Col xs={{ offset: 2, size: 3 }}>
                                            <Label dataUI={getDataUI()} className="justify-content-end text-right">Business Line</Label>
                                        </Col>
                                        <Col xs='3'>
                                            <div className='d-flex'>
                                                <Select
                                                    dataUI={getDataUI()}
                                                    className='width-full'
                                                    options={[]}
                                                    value={''}
                                                    placeholder=""
                                                />
                                                <span className='text-danger ml-xs'>*</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mb-s">
                                        <Col xs={{ offset: 2, size: 3 }}>
                                            <Label dataUI={getDataUI()} className="justify-content-end text-right">Branch</Label>
                                        </Col>
                                        <Col xs='3'>
                                            <div className='d-flex'>
                                                <Select
                                                    dataUI={getDataUI()}
                                                    className='width-full'
                                                    options={[]}
                                                    value={''}
                                                    placeholder=""
                                                />
                                                <span className='text-danger ml-xs'>*</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={{ offset: 2, size: 3 }}>
                                            <Label dataUI={getDataUI()} className="justify-content-end text-right">Create Date</Label>
                                        </Col>
                                        <Col xs='3'></Col>
                                    </Row>
                                </div>

                                <div className='section-container'>
                                    <h2>Facility</h2>
                                    <hr className='mt-m mb-m' />

                                    <Row>
                                        <Col xs={{ offset: 2, size: 3 }} >
                                            <Label dataUI={getDataUI()} className="justify-content-end text-right">Action</Label></Col>
                                        <Col xs='3'>
                                            <div className='d-flex'>
                                                <Select
                                                    dataUI={getDataUI()}
                                                    className='width-full'
                                                    options={selectOptionsFActions}
                                                    value={selectFA}
                                                    placeholder=""
                                                    onChange={onSelectFA}
                                                />
                                                <span className='text-danger ml-xs'>*</span>
                                            </div>
                                        </Col>
                                    </Row>

                                    {renderFacilityContent()}
                                </div>

                            </>
                        )
                    }
                    {
                        currentStepIndex === 2 && <h2 className='mt-m'>Parties content</h2>
                    }
                    {
                        currentStepIndex === 3 && renderColateralContent()
                    }
                </ModalScrollableContainer>
            </Modal>
        </ReactAux >
    );
}


export default DemoRenewalsAndRefinance;