import React from 'react';
import Highlight from 'react-highlight';

import { Button, ProgressBar } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

class ProgressBarPage extends React.Component {

    state = {
        showProgress: false,
        currentStep: 0
    };

    toggleProgressBar() {
        this.setState({
            showProgress: !this.state.showProgress
        });
    }

    addStep() {
        this.setState({
            currentStep: this.state.currentStep + 1
        })
    }

    render() {
        return (
            <div>
                <h1 className="mb-s">Progress Bar</h1>

                <p className="mb-l">ProgressBar can be used to show the level of completion of a given process.</p>

                <div>
                    <Button
                        dataUI={getDataUI()}
                        className="mb-xl"
                        btnType="secondary"
                        icon="fal fa-dollar-sign"
                        style={{ zIndex: 101, position: 'relative' }}
                        onClick={() => { this.toggleProgressBar() }}
                    >
                        Toggle Progress Bar
                    </Button>
                    <Button
                        dataUI={getDataUI()}
                        className="mb-xl ml-m"
                        btnType="secondary"
                        icon="fal fa-dollar-sign"
                        style={{ zIndex: 101, position: 'relative' }}
                        onClick={() => { this.addStep() }}
                    >
                        Add Step
                    </Button>
                    <Button
                        dataUI={getDataUI()}
                        className="mb-xl ml-m"
                        btnType="secondary"
                        icon="fal fa-dollar-sign"
                        style={{ zIndex: 101, position: 'relative' }}
                        onClick={() => { this.setState({ currentStep: 0 }) }}
                    >
                        Reset
                    </Button>

                    <ProgressBar
                        dataUI={getDataUI()}
                        isVisible={this.state.showProgress}
                        steps={4}
                        currentStep={this.state.currentStep} />
                </div>

                <Highlight className="React">
                    {
                        `
import React from 'react';
import { Button, ProgressBar } from '@jkhy/vsg-loanvantage-design-system';
 
class Example extends React.Component {
    
    state = {
        showProgress: false,
        currentStep: 0
    };

    toggleProgressBar() {
        this.setState({
            showProgress: !this.state.showProgress
        });
    }

    addStep() {
        this.setState({
            currentStep: this.state.currentStep + 1
        })
    }

    render(){
        return(
            <div>
                // Buttons is for demo only
                <Button
                    dataUI={getDataUI()} onClick={() => { this.toggleProgressBar() }}>Toggle Progress Bar</Button>
                <Button
                    dataUI={getDataUI()} onClick={() => { this.addStep() }}>Add Step</Button>
                <Button
                    dataUI={getDataUI()} onClick={() => { this.setState({ currentStep: 0 }) }}>Reset</Button>

                <ProgressBar
                    dataUI={getDataUI()}
                    isVisible={this.state.showProgress}
                    steps={4}
                    currentStep={this.state.currentStep} />
            </div>
        )
    }
}

export default Example; 
    `
                    }
                </Highlight>

                <h2 className="mb-m">API</h2>
                <table className="doc-table mb-l">
                    <thead>
                        <tr>
                            <th colSpan={4}>Props</th>
                        </tr>
                        <tr>
                            <td>
                                <strong>Name</strong>
                            </td>
                            <td>
                                <strong>Options</strong>
                            </td>
                            <td>
                                <strong>Default</strong>
                            </td>
                            <td>
                                <strong>Description</strong>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>isVisible</td>
                            <td>boolean</td>
                            <td>false</td>
                            <td>Sets progress bar visibility.</td>
                        </tr>
                        <tr>
                            <td>steps</td>
                            <td>number</td>
                            <td className="text-center">-</td>
                            <td>Sets progress bar total steps.</td>
                        </tr>
                        <tr>
                            <td>currentStep</td>
                            <td>number</td>
                            <td className="text-center">-</td>
                            <td>Determines the current step.</td>
                        </tr>
                        <tr>
                            <td>title</td>
                            <td>string</td>
                            <td>'Please wait...'</td>
                            <td>Sets title to the progress bar.</td>
                        </tr>
                        <tr>
                            <td>description</td>
                            <td>string</td>
                            <td>'Please wait while we process your request. This may take a few minutes.'</td>
                            <td>Sets description to the progress bar.</td>
                        </tr>
                    </tbody>
                </table>

            </div>
        )
    }
}

export default ProgressBarPage;