import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Highlight from 'react-highlight';
import { CardList, CardListItem, Row, Col, Button, ButtonGroup } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const CardListPage: React.FC = () => {
    const [showAll, setShowAll] = useState(false);
    return (
        <div>
            <h1 className="mb-s">CardList</h1>
            <ButtonGroup className='d-flex justify-content-end mb-m' dataUI={getDataUI()}>
                <Button btnType={!showAll ? 'primary' : 'secondary'} onClick={() => setShowAll(false)} dataUI={getDataUI()}>My Admin</Button>
                <Button btnType={showAll ? 'primary' : 'secondary'} onClick={() => setShowAll(true)} dataUI={getDataUI()}>All</Button>
            </ButtonGroup>
            <div className="mb-m">
                <Row>
                    <Col>
                        {/* First demo CardList show that: 
                                     1. If you pass to CardListItem as child text only. Set onClick to CardListItem. 
                                     2. If you need some "span", "a" or "router link" as child to CardListItem, set onClick to your element.
                        */}
                        <CardList colSpacing cardTitle='Users Access' icon="fal fa-user-shield" className="mb-xxl" muted dataUI={getDataUI()}>
                            <CardListItem muted onClick={() => console.log('click li')} dataUI={getDataUI()}>Authentication</CardListItem>
                            <CardListItem muted dataUI={getDataUI()}><span onClick={() => console.log('click span')}>Roles</span></CardListItem>
                            <CardListItem muted dataUI={getDataUI()}><a onClick={(e) => { e.preventDefault(); console.log('click a') }} href='/' title="test">Teams</a></CardListItem>
                            <CardListItem muted dataUI={getDataUI()}><Link to='/'>Users</Link></CardListItem>
                            <CardListItem muted onClick={() => console.log('click li')} dataUI={getDataUI()}>User Devices (Devices)</CardListItem>
                        </CardList>
                    </Col>

                    <Col>
                        <CardList colSpacing cardTitle='FIle Management' icon="fal fa-folder-tree" active dataUI={getDataUI()}>
                            <CardListItem muted onClick={() => console.log('click item')} dataUI={getDataUI()}>Process Definitions</CardListItem>
                            <CardListItem muted onClick={() => console.log('click item')} dataUI={getDataUI()}>Loan Wizard</CardListItem>
                            <CardListItem muted onClick={() => console.log('click item')} dataUI={getDataUI()}>Report Definition</CardListItem>
                            <CardListItem muted onClick={() => console.log('click item')} dataUI={getDataUI()}>HTML Narrative</CardListItem>
                            <CardListItem muted onClick={() => console.log('click item')} dataUI={getDataUI()}>Interest Rate Matrix</CardListItem>
                            <CardListItem active onClick={() => console.log('click item')} dataUI={getDataUI()}>Score Cards</CardListItem>
                            <CardListItem muted onClick={() => console.log('click item')} dataUI={getDataUI()}>Declarations (Questions)</CardListItem>
                            <CardListItem muted onClick={() => console.log('click item')} dataUI={getDataUI()}>Credit Decision Ratios</CardListItem>
                            <CardListItem muted onClick={() => console.log('click item')} dataUI={getDataUI()}>Fee Schedules</CardListItem>
                            <CardListItem muted onClick={() => console.log('click item')} dataUI={getDataUI()}>Rate Exceptions</CardListItem>
                            <CardListItem muted onClick={() => console.log('click item')} dataUI={getDataUI()}>Loan Review Automated Setup</CardListItem>
                        </CardList>
                    </Col>
                    <Col>
                        <CardList colSpacing cardTitle='Tracking Items' icon="fal fa-map-marked-alt" className="mb-xxl" dataUI={getDataUI()}>
                            <CardListItem onClick={() => console.log('click item')} dataUI={getDataUI()}>Tracking Settings </CardListItem>
                            <CardListItem onClick={() => console.log('click item')} dataUI={getDataUI()}>Prerequisite Conditions</CardListItem>
                            <CardListItem hidden={!showAll} muted className='cursor-not-allowed' dataUI={getDataUI()}>Reporting Requirements</CardListItem>
                            <CardListItem hidden={!showAll} muted className='cursor-not-allowed'dataUI={getDataUI()}>Historical Periods</CardListItem>
                            <CardListItem hidden={!showAll} muted className='cursor-not-allowed'dataUI={getDataUI()}>Covenants</CardListItem>
                        </CardList>

                    </Col>
                    <Col>
                        <CardList colSpacing cardTitle='Work Flow' icon="fal fa-tasks" dataUI={getDataUI()}>
                            <CardListItem onClick={() => console.log('click item')} dataUI={getDataUI()}>Business Rules </CardListItem>
                            <CardListItem onClick={() => console.log('click item')} dataUI={getDataUI()}>Task Definitions</CardListItem>
                            <CardListItem hidden={!showAll} muted className='cursor-not-allowed' dataUI={getDataUI()}>Activity Definitions</CardListItem>
                            <CardListItem hidden={!showAll} muted className='cursor-not-allowed' dataUI={getDataUI()}>Task/Acivity Routing</CardListItem>
                            <CardListItem hidden={!showAll} muted className='cursor-not-allowed' dataUI={getDataUI()}>Lead Routes</CardListItem>
                            <CardListItem hidden={!showAll} muted className='cursor-not-allowed' dataUI={getDataUI()}>Approval Matrix</CardListItem>
                            <CardListItem hidden={!showAll} muted className='cursor-not-allowed' dataUI={getDataUI()}>Policy Exception Matrix</CardListItem>
                        </CardList>
                    </Col>
                </Row>
            </div>



            <Highlight className="React">
                {
                    `
import React,  { useState } from 'react';
import { getDataUI } from '../../helpers/helpers';

const Example: React.FC = (props) => {
    const [showAll, setShowAll] = useState(false);
    return (
    <ButtonGroup className='d-flex justify-content-end mb-m'>
        <Button btnType={!showAll ? 'primary' : 'secondary'} onClick={() => setShowAll(false)}>My Admin</Button>
        <Button btnType={showAll ? 'primary' : 'secondary'} onClick={() => setShowAll(true)}>All</Button>
    </ButtonGroup>
      <div>
            <Row>
                <Col>
                    {/* First demo CardList show that: 
                            1. If you pass to CardListItem as child text only. Set onClick to CardListItem. 
                            2. If you need some "span", "a" or "router link" as child to CardListItem, set onClick to your element.
                    */}
                    <CardList colSpacing cardTitle='Users Access' icon="fal fa-user-shield" className="mb-xxl" muted dataUI={getDataUI()}>
                        <CardListItem muted onClick={() => console.log('click li')} dataUI={getDataUI()}>Authentication</CardListItem>
                        <CardListItem muted dataUI={getDataUI()}><span onClick={() => console.log('click span')}>Roles</span></CardListItem>
                        <CardListItem muted dataUI={getDataUI()}><a onClick={(e) => { e.preventDefault(); console.log('click a') }} href='/' title="test">Teams</a></CardListItem>
                        <CardListItem muted dataUI={getDataUI()}><Link to='/'>Users</Link></CardListItem>
                        <CardListItem muted onClick={() => console.log('click li')} dataUI={getDataUI()}>User Devices (Devices)</CardListItem>
                    </CardList>
                </Col>

                <Col>
                    <CardList colSpacing cardTitle='FIle Management' icon="fal fa-folder-tree" active dataUI={getDataUI()}>
                            <CardListItem muted onClick={() => console.log('click item')} dataUI={getDataUI()}>Process Definitions</CardListItem>
                            ...
                            <CardListItem active onClick={() => console.log('click item')} dataUI={getDataUI()}>Score Cards</CardListItem>
                            ...
                    </CardList>
                </Col>
                <Col>
                    <CardList colSpacing cardTitle='Tracking Items' icon="fal fa-map-marked-alt" className="mb-xxl" dataUI={getDataUI()}>
                            ...
                    </CardList>

                </Col>
                <Col>
                    <CardList colSpacing cardTitle='Work Flow' icon="fal fa-tasks" dataUI={getDataUI()}>
                         <CardListItem onClick={() => console.log('click item')} dataUI={getDataUI()}>Business Rules </CardListItem>
                            ...
                        
                        <CardListItem hidden={!showAll} muted className='cursor-not-allowed' dataUI={getDataUI()}>Activity Definitions</CardListItem>
                        <CardListItem hidden={!showAll} muted className='cursor-not-allowed' dataUI={getDataUI()}>Task/Acivity Routing</CardListItem>
                        <CardListItem hidden={!showAll} muted className='cursor-not-allowed' dataUI={getDataUI()}>Lead Routes</CardListItem>
                        <CardListItem hidden={!showAll} muted className='cursor-not-allowed' dataUI={getDataUI()}>Approval Matrix</CardListItem>
                        <CardListItem hidden={!showAll} muted className='cursor-not-allowed' dataUI={getDataUI()}>Policy Exception Matrix</CardListItem>
                    </CardList>
                </Col>
            </Row>
      </div>
    );
}

export default Example;
    `}
            </Highlight>

            <h2 className="mb-m">CardList API</h2>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>active</td>
                        <td>boolean</td>
                        <td className="text-center">-</td>
                        <td>Sets component active state.</td>
                    </tr>
                    <tr>
                        <td>muted</td>
                        <td>boolean</td>
                        <td className="text-center">-</td>
                        <td>Sets component muted state.</td>
                    </tr>
                    <tr>
                        <td>cardTitle</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Shows component title</td>
                    </tr>
                    <tr>
                        <td>icon</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Shows component icon</td>
                    </tr>
                    <tr>
                        <td>colSpacing</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Set left and right margin to the component. When you use the component in "Col" you may need to add this prop to achieve design spacings</td>
                    </tr>
                    <tr>
                        <td>all native div attributes</td>
                        <td className="text-center">-</td>
                        <td className="text-center">-</td>
                        <td>You can use all native div attributes.</td>
                    </tr>
                </tbody>
            </table>



            <h2 className="mb-m">CardListItem API</h2>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>active</td>
                        <td>boolean</td>
                        <td className="text-center">-</td>
                        <td>Sets component active state.</td>
                    </tr>
                    <tr>
                        <td>muted</td>
                        <td>boolean</td>
                        <td className="text-center">-</td>
                        <td>Sets component muted state.</td>
                    </tr>
                    <tr>
                        <td>hidden</td>
                        <td>boolean</td>
                        <td className="text-center">-</td>
                        <td>Hide CardListitem</td>
                    </tr>
                    <tr>
                        <td>onClick</td>
                        <td>void</td>
                        <td className="text-center">-</td>
                        <td>Set component click event</td>
                    </tr>
                    <tr>
                        <td>all native li attributes</td>
                        <td className="text-center">-</td>
                        <td className="text-center">-</td>
                        <td>You can use all native li attributes.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default CardListPage;
