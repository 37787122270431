import React from 'react';

import { getDataUI } from '../helpers/helpers';
import { GridContainer, Grid, GridColumn, ActionContainer, ActionContainerCrumbs, ActionContainerHeader, MainContainerHeader, MainContainerScrollable } from '@jkhy/vsg-loanvantage-design-system';

import products from './KendoTable/products.json';

const DemoKendoGridFixColumns = (props: any) => {

    return (
        <>
            <MainContainerHeader dataUI={getDataUI()}>
                <ActionContainer dataUI={getDataUI()}>
                    <ActionContainerHeader dataUI={getDataUI()}>
                        <ActionContainerCrumbs dataUI={getDataUI()} crumbs={['Kendo Grid with fixed columns and horizontal scroll']} />
                    </ActionContainerHeader>
                </ActionContainer>
            </MainContainerHeader>

            <MainContainerScrollable>
                <GridContainer dataUI={getDataUI()} style={{ height: '100%', maxHeight: '100%' }}>
                    <Grid
                        style={{ height: '100%', maxHeight: '100%' }}
                        data={products}
                    >
                        <GridColumn field="ProductID" width="100px" locked={true} />
                        <GridColumn field="ProductName" width="120px" title="Product Name" locked={true} />
                        <GridColumn field="SupplierID" width="100px" locked={true} />
                        <GridColumn field="CategoryID" width="120px" />
                        <GridColumn field="QuantityPerUnit" width="120px" />
                        <GridColumn field="QuantityPerUnit" width="120px" />
                        <GridColumn field="UnitPrice" width="120px" title="Unit Price" />
                        <GridColumn field="UnitsInStock" width="120px" />
                        <GridColumn field="UnitsOnOrder" width="120px" />
                        <GridColumn field="ReorderLevel" width="120px" />
                        <GridColumn field="Discontinued" width="120px" />
                        <GridColumn field="ProductName" width="120px" title="Product Name Copy 2" />
                        <GridColumn field="QuantityPerUnit" width="120px" />
                        <GridColumn field="QuantityPerUnit" width="120px" />
                        <GridColumn field="ProductName" width="120px" title="Product Name Copy 3" />
                        <GridColumn field="QuantityPerUnit" width="120px" />
                        <GridColumn field="QuantityPerUnit" width="120px" />
                    </Grid>
                </GridContainer>
            </MainContainerScrollable>
        </>
    )
}

export default DemoKendoGridFixColumns
