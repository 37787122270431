import React from 'react';
import Highlight from 'react-highlight';
import { Timeline, TimelineItem, TimelineItemProps } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const timelineMockData: TimelineItemProps[] = [
    {
        title: 'Today',
        dataUI: getDataUI(),
        timelineItemData: [
            {
                badge: {
                    title: '1',
                    color: 'pink',
                    tooltip: 'Company Prepared for Q1, Q2, Q3 for 1st FYE 12/31/2022 with 30 Grace Days'
                },
                left: {
                    title: 'Statemet Date:',
                    value: '09/30/2022'
                },
                mid: {
                    title: 'Due Date:',
                    value: '13/31/2022'
                },
                right: {
                    title: 'Create Date:',
                    value: '13/31/2022'
                },
                rightBadge: {
                    type: 'check',
                    tooltip: 'Existing Match',
                },
                contentItems: [
                    {
                        title: 'Frequency:',
                        value: 'Quarterly'
                    },
                    {
                        title: 'Quality:',
                        value: 'CPA Prepared'
                    },
                    {
                        title: 'Grace days:',
                        value: '30'
                    }
                ]
            },
            {
                badge: {
                    title: '2',
                    color: 'blue',
                    tooltip: 'CPA Prepared for every 1 year beginning on 12/31/2022 with 30 Grace Days'
                },
                left: {
                    title: 'Statemet Date:',
                    value: '12/31/2022'
                },
                mid: {
                    title: 'Due Date:',
                    value: '13/31/2022'
                },
                right: {
                    title: 'Create Date:',
                    value: '04/30/2022'
                },
                rightBadge: {
                    type: 'clock',
                    tooltip: 'TBD',
                },
                contentItems: [
                    {
                        title: 'Frequency:',
                        value: 'Annually'
                    },
                    {
                        title: 'Quality:',
                        value: 'CPA Prepared'
                    },
                    {
                        title: 'Grace days:',
                        value: '120'
                    },
                ]
            }
        ]
    },
    {
        title: '2023',
        dataUI: getDataUI(),
        timelineItemData: [
            {
                badge: {
                    title: '1',
                    color: 'pink',
                    tooltip: 'Company Prepared for Q1, Q2, Q3 for 1st FYE 12/31/2022 with 30 Grace Days'
                },
                left: {
                    title: 'Statemet Date:',
                    value: '03/31/2023'
                },
                mid: {
                    title: 'Due Date:',
                    value: '13/31/2022'
                },
                right: {
                    title: 'Create Date:',
                    value: '04/30/2023'
                },
                rightBadge: {
                    type: 'check',
                    tooltip: 'Existing Match',
                },
                contentItems: [
                    {
                        title: 'Frequency:',
                        value: 'Annually'
                    },
                    {
                        title: 'Quality:',
                        value: 'CPA Prepared'
                    },
                    {
                        title: 'Grace days:',
                        value: '30'
                    },
                ]
            },
            {
                badge: {
                    title: '2',
                    color: 'blue',
                    tooltip: 'CPA Prepared for every 1 year beginning on 12/31/2022 with 30 Grace Days'
                },
                left: {
                    title: 'Statemet Date:',
                    value: '06/30/2023'
                },
                mid: {
                    title: 'Due Date:',
                    value: '13/31/2022'
                },
                right: {
                    title: 'Create Date:',
                    value: '04/30/2023'
                },
                rightBadge: {
                    type: 'clock',
                    tooltip: 'TBD',
                },
                contentItems: [
                    {
                        title: 'Frequency:',
                        value: 'Quarterly'
                    },
                    {
                        title: 'Quality:',
                        value: 'CPA Prepared'
                    },
                    {
                        title: 'Grace days:',
                        value: '30'
                    },
                ]
            }
        ]
    },
    {
        title: '2024',
        dataUI: getDataUI(),
        timelineItemData: [
            {
                badge: {
                    title: '1',
                    color: 'pink',
                    tooltip: 'Company Prepared for Q1, Q2, Q3 for 1st FYE 12/31/2022 with 30 Grace Days'
                },
                left: {
                    title: 'Statemet Date:',
                    value: '12/31/2024'
                },
                mid: {
                    title: 'Due Date:',
                    value: '13/31/2022'
                },
                right: {
                    title: 'Create Date:',
                    value: '04/30/2024'
                },
                rightBadge: {
                    type: 'check',
                    tooltip: 'Existing Match',
                },
                contentItems: [
                    {
                        title: 'Frequency:',
                        value: 'Annually'
                    },
                    {
                        title: 'Quality:',
                        value: 'CPA Prepared'
                    },
                    {
                        title: 'Grace days:',
                        value: '30'
                    },
                ]
            },
            {
                badge: {
                    title: '2',
                    color: 'blue',
                    tooltip: 'CPA Prepared for every 1 year beginning on 12/31/2022 with 30 Grace Days'
                },
                left: {
                    title: 'Statemet Date:',
                    value: '05/30/2024'
                },
                mid: {
                    title: 'Due Date:',
                    value: '13/31/2022'
                },
                right: {
                    title: 'Create Date:',
                    value: '06/30/2024'
                },
                rightBadge: {
                    type: 'clock',
                    tooltip: 'TBD',
                },
                contentItems: [
                    {
                        title: 'Frequency:',
                        value: 'Annually'
                    },
                    {
                        title: 'Quality:',
                        value: 'CPA Prepared'
                    },
                    {
                        title: 'Grace days:',
                        value: '120'
                    },
                ]
            }
        ]
    }
]

const TimelinePage: React.FC = () => {
    return (
        <div>
            <h1 className="mb-s">Timeline</h1>
            <p className="mb-l">The timeline displays a list of events in chronological order.</p>

            <h2 className="mb-m">Timeline module components:</h2>
            <p className="mb-s"><strong><a href="#timeline">Timeline</a></strong> - The main wrapper component</p>
            <p className="mb-xl"><strong><a href="#timelineItem">TimelineItem</a></strong> - Element that show different events.</p>

            <Timeline dataUI={getDataUI()}>
                {timelineMockData.map(({ title, timelineItemData, dataUI }) => {
                    return <TimelineItem dataUI={dataUI} title={title} timelineItemData={timelineItemData} key={title} />
                })}
            </Timeline>

            <Highlight className="React">
                {
                    `
import React from 'react';
import { Timeline, TimelineItem, TimelineItemProps } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const timelineMockData: TimelineItemProps[] = [
    {
        title: 'Today',
        dataUI: getDataUI(),
        timelineItemData: [
            {
                badge: {
                    title: '1',
                    color: 'pink',
                    tooltip: 'Company Prepared for Q1, Q2, Q3 for 1st FYE 12/31/2022 with 30 Grace Days'
                },
                left: {
                    title: 'Statemet Date:',
                    value: '09/30/2022'
                },
                mid: {
                    title: 'Due Date:',
                    value: '13/31/2022'
                },
                right: {
                    title: 'Create Date:',
                    value: '13/31/2022'
                },
                rightBadge: {
                    type: 'check',
                    tooltip: 'Existing Match',
                },
                contentItems: [
                    {
                        title: 'Frequency:',
                        value: 'Quarterly'
                    },
                    {
                        title: 'Quality:',
                        value: 'CPA Prepared'
                    },
                    {
                        title: 'Grace days:',
                        value: '30'
                    },
                ]
            },
            {
               ...
            }
        ]
    },
    {
        ...
    },
    {
        ...
    }
]
const Example: React.FC = (props) => {
    return (
        <Timeline dataUI={getDataUI}>
            {timelineMockData.map(({ title, timelineItemData, dataUI }) => {
                return <TimelineItem dataUI={dataUI} title={title} timelineItemData={timelineItemData} key={title} />
            })}
        </Timeline>
    );
}

export default Example;
    `}
            </Highlight>

            <h2 className="mb-m">API</h2>

            <h3 id="timeline">Timeline</h3>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>all native div attributes</td>
                        <td className="text-center">-</td>
                        <td className="text-center">-</td>
                        <td>You can use all native div attributes like: "className", etc.</td>
                    </tr>
                </tbody>
            </table>

            <h3 id="timelineItem">TimelineItem</h3>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>title</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Sets Timeline item title.</td>
                    </tr>
                    <tr>
                        <td>timelineItemData</td>
                        <td>
                            {'{'} <br /><br />
                            badge?: {'{'}<br />
                            title?: string,<br />
                            color?: 'blue' | 'green' | 'yellow' | 'orange' | 'pink',<br />
                            tooltip?: string<br />
                            {'}'},<br/>
                            left?: {'{'}<br />
                            title?: string,<br />
                            value?: string<br />
                            {'}'},<br/>
                            mid?: {'{'}<br />
                            title?: string,<br />
                            value?: string<br />
                            {'}'},<br/>
                            right?: {'{'}<br />
                            title?: string,<br />
                            value?: string<br />
                            {'}'},<br/>
                            rightBadge?: {'{'}<br />
                            tooltip?: string,<br />
                            type?: 'check' | 'clock'<br />
                            {'}'},<br />
                            contentItems?: {'{'}<br />
                            title?: string<br />
                            value?: string<br />
                            {'}'}[]<br /><br />
                            {'}[]'}
                        </td>
                        <td className="text-center">-</td>
                        <td>Sets Timeline item data.</td>
                    </tr>
                    <tr>
                        <td>all native div attributes</td>
                        <td className="text-center">-</td>
                        <td className="text-center">-</td>
                        <td>You can use all native div attributes like: "className", etc.</td>
                    </tr>
                </tbody>
            </table>

        </div>
    )
}

export default TimelinePage;
