import React from 'react';
import { Link } from 'react-router-dom';

type LinkItemProps = {
    title: string,
    links: { title: string, path: string }[]
}

const LinkItem: React.FC<LinkItemProps> = (props) => {

    const locationPath = window.location.pathname;

    let isActive = null;

    const links = props.links.map((l, i) => {

        return <li key={i} className={locationPath === l.path ? isActive = 'active' : ''}>
            <Link to={l.path}>{l.title}</Link>
        </li>
    });

    return <div className={`link-item${isActive ? ' active' : ''}`}>
        <Link to={props.links[0].path} className="link">{props.title}</Link>
        <ul className="sidenav">
            {links}
        </ul>
    </div>
}

export default LinkItem;