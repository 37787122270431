import React from 'react';

import {
    ReactAux, Main, MainContainer, MainContainerRemainingPart, LeftSideBar, TopSection, ScrollableContainer,
    Button, WizardNavStepsControls, ActionContainer, ActionContainerCrumbs,
    ActionContainerHeader, MainContainerHeader, LeftBarCrumbs, LinkWithIcon, Label, ButtonGroup,
} from '@jkhy/vsg-loanvantage-design-system';

import DemoHeader from './DemoHeader';
import { getDataUI } from '../helpers/helpers';

class DemoWorkPage4 extends React.Component {

    state = {
        activeBtn: 0,
    }

    render() {
        return (
            <ReactAux>
                <DemoHeader />
                <Main dataUI={getDataUI()}>
                    <LeftSideBar dataUI={getDataUI()}>
                        <TopSection dataUI={getDataUI()}>
                            <LeftBarCrumbs
                                dataUI={getDataUI()}
                                className="mb-m lv-side-bar-neggative-margin"
                                links={[<LinkWithIcon dataUI={getDataUI()} icon="fal fa-arrow-left" iconPlace="left">
                                    <a href="/">Back to Work Queue</a>
                                </LinkWithIcon>]}
                            />

                            <ScrollableContainer>
                                <Button dataUI={getDataUI()} className="mb-m" icon="fal fa-file-alt" btnType="secondary">Edit File</Button>
                                <div className="d-flex mb-s">
                                    <Label dataUI={getDataUI()} className="mr-s"><strong>File Number</strong></Label>
                                    <Label dataUI={getDataUI()}>20698</Label>
                                </div>
                                <div className="d-flex mb-s">
                                    <Label dataUI={getDataUI()} className="mr-s"><strong>File Type</strong></Label>
                                    <Label dataUI={getDataUI()}>Loan Request</Label>
                                </div>
                                <div className="d-flex mb-s">
                                    <Label dataUI={getDataUI()} className="mr-s"><strong>File Name</strong></Label>
                                    <Label dataUI={getDataUI()}>New Line of Credit</Label>
                                </div>
                                <div className="d-flex mb-s">
                                    <Label dataUI={getDataUI()} className="mr-s"><strong>Current Exposure</strong></Label>
                                    <Label dataUI={getDataUI()}>$10,527</Label>
                                </div>
                                <div className="d-flex mb-s">
                                    <Label dataUI={getDataUI()} className="mr-s"><strong>Proposed Changes</strong></Label>
                                    <Label dataUI={getDataUI()}>$5,000,000</Label>
                                </div>
                                <div className="d-flex mb-s">
                                    <Label dataUI={getDataUI()} className="mr-s"><strong>Plus Pending Approvals</strong></Label>
                                    <Label dataUI={getDataUI()}>$0</Label>
                                </div>
                                <div className="d-flex mb-m">
                                    <Label dataUI={getDataUI()} className="mr-s"><strong>Total Proposed Exposure</strong></Label>
                                    <Label dataUI={getDataUI()}>$15,527,000</Label>
                                </div>

                                <WizardNavStepsControls
                                    dataUI={getDataUI()}
                                    className="mb-m"
                                    saveBtn={<Button dataUI={getDataUI()} icon="fal fa-save" btnType="secondary">Save and proceed</Button>}
                                    previousStep={<a href="/">Prev</a>}
                                    nextStep={<a href="/">Skip</a>}
                                />
                                <p className="text-small"><strong>27 more</strong> files remaining</p>
                            </ScrollableContainer>
                        </TopSection>
                    </LeftSideBar>

                    <MainContainer dataUI={getDataUI()}>
                        <MainContainerHeader dataUI={getDataUI()}>
                            <ActionContainer singleLine dataUI={getDataUI()}>
                                <ActionContainerHeader dataUI={getDataUI()}>
                                    <ActionContainerCrumbs dataUI={getDataUI()} crumbs={[
                                        <div className="d-flex align-items-center">
                                            <ButtonGroup  dataUI={getDataUI()}>
                                                <Button dataUI={getDataUI()} btnType={this.state.activeBtn === 1 ? 'primary' : 'secondary'} onClick={() => this.setState({ activeBtn: 1 })}>File</Button>
                                                <Button dataUI={getDataUI()} btnType={this.state.activeBtn === 2 ? 'primary' : 'secondary'} onClick={() => this.setState({ activeBtn: 2 })}>Facility</Button>
                                                <Button dataUI={getDataUI()} disabled btnType={this.state.activeBtn === 3 ? 'primary' : 'secondary'} onClick={() => this.setState({ activeBtn: 3 })}>Presentation Report</Button>
                                            </ButtonGroup>
                                            <p className="text-muted ml-s">No presentation report is on file.</p>
                                        </div>
                                    ]} />
                                </ActionContainerHeader>
                                <div className="d-flex align-items-center ml-l">
                                    <Button dataUI={getDataUI()} disabled btnType="secondary" icon="fal fa-arrow-circle-left">Prev</Button>
                                    <p className="mr-s"><strong>Facility 1 of 2</strong></p>
                                    <Button dataUI={getDataUI()} btnType="secondary" icon="fal fa-arrow-circle-right">Next</Button>
                                </div>
                            </ActionContainer>
                        </MainContainerHeader>

                        {/* <MainContainerScrollable></MainContainerScrollable> */}
                        <MainContainerRemainingPart>
                            <iframe
                                title="demo-pdf"
                                src={`${process.env.PUBLIC_URL}/img/anatomy-of-large-scale.pdf`}

                                // Important
                                height="100%"
                                // Important
                                width="100%"
                            />
                        </MainContainerRemainingPart>
                    </MainContainer>
                </Main>
            </ReactAux>
        );
    }
}

export default DemoWorkPage4;