import React, { useState } from 'react';
import Highlight from 'react-highlight';
import { Select, ValidationMessage, SelectOptionType } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const selectNameOptions = [
    { value: '1', label: 'Peter' },
    { value: '2', label: 'John' },
    { value: '3', label: 'Alex' },
    { value: '5', label: 'Simon' },
    { value: '7', label: 'Mark' },
    { value: '8', label: 'Ema' },
    { value: '9', label: 'Monica' },
    { value: '10', label: 'Carol' },
    { value: '12', label: 'Sara' },
    { value: '13', label: 'Ani' },
    { value: '14', label: 'Megan' },
    { value: '15', label: 'Nikol' },
    { value: '16', label: 'Robin' },
    { value: '17', label: 'Angel' },
    { value: '18', label: 'Elizabeth' },
    { value: '19', label: 'Eleonora' },
]

const selectOptions:SelectOptionType[] = [
    { value: '1', label: 'Option 1', isDisabled: true },
    { value: '2', label: 'Option 2', secondCode: 'code-123' },
    { value: '3', label: 'Option 3', isSomething: false },
    { value: '4', label: 'Option 4' }
];

const SelectPage: React.FC = () => {
    const [value1, setValue1] = useState('');
    const [value2, setValue2] = useState('1');

    const [value3, setValue3] = useState('');
    const [options, setOption] = useState([]);
    const [loading, setLoading] = useState(false);

    const [value4, setValue4] = useState('3');
    const [options2, setOption2] = useState([]);
    const [loading2, setLoading2] = useState(false);

    const [value5, setValue5] = useState('3');


    const [valueName, setValueName] = useState('1');
    return (
        <div>
            <h1 className="mb-s">Select</h1>
            <p className="mb-l">Custom component for select.</p>

            <Select
                dataUI={getDataUI()}
                className="mb-s"
                options={selectOptions}
                value={value1}
                placeholder="Select..."
                onChange={(obj: any) => {
                    console.log(obj);
                    setValue1(obj.value);
                }}
            />

            <Select
                dataUI={getDataUI()}
                className="mb-s"
                options={selectOptions}
                value={value2}
                text="Option 1"
                onChange={(obj: any) => {
                    setValue2(obj.value);
                }}
            />

            <Select
                dataUI={getDataUI()}
                className="mb-s"
                options={selectNameOptions}
                value={valueName}
                placeholder="Select..."
                onChange={(obj: any) => {
                    console.log('Selected value:', obj)
                    setValueName(obj.value);
                }}
            />

            <Select
                dataUI={getDataUI()}
                className="mb-m"
                options={selectOptions}
                value="3"
                disabled
                invalid
            />

            <h3 className="mb-s">Selects with loading options.</h3>
            <Select
                dataUI={getDataUI()}
                className="mb-s"
                options={options}
                value={value3}
                placeholder="Select..."
                isLoading={loading}
                onChange={(obj: any) => {
                    setValue3(obj.value);
                }}
                onFocus={() => {
                    if (options.length === 0) {
                        setLoading(true);

                        setTimeout(() => {
                            setLoading(false);

                            setOption(selectOptions as any);
                        }, 3000);
                    }
                }}
            />

            <Select
                dataUI={getDataUI()}
                className="mb-s"
                options={options2}
                value={value4}
                text="Option 3"
                isLoading={loading2}
                onChange={(obj: any) => {
                    setValue4(obj.value);
                }}
                onFocus={() => {
                    if (options2.length === 0) {
                        setLoading2(true);

                        setTimeout(() => {
                            setLoading2(false);

                            setOption2(selectOptions as any);
                        }, 3000);
                    }
                }}
            />

            <h3 className="mb-s">Select with dark border.</h3>
            <Select
                className="mb-m"
                darkBorder
                value={''}
                options={selectOptions}
                placeholder="Select..."
                dataUI={getDataUI()}
            />


            <h3 className="mb-s">Select invalid.</h3>
            <Select
                invalid
                value={value5}
                options={selectOptions}
                placeholder="Select..."
                onChange={(obj: any) => {
                    setValue5(obj.value);
                }}
                dataUI={getDataUI()}
            />
            <ValidationMessage type='error' message='Invalid message' dataUI={getDataUI()} />

            <Highlight className="React">
                {
                    `
import React, { useState } from 'react';
import { Select, ValidationMessage } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const selectOptions = [
    { value: '1', label: 'Option 1', isDisabled: true },
    { value: '2', label: 'Option 2', secondCode: 'code-123' },
    { value: '3', label: 'Option 3', isSomething: false },
    { value: '4', label: 'Option 4' }
];

const Example: React.FC = (props) => {
    const [value1, setValue1] = useState('');
    const [value2, setValue2] = useState('1');

    const [value3, setValue3] = useState('');
    const [options, setOption] = useState([]);
    const [loading, setLoading] = useState(false);

    const [value4, setValue4] = useState('3');
    const [options2, setOption2] = useState([]);
    const [loading2, setLoading2] = useState(false);

    return (
      <div>
        <Select
            dataUI={getDataUI()} 
            className="mb-s"
            options={selectOptions}
            value={value1}
            placeholder="Select..."
            onChange={(obj: any) => {
                console.log(obj);
                setValue1(obj.value);
            }}
        />

        <Select
            dataUI={getDataUI()} 
            className="mb-s"
            options={selectOptions}
            value={value2}
            text="Option 1"
            onChange={(obj: any) => {
                setValue2(obj.value);
            }}
        />

        <Select
            dataUI={getDataUI()} 
            className="mb-s"
            options={selectOptions}
            value="3"
            disabled
        />

        <Select
            dataUI={getDataUI()} 
            className="mb-s"
            options={options}
            value={value3}
            placeholder="Select..."
            isLoading={loading}
            onChange={(obj: any) => {
                setValue3(obj.value);
            }}
            onFocus={() => {
                if (options.length === 0) {
                    setLoading(true);

                    setTimeout(() => {
                        setLoading(false);

                        setOption(selectOptions as any);
                    }, 3000);
                }
            }}
        />

        <Select
            dataUI={getDataUI()} 
            className="mb-s"
            options={options2}
            value={value4}
            text="Option 3"
            isLoading={loading2}
            onChange={(obj: any) => {
                setValue4(obj.value);
            }}
            onFocus={() => {
                if (options2.length === 0) {
                    setLoading2(true);

                    setTimeout(() => {
                        setLoading2(false);

                        setOption2(selectOptions as any);
                    }, 3000);
                }
            }}
        />
      </div>

      // Select with dark border
      <Select
          darkBorder
          value={''}
          options={selectOptions}
          placeholder="Select..."
          dataUI={getDataUI()}
      />

     // Select invalid
      <Select
          invalid
          // other way to set invalid
         // className='invalid'
          value={value5}
          options={selectOptions}
          placeholder="Select..."
          onChange={(obj: any) => {
              console.log(obj);
              setValue5(obj.value);
          }}
          dataUI={getDataUI()} 
      />
      <ValidationMessage type='error' message='Invalid message' dataUI={getDataUI()} />
    );
}

export default Example;
    `}
            </Highlight>

            <h2 className="mb-m">API</h2>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>options</td>
                        <td>{'{label: string | number, value: string, [key:string]:string}[]'}</td>
                        <td className="text-center">-</td>
                        <td>The array of options, which are displayed in the Select dropdown. The <i>label</i> and <i>value</i> keys
                            are required.
                        </td>
                    </tr>
                    <tr>
                        <td>value</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Sets the Select value.</td>
                    </tr>
                    <tr>
                        <td>text</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Text, which is displayed as label of the current value, when there is a value, but the options array is empty.</td>
                    </tr>
                    <tr>
                        <td>disabled</td>
                        <td>boolean</td>
                        <td className="text-center">-</td>
                        <td>Makes the select disabled.</td>
                    </tr>
                    <tr>
                        <td>placeholder</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Sets the given text as a placeholder of the Select. Placeholders are rendered
                            as the topmost option elements and have an empty value.
                        </td>
                    </tr>
                    <tr>
                        <td>id</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>The id to set on the select container.</td>
                    </tr>
                    <tr>
                        <td>name</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Name of the HTML Input, associated to the form control. If this property is left empty, no input will be rendered).</td>
                    </tr>
                    <tr>
                        <td>isLoading</td>
                        <td>boolean</td>
                        <td className="text-center">-</td>
                        <td>Indicates if the select should be displayed in loading state.</td>
                    </tr>
                    <tr>
                        <td>noOptionsMessage</td>
                        <td>string</td>
                        <td>"No options"</td>
                        <td>Text to display when there are no options.</td>
                    </tr>
                    <tr>
                        <td>onChange</td>
                        <td>Function</td>
                        <td className="text-center">-</td>
                        <td>Returns the currently selected object.</td>
                    </tr>
                    <tr>
                        <td>onFocus</td>
                        <td>Function</td>
                        <td className="text-center">-</td>
                        <td>Callback when the select is focused.</td>
                    </tr>
                    <tr>
                        <td>onMenuOpen</td>
                        <td>Function</td>
                        <td className="text-center">-</td>
                        <td>Callback when the select option list is opened.</td>
                    </tr>
                    <tr>
                        <td>onMenuClose</td>
                        <td>Function</td>
                        <td className="text-center">-</td>
                        <td>Callback when the select option list is closed.</td>
                    </tr>
                    <tr>
                        <td>noMenuPortal</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>This prop controls where the select options to be rendered - in the parent or in the HTML body element. <strong>Use this prop only on Select in Header  SearchMenu</strong>.</td>
                    </tr>
                    <tr>
                        <td>tabIndex</td>
                        <td>number</td>
                        <td>0</td>
                        <td>Sets the tabIndex attribute on the input.</td>
                    </tr>
                    <tr>
                        <td>invalid</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Sets component invalid styles. You can set component invalid styles and by adding class 'invalid' </td>
                    </tr>
                    <tr>
                        <td>validationInputID</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Sets id to component hidden input. If you don't pass id, it will be generated automatically.  Example for auto generated id: "lv-select-input-1".</td>
                    </tr>
                    <tr>
                        <td>darkBorder</td>
                        <td className='text-center'>boolean</td>
                        <td className='text-center'>-</td>
                        <td>Sets component border color</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default SelectPage;
