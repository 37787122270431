import React from 'react';
import { Link } from 'react-router-dom';
import Highlight from 'react-highlight';
import { Icon, UploadedFileRow } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';


const UploadedFileRowPage: React.FC = () => {
    const examplePDF = new File(['example pdf'], 'file asdasd dasdas dasdasd dasdsad asd sa.pdf', { type: 'application/pdf' })
    const exampleJPG = new File(['example jpg image'], 'picture02 asdasd dasda dasdas da adada.jpg', { type: 'image/jpeg' })
    const exampleDoc = new File([''], 'file.doc', { type: 'application/msword' })

    return (
        <div>
            <h1 className="mb-s">UploadedFileRow</h1>
            <p className="mb-l">A component that visually tracks the progress of an uploaded file, added
                from <Link to="/docs/file-upload">FileUpload</Link>.</p>

            <div className="mb-m">
                <h3 className='mb-s'>Uploading/On progress</h3>
                <UploadedFileRow
                    dataUI={getDataUI()}
                    className="mb-m"
                    uploadedFile={{
                        file: exampleJPG,
                        progress: 90,
                        uploading: true
                    }}
                    onDelete={() => console.log('DELETE')}
                />

                <h3 className='mb-s'>Upload Successful</h3>
                <UploadedFileRow
                    className="mb-m"
                    uploadedFile={{
                        file: examplePDF,
                        uploadSuccessful: true,
                    }}
                    dataUI={getDataUI()}
                />

                <h3 className='mb-s'>Upload Error</h3>
                <UploadedFileRow
                    className="mb-m"
                    uploadedFile={{
                        file: exampleDoc,
                        uploadError: true,
                        errorInfo: {
                            title: 'File is password protected',
                            description: 'Uploaded file appears to have a password protection. Please upload a file that is not password protected.'
                        }
                    }}
                    onDelete={() => console.log('DELETE')}
                    dataUI={getDataUI()}
                />

                <h3 className='mb-s'>Upload Done</h3>
                <UploadedFileRow
                    className="mb-l"
                    uploadedFile={{
                        file: exampleDoc,
                        uploadDone: true,
                        uploadSuccessful: true
                    }}
                    onDelete={() => console.log('DELETE')}
                    dataUI={getDataUI()}
                />

                <h3 className='mb-s'>Example with noBorder and link</h3>
                <UploadedFileRow
                    className="mb-m"
                    noBorder
                    uploadedFile={{
                        file: examplePDF,
                        uploadSuccessful: true,
                        linkSettings: { title: 'test', href: '/', target: '_blank', download: true, className: 'text-primary' }
                    }}
                    dataUI={getDataUI()}
                />

                <h3 className='mb-s'>Example ViewMode</h3>
                <UploadedFileRow
                    className="mb-l"
                    uploadedFile={{
                        file: exampleDoc,
                        uploadDone: true,
                        uploadSuccessful: true
                    }}
                    viewMode
                    onDelete={() => console.log('DELETE')}
                    dataUI={getDataUI()}
                />

            </div>

            <Highlight className="React">
                {
                    `
import React from 'react';
import { UploadedFileRow } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const Example: React.FC = (props) => {
    
    // Programmatically created files for demo purpose
    const examplePDF = new File(['example pdf'], 'file.pdf', { type: 'application/pdf'})
    const exampleJPG = new File(['example jpg image'], 'picture02.jpg', { type: 'image/jpeg'})
    const exampleDoc = new File([''], 'file.doc', { type: 'application/msword' })
 
    return (
      <div>
                // Uploading/On progress
                <UploadedFileRow
                    uploadedFile={{
                        file: exampleJPG,
                        progress: 90,
                        uploading: true
                    }}
                    onDelete={() => console.log('DELETE')}
                    dataUI={getDataUI()}
                />

                // Upload Successful
                <UploadedFileRow
                    uploadedFile={{
                        file: examplePDF,
                        uploadSuccessful: true,
                    }}
                    dataUI={getDataUI()}
                />

                // Upload Error
                <UploadedFileRow
                    uploadedFile={{
                        file: exampleDoc,
                        uploadError: true,
                        errorInfo: {
                            title: 'File is password protected',
                            description: 'Uploaded file appears to have a password protection. Please upload a file that is not password protected.'
                        }
                    }}
                    dataUI={getDataUI()}
                />

                // Upload Done
                <UploadedFileRow
                    uploadedFile={{
                        file: exampleDoc,
                        uploadDone: true,
                        uploadSuccessful: true
                    }}
                    onDelete={() => console.log('DELETE')}
                    dataUI={getDataUI()}
                />

                // Example with noBorder and link
                <UploadedFileRow
                    noBorder
                    uploadedFile={{
                        file: examplePDF,
                        uploadSuccessful: true,
                        linkSettings: {title: 'test', href: '/', target: '_blank', download: true, className: 'text-primary' }
                    }}
                    dataUI={getDataUI()}
                />

                // Example ViewMode
                 <UploadedFileRow
                    uploadedFile={{
                        file: exampleDoc,
                        uploadDone: true,
                        uploadSuccessful: true
                    }}
                    viewMode
                    onDelete={() => console.log('DELETE')}
                    dataUI={getDataUI()}
                />
      </div>
    );
}

export default Example;
    `}
            </Highlight>

            <h2 className="mb-m">API</h2>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>uploadedFile</td>
                        <td>{
                            `{ 
                                file: File,
                                uploading?: boolean,
                                progress?: number,
                                uploadSuccessful?: boolean,
                                uploadDone?: boolean,
                                uploadError?: boolean,
                                errorInfo?: {
                                  title?: string,
                                  description?: string,
                                },
                                linkSettings?: all native link attributes
                            }`
                        }</td>
                        <td className="text-center">-</td>
                        <td>
                            Contains the data, necessary for the target uploaded file.
                        </td>
                    </tr>
                    <tr>
                        <td>noBorder</td>
                        <td>boolean</td>
                        <td className="text-center">-</td>
                        <td>
                            Set styles without border and border radius.
                        </td>
                    </tr>
                    <tr>
                        <td>onDelete</td>
                        <td>{'()=> void'}</td>
                        <td className="text-center">-</td>
                        <td>
                            Callback to execute when user remove file.
                        </td>
                    </tr>
                    <tr>
                        <td>onProgress</td>
                        <td>{'(setProgress:(progress:number)=>void )=> void'}</td>
                        <td className="text-center">-</td>
                        <td>
                            Function which receives as parameter progress function setter. You can execute it and pass value to it and it will change component progress.
                        </td>
                    </tr>
                    <tr>
                        <td>onSuccessUploaded</td>
                        <td>{'(progress:number, setUploadSuccessful:(value:boolean)=>void )=> void'}</td>
                        <td className="text-center">-</td>
                        <td>
                            Function which receives as parameter "progress" and upload successful setter. You can execute "setUploadSuccessful" and pass value to it and it will change component state to uploaded successful.
                        </td>
                    </tr>
                    <tr>
                        <td>onUploadDone</td>
                        <td>{'( uploadSuccessful:boolean, setUploadDone:(value:boolean)=>void )=> void'}</td>
                        <td className="text-center">-</td>
                        <td>
                            Function which receives as parameter "uploadSuccessful" and upload done setter. You can execute "setUploadDone" and pass value to it and it will change component state to uploaded done.
                        </td>
                    </tr>
                    <tr>
                        <td>viewMode</td>
                        <td>boolean</td>
                        <td className="text-center">-</td>
                        <td>
                            Set component view mode styles
                        </td>
                    </tr>
                    <tr>
                        <td>removeBtnsTooltipText</td>
                        <td>string</td>
                        <td>"Delete"</td>
                        <td>
                            Set tooltip text to delete buttons: <Icon dataUI={getDataUI()} className="cursor-pointer text-primary ml-xs" icon="fal fa-trash" size="M" /> and 
                            <Icon dataUI={getDataUI()} className="cursor-pointer text-primary ml-xs" icon="fal fa-times" size="M" />.
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>
    )
}

export default UploadedFileRowPage;
