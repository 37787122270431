import React from 'react';
import Highlight from 'react-highlight';
import { Toggle } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';


const TogglePage: React.FC = () => {
    return (
        <div>
            <h1 className="mb-s">Toggle</h1>
            <p className="mb-l">Form control, useful for switching between states, for example -  active and inactive.</p>

            <h3 className="mb-s">With Label</h3>
            <div className="mb-m">
                <Toggle dataUI={getDataUI()}>Toggle</Toggle>
                <br />
                <Toggle checked onChange={() => { }} dataUI={getDataUI()}>Toggle On</Toggle>
                <br />
                <Toggle checked={false} onChange={() => { }} dataUI={getDataUI()}>Toggle Off</Toggle>
                <br />
                <Toggle readOnly checked dataUI={getDataUI()}>Toggle On ReadOnly</Toggle>
                <br />
                <Toggle readOnly dataUI={getDataUI()}>Toggle Off ReadOnly</Toggle>
                <br />
                <Toggle disabled checked onChange={() => { }} dataUI={getDataUI()}>Toggle On Disabled</Toggle>
                <br />
                <Toggle disabled dataUI={getDataUI()}>Toggle Off Disabled</Toggle>
            </div>

            <h3 className="mb-s">Without Label</h3>
            <div className="mb-m">
                <Toggle dataUI={getDataUI()}></Toggle>
                <br />
                <Toggle checked onChange={() => { }} dataUI={getDataUI()}/>
                <br />
                <Toggle checked={false} onChange={() => { }} dataUI={getDataUI()}/>
                <br />
                <Toggle readOnly checked dataUI={getDataUI()}/>
                <br />
                <Toggle readOnly dataUI={getDataUI()}/>
                <br />
                <Toggle disabled checked onChange={() => { }} dataUI={getDataUI()}/>
                <br />
                <Toggle disabled dataUI={getDataUI()}/>
            </div>

            <Highlight className="React">
                {
                    `
import React from 'react';
import { Toggle } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const Example: React.FC = (props) => {
    return (
      <div>
        // With Label
        <Toggle>Toggle</Toggle>
        <Toggle checked onChange={() => { }} dataUI={getDataUI()}>Toggle On</Toggle>
        <Toggle checked={false} onChange={() => { }} dataUI={getDataUI()}>Toggle Off</Toggle>
        <Toggle readOnly checked dataUI={getDataUI()}>Toggle On ReadOnly</Toggle>
        <Toggle readOnly dataUI={getDataUI()}>Toggle Off ReadOnly</Toggle>
        <Toggle disabled checked onChange={() => { }} dataUI={getDataUI()}>Toggle On Disabled</Toggle>
        <Toggle disabled dataUI={getDataUI()}>Toggle Off Disabled</Toggle>

        // Without Label
        <Toggle></Toggle>
        <Toggle checked onChange={() => { }} dataUI={getDataUI()}/>
        <Toggle checked={false} onChange={() => { }} dataUI={getDataUI()}/>
        <Toggle readOnly checked dataUI={getDataUI()}/>
        <Toggle readOnly dataUI={getDataUI()}/>
        <Toggle disabled checked onChange={() => { }} dataUI={getDataUI()}/>
        <Toggle disabled dataUI={getDataUI()}/>
      </div>
    );
}

export default Example;
    `}
            </Highlight>

            <h2 className="mb-m">API</h2>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>all native input attributes</td>
                        <td className='text-center'>-</td>
                        <td className='text-center'>-</td>
                        <td>You can use all native input attributes like: "disabled", "name", "checked"...</td>
                    </tr>
                </tbody>
            </table>

        </div>
    )
}

export default TogglePage;