import React from 'react';
import Highlight from 'react-highlight';

import {
    InfoBox
} from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';


const InfoBoxPage: React.FC = () => {

    return (
        <div>
            <h1 className="mb-s">InfoBox</h1>

            <p className="mb-l">Container for information text.</p>

            <InfoBox dataUI={getDataUI()} className="mb-l">
                By clicking this button, you confirm that you have a Permissible Purpose for obtaining this credit report.Unauthorized access is prohibited under the Fair Credit Reporting Act (FCRA) and punishable by $2500 and/or 1 year in Federal prison per occurrence. Commercial Customers in this File.
            </InfoBox>
            <InfoBox dataUI={getDataUI()} className="mb-l" icon="fal fa-check">
                InfoBox with Custom icon.
            </InfoBox>
            <InfoBox dataUI={getDataUI()} title="Title" className="mb-l">Review or edit party profile information and save any chages.</InfoBox>
            <InfoBox dataUI={getDataUI()} title="Title" className="mb-l">
                <ul>
                    <li>1. Item</li>
                    <li>2. Item</li>
                    <li>3. Item</li>
                    <li>4. Item</li>
                </ul>
            </InfoBox>
            <InfoBox dataUI={getDataUI()} title="Title" className="mb-l">
                <p className="lv-small mb-s">Review or edit party profile information and save any chages.</p>
                <ul className="mb-s">
                    <li>1. Item</li>
                    <li>2. Item</li>
                    <li>3. Item</li>
                    <li>4. Item</li>
                </ul>
                <p className="lv-small">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
            </InfoBox>

            <InfoBox dataUI={getDataUI()} className="mb-l" type="success" icon="fal fa-check">
                InfoBox with success type.
            </InfoBox>

            <InfoBox dataUI={getDataUI()} className="mb-l" type="warning" icon="fal fa-exclamation-triangle">
                InfoBox with warning type.
            </InfoBox>

            <InfoBox dataUI={getDataUI()} className="mb-l" type="danger" icon={'fal fa-times-circle'}>
                InfoBox with danger type.
            </InfoBox>

            <Highlight className="React">
                {
                    `
import React, { useState } from 'react';

import { InfoBox } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

class Example extends React.Component {

    render() {
        return (
            <div>
                <InfoBox dataUI={getDataUI()}>
                    By clicking this button...
                </InfoBox>
                
                <InfoBox dataUI={getDataUI()} icon="fal fa-check">
                    InfoBox with Custom icon.
                </InfoBox>
                
                <InfoBox dataUI={getDataUI()} title="Title">
                    Review or edit...
                </InfoBox>
                
                <InfoBox dataUI={getDataUI()} title="Title">
                    <ul>
                        <li>1. Item</li>
                        <li>2. Item</li>
                        <li>3. Item</li>
                        <li>4. Item</li>
                    </ul>
                </InfoBox>
                
                <InfoBox dataUI={getDataUI()} title="Title">
                    <p className="lv-small mb-s">Review or edit...</p>
                    <ul className="mb-s">
                        <li>1. Item</li>
                        ...
                    </ul>
                    <p className="lv-small">Lorem Ipsum is simply...</p>
                </InfoBox>

                <InfoBox dataUI={getDataUI()} type="success" icon="fal fa-check">
                     InfoBox with success type.
                </InfoBox>

                <InfoBox dataUI={getDataUI()} type="warning" icon="fal fa-exclamation-triangle">
                    InfoBox with warning type.
                </InfoBox>

                <InfoBox dataUI={getDataUI()} type="danger" icon={'fal fa-times-circle'}>
                    InfoBox with danger type.
                </InfoBox>
            </div>
        );
    }
}

export default Example;
        `}
            </Highlight>


            <h2 className="mb-m mt-l">API</h2>

            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>icon</td>
                        <td>string</td>
                        <td>'fal fa-info'</td>
                        <td>Sets the InfoBox icon.</td>
                    </tr>
                    <tr>
                        <td>title</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Sets the InfoBox title.</td>
                    </tr>
                    <tr>
                        <td>type</td>
                        <td>'success' | 'warning' | 'danger'</td>
                        <td className="text-center">-</td>
                        <td>Sets the InfoBox type (color).</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
};

export default InfoBoxPage;
