import React from 'react';
import Highlight from 'react-highlight';

const TypographyPage: React.FC = () => {
    return (
        <div>
            <h1 className="mb-s">Typography</h1>

            <p className="mb-s">
                One of the key features of the CSS framework in LoanVantage Design System is the set
		        of typographical elements. Notice that they are not React components, but pure HTML elements instead.
            </p>
            <p className="mb-s">
                All typographical elements come without any margins by default. Spacing between those elements
                has to be configured through utility classes.
            </p>
            <p className="mb-m">
                An important thing to keep in mind is the need for environment without other stylesheets, except
                the one of LoanVantage Design System. The typography is easy to use, but it could also be easily
		        overriden by other style rules, thus breaking the design system.
            </p>

            <h1>Heading - H1</h1>
            <h2>Heading - H2</h2>
            <h3>Heading - H3</h3>
            <h4>Heading - H4</h4>
            <h5>Heading - H5</h5>
            <h6>Heading - H6</h6>
            <p>Paragraph - &lt;p&gt;</p>
            <p className="lv-small">Small paragraph - &lt;p className="lv-small"&gt;</p>

            <Highlight className="React">
                {
                    `
import React from 'react';
import '@jkhy/vsg-loanvantage-design-system/dist/styles.css';

const Example: React.FC = (props) => {
    return (
        <div>
            <h1>Heading - H1</h1>
            <h2>Heading - H2</h2>
            <h3>Heading - H3</h3>
            <h4>Heading - H4</h4>
            <h5>Heading - H5</h5>
            <h6>Heading - H6</h6>
            <p>Paragraph - &lt;p></p>
            <p className="lv-small">Small paragraph - &lt;p className="lv-small"></p>
        </div>
    );
}

export default Example;
    `}
            </Highlight>

        </div>
    )
}

export default TypographyPage;
