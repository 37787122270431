import React from 'react';
import {
    TopSection, LeftBarCrumbs, ScrollableContainer, Collapse, BottomSection, Tabs,
    TabList, TabItem, TabItemTitle, TabContent, TabContentItem, Checkbox, Button,
    ReactAux, PartyNavigationItem, ChecklistActions, ChecklistContent, ChecklistItem,
    Icon, Tooltip, FileUpload
} from '@jkhy/vsg-loanvantage-design-system';

import {
    exampleText, examplePDF, onAddFile, renderFileRows, UploadedFileRowProps
} from '../helpers/fileUploadHelpers';
import { getDataUI } from '../helpers/helpers';

const collapseItems = [
    {
        title: 'BFT 15977', expanded: false, content: <ReactAux>
            <PartyNavigationItem dataUI={getDataUI()} render={() => <a href="/">Profile</a>} />
            <PartyNavigationItem dataUI={getDataUI()} isActive render={() => <a href="/">Relationships</a>} />
            <PartyNavigationItem dataUI={getDataUI()} render={() => <a href="/">Engagements</a>} />
        </ReactAux>
    },
    {
        title: 'First Southeast Insurance Services, Inc', expanded: false, secondLevel: [
            {
                title: 'Financial Spreading', expanded: false, content:
                    <ReactAux>
                        <PartyNavigationItem dataUI={getDataUI()} render={() => <a href="/">RMA Ratios</a>} />
                        <PartyNavigationItem dataUI={getDataUI()} render={() => <a href="/">Proforma Debts</a>} />
                        <PartyNavigationItem dataUI={getDataUI()} render={() => <a href="/">Debt Service Overlays</a>} />
                    </ReactAux>
            },
            {
                title: 'Tax Returns', expanded: false, content:
                    <ReactAux>
                        <PartyNavigationItem dataUI={getDataUI()} render={() => <a href="/">1040</a>} />
                        <PartyNavigationItem dataUI={getDataUI()} render={() => <a href="/">Tax Return Analysis</a>} />
                    </ReactAux>
            },
            {
                title: 'Financial Spreading', expanded: false, content:
                    <ReactAux>
                        <PartyNavigationItem dataUI={getDataUI()} render={() => <a href="/">Personal Cash Flow</a>} />
                        <PartyNavigationItem dataUI={getDataUI()} render={() => <a href="/">Financial Covenants (Personal)</a>} />
                    </ReactAux>
            },
        ]
    },
    {
        title: 'BANKATLANTIC TEST', expanded: false, content:
            <ReactAux>
                <PartyNavigationItem dataUI={getDataUI()} render={() => <a href="/">Profile</a>} />
                <PartyNavigationItem dataUI={getDataUI()} render={() => <a href="/">Relationships</a>} />
                <PartyNavigationItem dataUI={getDataUI()} render={() => <a href="/">Engagements</a>} />
            </ReactAux>
    },
    {
        title: 'New Business Party', expanded: false, content:
            <ReactAux>
                <PartyNavigationItem dataUI={getDataUI()} render={() => <a href="/">Profile</a>} />
                <PartyNavigationItem dataUI={getDataUI()} render={() => <a href="/">Relationships</a>} />
                <PartyNavigationItem dataUI={getDataUI()} render={() => <a href="/">Engagements</a>} />
                <PartyNavigationItem dataUI={getDataUI()} render={() => <a href="/">Profile</a>} />
                <PartyNavigationItem dataUI={getDataUI()} render={() => <a href="/">Relationships</a>} />
                <PartyNavigationItem dataUI={getDataUI()} render={() => <a href="/">Engagements</a>} />
                <PartyNavigationItem dataUI={getDataUI()} render={() => <a href="/">Profile</a>} />
                <PartyNavigationItem dataUI={getDataUI()} render={() => <a href="/">Relationships</a>} />
                <PartyNavigationItem dataUI={getDataUI()} render={() => <a href="/">Engagements</a>} />
            </ReactAux>
    },
    {
        title: 'ALVIN BYRNE', expanded: false, content:
            <ReactAux>
                <PartyNavigationItem dataUI={getDataUI()} render={() => <a href="/">Profile</a>} />
                <PartyNavigationItem dataUI={getDataUI()} render={() => <a href="/">Relationships</a>} />
                <PartyNavigationItem dataUI={getDataUI()} render={() => <a href="/">Engagements</a>} />
            </ReactAux>
    },
];

const getActivityChecklistDataTip = (isChecked: any) => {
    return isChecked ? 'Completed' : 'Opened'
}

export default class DemoSectionsForLeftSideBar extends React.Component {
    private demoFilesUnmountedRows = React.createRef() as any;

    state = {
        collapseItems: collapseItems,
        activityChecklistTabIndex: 0,
        activityChecklistStatuses: [
            { isChecked: false }, { isChecked: true }, { isChecked: false }, { isChecked: false }, { isChecked: false }
        ],
        demoFiles: [examplePDF, exampleText] as UploadedFileRowProps[],
    };

    componentDidMount() {
        this.demoFilesUnmountedRows.current = {};
    }


    onCollapseItemClick = (i: any) => {
        const collapseItems = this.state.collapseItems;
        const currentItem = collapseItems[i];

        if (currentItem.expanded === true) {
            collapseItems[i].expanded = false;
        } else {

            collapseItems.forEach((c: any) => c.expanded = false);

            collapseItems[i].expanded = true;
        }

        this.setState({
            collapseItems
        });
    }

    onCollapseSecondItemClick = (i: any, j: any) => {
        const collapseItems = this.state.collapseItems;
        const currentItemParent = collapseItems[i].secondLevel;

        if (currentItemParent) {
            const currentItem = currentItemParent[j];

            if (currentItem.expanded === true) {
                currentItemParent[j].expanded = false;
            } else {
                currentItemParent.forEach((c: any) => c.expanded = false);

                currentItemParent[j].expanded = true;
            }
        }

        this.setState({
            collapseItems
        });
    }

    onChangeActivitiChecklistStatus = (index: any) => {
        const copyStatuses = [...this.state.activityChecklistStatuses];
        copyStatuses[index].isChecked = !copyStatuses[index].isChecked;
        this.setState({
            activityChecklistStatuses: copyStatuses
        });
    }

    render() {
        const loaction = window.location;

        return (
            <ReactAux>
                <TopSection dataUI={getDataUI()}>
                    <LeftBarCrumbs
                        dataUI={getDataUI()}
                        className="mb-m lv-side-bar-neggative-margin"
                        links={[<a href="/">Fallow Fruits</a>, <a href="/">36465: Loan Request</a>]} />
                    <ScrollableContainer>
                        {this.state.collapseItems.map((item: any, i: any) =>
                            <Collapse
                                dataUI={getDataUI()}
                                key={i}
                                title={item.title}
                                expanded={item.expanded}
                                onClick={() => this.onCollapseItemClick(i)}
                                rightIcon="fal fa-user"
                                {...(i === 2 && { rightIconTooltip: 'Additional information', rightIcon: 'custom-icon-user-core-active-s' })}
                            >
                                {item.content || item.secondLevel?.map((secondItem: any, j: any) =>
                                    <Collapse
                                        dataUI={getDataUI()}
                                        key={j}
                                        title={secondItem.title}
                                        expanded={secondItem.expanded}
                                        onClick={() => this.onCollapseSecondItemClick(i, j)}
                                    >
                                        {secondItem.content}
                                    </Collapse>
                                )}
                            </Collapse>
                        )}
                    </ScrollableContainer>
                </TopSection >
                {loaction.search !== '?only-top' && <BottomSection dataUI={getDataUI()}>
                    <Tabs dataUI={getDataUI()} currentlyActiveTabIndex={this.state.activityChecklistTabIndex} onTabIndexChange={(index: number) => this.setState({ activityChecklistTabIndex: index })}>
                        <TabList dataUI={getDataUI()}>
                            <TabItem dataUI={getDataUI()}>
                                <TabItemTitle dataUI={getDataUI()}>Checklist</TabItemTitle>
                            </TabItem>
                            <TabItem dataUI={getDataUI()}>
                                <TabItemTitle dataUI={getDataUI()}>Attachments</TabItemTitle>
                            </TabItem>
                        </TabList>
                        <TabContent dataUI={getDataUI()} className={`${this.state.activityChecklistTabIndex === 1 && 'p-0'}`}>
                            <TabContentItem dataUI={getDataUI()}>
                                <ChecklistActions dataUI={getDataUI()} title="Show only:">
                                    <Checkbox dataUI={getDataUI()} name="parents-check">Parents</Checkbox>
                                    <Checkbox dataUI={getDataUI()} name="unfinished-tasks-check">Unfinished tasks</Checkbox>
                                </ChecklistActions>
                            </TabContentItem>
                            <TabContentItem dataUI={getDataUI()}>
                            </TabContentItem>
                        </TabContent>
                    </Tabs>
                    <ScrollableContainer>
                        {this.state.activityChecklistTabIndex === 0 &&
                            <ChecklistContent dataUI={getDataUI()}>
                                <ChecklistItem
                                    dataUI={getDataUI()}
                                    title="Group task name"
                                    assignedTo="BL Approval Team 3"
                                    renderBtn={<Tooltip title="Some Action">
                                        <Button dataUI={getDataUI()} btnType="icon" icon="fal fa-file-alt" />
                                    </Tooltip>
                                    }
                                    renderStatus={<Tooltip title={getActivityChecklistDataTip(this.state.activityChecklistStatuses[0].isChecked)}>
                                        <Checkbox dataUI={getDataUI()}
                                            checked={this.state.activityChecklistStatuses[0].isChecked}
                                            onChange={() => this.onChangeActivitiChecklistStatus(0)}
                                        />
                                    </Tooltip>
                                    }
                                    onClick={() => console.log('click span')}
                                    contextMenuItems={[<a href="/" onClick={(e) => { e.preventDefault(); console.log('link context') }}>Add actvity below</a>]}
                                >
                                    <ChecklistItem
                                        dataUI={getDataUI()}
                                        title="Subtask name 1"
                                        assignedTo="BL Approval Team 3"
                                        renderBtn={<Tooltip title="Some Action">
                                            <Button dataUI={getDataUI()} btnType="icon" icon='fal fa-file-alt' />
                                        </Tooltip>
                                        }
                                        renderStatus={<Tooltip title={getActivityChecklistDataTip(this.state.activityChecklistStatuses[1].isChecked)}>
                                            <Checkbox dataUI={getDataUI()}
                                                checked={this.state.activityChecklistStatuses[1].isChecked}
                                                onChange={() => this.onChangeActivitiChecklistStatus(1)}
                                            />
                                        </Tooltip>}
                                        onClick={() => console.log('click span 2')}
                                        contextMenuItems={[<a href="/" onClick={(e) => { e.preventDefault(); console.log('link context') }}>Add actvity above</a>, <a href="/" onClick={(e) => { e.preventDefault(); console.log('link context') }}>Add actvity below</a>]}
                                    />
                                    <ChecklistItem
                                        dataUI={getDataUI()}
                                        title="Subtask name 2"
                                        assignedTo="BL Approval Team 3"
                                        renderBtn={<Tooltip title="Some Action">
                                            <Button dataUI={getDataUI()} btnType="icon" icon='fal fa-file-alt' />
                                        </Tooltip>
                                        }
                                        renderStatus={<Tooltip title="Winged">
                                            <Checkbox dataUI={getDataUI()} disabled checked />
                                        </Tooltip>}
                                        contextMenuItems={[<a href="/" onClick={(e) => { e.preventDefault(); console.log('link context') }}>Add actvity above</a>, <a href="/" onClick={(e) => { e.preventDefault(); console.log('link context') }}>Add actvity below</a>]}
                                    />
                                    <ChecklistItem
                                        dataUI={getDataUI()} title="Subtask name 3" assignedTo="BL Approval Team 3"
                                        renderBtn={<Tooltip title="Some Action">
                                            <Button dataUI={getDataUI()} btnType="icon" icon='fal fa-file-alt' />
                                        </Tooltip>
                                        }
                                        renderStatus={<Tooltip title="Opened">
                                            <Checkbox dataUI={getDataUI()} disabled />
                                        </Tooltip>}
                                        contextMenuItems={[<a href="/" onClick={(e) => { e.preventDefault(); console.log('link context') }}>Add actvity above</a>, <a href="/" onClick={(e) => { e.preventDefault(); console.log('link context') }}>Add actvity below</a>]}
                                    />

                                    <ChecklistItem
                                        dataUI={getDataUI()}
                                        title={<a href='/' onClick={(e) => { e.preventDefault(); console.log('click href 1') }}>Subtask Link</a>}
                                        assignedTo={<a href='/' onClick={(e) => { e.preventDefault(); console.log('click href 2') }}>Approval Team 2</a>}
                                        contextMenuItems={[<a href="/" onClick={(e) => { e.preventDefault(); console.log('link context') }}>Add actvity above</a>, <a href="/" onClick={(e) => { e.preventDefault(); console.log('link context') }}>Add actvity below</a>]}
                                        renderBtn={<Tooltip title="Some Action">
                                            <Button dataUI={getDataUI()} btnType="icon" icon='fal fa-file-alt' />
                                        </Tooltip>
                                        }
                                        renderStatus={<Tooltip title="Cancelled">
                                            <Icon dataUI={getDataUI()} size="S" icon="far fa-ban" />
                                        </Tooltip>
                                        }
                                    />
                                </ChecklistItem>

                                <ChecklistItem
                                    dataUI={getDataUI()}
                                    title={<a href="/" onClick={(e) => { e.preventDefault(); console.log('link parent') }}>Group task name</a>}
                                    assignedTo="BL Approval Team 3"
                                    renderBtn={<Tooltip title="Some Action">
                                        <Button dataUI={getDataUI()} btnType="icon" icon='fal fa-file-alt' />
                                    </Tooltip>
                                    }
                                    renderStatus={<Tooltip title="N/A">
                                        <Icon dataUI={getDataUI()} size="S" icon="far fa-check-double" />
                                    </Tooltip>
                                    }
                                    contextMenuItems={[<a href="/" onClick={(e) => { e.preventDefault(); console.log('link context') }}>Add actvity below</a>]}
                                >
                                    <ChecklistItem
                                        dataUI={getDataUI()}
                                        title="Subtask name 1"
                                        assignedTo="BL Approval Team 3"
                                        renderBtn={<Tooltip title="Some Action">
                                            <Button dataUI={getDataUI()} btnType="icon" icon='fal fa-file-alt' />
                                        </Tooltip>
                                        }
                                        renderStatus={<Tooltip title={getActivityChecklistDataTip(this.state.activityChecklistStatuses[2].isChecked)}>
                                            <Checkbox dataUI={getDataUI()}
                                                onChange={() => this.onChangeActivitiChecklistStatus(2)}
                                                checked={this.state.activityChecklistStatuses[2].isChecked}
                                            />
                                        </Tooltip>}
                                        contextMenuItems={[<a href="/" onClick={(e) => { e.preventDefault(); console.log('link context') }}>Add actvity above</a>, <a href="/" onClick={(e) => { e.preventDefault(); console.log('link context') }}>Add actvity below</a>]}
                                    />
                                    <ChecklistItem
                                        dataUI={getDataUI()}
                                        title="Subtask name 2"
                                        assignedTo="BL Approval Team 3"
                                        renderStatus={<Tooltip title={getActivityChecklistDataTip(this.state.activityChecklistStatuses[3].isChecked)}>
                                            <Checkbox dataUI={getDataUI()}
                                                onChange={() => this.onChangeActivitiChecklistStatus(3)}
                                                checked={this.state.activityChecklistStatuses[3].isChecked}
                                            />
                                        </Tooltip>}
                                        renderBtn={<Tooltip title="Some Action">
                                            <Button dataUI={getDataUI()} btnType="icon" icon='fal fa-file-alt' />
                                        </Tooltip>
                                        }
                                        contextMenuItems={[<a href="/" onClick={(e) => { e.preventDefault(); console.log('link context') }}>Add actvity above</a>, <a href="/" onClick={(e) => { e.preventDefault(); console.log('link context') }}>Add actvity below</a>]}
                                    />
                                    <ChecklistItem
                                        dataUI={getDataUI()}
                                        title="Subtask name 3"
                                        assignedTo="BL Approval Team 3"
                                        renderStatus={<Tooltip title={getActivityChecklistDataTip(this.state.activityChecklistStatuses[4].isChecked)}>
                                            <Checkbox dataUI={getDataUI()}
                                                onChange={() => this.onChangeActivitiChecklistStatus(4)}
                                                checked={this.state.activityChecklistStatuses[4].isChecked}
                                            />
                                        </Tooltip>}
                                        renderBtn={<Tooltip title="Some Action">
                                            <Button dataUI={getDataUI()} btnType="icon" icon='fal fa-file-alt' />
                                        </Tooltip>
                                        }
                                        contextMenuItems={[<a href="/" onClick={(e) => { e.preventDefault(); console.log('link context') }}>Add actvity above</a>, <a href="/" onClick={(e) => { e.preventDefault(); console.log('link context') }}>Add actvity below</a>]}
                                    />
                                </ChecklistItem>

                            </ChecklistContent>
                        }

                        {this.state.activityChecklistTabIndex === 1 &&
                            <>
                                <FileUpload
                                    dataUI={getDataUI()}
                                    className="mt-s"
                                    onChange={(files: any) => {
                                        onAddFile(files, 'demoFiles', this);
                                    }}
                                    isMultiple
                                >
                                    {renderFileRows('demoFiles', this)}
                                </FileUpload>
                            </>
                        }
                    </ScrollableContainer>
                </BottomSection>}
            </ReactAux>
        )
    }
}