import React from 'react';
import Highlight from 'react-highlight';
import { MaskInput, ValidationMessage } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';


const MaskInputPage: React.FC = () => {
    return (
        <div>
            <h1 className="mb-s">Mask Input</h1>
            <p className="mb-l">The Mask Input component extends the native input element.</p>

            <div className="mb-m">
                <MaskInput
                    mask="(999) 000-9999"
                    placeholder="(999) 000-9999"
                    name="phone-mask"
                    onChange={(e) => console.log(e.target.name, e.target.value)}
                    className="mb-s"
                    dataUI={getDataUI()}
                />
                <MaskInput
                    className="mb-s"
                    placeholder="MM/YY"
                    mask="00/00"
                    name="date-mask"
                    onChange={(e) => console.log(e.target.name, e.target.value)}
                    dataUI={getDataUI()}
                />
                <MaskInput
                    maskChar='-'
                    alwaysShowMask={true}
                    mask="999999999"
                    name="tin-mask"
                    onChange={(e) => console.log(e.target.name, e.target.value)}
                    className="mb-s"
                    dataUI={getDataUI()}
                />
            </div>

            <h3 className="mb-s">MaskInput with dark border.</h3>
            <MaskInput
                dataUI={getDataUI()}
                darkBorder
                mask="(999) 000-9999"
                placeholder="(999) 000-9999"
                name="phone-mask"
                onChange={(e) => console.log(e.target.name, e.target.value)}
                className="mb-m"
            />

            <h3 className="mb-s">MaskInput invalid</h3>
            <MaskInput
                dataUI={getDataUI()}
                invalid
                mask="(999) 000-9999"
                placeholder="(999) 000-9999"
                name="phone-mask"
                onChange={(e) => console.log(e.target.name, e.target.value)}
            />
            <ValidationMessage dataUI={getDataUI()}  type='error' message='Invalid message' />

            <Highlight className="React">
                {
                    `
import React from 'react';
import { Input } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const Example: React.FC = (props) => {
    return (
      <div>
            <MaskInput
                mask="(999) 000-9999"
                placeholder="(999) 000-9999"
                name="phone-mask"
                onChange={(e) => console.log(e.target.name, e.target.value)}
                className="mb-s"
                dataUI={getDataUI()}
            />
            <MaskInput
                className="mb-s"
                mask="00/00"
                placeholder="MM/YY"
                name="date-mask"
                onChange={(e) => console.log(e.target.name, e.target.value)}
                dataUI={getDataUI()}
            />
            <MaskInput
                maskChar='-'
                alwaysShowMask={true}
                mask="999999999"
                name="tin-mask"
                onChange={(e) => console.log(e.target.name, e.target.value)}
                className="mb-s"
                dataUI={getDataUI()}
            />

            // MaskInput with dark border.
            <MaskInput
                darkBorder
                mask="(999) 000-9999"
                placeholder="(999) 000-9999"
                name="phone-mask"
                onChange={(e) => console.log(e.target.name, e.target.value)}
                dataUI={getDataUI()}
            />

            // MaskInput invalid
            <MaskInput
                invalid
                mask="(999) 000-9999"
                placeholder="(999) 000-9999"
                name="phone-mask"
                onChange={(e) => console.log(e.target.name, e.target.value)}
                dataUI={getDataUI()}
            />
            <ValidationMessage type='error' message='Invalid message' dataUI={getDataUI()}/>
      </div>
    );
}

export default Example;
    `}
            </Highlight>

            <h2 className="mb-m">API</h2>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>mask</td>
                        <td className='text-center'>string</td>
                        <td className='text-center'>-</td>
                        <td>Set the mask</td>
                    </tr>
                    <tr>
                        <td>alwaysShowMask</td>
                        <td className='text-center'>boolean</td>
                        <td className='text-center'>false</td>
                        <td>Set mask to be visible</td>
                    </tr>
                    <tr>
                        <td>maskChar</td>
                        <td className='text-center'>string</td>
                        <td className='text-center'>"_"</td>
                        <td>Set character to cover unfilled parts of the mask</td>
                    </tr>
                    <tr>
                        <td>invalid</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Sets component invalid styles. You can set component invalid styles and by adding class 'invalid' </td>
                    </tr>
                    <tr>
                        <td>darkBorder</td>
                        <td className='text-center'>boolean</td>
                        <td className='text-center'>-</td>
                        <td>Sets component border color</td>
                    </tr>
                    <tr>
                        <td>all native input attributes</td>
                        <td className='text-center'>-</td>
                        <td className='text-center'>-</td>
                        <td>You can use all native input attributes like: "type", "disabled", "name"...</td>
                    </tr>
                </tbody>
            </table>

        </div>
    )
}

export default MaskInputPage;
