import React from 'react';
import Highlight from 'react-highlight';

const PortalHeaderPage: React.FC = () => {

    return <div>
        <h1 className="mb-s">ResizableSections</h1>
        <p className="mb-s">
            Helper for rendering two sections with resizable.
        </p>
        <p className="mb-l">An example can be seen <a href='/demo/resizable-sections' title="ResizableSections example">here</a>.</p>

        <Highlight className="React">
            {
                `
import React from 'react';
import { ResizableSections } from 'vsg-design-system';
import { getDataUI } from '../../helpers/helpers';

const Example: React.FC = () => {
    return (
        <ResizableSections
            className="mt-s"
            dataUI={getDataUI()}
            leftDefaultPercent={40}
            minPercentLeft={22}
            maxPercentLeft={77}
            onChange={(value: any) => {
                console.log(value, ' ~~~onChange out');
            }}
            leftSection={
                <>
                    Content...
                </>
            }
            rightSection={
                <>
                    Content...
                </>
            }
        />
    );
}
export default Example;
    `}
        </Highlight>

        <h2 className="mb-m">API</h2>
        <table className="doc-table mb-l">
            <thead>
                <tr>
                    <th colSpan={4}>Props</th>
                </tr>
                <tr>
                    <td>
                        <strong>Name</strong>
                    </td>
                    <td>
                        <strong>Options</strong>
                    </td>
                    <td>
                        <strong>Default</strong>
                    </td>
                    <td>
                        <strong>Description</strong>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>leftSection</td>
                    <td>JSX.Element</td>
                    <td className="text-center">-</td>
                    <td>Render the left content.</td>
                </tr>
                <tr>
                    <td>rightSection</td>
                    <td>JSX.Element</td>
                    <td className="text-center">-</td>
                    <td>Render the right content.</td>
                </tr>
                <tr>
                    <td>leftDefaultPercent</td>
                    <td>number</td>
                    <td>50</td>
                    <td>Set default percent for left section.</td>
                </tr>
                <tr>
                    <td>minPercentLeft</td>
                    <td>number</td>
                    <td>25</td>
                    <td>Set min percent for left section.</td>
                </tr>
                <tr>
                    <td>maxPercentLeft</td>
                    <td>number</td>
                    <td>75</td>
                    <td>Set max percent for left section.</td>
                </tr>
                <tr>
                    <td>onChange</td>
                    <td>() {'=>'} void</td>
                    <td className="text-center">-</td>
                    <td>Callback function when resize.</td>
                </tr>
            </tbody>
        </table>
    </div>;
}

export default PortalHeaderPage;
