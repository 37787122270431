/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Highlight from 'react-highlight';
import { Button, Tooltip, InfoBox } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const ButtonPage: React.FC = () => {
    return (
        <div>
            <h1 className="mb-s">Button</h1>
            <p className="mb-l">The Button component extends the native Button element.</p>

            <h3 className="mb-s">Button types</h3>
            <p className="mb-l">
                There are several types of buttons : "primary", "secondary", "narrow", "icon", "link".
            </p>

            <div className="d-flex mb-m">
                <Button btnType="primary" icon="fal fa-check" className="mr-s" dataUI={getDataUI()}>Primary</Button>
                <Button btnType="secondary" icon="fal fa-check" className="mr-s" dataUI={getDataUI()}>Secondary</Button>
                <Tooltip title="Narrow button">
                    <Button btnType="narrow" className="mr-s" dataUI={getDataUI()}>Narrow</Button>
                </Tooltip>
                <Tooltip title="File">
                    <Button btnType="icon" icon="fal fa-file-alt" className="mr-s"  dataUI={getDataUI()}/>
                </Tooltip>
                <Tooltip title="File">
                    <Button isSuccess btnType="icon" icon="fal fa-file-alt" className="mr-s" dataUI={getDataUI()}/>
                </Tooltip>
                <Tooltip title="File">
                    <Button isDanger btnType="icon" icon="fal fa-file-alt" className="mr-s" dataUI={getDataUI()}/>
                </Tooltip>
                <Button btnType="link" className="mr-s" dataUI={getDataUI()}>Link</Button>
            </div>

            <h3 className="mb-s">Disabled Button</h3>
            <div className="d-flex mb-m">
                <Button btnType="primary" icon="fal fa-check" className="mr-s" disabled dataUI={getDataUI()}>Primary</Button>
                <Button btnType="secondary" icon="fal fa-check" className="mr-s" disabled dataUI={getDataUI()}>Secondary</Button>
                <Tooltip title="Narrow button">
                    <Button btnType="narrow" className="mr-s" disabled dataUI={getDataUI()}>Narrow</Button>
                </Tooltip>
                <Tooltip title="File">
                    <Button btnType="icon" icon="fal fa-file-alt" className="mr-s" disabled  dataUI={getDataUI()}/>
                </Tooltip>
                <Tooltip title="File">
                    <Button isSuccess btnType="icon" icon="fal fa-file-alt" className="mr-s" disabled dataUI={getDataUI()}/>
                </Tooltip>
                <Button btnType="link" className="mr-s" disabled dataUI={getDataUI()}>Link</Button>
            </div>

            <h3 className="mb-s">Button with link child</h3>
            <InfoBox className="mb-s" type="warning" icon="fal fa-exclamation-triangle" dataUI={getDataUI()}>
                This type of button usage is exceptional.
                <br />
                Although supported for the purpose of consistency, it is not encouraged to use button
                with <i>btnType="link"</i> to wrap links, because this leads to ambiguity.
            </InfoBox>
            <div className="d-flex mb-xl">
                <Button btnType="primary" icon="fal fa-check" className="lv-download-link-btn mr-s" dataUI={getDataUI()}>Primary<a href='/' onClick={() => console.log('Link clicked')}>File download</a></Button>
                <Button btnType="secondary" icon="fal fa-check" className="lv-download-link-btn mr-s" dataUI={getDataUI()}>Secondary<a href='/' onClick={() => console.log('Link clicked')}>File download</a></Button>
                <Tooltip title="Narrow button">
                    <Button btnType="narrow" className="lv-download-link-btn mr-s" dataUI={getDataUI()}>Narrow<a href='/' onClick={() => console.log('Link clicked')}>File download</a></Button>
                </Tooltip>
                <Tooltip title="File">
                    <Button className='lv-download-link-btn' btnIconChildren btnType="icon" icon="fal fa-file-alt" dataUI={getDataUI()}>
                        <a href='/' onClick={() => console.log('Link clicked')}>File download</a>
                    </Button>
                </Tooltip>
                <Button btnType="link" className="mr-s" dataUI={getDataUI()}><a href='/' onClick={() => console.log('Link clicked')}>Link</a></Button>
            </div>

            <p className="mb-xl"><strong style={{ color: 'var(--color-warning)' }}>IMPORTANT:</strong> Buttons with <i>btnType</i> "narrow" always require to be wrapped with tooltip.</p>

            <Highlight className="React">
                {
                    `
import React from 'react';
import { Button, Tooltip, } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const Example: React.FC = (props) => {
    return (
      <div>
        <Button btnType="primary" icon="fal fa-check" className="mr-s" dataUI={getDataUI()}>Primary</Button>
        <Button btnType="secondary" icon="fal fa-check" className="mr-s" dataUI={getDataUI()}>Secondary</Button>
        <Tooltip title="Narrow button">
            <Button btnType="narrow" className="mr-s" dataUI={getDataUI()}>Narrow</Button>
        </Tooltip>
        <Tooltip title="File">
            <Button btnType="icon" icon="fal fa-file-alt" className="mr-s" dataUI={getDataUI()}/>
        </Tooltip>
        <Tooltip title="File">
            <Button isSuccess btnType="icon" icon="fal fa-file-alt" className="mr-s" dataUI={getDataUI()}/>
        </Tooltip>
        <Tooltip title="File">
            <Button isDanger btnType="icon" icon="fal fa-file-alt" className="mr-s" dataUI={getDataUI()}/>
        </Tooltip>
        <Button btnType="link" className="mr-s" dataUI={getDataUI()}>Link</Button>

        // Disabled buttons
        <Button btnType="primary" icon="fal fa-check" className="mr-s" disabled dataUI={getDataUI()}>Primary</Button>
        <Button btnType="secondary" icon="fal fa-check" className="mr-s" disabled dataUI={getDataUI()}>Secondary</Button>
        <Tooltip title="Narrow button">
            <Button btnType="narrow" className="mr-s" disabled dataUI={getDataUI()}>Narrow</Button>
        </Tooltip>
        <Tooltip title="File">
            <Button btnType="icon" icon="fal fa-file-alt" className="mr-s" disabled dataUI={getDataUI()}/>
        </Tooltip>
        <Tooltip title="File">
            <Button isSuccess btnType="icon" icon="fal fa-file-alt" className="mr-s" disabled dataUI={getDataUI()}/>
        </Tooltip>
        <Button btnType="link" className="mr-s" disabled dataUI={getDataUI()}>Link</Button>

        // Button with link child
        <Button btnType="primary" icon="fal fa-check" className="lv-download-link-btn mr-s" dataUI={getDataUI()}>Primary<a onClick={() => console.log('Link clicked')}>File download</a></Button>
        <Button btnType="secondary" icon="fal fa-check" className="lv-download-link-btn mr-s" dataUI={getDataUI()}>Secondary<a onClick={() => console.log('Link clicked')}>File download</a></Button>
        <Tooltip title="Narrow button">
            <Button btnType="narrow" className="lv-download-link-btn mr-s" dataUI={getDataUI()}>Narrow<a onClick={() => console.log('Link clicked')}>File download</a></Button>
        </Tooltip>
        <Tooltip title="File">
            <Button className='lv-download-link-btn' btnIconChildren btnType="icon" icon="fal fa-file-alt" dataUI={getDataUI()}>
                <a onClick={() => console.log('Link clicked')}>File download</a>
            </Button>
        </Tooltip>
        <Button btnType="link" className="mr-s" dataUI={getDataUI()}><a onClick={() => console.log('Link clicked')}>Link</a></Button>
      </div>
    );
}

export default Example;
    `}
            </Highlight>

            <h2 className="mb-m">API</h2>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>btnType</td>
                        <td>"primary", "secondary", "narrow", "icon", "link"</td>
                        <td>"primary"</td>
                        <td>Sets different style to the button.</td>
                    </tr>
                    <tr>
                        <td>icon</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Sets the icon to the button.</td>
                    </tr>
                    <tr>
                        <td>isSuccess</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Shows a success icon. Available only when <i>btnType</i> is "icon".</td>
                    </tr>
                    <tr>
                        <td>isDanger</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Makes icon to be 'danger' color.</td>
                    </tr>
                    <tr>
                        <td>all native button attributes</td>
                        <td className="text-center">-</td>
                        <td className="text-center">-</td>
                        <td>You can use all native button attributes like: "type", "disabled", "name", etc.</td>
                    </tr>
                </tbody>
            </table>

        </div>
    )
}

export default ButtonPage;
