import React from 'react';
import Highlight from 'react-highlight';
import { Checkbox } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';


const CheckboxPage: React.FC = () => {
    return (
        <div>
            <h1 className="mb-s">Checkbox</h1>
            <p className="mb-l">The Checkbox component extends the native <i>input type="checkbox"</i> element.</p>

            <h3 className="mb-s">Default</h3>
            <div className="mb-m">
                <Checkbox checked onChange={() => { }} dataUI={getDataUI()}>Checkbox</Checkbox>
                <Checkbox readOnly checked dataUI={getDataUI()}>Checkbox ReadOnly</Checkbox>
                <Checkbox disabled checked onChange={() => { }} dataUI={getDataUI()}>Checkbox Disabled</Checkbox>
                <Checkbox dataUI={getDataUI()}>Checkbox</Checkbox>
                <Checkbox readOnly dataUI={getDataUI()}>Checkbox ReadOnly</Checkbox>
                <Checkbox disabled dataUI={getDataUI()}>Checkbox Disabled</Checkbox>
            </div>

            <h3 className="mb-s">Default no text</h3>
            <div className="mb-m">
                <Checkbox checked onChange={() => { }} dataUI={getDataUI()}/>
                <Checkbox readOnly checked dataUI={getDataUI()}/>
                <Checkbox checked disabled onChange={() => { }} dataUI={getDataUI()}/>
                <Checkbox dataUI={getDataUI()}/>
                <Checkbox readOnly dataUI={getDataUI()}/>
                <Checkbox disabled dataUI={getDataUI()}/>
            </div>

            <h3 className="mb-s">Block with text</h3>
            <div className="mb-m">
                <Checkbox checked onChange={() => { }} className="d-block" dataUI={getDataUI()}>Checkbox</Checkbox>
                <Checkbox className="d-block" readOnly checked dataUI={getDataUI()}>Checkbox ReadOnly</Checkbox>
                <Checkbox disabled checked className="d-block" dataUI={getDataUI()}>Checkbox Disabled</Checkbox>
                <Checkbox className="d-block" dataUI={getDataUI()}>Checkbox</Checkbox>
                <Checkbox className="d-block" readOnly dataUI={getDataUI()}>Checkbox ReadOnly</Checkbox>
                <Checkbox disabled className="d-block" dataUI={getDataUI()}>Checkbox Disabled</Checkbox>
            </div>

            <h3 className="mb-s">Block no text</h3>
            <div className="mb-m">
                <Checkbox checked onChange={() => { }} className="d-block" dataUI={getDataUI()}/>
                <Checkbox readOnly checked className="d-block" dataUI={getDataUI()}/>
                <Checkbox disabled checked onChange={() => { }} className="d-block" dataUI={getDataUI()}/>
                <Checkbox className="d-block" dataUI={getDataUI()}/>
                <Checkbox readOnly className="d-block" dataUI={getDataUI()}/>
                <Checkbox disabled className="d-block" dataUI={getDataUI()}/>
            </div>

            <Highlight className="React">
                {
                    `
import React from 'react';
import { Checkbox } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const Example: React.FC = (props) => {
    return (
      <div>
        // Default
        <Checkbox checked onChange={() => { }} dataUI={getDataUI()}>Checkbox</Checkbox>
        <Checkbox readOnly checked dataUI={getDataUI()}>Checkbox ReadOnly</Checkbox>
        <Checkbox disabled checked onChange={() => { }} dataUI={getDataUI()}>Checkbox Disabled</Checkbox>
        <Checkbox dataUI={getDataUI()}>Checkbox</Checkbox>
        <Checkbox readOnly dataUI={getDataUI()}>Checkbox ReadOnly</Checkbox>
        <Checkbox disabled dataUI={getDataUI()}>Checkbox Disabled</Checkbox>

        // Default no text
        <Checkbox checked onChange={() => { }} dataUI={getDataUI()}/>
        <Checkbox readOnly checked dataUI={getDataUI()}/>
        <Checkbox checked disabled onChange={() => { }} dataUI={getDataUI()}/>
        <Checkbox dataUI={getDataUI()}/>
        <Checkbox readOnly dataUI={getDataUI()}/>
        <Checkbox disabled dataUI={getDataUI()}/>

         // Block with text
        <Checkbox checked onChange={() => { }} className="d-block" dataUI={getDataUI()}>Checkbox</Checkbox>
        <Checkbox className="d-block" readOnly checked dataUI={getDataUI()}>Checkbox ReadOnly</Checkbox>
        <Checkbox disabled checked className="d-block" dataUI={getDataUI()}>Checkbox Disabled</Checkbox>
        <Checkbox className="d-block" dataUI={getDataUI()}>Checkbox</Checkbox>
        <Checkbox className="d-block" readOnly dataUI={getDataUI()}>Checkbox ReadOnly</Checkbox>
        <Checkbox disabled className="d-block" dataUI={getDataUI()}>Checkbox Disabled</Checkbox>

        // Block no text
        <Checkbox checked onChange={() => { }} className="d-block" dataUI={getDataUI()}/>
        <Checkbox readOnly checked className="d-block" dataUI={getDataUI()}/>
        <Checkbox disabled checked onChange={() => { }} className="d-block" dataUI={getDataUI()} />
        <Checkbox className="d-block" dataUI={getDataUI()} />
        <Checkbox readOnly className="d-block" dataUI={getDataUI()}/>
        <Checkbox disabled className="d-block" dataUI={getDataUI()}/>
      </div>
    );
}

export default Example;
    `}
            </Highlight>

            <h2 className="mb-m">API</h2>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>all native input attributes</td>
                        <td className='text-center'>-</td>
                        <td className='text-center'>-</td>
                        <td>You can use all native input attributes like: "disabled", "name", "checked"...</td>
                    </tr>
                </tbody>
            </table>

        </div>
    )
}

export default CheckboxPage;