import React from 'react';

import {
    MainContainerHeader, ReactAux, ActionContainer, ActionContainerHeader,
    ActionContainerCrumbs, Button, MainContainerScrollable, Modal, ModalScrollableContainer, Toolbar,
} from '@jkhy/vsg-loanvantage-design-system';

import KendoTable from './KendoTable/KendoTable';
import { getDataUI } from '../helpers/helpers';


class DemoScroll6 extends React.Component {

    state = {
        modal: false,
    }

    render() {

        return (
            <ReactAux>
                <MainContainerHeader dataUI={getDataUI()} className="mb-m">
                    <ActionContainer dataUI={getDataUI()}>
                        <ActionContainerHeader dataUI={getDataUI()}>
                            <ActionContainerCrumbs dataUI={getDataUI()} crumbs={[<a href="/">Loan Collateral</a>, '36465: Loan Request']} />
                        </ActionContainerHeader>
                        <Button dataUI={getDataUI()} icon="fal fa-pen" onClick={() => this.setState({ modal: true })}>
                            Open Modal with KendoTalbe
                        </Button>
                    </ActionContainer>
                </MainContainerHeader>

                <MainContainerScrollable>
                    <KendoTable />
                </MainContainerScrollable>

                <Modal
                    dataUI={getDataUI()}
                    title="Modal with KendoTable"
                    size="M"
                    isVisible={this.state.modal}
                    onClose={() => this.setState({ modal: false })}
                >
                    <Toolbar dataUI={getDataUI()} className="mb-m"
                        leftSide={
                            <>
                                <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-save">Save</Button>
                                <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-save">Save and Close</Button>
                                <Button dataUI={getDataUI()} icon="fal fa-times-circle" btnType="secondary">Cancel</Button>
                            </>
                        }
                    />
                    <ModalScrollableContainer>
                        <KendoTable />
                    </ModalScrollableContainer>
                </Modal>
            </ReactAux>
        );
    }
}

export default DemoScroll6;