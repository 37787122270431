import React from 'react';

import {
    Tabs, TabList, TabItem, TabItemTitle, TabContent, TabContentItem, MainContainerScrollable
} from '@jkhy/vsg-loanvantage-design-system';

import Components from './Components';
import DemoLocationPage from './DemoLocationPage';
import DemoPagesLinks from './DemoPagesLinks';
import DemoChartsPage from './DemoChartsPage';
import DnD from './DnD';
import { getDataUI } from '../helpers/helpers';



class ComponentsTabs extends React.Component {
    state = {
        currentlyActiveTab: 0
    };

    setCurrentlyActiveTab = (newTabIndex: number) => {
        this.setState({ currentlyActiveTab: newTabIndex });
    }

    render() {

        return (
            <Tabs dataUI={getDataUI()}  currentlyActiveTabIndex={this.state.currentlyActiveTab} onTabIndexChange={i => this.setCurrentlyActiveTab(i)}>
                <TabList dataUI={getDataUI()} >
                    <TabItem dataUI={getDataUI()} >
                        <TabItemTitle dataUI={getDataUI()} >Demo Pages/Components</TabItemTitle>
                    </TabItem>
                    <TabItem dataUI={getDataUI()} >
                        <TabItemTitle dataUI={getDataUI()} >Demo Location Page</TabItemTitle>
                    </TabItem>
                    <TabItem dataUI={getDataUI()} >
                        <TabItemTitle dataUI={getDataUI()} >Components</TabItemTitle>
                    </TabItem>
                    <TabItem dataUI={getDataUI()} >
                        <TabItemTitle dataUI={getDataUI()} >Charts</TabItemTitle>
                    </TabItem>
                    <TabItem dataUI={getDataUI()} >
                        <TabItemTitle dataUI={getDataUI()} >Drag-n-Drop examples</TabItemTitle>
                    </TabItem>
                </TabList>
                <TabContent dataUI={getDataUI()} >
                    <TabContentItem dataUI={getDataUI()} >

                        <DemoPagesLinks />

                    </TabContentItem>
                    <TabContentItem dataUI={getDataUI()} >

                        <MainContainerScrollable>
                            <DemoLocationPage />
                        </MainContainerScrollable>

                    </TabContentItem>
                    <TabContentItem dataUI={getDataUI()} >

                        <Components />

                    </TabContentItem>
                    <TabContentItem dataUI={getDataUI()} >
                        <MainContainerScrollable>
                            <DemoChartsPage />
                        </MainContainerScrollable>
                    </TabContentItem>
                    <TabContentItem dataUI={getDataUI()} >
                        <DnD />
                    </TabContentItem>
                </TabContent>
            </Tabs>
        );
    }
}

export default ComponentsTabs;
