import React from 'react';

import {
    ReactAux, Main, MainContainer, MainContainerScrollable, LeftSideBar, TopSection, SimpleTableContainer,
    ScrollableContainer, Button, WizardNavStepsControls, ActionContainer, ActionContainerCrumbs,
    ActionContainerHeader, MainContainerHeader, LeftBarCrumbs, LinkWithIcon, Label, Radio,
} from '@jkhy/vsg-loanvantage-design-system';

import DemoHeader from './DemoHeader';
import { getDataUI } from '../helpers/helpers';

class DemoWorkPage1 extends React.Component {

    render() {
        return (
            <ReactAux>
                <DemoHeader />
                <Main dataUI={getDataUI()}>
                    <LeftSideBar dataUI={getDataUI()}>
                        <TopSection dataUI={getDataUI()}>
                            <LeftBarCrumbs
                                dataUI={getDataUI()}
                                className="mb-m lv-side-bar-neggative-margin"
                                links={[<LinkWithIcon dataUI={getDataUI()} icon="fal fa-arrow-left" iconPlace="left">
                                    <a href="/">Back to Work Queue</a>
                                </LinkWithIcon>]}
                            />

                            <ScrollableContainer>
                                <div className="d-flex mb-s">
                                    <Label dataUI={getDataUI()} className="mr-s"><strong>Loan Number</strong></Label>
                                    <Label dataUI={getDataUI()}>1-00101</Label>
                                </div>
                                <div className="d-flex mb-s">
                                    <Label dataUI={getDataUI()} className="mr-s"><strong>Account Name</strong></Label>
                                    <Label dataUI={getDataUI()}>478 WARNER</Label>
                                </div>
                                <div className="d-flex mb-m">
                                    <Label dataUI={getDataUI()} className="mr-s"><strong>Maturity Date</strong></Label>
                                    <Label dataUI={getDataUI()}>10/29/1996</Label>
                                </div>

                                <SimpleTableContainer dataUI={getDataUI()} className="mb-m">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Business Borrowers</th>
                                                <th></th>
                                                <th>Is Primary</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Thomas W Conlin LLC</td>
                                                <td>Sole Owner</td>
                                                <td className="text-center"><Radio dataUI={getDataUI()} name="radio-4" /></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </SimpleTableContainer>

                                <SimpleTableContainer dataUI={getDataUI()} className="mb-m">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Personal Guarantors</th>
                                                <th></th>
                                                <th>Is Primary</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Kary Testing</td>
                                                <td>Sole Owner</td>
                                                <td className="text-center"><Radio dataUI={getDataUI()} name="radio-4" /></td>
                                            </tr>
                                            <tr>
                                                <td>Mo Tester</td>
                                                <td>Sole Owner</td>
                                                <td className="text-center"><Radio dataUI={getDataUI()} name="radio-4" /></td>
                                            </tr>
                                            <tr>
                                                <td>Moby Tester</td>
                                                <td>Sole Owner</td>
                                                <td className="text-center"><Radio dataUI={getDataUI()} name="radio-4" /></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </SimpleTableContainer>

                                <WizardNavStepsControls
                                    dataUI={getDataUI()}
                                    className="mb-m"
                                    saveBtn={<Button  dataUI={getDataUI()} icon="fal fa-save" btnType="secondary">Save and proceed</Button>}
                                    nextStep={<a href="/">Skip</a>}
                                />
                                <p className="text-small"><strong>146 more</strong> loans remaining</p>
                            </ScrollableContainer>
                        </TopSection>
                    </LeftSideBar>

                    <MainContainer dataUI={getDataUI()}>
                        <MainContainerHeader dataUI={getDataUI()}>
                            <ActionContainer dataUI={getDataUI()}>
                                <ActionContainerHeader dataUI={getDataUI()}>
                                    <ActionContainerCrumbs dataUI={getDataUI()} crumbs={['File Notes']} />
                                </ActionContainerHeader>
                            </ActionContainer>
                        </MainContainerHeader>

                        <MainContainerScrollable>
                            <p className="mt-m mb-s">Content...</p>
                        </MainContainerScrollable>
                    </MainContainer>
                </Main>
            </ReactAux>
        );
    }
}

export default DemoWorkPage1;