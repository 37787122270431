import React from 'react';
import Highlight from 'react-highlight';

const ErrorStatusPage: React.FC = () => {

    return <div>
        <h1 className="mb-s">ErrorStatus</h1>
        <p className="mb-s">Helper to display error status.</p>
        <p className="mb-l">Demo pages: <a href='/demo/401' title="401">401</a>, <a href='/demo/403' title="403">403</a>, <a href='/demo/404' title="404">404</a> and <a href='/demo/500' title="500">500</a>.</p>

        <Highlight className="React">
            {
                `
import React from 'react';
import { ErrorStatus } from 'vsg-design-system';

const Example: React.FC = () => {
    return (
        <ErrorStatus
            status="401"
            onButtonClick={() => {
                history.push('/home')
            }}
        />
    );
}
export default Example;
    `}
        </Highlight>

        <h2 className="mb-m">API</h2>
        <table className="doc-table mb-l">
            <thead>
                <tr>
                    <th colSpan={4}>Props</th>
                </tr>
                <tr>
                    <td>
                        <strong>Name</strong>
                    </td>
                    <td>
                        <strong>Options</strong>
                    </td>
                    <td>
                        <strong>Default</strong>
                    </td>
                    <td>
                        <strong>Description</strong>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>status</td>
                    <td>'401' | '403' | '404' | '500'</td>
                    <td className="text-center">-</td>
                    <td>Set the status.</td>
                </tr>
                <tr>
                    <td>title</td>
                    <td>string</td>
                    <td>
                        401: 'Unauthorized'
                        <br />
                        403: 'Missing Permissions'
                        <br />
                        404: 'Page Not Found'
                        <br />
                        500: 'Internal Server Error'
                    </td>
                    <td>Set text for title. Depends on the <i>status</i> for value.</td>
                </tr>
                <tr>
                    <td>description</td>
                    <td>string</td>
                    <td>
                        401: 'You are not authorized to view this page.'
                        <br />
                        403: 'You don’t have permission to view this page.'
                        <br />
                        404: 'The page you are looking for could not be found.'
                        <br />
                        500: 'We are working on fixing the problem. Please try again.'
                    </td>
                    <td>Set text for description. Depends on the <i>status</i> for value.</td>
                </tr>
                <tr>
                    <td>cta</td>
                    <td>string</td>
                    <td>
                        401: 'Return home'
                        <br />
                        403: 'Return home'
                        <br />
                        404: 'Go back'
                        <br />
                        500: 'Refresh page'
                    </td>
                    <td>Set text for cta. Depends on the <i>status</i> for value.</td>
                </tr>
                <tr>
                    <td>onButtonClick</td>
                    <td>() {'=>'} void</td>
                    <td className="text-center">-</td>
                    <td>Callback when button is clicked.</td>
                </tr>
            </tbody>
        </table>
    </div>;
}

export default ErrorStatusPage;
