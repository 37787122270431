import React from 'react';
import Highlight from 'react-highlight';
import {
    Toolbar, Button, Icon, Select, ReactAux, Tooltip,
} from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const ToolbarPage: React.FC = () => {
    return (
        <div>
            <h1 className="mb-s">Toolbar</h1>
            <p className="mb-l">Wrapper component with left and right side.</p>

            <Toolbar
                dataUI={getDataUI()}
                className="mb-m"
                leftSide={
                    <>
                        <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-plus">Add note</Button>
                        <Button dataUI={getDataUI()} icon="fal fa-bell-slash" btnType="secondary">Unsubscribe</Button>
                    </>
                }
            />

            <Toolbar 
                className="mb-l"
                dataUI={getDataUI()}
                leftSide={
                    <ReactAux>
                        <Icon dataUI={getDataUI()} className="mr-xs" icon="fal fa-search" size="S" />
                        <p className="mr-xs">Zoom:</p>
                        <Select dataUI={getDataUI()} className="mr-s width-s" value="100%" options={[{ label: '100%', value: '100%' }, { label: '50%', value: '50%' }, { label: '0%', value: '0%' }]} />
                        <Tooltip title="Zoom in">
                            <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-plus" btnType="icon" />
                        </Tooltip>
                        <Tooltip title="Zoom out">
                            <Button dataUI={getDataUI()} icon="fal fa-minus" btnType="icon" />
                        </Tooltip>
                    </ReactAux>
                }
                rightSide={
                    <ReactAux>
                        <Icon dataUI={getDataUI()} className="mr-xs" icon="fal fa-hand-paper" size="S" />
                        <p>Hold ‘Shift’ key to move.</p>
                    </ReactAux>
                }
            />

            <Highlight className="React">
                {
                    `
import React from 'react';
import { Toolbar, Button, Icon, Select, ReactAux, Tooltip, } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const Example: React.FC = (props) => {
    return (
        <div>
            <Toolbar
                dataUI={getDataUI()}
                leftSide={
                    <>
                        <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-plus">Add note</Button>
                        <Button dataUI={getDataUI()} icon="fal fa-bell-slash" btnType="secondary">Unsubscribe</Button>
                    </>
                } 
            />

            <Toolbar
                dataUI={getDataUI()}
                leftSide={
                    <ReactAux>
                        <Icon dataUI={getDataUI()} className="mr-xs" icon="fal fa-search" size="S" />
                        <p className="mr-xs">Zoom:</p>
                        <Select dataUI={getDataUI()} className="mr-s width-s" value="100%" options={[{ label: '100%', value: '100%' }, { label: '50%', value: '50%' }, { label: '0%', value: '0%' }]} />
                        <Tooltip title="Zoom in">
                            <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-plus" btnType="icon" />
                        </Tooltip>
                        <Tooltip title="Zoom out">
                            <Button dataUI={getDataUI()} icon="fal fa-minus" btnType="icon" />
                        </Tooltip>
                    </ReactAux>
                }
                rightSide={
                    <ReactAux>
                        <Icon dataUI={getDataUI()} className="mr-xs" icon="fal fa-hand-paper" size="S" />
                        <p>Hold ‘Shift’ key to move.</p>
                    </ReactAux>
                }
            />
        </div>
    );
}

export default Example;
    `}
            </Highlight>

            <h2 className="mb-m">API</h2>

            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>leftSide</td>
                        <td>JSX.Element</td>
                        <td className="text-center">-</td>
                        <td>Set element for left side.</td>
                    </tr>
                    <tr>
                        <td>leftSideClassName</td>
                        <td>JSX.Element</td>
                        <td className="text-center">-</td>
                        <td>Set calssName to left side container.</td>
                    </tr>
                    <tr>
                        <td>rightSide</td>
                        <td>JSX.Element</td>
                        <td className="text-center">-</td>
                        <td>Set element for right side.</td>
                    </tr>
                    <tr>
                        <td>rightSideClassName</td>
                        <td>JSX.Element</td>
                        <td className="text-center">-</td>
                        <td>Set calssName to right side container.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default ToolbarPage;
