import React from 'react';

import {
    ReactAux, Main, MainContainer, MainContainerScrollable, LeftSideBar, TopSection, SimpleTableContainer,
    ScrollableContainer, Button, WizardNavStepsControls, ActionContainer, ActionContainerCrumbs,
    ActionContainerHeader, MainContainerHeader, LeftBarCrumbs, LinkWithIcon, Label, Textarea,
    Tooltip, ConfirmationDialog, Toolbar,
} from '@jkhy/vsg-loanvantage-design-system';

import DemoHeader from './DemoHeader';
import { getDataUI } from '../helpers/helpers';

class DemoWorkPage2 extends React.Component {

    state = {
        confirmShow: false,
    }

    render() {
        return (
            <ReactAux>
                <DemoHeader />
                <Main dataUI={getDataUI()}>
                    <LeftSideBar dataUI={getDataUI()}>
                        <TopSection dataUI={getDataUI()}>
                            <LeftBarCrumbs
                                dataUI={getDataUI()}
                                className="mb-m lv-side-bar-neggative-margin"
                                links={[<LinkWithIcon dataUI={getDataUI()} icon="fal fa-arrow-left" iconPlace="left">
                                    <a href="/">Back to Work Queue</a>
                                </LinkWithIcon>]}
                            />

                            <ScrollableContainer>
                                <Button dataUI={getDataUI()} className="mb-m" icon="fal fa-file-alt" btnType="secondary">View/Edit Required Document</Button>
                                <div className="d-flex mb-s">
                                    <Label dataUI={getDataUI()} className="mr-s"><strong>Document Type</strong></Label>
                                    <Label dataUI={getDataUI()}>Elena - Doc Type</Label>
                                </div>
                                <div className="d-flex mb-s">
                                    <Label dataUI={getDataUI()} className="mr-s"><strong>Description</strong></Label>
                                    <Label dataUI={getDataUI()}>478 WARNER</Label>
                                </div>
                                <div className="d-flex mb-m">
                                    <Label dataUI={getDataUI()} className="mr-s"><strong>Statement Date</strong></Label>
                                    <Label dataUI={getDataUI()}>10/29/1996</Label>
                                </div>

                                <SimpleTableContainer dataUI={getDataUI()} className="mb-m">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Entities</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>No. 1 Collateral</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </SimpleTableContainer>

                                <Label dataUI={getDataUI()} htmlFor="reason" className="mb-xs"><strong>Decline Reason</strong></Label>
                                <Textarea dataUI={getDataUI()} className="mb-m" name="textarea" id="reason" />

                                <WizardNavStepsControls
                                    dataUI={getDataUI()}
                                    className="mb-m"
                                    saveBtn={<><Button dataUI={getDataUI()} className="mr-s" icon="fal fa-check-double">Accept</Button><Button dataUI={getDataUI()} icon="fal fa-times-circle" btnType="secondary">Decline</Button></>}
                                    nextStep={<a href="/">Skip</a>}
                                />
                                <p className="text-small"><strong>146 more</strong> documents remaining</p>
                            </ScrollableContainer>
                        </TopSection>
                    </LeftSideBar>

                    <MainContainer dataUI={getDataUI()}>
                        <MainContainerHeader dataUI={getDataUI()}>
                            <ActionContainer singleLine dataUI={getDataUI()}>
                                <ActionContainerHeader dataUI={getDataUI()}>
                                    <ActionContainerCrumbs dataUI={getDataUI()} crumbs={['Document Review']} />
                                </ActionContainerHeader>
                                <div className="d-flex align-items-center ml-l">
                                    <Button dataUI={getDataUI()} disabled btnType="secondary" icon="fal fa-arrow-circle-left">Prev</Button>
                                    <p className="mr-s"><strong>Attachment 1 of 2</strong></p>
                                    <Button dataUI={getDataUI()} btnType="secondary" icon="fal fa-arrow-circle-right">Next</Button>
                                    <p className="mr-s">2018-01-25-13h19_20.png</p>
                                    <ConfirmationDialog
                                        dataUI={getDataUI()}
                                        show={this.state.confirmShow}
                                        render={() => <>
                                            <p className="mb-m">Are you sure you want to delete this attachment?</p>
                                            <Toolbar dataUI={getDataUI()} leftSide={
                                                <>
                                                    <Button dataUI={getDataUI()} onClick={() => this.setState({ confirmShow: false })} btnType="primary" icon='fal fa-check' className="mr-s" >Yes</Button>
                                                    <Button dataUI={getDataUI()} onClick={() => this.setState({ confirmShow: false })} btnType="secondary" icon='fal fa-times' className="mr-s" >No</Button>
                                                </>}
                                            />
                                        </>}
                                        onClose={() => this.setState({ confirmShow: false })}
                                    >
                                        <Tooltip title="Delete attachment">
                                            <Button dataUI={getDataUI()} onClick={() => this.setState({ confirmShow: true })} btnType="icon" icon="fal fa-trash" />
                                        </Tooltip>
                                    </ConfirmationDialog>
                                </div>
                            </ActionContainer>
                        </MainContainerHeader>

                        <MainContainerScrollable>
                            <p className="mt-m mb-s">Content...</p>
                        </MainContainerScrollable>
                    </MainContainer>
                </Main>
            </ReactAux>
        );
    }
}

export default DemoWorkPage2;