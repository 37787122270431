import React, { useState } from 'react';
import {
    MainContainerScrollable, DragNDropContext, DragNDropSection, VirtualizedDroparea,
    DraggableItemActions, DraggableItemTitle, Button, DragNDropUtils, Tooltip,
} from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';


const DEMO_ITEMS = Array.from({ length: 400 }).map((e, i) => (
    { title: `Item ${i}`, id: `item-${i}` }
))

const VirtualDnDSimple: React.FC = () => {

    const firstDropareaID = 'first';
    const secondDropareaID = 'second';
    const [firstSectionItems, setFirstSectionItems] = useState(DEMO_ITEMS);
    const [secondSectionItems, setSecondSectionItems] = useState<{ title: string, id: string }[]>([]);
    const [dragStartEvent, setDragStartEvent] = useState(null);
    const [dragUpdateEvent, setDragUpdateEvent] = useState(null);

    const handleDragStart = (event: any) => {
        setDragStartEvent(event);
        setDragUpdateEvent(null);
    };

    const handleDragUpdate = (event: any) => {
        setDragStartEvent(null);
        setDragUpdateEvent(event);
    };

    const handleDragEnd = (result: any) => {
        const { source, destination } = result;
        if (!result.destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            if (source.droppableId === firstDropareaID) {
                const items = DragNDropUtils.reorderList(
                    firstSectionItems,
                    source.index,
                    destination.index
                );
                setFirstSectionItems(items);
            } else if (source.droppableId === secondDropareaID) {
                const items = DragNDropUtils.reorderList(
                    secondSectionItems,
                    source.index,
                    destination.index
                );
                setSecondSectionItems(items);
            }
        } else {
            const result: any = DragNDropUtils.moveBetweenLists(
                source.droppableId === firstDropareaID ? firstSectionItems : secondSectionItems,
                destination.droppableId === firstDropareaID ? firstSectionItems : secondSectionItems,
                source.index,
                destination.index
            );
            if (source.droppableId === firstDropareaID) {
                setFirstSectionItems(result[0]);
                setSecondSectionItems(result[1]);
            } else if (source.droppableId === secondDropareaID) {
                setSecondSectionItems(result[0]);
                setFirstSectionItems(result[1]);
            }
        }

        setDragStartEvent(null);
        setDragUpdateEvent(null);
    }


    return (
        <MainContainerScrollable>
            <h1 className="mb-l">Virtualized Simple Drag-n-Drop demo:</h1>

            <DragNDropContext onDragStart={handleDragStart} onDragUpdate={handleDragUpdate} onDragEnd={handleDragEnd}>
                <div className="d-flex" style={{ height: '500px' }}>
                    <DragNDropSection dataUI={getDataUI()} className="mr-l">
                        <h2>Section 1</h2>
                        <VirtualizedDroparea dataUI={getDataUI()} dropareaID={firstDropareaID} allowedItems={[firstDropareaID, secondDropareaID]}
                            dragStartEvent={dragStartEvent}
                            dragUpdateEvent={dragUpdateEvent}
                            items={firstSectionItems}
                            itemIdKey="id"
                            itemTemplate={(item) => (
                                <>
                                    <DraggableItemActions dataUI={getDataUI()}>
                                        <DraggableItemTitle dataUI={getDataUI()}>{item.title}</DraggableItemTitle>
                                    </DraggableItemActions>
                                    <DraggableItemActions dataUI={getDataUI()}>
                                        <Tooltip title="Some text">
                                            <Button dataUI={getDataUI()} onClick={() => console.log(`Clicked item ${item.title}`)} btnType="icon" icon="fab fa-react" />
                                        </Tooltip>
                                    </DraggableItemActions>
                                </>
                            )}
                        />
                    </DragNDropSection>
                    <DragNDropSection dataUI={getDataUI()}>
                        <h2>Section 2</h2>
                        <VirtualizedDroparea dataUI={getDataUI()} dropareaID={secondDropareaID} allowedItems={[firstDropareaID, secondDropareaID]}
                            dragStartEvent={dragStartEvent}
                            dragUpdateEvent={dragUpdateEvent}
                            items={secondSectionItems}
                            itemIdKey="id"
                            itemTemplate={(item) => (
                                <>
                                    <DraggableItemActions dataUI={getDataUI()}>
                                        <DraggableItemTitle dataUI={getDataUI()}>{item.title}</DraggableItemTitle>
                                    </DraggableItemActions>
                                    <DraggableItemActions dataUI={getDataUI()}>
                                        <Tooltip title="Some text">
                                            <Button dataUI={getDataUI()} onClick={() => console.log(`Clicked item ${item.title}`)} btnType="icon" icon="fab fa-react" />
                                        </Tooltip>
                                    </DraggableItemActions>
                                </>
                            )}
                        />
                    </DragNDropSection>
                </div>
            </DragNDropContext>
        </MainContainerScrollable >
    );
};

export default VirtualDnDSimple;
