import * as React from 'react';
import {
  Grid, GridColumn, GridContainer
} from '@jkhy/vsg-loanvantage-design-system';

import products from './products.json';
import { getDataUI } from '../../helpers/helpers';

interface PageState {
  skip: number,
  take: number
}

const initialDataState: PageState = { skip: 0, take: 50 };

const KendoTable = () => {
  const [page, setPage] = React.useState<PageState>(initialDataState);

  const pageChange = (event: any) => {
    setPage(event.page);
  }

  return (
    <GridContainer dataUI={getDataUI()} style={{ maxHeight: '100%' }} className="d-flex">
      <Grid
        style={{ maxHeight: '100%' }}
        data={products.slice(page.skip, page.take + page.skip)}
        skip={page.skip}
        take={page.take}
        total={products.length}
        pageable={true}
        onPageChange={pageChange}
      >
        <GridColumn field="ProductID" />
        <GridColumn field="ProductName" title="Product Name" />
        <GridColumn field="UnitPrice" title="Unit Price" />
      </Grid>
    </GridContainer>
  );
}

export default KendoTable;