import React from 'react';
import { Link } from 'react-router-dom';
import Highlight from 'react-highlight';

const DragNDrop: React.FC = () => {

    return (
        <div>
            <h1 className="mb-s">Drag-n-Drop</h1>
            <p className="mb-s">
                The Drag-n-Drop module is exposing a set of components which handle dragging and dropping on
                relatively low level. A full configuration of those components requires state management in a parent
                component, that should manage the collections of items in each section, as well as the manipulation of those
                collections, whenever an item is moved between them.
            </p>
            <p className="mb-s">Several example pages are available, in order to show
                how to achieve complex UX scenarios with these components.
            </p>
            <p className="mb-s">
                Scrollable sections of Drag-n-Drop can be configured with <Link to="/docs/dynamic-scrollable-container">DynamicScrollableContainer</Link>.
            </p>
            <p className="mb-l">
                Aiming to avoid code duplication, the Drag-n-Drop module exports a DragNDropUtils class with
                utility methods for common operations.
            </p>

            <h2 className="mb-m">Drag-n-Drop components & utilities:</h2>
            <p className="mb-s"><strong><a href="#api-dnd-context">DragNDropContext</a></strong> - The wrapper, providing drag and drop events functionality of elements, placed within it.</p>
            <p className="mb-s"><strong><a href="#api-dnd-section">DragNDropSection</a></strong> - An element that provides consistent styling of sections, that are available for drag and drop.</p>
            <p className="mb-s"><strong>DragNDropSectionScrollable</strong> - Scrollable container element, which provides scroll when you use multiple Dropareas.</p>
            <p className="mb-s"><strong>DragNDropSectionHeader</strong> - Wrapper component for all the elements, which are in the top part of a DragNDropSection, but not in the Droparea.</p>
            <p className="mb-s"><strong><a href="#api-droparea">Droparea</a></strong> - The container, which expects elements to be dropped over it.</p>
            <p className="mb-s"><strong>
                <a href="#api-virtualized-droparea">VirtualizedDroparea</a></strong> - Alternative component,
                that supports virtualization of the Drag-n-Drop module.
                Read more about Drag-n-Drop virtualization <a href="#virtual-dnd-section">here</a>.
            </p>
            <p className="mb-s"><strong><a href="#api-draggable-item">DraggableItem</a></strong> - The component, used for elements which are to be dragged.</p>
            <p className="mb-s"><strong>DraggableItemContainer</strong> - The styled box element, contained inside the DraggableItem elements.</p>
            <p className="mb-s"><strong>DraggableItemActions</strong> - Generic container for elements inside DraggableItem. DraggableItemActions elements represent the left and right side of the DraggableItem. </p>
            <p className="mb-s"><strong>DraggableItemTitle</strong> - Simple wrapper of the styled title for DraggableItem.</p>
            <p className="mb-xl"><strong>DragNDropUtils</strong> - Non-JSX class, which exposes utility methods for common Drag-n-Drop operations.</p>


            <p>
                Example pages for various Drag-n-Drop scenarios:
            </p>
            <ul className="mb-l">
                <li><Link to="/components/dnd/">Simple example (described in the snippet below)</Link></li>
                <li><Link to="/components/dnd/presentation-report">Presentation Report Drag-n-Drop</Link></li>
                <li><Link to="/components/dnd/presentation-report-async">Presentation Report Drag-n-Drop with async data</Link></li>
                <li><Link to="/components/dnd/column-selection">Simple column selection in modal</Link></li>
                <li><Link to="/components/dnd/column-selection-tabs">Column selection in modal with tabs</Link></li>
                <li><Link to="/components/dnd/column-selection-tabs-async">Column selection in modal with tabs and async data</Link></li>
                <li><Link to="/components/dnd/process-definition-modal">Process Definition Modal with Drag-n-Drop inside pre-rendered tabs and async data</Link></li>
                <li><Link to="/demo/sba">Column selection with tabs and multiple Dropareas (SBA Attachments)</Link></li>
                <li><Link to="/components/dnd/virtual-simple">Virtualized version of the Simple example</Link></li>
                <li><Link to="/components/dnd/virtual-process-definition-modal">Virtualized version of the Process Definition Modal Drag-n-Drop</Link></li>
            </ul>

            <p className="mb-xl">
                <strong style={{ color: 'var(--color-warning)' }}>IMPORTANT:</strong> If a Virtualized Drag-n-Drop module has to 
                be rendered inside a Modal, this modal should be configured with <a href="/docs/modal">"isHeightExpanded"</a> prop set to true. This is required, due to the
                implementation of the virtual list, which might not be able to automatically expands its parent container (in this case - the Modal).
                <br />
                If the Drag-n-Drop is rendered inside Tabs, that are in a Modal, the height of the Modal should be expanded only for the given tab.
                See a demo on the page with <Link to="/components/dnd/virtual-process-definition-modal">virtualized Process Definition Modal</Link>.
            </p>


            <Highlight className="React mb-l">
                {
                    `
import React, { useState } from 'react';
import {
    MainContainerScrollable, DragNDropContext, DragNDropSection, Droparea,
    DraggableItem, DraggableItemActions, DraggableItemTitle, Button, DragNDropUtils
} from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const DnD: React.FC = () => {

    // Configuration of the parent component state: droparea IDs, items, events
    const firstDropareaID = 'first';
    const secondDropareaID = 'second';
    const [firstSectionItems, setFirstSectionItems] = useState([
        { title: 'Item 1', id: 'item-1' }, { title: 'Item 2', id: 'item-2' }
    ]);
    const [secondSectionItems, setSecondSectionItems] = useState<{ title: string, id: string }[]>([]);
    const [dragStartEvent, setDragStartEvent] = useState(null);
    const [dragUpdateEvent, setDragUpdateEvent] = useState(null);

    const handleDragStart = (event: any) => {
        setDragStartEvent(event);
        setDragUpdateEvent(null);
    };

    const handleDragUpdate = (event: any) => {
        setDragStartEvent(null);
        setDragUpdateEvent(event);
    };

    // The function, that manages the items order in both sections
    const handleDragEnd = (result: any) => {
        const { source, destination } = result;
        if (!result.destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            if (source.droppableId === firstDropareaID) {
                const items = DragNDropUtils.reorderList(
                    firstSectionItems,
                    source.index,
                    destination.index
                );
                setFirstSectionItems(items);
            } else if (source.droppableId === secondDropareaID) {
                const items = DragNDropUtils.reorderList(
                    secondSectionItems,
                    source.index,
                    destination.index
                );
                setSecondSectionItems(items);
            }
        } else {
            const result: any = DragNDropUtils.moveBetweenLists(
                source.droppableId === firstDropareaID ? firstSectionItems : secondSectionItems,
                destination.droppableId === firstDropareaID ? firstSectionItems : secondSectionItems,
                source.index,
                destination.index
            );

            if (source.droppableId === firstDropareaID) {
                setFirstSectionItems(result[0]);
                setSecondSectionItems(result[1]);
            } else if (source.droppableId === secondDropareaID) {
                setSecondSectionItems(result[0]);
                setFirstSectionItems(result[1]);
            }
        }

        setDragStartEvent(null);
        setDragUpdateEvent(null);
    }


    return (
        <MainContainerScrollable>
            <h1 className="mb-l">Simple Drag-n-Drop demo:</h1>

            <DragNDropContext onDragStart={handleDragStart} onDragUpdate={handleDragUpdate} onDragEnd={handleDragEnd}>
                {/* For the sake of the simple demo, there is a fixed height of the DragNDropSections */}
                <div className="d-flex" style={{ height: '200px' }}>
                    <DragNDropSection className="mr-l" dataUI={getDataUI()}>
                        <h2>Section 1</h2>
                        <Droparea dataUI={getDataUI()} dropareaID={firstDropareaID} allowedItems={[firstDropareaID, secondDropareaID]}
                            dragStartEvent={dragStartEvent}
                            dragUpdateEvent={dragUpdateEvent}
                        >
                            {firstSectionItems.map((item, index) => {
                                return (
                                    <DraggableItem dataUI={getDataUI()} key={item.title} index={index} draggableID={item.id}>
                                        <DraggableItemActions dataUI={getDataUI()}>
                                            <DraggableItemTitle dataUI={getDataUI()}>{item.title}</DraggableItemTitle>
                                        </DraggableItemActions>
                                        <DraggableItemActions dataUI={getDataUI()}>
                                        <Tooltip title="Some Text">
                                            <Button dataUI={getDataUI()} onClick={() => console.log(\`Clicked item \${item.title}\`)} 
                                                btnType="icon" icon="fab fa-react" />
                                        </Tooltip>
                                        </DraggableItemActions>
                                    </DraggableItem>
                                )
                            })}
                        </Droparea>
                    </DragNDropSection>
                    <DragNDropSection>
                        <h2>Section 2</h2>
                        <Droparea dataUI={getDataUI()} dropareaID={secondDropareaID} allowedItems={[firstDropareaID, secondDropareaID]}
                            dragStartEvent={dragStartEvent}
                            dragUpdateEvent={dragUpdateEvent}
                        >
                            {secondSectionItems.map((item, index) => {
                                return (
                                    <DraggableItem dataUI={getDataUI()} key={item.title} index={index} draggableID={item.id}>
                                        <DraggableItemActions dataUI={getDataUI()}>
                                            <DraggableItemTitle dataUI={getDataUI()}>{item.title}</DraggableItemTitle>
                                        </DraggableItemActions>
                                        <DraggableItemActions dataUI={getDataUI()}>
                                        <Tooltip title="Some Text">
                                            <Button dataUI={getDataUI()} onClick={() => console.log(\`Clicked item \${item.title}\`)} 
                                                btnType="icon" icon="fab fa-react" />
                                        </Tooltip>
                                        </DraggableItemActions>
                                    </DraggableItem>
                                )
                            })}
                        </Droparea>
                    </DragNDropSection>
                </div>
            </DragNDropContext>
        </MainContainerScrollable >
    );
};

export default DnD;
        `}
            </Highlight>

            <h2 className="mb-m" id="virtual-dnd-section">Virtualized Drag-n-Drop</h2>
            <p className="mb-s">
                As the number of DraggableItem elements in a given Droparea grows, the need for optimizations emerges,
                because the whole Drag-n-Drop module might become too heavy.
            </p>
            <p className="mb-s">The solution is virtualization - the "VirtualizedDroparea" serves for this purpose.</p>
            <p className="mb-xl">
                <strong style={{ color: 'var(--color-warning)' }}>IMPORTANT:</strong> The virtualized Drag-n-Drop module has slightly
                different structure and usage - "VirtulizedDroparea" does not contain any child elements and has several specific
                props, as illustrated in the example below and in the <a href="#api-virtualized-droparea">API table</a>.
            </p>

            <Highlight className="React mb-l">
                {
                    `
import React, { useState } from 'react';
import {
    MainContainerScrollable, DragNDropContext, DragNDropSection, VirtualizedDroparea,
    DraggableItemActions, DraggableItemTitle, Button, DragNDropUtils, Tooltip,
} from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const VirtualDnD: React.FC = () => {

    const firstDropareaID = 'first';
    const secondDropareaID = 'second';
    const [firstSectionItems, setFirstSectionItems] = useState(DEMO_ITEMS);
    const [secondSectionItems, setSecondSectionItems] = useState<{ title: string, id: string }[]>([]);
    const [dragStartEvent, setDragStartEvent] = useState(null);
    const [dragUpdateEvent, setDragUpdateEvent] = useState(null);

    const handleDragStart = (event: any) => {
        // No difference than the regular DnD
    };

    const handleDragUpdate = (event: any) => {
        // No difference than the regular DnD
    };

    const handleDragEnd = (result: any) => {
        // No difference than the regular DnD
    }


    return (
        <MainContainerScrollable>
            <h1 className="mb-l">Simple Drag-n-Drop demo:</h1>

            <DragNDropContext onDragStart={handleDragStart} onDragUpdate={handleDragUpdate} onDragEnd={handleDragEnd}>
                <div className="d-flex" style={{ height: '500px' }}>
                    <DragNDropSection dataUI={getDataUI()} className="mr-l">
                        <h2>Section 1</h2>
                        <VirtualizedDroparea dataUI={getDataUI()} dropareaID={firstDropareaID} allowedItems={[firstDropareaID, secondDropareaID]}
                            dragStartEvent={dragStartEvent}
                            dragUpdateEvent={dragUpdateEvent}
                            items={firstSectionItems}
                            itemIdKey="id"
                            itemTemplate={(item) => (
                                <>
                                    <DraggableItemActions dataUI={getDataUI()}>
                                        <DraggableItemTitle dataUI={getDataUI()}>{item.title}</DraggableItemTitle>
                                    </DraggableItemActions>
                                    <DraggableItemActions dataUI={getDataUI()}>
                                        <Tooltip title="Some text">
                                            <Button dataUI={getDataUI()} onClick={() => console.log(\`Clicked item \${item.title}\`)} btnType="icon" icon="fab fa-react" />
                                        </Tooltip>
                                    </DraggableItemActions>
                                </>
                            )}
                        />
                    </DragNDropSection >
                    <DragNDropSection dataUI={getDataUI()}>
                        <h2>Section 2</h2>
                        <VirtualizedDroparea dataUI={getDataUI()} dropareaID={secondDropareaID} allowedItems={[firstDropareaID, secondDropareaID]}
                            dragStartEvent={dragStartEvent}
                            dragUpdateEvent={dragUpdateEvent}
                            items={secondSectionItems}
                            itemIdKey="id"
                            itemTemplate={(item) => (
                                <>
                                    <DraggableItemActions dataUI={getDataUI()}>
                                        <DraggableItemTitle dataUI={getDataUI()}>{item.title}</DraggableItemTitle>
                                    </DraggableItemActions>
                                    <DraggableItemActions dataUI={getDataUI()}>
                                        <Tooltip title="Some text">
                                            <Button dataUI={getDataUI()} onClick={() => console.log(\`Clicked item \${item.title}\`)} btnType="icon" icon="fab fa-react" />
                                        </Tooltip>
                                    </DraggableItemActions>
                                </>
                            )}
                        />
                    </DragNDropSection>
                </div >
            </DragNDropContext>
        </MainContainerScrollable>
    );
};
export default DnD;
`}
            </Highlight>

            <h2 className="mb-m">API</h2>

            <h3 id="api-dnd-context">DragNDropContext</h3>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>onDragStart</td>
                        <td>(event) {'=>'} void</td>
                        <td className="text-center">-</td>
                        <td>
                            Callback for when a drag of DraggableItem inside the DragNDropContext has
                            been started. All the logic encompassing the returned event is encapsulated
                            in the Droparea component, therefore most often this callback should serve only as a way
                            to pass the event object to <strong>all Dropareas in the given context</strong>.
                        </td>
                    </tr>
                    <tr>
                        <td>onDragUpdate</td>
                        <td>(event) {'=>'} void</td>
                        <td className="text-center">-</td>
                        <td>Callback for when a DraggableItem is being dragged around the DragNDropContext. All the logic encompassing the
                            returned event is encapsulated in the Droparea component, therefore most often this callback
                            should serve only as a way to pass the event object to <strong>all Dropareas in the given context</strong>.</td>
                    </tr>
                    <tr>
                        <td>onDragEnd</td>
                        <td>(event) {'=>'} void</td>
                        <td className="text-center">-</td>
                        <td>Callback for when a DraggableItem is dropped. Compared to the other two events, this one
                            is the point where all the state management of items should be done. The Droparea component <strong>does
                                not require the result of this callback</strong>, but it is important to <strong>set to null the other two events</strong>.
                            These specific API requirements are required to provide smooth and convenient Drag-n-Drop experience,
                            by encapsulating low-level logic between the components.
                        </td>
                    </tr>
                </tbody>
            </table>

            <h3 id="api-dnd-section">DragNDropSection</h3>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>hasMultipleDropareas</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Set it when you have multiple Dropareas. This will set proper styles.</td>
                    </tr>
                </tbody>
            </table>

            <h3 id="api-droparea">Droparea</h3>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>dropareaID</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>
                            The unique identifier of each Droparea component. There should be <strong>no duplication</strong> between
                            the IDs of Dropareas in a single DragNDropContext.
                        </td>
                    </tr>
                    <tr>
                        <td>allowedItems</td>
                        <td>string []</td>
                        <td className="text-center">-</td>
                        <td>An array with the IDs of all Dropareas, the items of which can be dropped in the given Droparea. In order to allow
                            drag-n-drop inside a given Droparea, i.e to make it "sortable", the dropareaID value should be present
                            in the allowedItems array.
                        </td>
                    </tr>
                    <tr>
                        <td>dragStartEvent</td>
                        <td>{'{ source: { droppableId : string, index:string}, draggableId:string }'}</td>
                        <td className="text-center">-</td>
                        <td>
                            A property that accepts the event object, returned by
                            the onDragStart event of DragNDropContext. It is <strong>not assumed
                                that the object will be modified at any point</strong>, therefore the object
                            type shown here is only for reference - the Droparea will handle all the necessary
                            operations with that object.
                        </td>
                    </tr>
                    <tr>
                        <td>dragUpdateEvent</td>
                        <td>{'{source: { droppableId : string, index:string}, destination: { droppableId:string, index:string} }'}</td>
                        <td className="text-center">-</td>
                        <td>
                            A property that accepts the event object, returned by
                            the onDragUpdate event of DragNDropContext. It is <strong>not assumed
                                that the object will be modified at any point</strong>, therefore the object
                            type shown here is only for reference - the Droparea will handle all the necessary
                            operations with that object.
                        </td>
                    </tr>
                    <tr>
                        <td>callToActionText</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>
                            The text, shown below the items in a given Droparea, designed
                            to indicate the items should be dropped.
                        </td>
                    </tr>
                    <tr>
                        <td>title</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set title.</td>
                    </tr>
                    <tr>
                        <td>stopScrollable</td>
                        <td>boolean</td>
                        <td className="text-center">-</td>
                        <td>Stops the scrolling of the Droparea. The common use-case for this behaviour is when multiple Dropareas are used in a single DragNDropSection.</td>
                    </tr>
                </tbody>
            </table>

            <h3 id="api-virtualized-droparea">VirtualizedDroparea</h3>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>dropareaID</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>
                            The unique identifier of each VirualizedDroparea component. There should be <strong>no duplication</strong> between
                            the IDs of VirualizedDropareas in a single DragNDropContext.
                        </td>
                    </tr>
                    <tr>
                        <td>allowedItems</td>
                        <td>string []</td>
                        <td className="text-center">-</td>
                        <td>An array with the IDs of all VirualizedDropareas, the items of which can be dropped in the given VirualizedDroparea. In order to allow
                            drag-n-drop inside a given VirualizedDroparea, i.e to make it "sortable", the dropareaID value should be present
                            in the allowedItems array.
                        </td>
                    </tr>
                    <tr>
                        <td>dragStartEvent</td>
                        <td>{'{ source: { droppableId : string, index:string}, draggableId:string }'}</td>
                        <td className="text-center">-</td>
                        <td>
                            A property that accepts the event object, returned by
                            the onDragStart event of DragNDropContext. It is <strong>not assumed
                                that the object will be modified at any point</strong>, therefore the object
                            type shown here is only for reference - the VirualizedDroparea will handle all the necessary
                            operations with that object.
                        </td>
                    </tr>
                    <tr>
                        <td>dragUpdateEvent</td>
                        <td>{'{source: { droppableId : string, index:string}, destination: { droppableId:string, index:string} }'}</td>
                        <td className="text-center">-</td>
                        <td>
                            A property that accepts the event object, returned by
                            the onDragUpdate event of DragNDropContext. It is <strong>not assumed
                                that the object will be modified at any point</strong>, therefore the object
                            type shown here is only for reference - the VirualizedDroparea will handle all the necessary
                            operations with that object.
                        </td>
                    </tr>
                    <tr>
                        <td>title</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set title.</td>
                    </tr>
                    <tr>
                        <td>stopScrollable</td>
                        <td>boolean</td>
                        <td className="text-center">-</td>
                        <td>Stops the scrolling of the VirualizedDroparea. The common use-case for this behaviour is when multiple VirualizedDropareas are used in a single DragNDropSection.</td>
                    </tr>
                    <tr>
                        <td>items</td>
                        <td>{'T[]'}</td>
                        <td className="text-center">-</td>
                        <td>List of objects, that will be rendered as virtual DraggableItem elements.</td>
                    </tr>
                    <tr>
                        <td>itemIdKey</td>
                        <td>{'keyof T'}</td>
                        <td className="text-center">-</td>
                        <td>Defines the unique key, that will be used as an identifier for draggable items
                            inside the virtual list.
                        </td>
                    </tr>
                    <tr>
                        <td>itemTemplate</td>
                        <td>{'(item: T) => React.ReactNode'}</td>
                        <td className="text-center">-</td>
                        <td>Defines the layout of each DraggableItem inside the VirtualizedDroparea.</td>
                    </tr>
                </tbody>
            </table>

            <h3 id="api-draggable-item">DraggableItem</h3>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>draggableID</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>
                            The unique identifier of each DraggableItem component. There should be <strong>no duplication</strong> between
                            the IDs of DraggableItems in a single DragNDropContext.
                        </td>
                    </tr>
                    <tr>
                        <td>index</td>
                        <td>number</td>
                        <td className="text-center">-</td>
                        <td>Numeric index that indicates the position of each item inside its current Droparea.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
};

export default DragNDrop;
